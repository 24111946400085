import { ButtonProps } from '@material-ui/core';
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, ReactNode } from 'react';
import * as S from './styles';

type ButtonTypes = AnchorHTMLAttributes<HTMLAnchorElement> | ButtonHTMLAttributes<HTMLButtonElement>;

type ErrorButtonProps = {
    children: ReactNode;
} & ButtonTypes & ButtonProps; 

export default function ErrorButton({ children, ...props }: ErrorButtonProps) {
    return (
        <S.MuiButton {...props}>
            { children }
        </S.MuiButton>
    )
}
