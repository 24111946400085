import React from 'react';

import WithLayout from 'WithLayout';
import { Main } from 'layouts';
import { Explore } from 'views';

export default function CollectionPage (): JSX.Element {
    document.title = "Explore";
    
    return <WithLayout component={Explore} layout={Main} />;
}