import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import { transferTokenAction } from 'reducer/async/actions';
import { useDispatch } from 'reducer';

import { ContentModal } from 'views/CreateNFT/components';
import ErrorButton from 'components/atoms/ErrorButton';

interface BurnTokenProps {
    contract: string;
    tokenId: number;
    open: boolean;
    handleClose: () => void;
}

export default function BurnToken({ contract, tokenId, open, handleClose }: BurnTokenProps) {
    const [openContentModal, setOpenContentModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    function handleCloseModal() {
        setOpenContentModal(false);
        handleClose();
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Burn Token</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure about burning this token? This action is irreversible.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <ErrorButton variant="contained" onClick={handleClose} autoFocus>
                        No
                    </ErrorButton>

                    <Button variant='contained' color="primary" onClick={() => { setOpenContentModal(true); handleClose() }}>Burn token</Button>                
                </DialogActions>
            </Dialog>

            <ContentModal 
                isOpen={openContentModal} 
                method="transferToken"
                dispatchThunk={() => dispatch(transferTokenAction({ contract, tokenId, to: 'tz1burnburnburnburnburnburnburjAYjjX' }))}
                onClose={() => handleCloseModal()}
                dispatchOnOpen={true}	 
                pendingAsyncMessage="Burning token..." 
            />
        </>
    );
}
