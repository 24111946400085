import styled from 'styled-components';

import { Box, Grid } from '@material-ui/core';

export const CounterItem = styled(Grid)`
    text-align: center;
    z-index: 1;
    color: #fff;
    
    & > i {
        display: block;
        font-size: 40px;        
    }

    span {
        font-size: 60px;
        font-weight: 400;
        display: block;        
    }
    
    h4 {
        display: block;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 16px;        
    }
`;

export const CounterLabel = styled.h4`
    font-family: 'mathias';
`;

export const Wrapper = styled(Box)`
	box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
`;
