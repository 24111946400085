import React from 'react';
import moment from 'moment';
import { Typography, Box, Grid, Link as MLink } from '@material-ui/core';
import { Link } from 'wouter';
import styled, { css } from 'styled-components';

import { Section } from 'components/organisms';

import * as S from './styles';

const MuiGrid = styled(Grid)`
    ${({ theme }) => css`
        background-color: ${theme.palette.topbar};
        padding: ${theme.spacing(2)}px;
    `}
`;

export type Transaction = {
    id: string;
    timestamp: string;
    sender: {
        address: string;
    };
    target: {
        address: string;
    };
    parameter: {
        entrypoint: string;
        value: {
            price: string;
            amount: string;
        }
    }
}

export default function History({ transactions }: ViewComponentProps) {
    return (
        <S.Wrapper>
            <Section>
                <>
                    <MuiGrid container>
                        <Grid item xs={2} md={2} sm={2}>
                            <Box mb={2}>
                                <Typography>
                                    <strong>Event</strong><br />
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={4} md={4} sm={4}>
                            <Box mb={2}>
                                <Typography>
                                    <strong>From/To</strong><br />
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={2} md={2} sm={2}>
                            <Box mb={2}>
                                <Typography>
                                    <strong>Price (ꜩ)</strong><br />
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={4} md={4} sm={4}>
                            <Box mb={2}>
                                <Typography>
                                    <strong>Time</strong><br />
                                </Typography>
                            </Box>
                        </Grid>
                    </MuiGrid>

                    {transactions && transactions.map((transaction: Transaction) => (
                        <S.RowGrid container key={transaction.id}>
                            <Grid item xs={2} md={2} sm={2} key={transaction.id} >
                                <Box mb={2}>
                                    <Typography>
                                        {transaction && transaction.parameter.entrypoint}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={4} md={4} sm={4}  >
                                <Box mb={2} display="flex" flexDirection="column">
                                    <S.UserLink href={`/creator/${transaction.sender.address}`}>
                                        <Typography color="textPrimary">
                                            {transaction && (transaction.sender.address).slice(0, 5) + '...' + (transaction.sender.address).slice(-5)}
                                        </Typography>
                                    </S.UserLink>

                                    <S.UserLink href={`/creator/${transaction.target.address}`}>
                                        <Typography color="textPrimary">                                        
                                            {transaction && (transaction.target.address).slice(0, 5) + '...' + (transaction.target.address).slice(-5)}
                                        </Typography>
                                    </S.UserLink>
                                </Box>
                            </Grid>

                            <Grid item xs={2} md={2} sm={2}  >
                                <Box mb={2}>
                                    <Typography>
                                        {transaction && isNaN(Number(transaction.parameter.value.price) / 1000000) ? 0 : Number(transaction.parameter.value.price) / 1000000}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={4} md={4} sm={4}  >
                                <Box mb={2}>
                                    <Typography>
                                        {
                                            moment().diff(moment(transaction.timestamp), 'minutes') < 60 ?
                                                `${Math.floor(moment().diff(moment(transaction.timestamp), 'minutes'))} minute(s) ago` :
                                                (moment().diff(moment(transaction.timestamp), 'minutes') / 60 < 24 ?
                                                    `${Math.floor(moment().diff(moment(transaction.timestamp), 'hours'))} hour(s) ago` :
                                                    `${Math.floor(moment().diff(moment(transaction.timestamp), 'days'))} day(s) ago`)
                                        }
                                    </Typography>
                                </Box>
                            </Grid>
                        </S.RowGrid>
                    ))}
                </>
            </Section>
        </S.Wrapper>
    )
}