import React, { useState } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import { createAlchemyWeb3, Nft } from '@alch/alchemy-web3';

// import { ExternalProvider } from ''

import { ethers } from 'ethers';

import { SectionHeader } from "components/molecules";
import { CardBase, SectionAlternate } from "components/organisms";
import useAuth from 'hooks/auth';

import * as S from './styles';

declare global {
	interface Window { 
        ethereum: any; 
    }
}

export default function Wrapper(): JSX.Element {
    const { user } = useAuth();

    const [nfts, setNfts] = useState<Nft[]>([]);

    async function handleGetOwnedNfts() {
        if (window.ethereum && user) {
            const owner = user.ethereumWallet!;

            // const provider = new ethers.providers.Web3Provider(window.ethereum);
            // const signer = provider.getSigner();
            // const address = await signer.getAddress();
    
            // const etherScanProvider = new ethers.providers.EtherscanProvider(4, "C12XZF7SK5Z2IXGGDFDZTXA8ZUQWMGVKYJ");
            // // console.log({ etherScanProvider });
    
            // const history = await etherScanProvider.getHistory(address);        
            // console.log({ history });
    
            // // history?.map(transaction => {
            // //     console.log({ transaction });
            // // });
    
            // const balance = ethers.utils.formatEther(await provider.getBalance(address));
    
            // // const signature = await signer.signMessage(message);
            // // setSignature(signature);
    
            // console.log({ provider, signer, balance });

            const alchemyWeb3Provider = createAlchemyWeb3('https://eth-rinkeby.alchemyapi.io/v2/HCAFadnuYUpLeKE_JODyTWlfkXzwlc98');
            const { ownedNfts } = await alchemyWeb3Provider.alchemy.getNfts({ owner });
            console.log({ ownedNfts });
            setNfts(ownedNfts);
    
        }
    }

    function handleImageUrl(imageUrl: string) {
        if (imageUrl.includes('ipfs')) {
            return `https://gateway.thirdweb.dev/ipfs/${imageUrl.replace('ipfs://', '')}`;
        }

        return imageUrl;
    }

    return (
        <SectionAlternate>
        <>
            <Button 
                variant="contained" 
                type="button" 
                color="primary" 
                size="large"
                onClick={handleGetOwnedNfts}
            >
                Get NFTs from Ethereum Wallet
            </Button>

            <S.Wrapper>
                <S.Container>
                    <S.GridContainer>
                    { nfts?.map(nft => (
                        <S.GridCell>
                            <S.AssetLoaded>
                                <S.Article>
                                    <S.AssetAnchor>
                                        <S.AssetContainer>
                                            <img src={handleImageUrl(nft?.metadata?.image || 'https://via.placeholder.com/300')} alt="" />
                                        </S.AssetContainer>
                                    </S.AssetAnchor>
                                </S.Article>
                            </S.AssetLoaded>
                        </S.GridCell>
                    ))}
                    </S.GridContainer>
                </S.Container>
            </S.Wrapper>
        </>
        </SectionAlternate>
    );
}