import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
`;

export const TagContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    padding: 10px;

    border: 1px solid;
    border-color: inherit;
    border-radius: 2px;
`;

export const Tag = styled.div`
    display: flex;
    align-items: center;

    padding: 4px 8px;
    border: 1px solid #ccc;
    margin: 5px;

    border-radius: 12px;
    background: #f2f2f2;

    svg {
        font-size: 16px;
        margin-left: 5px;

        &:hover {
            cursor: pointer;
        }
    }
`;

export const TagInput = styled.input`
    flex: 1;
    font-size: 16px;
    padding: 5px;
    outline: none;
    border: 0;

    padding-left: 8px;
`;
