import styled, { css } from 'styled-components';

import { Button } from '../styles';

interface ButtonProps {
    onClick?: () => void;
}

export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;

        iframe {
            width: 100%;
            height: calc(100vh - 300px);
            min-width: 300px;
            max-height: 100vh;
            border-radius: 0px;
            border: 0px solid white;
            display: flex;
            margin-top: 0px;  
            
            ${theme.breakpoints.down('sm')} {
                height: 60vh;
                
                #body {
                    overflow-y: auto !important;
                }                
            }
        }    
    `}
`;

export const MintButton = styled(Button)<ButtonProps>`
    ${({ theme }) => css`
        padding: 15px 0;
        font-weight: 500;
        text-transform: uppercase;

        margin: 36px 0;
        width: 400px;    

        ${theme.breakpoints.down('sm')} {
            width: calc(100vw - 80px)
        }
    `}
`;

export const ButtonContainer = styled.div`
    ${({ theme }) => css`
        button {
            padding: 15px 0;
            font-weight: 500;
            text-transform: uppercase;
    
            margin: 36px 0;
            width: 400px;    
    
            ${theme.breakpoints.down('sm')} {
                width: calc(100vw - 80px)
            }
        }
    `}
`;