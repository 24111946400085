import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, colors, Divider } from '@material-ui/core';

import { IconAlternate } from 'components/molecules';
import { Section } from 'components/organisms';

import { Hero, About, Features } from './components';

import { features, services } from './data';
import { FaChevronDown } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
	promoSection: {
    width: '100%',
		background: theme.palette.alternate.main,
		padding: theme.spacing(6, 2),
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(12, 2),
		},
	},
  shape: {
    background: theme.palette.alternate.main,
    borderBottomRightRadius: '100%',
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
  hero: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(-9),
    },
  },
  fullHero: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  fullHeight: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disablePaddingTop: {
    paddingTop: 0,
  },
  scrollIconDiv: {
    padding: theme.spacing(2),
    borderRadius: '50%',
    background: 'transparent !important',
    border: `2px solid ${colors.amber[500]}`,
    cursor: 'pointer',
  },
  iconPosition: {
    position: 'absolute',
    bottom: 50,
  },
  scrollIcon: {
    background: 'transparent !important',
    border: `2px solid ${colors.amber[500]}`,
    cursor: 'pointer',
  },
  scrollTopIcon: {
    margin: '0 auto',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(12),
    },
  },
  adidasSection: {
    backgroundColor: '#003c0580',
  },
  larqSection: {
    backgroundColor: '#2d402f80',
  },
  nikeSection: {
    backgroundColor: '#5f3f3f80',
  },
}));

const HomeView = (): JSX.Element => {
  const classes = useStyles();

  const scrollTo = (id: string): void => {
    setTimeout(() => {
      const element: any = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.fullHero} id="home">
        <Hero className={classes.hero} />

        <Box className={clsx(classes.scrollIconDiv, classes.iconPosition)} onClick={() => scrollTo('minter-about')}>
          <FaChevronDown />
        </Box>
      </div>

      <Box className={classes.shape} id="minter-about" >
        <div className={clsx(classes.fullHeight)}>
          <Section>
            <About data={services} />
          </Section>

          <Box className={clsx(classes.scrollIconDiv)} onClick={() => scrollTo('minter-features')} data-aos="fade-up">
            <FaChevronDown />
          </Box>
        </div>
      </Box>

      <div className={classes.fullHero} id="minter-features">
        <Section>
          <Features data={features} />
        </Section>
      </div>

      <IconAlternate
        shape="circle"
        fontIconClass="fas fa-chevron-up"
        color={colors.amber}
        size="small"
        className={clsx(classes.scrollIcon, classes.scrollTopIcon)}
        onClick={() => scrollTo('home')}
        data-aos="fade-up"
      />

      <Divider />
    </div>
  );
};

export default HomeView;
