import React, { MouseEvent, useState } from 'react';
import { Share as ShareIcon, Visibility as VisibiltyIcon } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, colors, Grid, Card, CardContent, Typography, Divider, List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton } from '@material-ui/core';

import APP_CONFIG from 'config/app_config';

import { useDarkMode } from 'WithLayout';
import { Link } from 'wouter';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
	card: {
		boxShadow: '0 7px 14px 0 rgba(0, 0, 0, 0.1)',
	},
	icon: {
		borderRadius: 0,
	},
	listItemAvatar: {
		minWidth: 'auto',
		marginRight: theme.spacing(2),
	},	
	cardMedia: {
		minHeight: 280,
		position: 'relative',
		display: 'flex',
		alignItems: 'flex-end',
	},
	pricingContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: theme.spacing(3),
		width: '100%',
	},
	cardCta: {
		padding: theme.spacing(1 / 2, 1),
		background: theme.palette.background.paper,
		borderRadius: theme.spacing(1 / 2),
	},
	listItem: {
		padding: 0,
	},
	listItemShare: {
		padding: 0,
		justifyContent: 'flex-end',
	},
	listItemIcon: {
		minWidth: theme.spacing(3),
		'& i': {
			color: colors.blueGrey[500],
		},
	},
	fontWeight500: {
		fontWeight: 500,
	},
	propertyList: {
		display: 'flex',
	},
	pin: {
		color: `${colors.deepOrange[500]} !important`,
	},
	divider: {
		margin: theme.spacing(3, 0, 2, 0),
	},
}));

const Orbs = ({ data, className, orbsTitle, ...rest }: ViewComponentProps): JSX.Element => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
	  setAnchorEl(event.currentTarget);
	};
	
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	const [themeMode] = useDarkMode();

	return (
		<S.Wrapper className={className} themeMode={themeMode} {...rest}>
			{/* <SectionHeader title="Featured Orbs" data-aos="fade-up" /> */}
			
			<Grid container spacing={isMd ? 4 : 2}>
			{data.map((orb: Orbs, index: number) => (
				<Grid item xs={12} sm={6} md={4} key={index} data-aos="fade-up">
					<Card className={classes.card}>
						<Link href={`/t/${orb.user?.uid}/${orb.id}/${orb.url_slug}`}>
							<a>
								<S.MuiCardMedia>
									<img src={`${APP_CONFIG.BUCKET_URL_ROOT}${orb.thumbnail_url}`} alt={orb.url_slug} />
								</S.MuiCardMedia>
							</a>
						</Link>

						<CardContent>
							<List disablePadding>
								<ListItem disableGutters>
									<ListItemAvatar className={classes.listItemAvatar}>
										<Avatar src={orb.user.photo_url} className={classes.icon} />
									</ListItemAvatar>

									<Link href={`/user/${orb.user?.uid}`}>
										<a>
											<ListItemText primary={orb.user.display_name} secondary={new Date(orb.created_date).toDateString()} />
										</a>
									</Link>
								</ListItem>
							</List>

							<Typography color="textPrimary" variant="subtitle1" className={classes.fontWeight500}>
								{orb.name}
							</Typography>

							<Divider className={classes.divider} />

							<Grid container>
								<Grid item xs={6}>
									<List disablePadding>
										<ListItem disableGutters className={classes.listItemShare}>
											<IconButton onClick={handleClick}>
												<VisibiltyIcon />
											</IconButton>

											<ListItemText primary={orb?.page_views || 0} />
										</ListItem>
									</List>
								</Grid>

								<Grid item xs={6}>
									<List disablePadding className={classes.propertyList}>
										<ListItem disableGutters className={classes.listItemShare}>
											<IconButton aria-label="share to your favorite social media" onClick={handleClick}>
												<ShareIcon />
											</IconButton>
										</ListItem>
									</List>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			))}
			</Grid>
		</S.Wrapper>
	);
};

export default Orbs;
