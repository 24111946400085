import { Box } from "@material-ui/core";
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    img, video, iframe, model-viewer {
        border-radius: 16px;
        width: 100%;
    }

    ${({ theme }) => css`
        ${theme.breakpoints.up(992)} {
            &, .section {
                height: 100%;
                padding-right: 0;
            }

            .section > div {
                justify-content: flex-end;
            }

            &:not(:fullscreen) img, video {
                min-height: auto;
                /* object-fit: cover; */
            }
            
            iframe, model-viewer {
                min-height: 100%;
            }
        }

        ${theme.breakpoints.down(110)} {
            image-rendering, video, iframe, model-viewer {
                min-width: 450px;
            }
        }

        ${theme.breakpoints.down(450)} {
            img, video, iframe, model-viewer {
                /* object-fit: cover; */
                min-width: 350px;
                min-height: 300px;
            }

            .section > div {
                justify-content: center;
            }
        }
    `} 

    :fullscreen img, video {
        object-fit: contain !important;
    }
`;

export const BoxExpand = styled(Box)`
    ${({ theme }) => css`
        display: none;
        background-color: #0000003d;
        padding: 8px;
        border-radius: 50%;
        
        position: absolute;
        right: 10px;
        bottom: 10px;    
    `}
`;

export const BoxContainer = styled(Box)`
    display: flex;
    position: relative;
    height: 100%;

    &:hover {
        ${BoxExpand} {
            display: block;
            transition: ease-in;
        }
    }
`;
