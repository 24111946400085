import React, { useState, useEffect, ChangeEvent } from 'react';
import { useLocation } from 'wouter';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tabs as TabComponent, Tab, Box, Typography } from '@material-ui/core';

import { findByCreatorOwnerOrSeller, findForSaleTokensByWallet, findPurchasedTokensByWallet } from 'services/users';
import { findContractsByUserId, findCustomContracts } from 'services/custom-contracts';

import { getUrlQueryParams } from 'utils';

import BaseToken from 'templates/BaseToken';
import { TabPanel } from 'components/atoms';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  sectionAlternate: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    '& .section-alternate__content': {
    },
  },
  cardProduct: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: theme.spacing(1),
    '& .card-product__content': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',

      '& .card-product__content': {
        padding: theme.spacing(1)
      }
    }
  },
  image: {
    objectFit: 'cover',
    height: 300,
    [theme.breakpoints.down('sm')]: {
      height: 150,
      width: '100%'
    }
  },
  blogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'start',
      flexDirection: 'column',
    }
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    }
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  button: {
    minWidth: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 420,
    },
  },
  tabRoot: {
    width: '100%',
  },
  tab: {
    marginBottom: theme.spacing(3),
    width: 'fit-content',

    '& .MuiTab-textColorPrimary': {
      color: theme.palette.text.secondary,
    },

    '& .Mui-selected': {
      backgroundColor: 'transparent',
      fontWeight: 'bolder',
      color: theme.palette.text.primary,
      borderRadius: 'unset',
    },

    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.text.primary,
    }
  },
}));

interface CreatorDisplayProps {
  minter: string;
  ownedOnly?: boolean;
}

const Result = ({ minter, ownedOnly = true, className }: ViewComponentProps & CreatorDisplayProps): JSX.Element => {
  const classes = useStyles();

  const [location, setLocation] = useLocation();
  const [pageId, setPageId] = useState(0);

  const pageNumber = Number(getUrlQueryParams()['page']) || 1;
  const limit = 12, filter: Filter = { limit, offset: (pageNumber - 1) * limit, orderBy: 'tokenId', descending: true };

  useEffect(() => {
  }, [minter]);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    if (window.parent) {
      window.parent.postMessage("hello there!", "http://localhost:5000");
    }

    setLocation(`${location}`);
    setPageId(newValue);
  };

  return (
    <>
      <S.GridTab item xs={12}>
        <TabComponent variant="scrollable" textColor="primary" indicatorColor="primary" scrollButtons="auto" value={pageId} onChange={handleChange} className={classes.tab}>
          <Tab label="Creations" id="creations" aria-controls="creations" />
          <Tab label="For Sale" id="forSale" aria-controls="forSale" />
          <Tab label="Purchased" id="purchased" aria-controls="purchased" />
          <Tab label="Owned" id="owned" aria-controls="owned" />
          <Tab label="Contracts" id="contracts" aria-controls="contracts" />
        </TabComponent>
      </S.GridTab>

      <Grid item xs={12}>
        <TabPanel value={pageId} index={0}>
          <BaseToken 
            handleChange={async () => await findByCreatorOwnerOrSeller('creator', filter, minter)}
            handleChangeProps={{field: 'creator', filter, minter}}
            limit={limit}
            filter={filter}
            pageNumber={pageNumber}
          />
        </TabPanel>

        <TabPanel value={pageId} index={1}>
          <BaseToken 
            handleChange={async () => await findForSaleTokensByWallet(filter, minter)}
            handleChangeProps={{filter, minter}}
            limit={limit}
            filter={filter}
            pageNumber={pageNumber}
          />
        </TabPanel>

        <TabPanel value={pageId} index={2}>
          <BaseToken 
            handleChange={async () => await findPurchasedTokensByWallet(filter, minter)}
            handleChangeProps={{filter, minter}}
            limit={limit}
            filter={filter}
            pageNumber={pageNumber}
          />
        </TabPanel>

        <TabPanel value={pageId} index={3}>
          <BaseToken 
            handleChange={async () => await findByCreatorOwnerOrSeller('owner', filter, minter)}
            handleChangeProps={{field: 'owner', filter, minter}}
            limit={limit}
            filter={filter}
            pageNumber={pageNumber}
          />
        </TabPanel>

        <TabPanel value={pageId} index={4}>
          <BaseToken 
            handleChange={async () => await findContractsByUserId(minter, filter)}
            handleChangeProps={{userId: minter, filter}}
            limit={limit}
            filter={filter}
            pageNumber={pageNumber}
            hasUrl
          />
        </TabPanel>
      </Grid>
    </>
  );
};

export default Result;