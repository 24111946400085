import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

import * as S from './style';

const Story = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	return (
		<S.Container className={className} {...rest}>
			<Grid container spacing={isMd ? 4 : 2}>
				<Grid item xs={12}>
					<SectionHeader
						title="Add Ownership Verification to your Interactive NFT"
						align="left"
						disableGutter
						subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
					/>

					<Typography>
						<br/>
						Add ownership verification to your Interactive NFT, enabling such use cases as:
						<S.List>
							<li>Show/hide content</li>
							<li>Unlock additional functionality</li>
						</S.List>
						<br/>
						Ownership verification can be added in a few easy steps:
						<S.List>
							<li>implement the function handleTokenOwnershipValidated() as described below</li>
							<li>
								<a href="/downloads/minterverse.js.zip" target="_blank" rel="noopener noreferrer">
									<strong>Download and unzip minterverse.js file</strong>
								</a>
							</li>
							<li>
								include minterverse.js in HTML of the interactive NFT.
								(NOTE: this should be the last javascript file included in your HTML, or at least after the file where handleTokenOwnershipValidated() is defined
							</li>
						</S.List>
						<br/>
						<strong>handleTokenOwnershipValidated Function</strong>
						<br/><br/>
						As show below, handleTokenOwnershipValidated() takes a single boolean parameter
						indicating whether the token is owned by the current user.
						Inside this function should be the logic to show/hide content or functionality based on token ownership.
						<br/><br/>
						<pre>
							<code>
								{'function handleTokenOwnershipValidated(tokenOwnershipValidated) {\n' +
								'\tif(tokenOwnershipValidated){\n' +
								'\t\t// code goes here\n' +
								'\t}\n' +
								'}'}
							</code>
						</pre>
						<br/>
						<br/>
						<strong>SampleProjects</strong>
						<br/><br/>
						<a href={'https://github.com/orbix360/nft-examples/tree/main/ownership-validation/hello-world'} style={{ textDecoration: 'underline' }}>Hello World</a> -
						A simple project that demonstrates verifying token ownership and showing/hiding content based on token ownership.
					</Typography>
				</Grid>




			</Grid>
		</S.Container>
	);
};

export default Story;
