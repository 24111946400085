function replacedRoyaltiesValue(value: string) {
    return value
        .toString()
        .replace(/[^0-9\.]+/g, '')
        .replace(/[R$.]*/g, '')
        .replace(',', '.')
        .trim();
}
    
function formatValueWithDecimals(value: number, fractionDigits: number = 2) {
    return Number(((Math.round((value / 100) * 100) / 100) / Math.pow(10, fractionDigits - 2)).toFixed(fractionDigits));
}

function formatValue(value: number, fractionDigits: number = 2) {
    return new Intl.NumberFormat('en-EN', {
        minimumFractionDigits: fractionDigits,
    }).format(value);
}

export default function formatNumber(value: number | string, fractionDigits: number = 2) {
    const replaced = replacedRoyaltiesValue(value.toString());
    const formattedValue = formatValueWithDecimals(Number(replacedRoyaltiesValue(replaced)), fractionDigits);

    if (isNaN(formattedValue)) {
        return false;
    }

    return formatValue(formattedValue, fractionDigits);
}