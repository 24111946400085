import React, { ChangeEvent, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, TextField, Button, Divider, Box, Tooltip, IconButton, CircularProgress, Link } from '@material-ui/core';

import { MdHelpOutline } from 'react-icons/md';
import { Twitter as TwitterIcon } from '@material-ui/icons';

import { useSelector } from 'reducer';
import useAuth from 'hooks/auth';

import api from 'services/api';
import { getUserByWallet, updateUserFiles } from 'services/users';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
	inputTitle: {
		fontWeight: 700,
		marginBottom: theme.spacing(1),
	},
	twitterName: {
		color: 'blue',
		fontWeight: 700,
		marginBottom: theme.spacing(1),
	},
	icon: {
		fontSize: 24,
		color: theme.palette.text.primary,
	},
}));

const General = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
	const classes = useStyles();
	const system = useSelector(s => s.system);
	const { user, setUser } = useAuth();

	const wallet = system.tzPublicKey!;

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isCoverLoading, setIsCoverLoading] = useState<boolean>(false);
    const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(false);

	const [bio, setBio] = useState(user?.bio || '');
	const [displayName, setDisplayName] = useState(user?.displayName || '');
	const [instagram, setInstagram] = useState(user?.instagram || '');
	const [twitter, setTwitter] = useState(user?.twitter || '');		
	const [username, setUsername] = useState(user?.username || '');
	const [website, setWebsite] = useState(user?.website || '');

	const [avatar, setAvatar] = useState(user?.avatarUrl || '');
	const [cover, setCover] = useState(user?.coverImageUrl || '');

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	useEffect(() => {
		if (wallet) {
			(async () => {
				setIsLoading(true);

                const user = await getUserByWallet(wallet);

				if (user) {
					setUsername(user?.username);
					setBio(user?.bio);
					setDisplayName(user?.displayName);
					setWebsite(user?.website);
					setInstagram(user?.instagram);
					setTwitter(user?.twitter);
					setAvatar(user?.avatarUrl);
					setCover(user?.coverImageUrl);

					setUser(user);
				}

				setIsLoading(false);
			})();
		}
	}, [system.tzPublicKey]);

	async function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const file = event.target.files;
		const fileData = new FormData();

		if (file) {
			setIsAvatarLoading(true);

			fileData.append('field', 'avatar');
			fileData.append('file', file[0]);

			try {
                const { error, object, message } = await updateUserFiles(fileData, wallet);

				if (error) {
					alert(message);	
					return false;				
				}

				Object.assign(user, object);

				setAvatar(object.avatarUrl);				
				setUser(user);
				setIsAvatarLoading(false);
			} catch (error) {
				console.log({ error });
				setIsAvatarLoading(false);

				alert("Sorry, error on uploading file");
			}
		}
	}

	async function handleCoverChange(event: ChangeEvent<HTMLInputElement>) {
		const file = event.target.files;
		const fileData = new FormData();

		if (file) {
			setIsCoverLoading(true);

			fileData.append('field', 'cover');
			fileData.append('file', file[0]);

			try {
                const { error, object, message } = await updateUserFiles(fileData, wallet);

				if (error) {
					alert(message);	
					return false;				
				}
				
				Object.assign(user, object);

				setCover(object.coverImageUrl);				
				setUser(user);
				setIsCoverLoading(false);
			} catch (error) {
				console.log({ error });

				setIsCoverLoading(false);
				alert("Sorry, error on uploading file");
			}
		}
	}

	async function handleUpdate() {
		setIsLoading(true);

		try {

			const { data: { error, object }} = await api.post('/users/update-info', {
				username,
				bio,
				displayName,
				website,
				twitterUrl: twitter,
				instagramUrl: instagram,
			});

			if (!error) {
				alert("User details updated!");
				Object.assign(user, object);
				console.log({user});
				setUser(user);
			}
		} catch (error: any) {
			console.log(error);
			alert(error.message);
		}

		setIsLoading(false);
	}

	async function handleLinkTwitter() {
		// TODO: configure based on environment
		const url = `${system.config.ipfsApi}/get-verify-twitter-url?wallet=${wallet}`;
		fetch(url).then(response => {
				console.log(`response: ${JSON.stringify(response)}`)
				return response.json();
			}).then(data => {
				console.log(data.url)
				window.location.href = data.url;
			});
	}

	return (
		<S.Wrapper className={className} {...rest}>
			<Grid container spacing={isMd ? 4 : 2}>
				<Grid item xs={12}>
					<Typography variant="h6" color="textPrimary">
						Profile Settings
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12} md={6}>
					<Typography variant="h6">
						Avatar

						<Tooltip title="Recommended 300x300px">
							<IconButton>
								<MdHelpOutline size={16} />
							</IconButton>
						</Tooltip>
					</Typography>

					<S.CardImage>
						<input
							accept="image/*"
							id="raised-button-file"
							multiple
							type="file"
							hidden
							onChange={handleChange}
						/>

						<label htmlFor="raised-button-file">
							<S.AvatarContainer hasAvatar={!!avatar}>
								<S.FileButton>
									{ (avatar && !isAvatarLoading) && <img src={avatar} alt="Avatar from user" /> }

				                    <CircularProgress size={40} hidden={!isAvatarLoading} />
								</S.FileButton>
							</S.AvatarContainer>
						</label>
					</S.CardImage>

					{ wallet && (<S.Plan variant="subtitle2">Wallet address: <br /><strong>{ wallet }</strong></S.Plan>) }
				</Grid>

				<Grid item xs={12} md={6}>
					<Box display="flex" flexDirection="column">
						<Typography variant="h6">
							Profile banner

							<Tooltip title="Recommended 1400x300px">
								<IconButton>
									<MdHelpOutline size={16} />
								</IconButton>
							</Tooltip>
						</Typography>

						<S.ProfileBanner>
							<input
								accept="image/*"
								id="profile-banner-image"
								type="file"
								hidden
								onChange={handleCoverChange}
							/>

							<label htmlFor="profile-banner-image">
								<S.CoverContainer hascover={!!cover}>
									<S.FileButton>
										{ (cover && !isCoverLoading) && <img src={cover} alt="Avatar from user" /> }
										
										<CircularProgress size={64} hidden={!isCoverLoading} />
									</S.FileButton>
								</S.CoverContainer>
							</label>
						</S.ProfileBanner>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12} sm={6}>
					<Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
						Username
					</Typography>

					<TextField 
						placeholder="Username" 
						disabled={isLoading}
						variant="outlined" 
						size="medium" 
						name="username" 
						fullWidth 
						type="text"
						value={username} 
						onChange={(e) => setUsername(e.target.value)}  
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
						Twitter
					</Typography>

					{(user?.twitter) &&
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Link href={`https://twitter.com/${user.twitter}`} underline="hover" target="_blank">
							<Typography variant='subtitle1' style={{ color: '#1DA1F2' }} className={classes.twitterName}>
								<TwitterIcon className={classes.icon} style={{ color: '#1DA1F2' }}/> {user?.twitter}
							</Typography>
						</Link>

						<Button 
							variant='contained' 
							type='button' 
							onClick={handleLinkTwitter} 
							color='primary' 
							size='large' 
							disabled={isLoading}
						>
							Re-link Twitter
						</Button>
					</Box>
					}

					{!(user?.twitter) &&
					<Button 
						variant="contained" 
						type="button" 
						onClick={handleLinkTwitter} 
						color="primary" 
						size="large"
						disabled={isLoading}
					>
						Link Twitter
					</Button>
					}
				</Grid>
				{/* 
				<Grid item xs={12} sm={6}>
					<Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
						Website URL
					</Typography>

					<TextField 
						placeholder="Your website URL" 
						disabled={isLoading}
						variant="outlined" 
						size="medium" 
						name="website" 
						fullWidth 
						type="text"
						value={website} 
						onChange={(e) => setWebsite(e.target.value)}  
					/>
				</Grid>
				
				<Grid item xs={12} sm={6}>
					<Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
						Twitter
					</Typography>

					<TextField 
						placeholder="Twitter username" 
						disabled={isLoading}
						variant="outlined" 
						size="medium" 
						name="twitter" 
						fullWidth 
						type="text"
						value={twitter} 
						onChange={(e) => setTwitter(e.target.value)}  
						onBlur={() => handleNormalizedUrl(twitter, setTwitter)}
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
						Instagram
					</Typography>

					<TextField 
						placeholder="Instagram username" 
						disabled={isLoading}
						variant="outlined" 
						size="medium" 
						name="instagram" 
						fullWidth 
						type="text"
						value={instagram} 
						onChange={(e) => setInstagram(e.target.value)}  
						onBlur={() => handleNormalizedUrl(instagram, setInstagram)}
					/>
				</Grid> 
				*/}

				<Grid item xs={12}>
					<Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
						Bio
					</Typography>

					<TextField
						placeholder="Your bio"
						disabled={isLoading}
						variant="outlined"
						name="bio"
						fullWidth
						multiline
						rows={4}
						value={bio} 
						onChange={(e) => setBio(e.target.value)} 
					/>
				</Grid>

				<Grid item container justifyContent="flex-start" xs={12}>
					<Button variant="contained" type="button" onClick={handleUpdate} color="primary" size="large" disabled={isLoading}>
						Save
					</Button>
				</Grid>
			</Grid>
		</S.Wrapper>
	);
};

export default General;