import React from 'react';
import { SyncEthereum } from 'views';
import { Main } from 'layouts';
import WithLayout from 'WithLayout';
import { handleTitleAndScrollToTop } from 'utils';

const SyncEthereumPage = (): JSX.Element => {
	handleTitleAndScrollToTop("Minter - SyncEthereum");
	
	return (<WithLayout component={SyncEthereum} layout={Main} />)
};

export default SyncEthereumPage;