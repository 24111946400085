import React, { useState, useEffect } from 'react';
import { Link } from 'wouter';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { ipfsUriToGatewayUrl } from 'lib/util/ipfs';
import { Config } from 'lib/system';

import { Image, Tezos } from 'components/atoms';
import { CardProduct } from 'components/organisms';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
    image: {
        objectFit: 'cover',
        height: 450,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
          height: 450,
          width: '100%'
        }    
    },
    video: {
        width: '100%',
    },
    blogContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    list: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          alignItems: 'start',
          flexDirection: 'column',
        }
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          justifyContent: 'center',
        }
    },
    cardProduct: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRadius: theme.spacing(1),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,

        '& .card-product__content': {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
        
        [theme.breakpoints.down('sm')]: {
          height: 'auto',
    
          '& .card-product__content': {
            padding: theme.spacing(1)
          }
        }
    },                        
}));

type CardTokenProps = {
    hasTitle?: boolean;
    config: Config;
} & Token;

export default function CardToken(props: CardTokenProps) {
    const { config, hasTitle = false, title, artifactUri, displayUri, sale, edition_number, number_of_editions } = props;

    const classes = useStyles();

    const src = ipfsUriToGatewayUrl(config, artifactUri);
    const [obj, setObj] = useState<{ url: string; type: string } | null>(null);

    useEffect(() => {
        (async () => {
            let blob;
            
            try {
                blob = await fetch(src).then(r => r.blob());
                setObj({ url: URL.createObjectURL(blob), type: blob.type });
            } catch (e) {
                console.log(e);
            }            
        })();
    }, [src]);

    const CardMediaContent = () => {
        if (!obj) {
            return <></>;
        }

        if (/^video\/.*/.test(obj.type)) {
            return (
                <Link href={`/edit-trax/${props.slug}`} >
                    <video
                        preload="metadata"
                        onClick={e => e.preventDefault()}
                        onMouseEnter={e => e.currentTarget.play()}
                        onMouseLeave={e => e.currentTarget.pause()}
                        onLoadedMetadata={e => { (e.target as HTMLVideoElement).currentTime = .05; }}
                        muted
                        controls
                        className={clsx(classes.image, classes.video)}
                    >
                        <source src={obj.url} type={obj.type} />
                    </video>
                </Link>
            );
        }

        const imageSrc = ipfsUriToGatewayUrl(config, displayUri);

        return (
            <Link href={`/edit-trax/${props.slug}`} >
                <Image src={imageSrc} className={classes.image} lazyProps={{ width: '100%', height: '100%' }}/>
            </Link>
        );
    }
    
    const CardContent = () => (
        <>
            { hasTitle && (
            <div className={classes.blogContent}>
                <Box>
                    <S.FlexLink href={`/edit-trax/${props.slug}`}>
                        <Typography variant="body2" color="textPrimary">
                            {title?.slice(0, 20)}
                        </Typography>

                        { sale && (
                        <S.Sale variant="body1" color="textPrimary">
                            {sale.price}

                            <Tezos width={14} height={14} />
                        </S.Sale>
                        )}
                    </S.FlexLink>
                </Box>
            </div>
            )}
        </>
    );

    return (
        <S.Container>
            <CardProduct
                withShadow
                className={classes.cardProduct}
                mediaContent={
                    <Link href={`/edit-trax/${props.slug}`}>
                        <CardMediaContent />
                    </Link>
                }
                hasContent={hasTitle}
                cardContent={<CardContent />}
            />

            { edition_number && number_of_editions > 1 && (
            <S.Ribbon>
                {edition_number} of {number_of_editions}
            </S.Ribbon>
            )}
        </S.Container>
    );
}
