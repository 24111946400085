import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, colors, } from '@material-ui/core';

import { Image } from 'components/atoms';
import { SectionHeader, IconAlternate } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  coverImage: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const About = ({ data, className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <SectionHeader
            title={
              <span>
                Welcome to{' '}
                <Typography component="span" variant="inherit" color="primary">Orbix360 Minter.</Typography>
              </span>
            }
            subtitle="The Easiest way to Create, Sell, and Collect NFT artwork."
            align="left"
            disableGutter
          />

          <List disablePadding>
          {data.map((item: any, index: number) => (
            <ListItem disableGutters key={index} data-aos="fade-up">
              <ListItemAvatar className={classes.listItemAvatar}>
                <IconAlternate size="small" fontIconClass={item.icon} color={colors.indigo} />
              </ListItemAvatar>
              
              <ListItemText primary={item.title} secondary={item.subtitle} />
            </ListItem>
          ))}
          </List>
        </Grid>

        <Grid item container justifyContent="center" alignItems="center" xs={12} md={6} data-aos="fade-up" >
          <Image src="/assets/art_museum.svg" alt="..." className={classes.coverImage} /> </Grid>
      </Grid>
    </div>
  );
};

export default About;
