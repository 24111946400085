import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { playerConfigs } from 'views/EditTrax/data';

import * as S from './styles';
import APP_CONFIG from 'config/app_config';

export type EditTraxtTokenProps = typeof playerConfigs['alpha-test'];

const Details = ({ token, className, contract, ...rest }: ViewComponentProps): JSX.Element => {
    const editTraxTokens: EditTraxtTokenProps = token as EditTraxtTokenProps;
    const customContract = contract as Contracts;

    const uri = customContract?.metadata?.displayUri || customContract.artifactUri;
	let src = uri!.replace('ipfs://', 'https://orbix360.mypinata.cloud/ipfs/')

	return (
		<S.Container>
            <Box marginBottom={3}>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <S.ThumbBox>
                            <S.Thumbnail src={src} />
                        </S.ThumbBox>
                    </Grid>

                    <S.GridNameTags item xs={12} md={8}>
                        <S.NameTagsBox>
                            <S.Title>
                                <h2>Title</h2>
                                <h6>{customContract?.title}</h6>
                            </S.Title>

                            <S.Tags>
                            { customContract?.tags && customContract.tags.map(tag => (
                                <S.Tag>{tag}</S.Tag>                        
                            ))}
                            </S.Tags>
                        </S.NameTagsBox>
                    </S.GridNameTags>
                </Grid>
            </Box>

            <Grid container>
                <Grid item xs={12} md={4}>
                    <S.CollaborationsBox>
                        { customContract?.collaborators && customContract?.collaborators.length > 0 && (
                        <S.Content>
                            <S.SectionName><strong>COLLABORATOR(S)</strong></S.SectionName>

                            { customContract.collaborators.map((creator, index) => (
                            <S.Profile key={`creator-${index}`}>
                                <S.Avatar src="/assets/edit-trax/et_logo.png" />
                                <S.Name>{editTraxTokens.artistName}</S.Name>
                            </S.Profile>
                            ))}
                        </S.Content>
                        )}

                        <S.Content>
                            <h6>
                                <strong>MINTED</strong>
                                <span>{ ' ' + new Intl.DateTimeFormat('en-US').format(new Date(customContract.createdDate))}</span>
                            </h6>
                            
                            <h6>
                                <strong>
                                    <a href={src} target="_blank" rel="noopener noreferrer">
                                        LINK TO IPFS 
                                    </a>
                                </strong>
                            </h6>
                            
                            <h6>
                                <strong>
                                    <a 
                                        href={ `${APP_CONFIG.WALLET_ADDRESS}/${customContract.contractAddress}`} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        CONTRACT ADRESS 
                                    </a>
                                </strong>
                            </h6>                           
                        </S.Content>
                    </S.CollaborationsBox>
                </Grid>

                <Grid item xs={12} md={8}>
                    <S.Content>
                        <S.SectionName>
                            <strong>DESCRIPTION</strong>
                        </S.SectionName>

                        <span>{customContract.description}</span>
                    </S.Content>

                    { customContract?.info && (
                    <S.Content>
                        <S.SectionName><strong>Info</strong></S.SectionName>

                        <span>{customContract.info}</span>
                    </S.Content>
                    )}
                </Grid>
            </Grid>
		</S.Container>
	);
};

export default Details;
