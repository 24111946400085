import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Link } from 'wouter';

import * as S from './styles';

const Footer = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
	return (
		<S.Wrapper>
			<Grid container>
				<Grid item xs={12} md={4}>
					<Box display="flex" flexDirection="column" alignItems="center">
						<S.Title>Documentation</S.Title>

						<S.Content>
							<a href="https://iris-termite-e82.notion.site/WIKI-824dbe0144744143b43ee26f79f6c50a" target="_blank" rel="noopener noreferrer">Wiki</a>
							<a href="https://edittrax.com/" target="_blank" rel="noopener noreferrer">Website</a>
							<a href="/downloads/EditTraxQuickStartGuide.pdf" target="_blank" rel="noopener noreferrer">
								Handbook
							</a>
						</S.Content>
					</Box>
				</Grid>

				<Grid item xs={12} md={4}>
					<Box display="flex" flexDirection="column" alignItems="center">
						<S.Title>Community</S.Title>

						<S.Content>
							<a href="https://twitter.com/edittrax" target="_blank" rel="noopener noreferrer">Twitter</a>
						</S.Content>
					</Box>
				</Grid>

				<Grid item xs={12} md={4}>
					<Box display="flex" flexDirection="column" alignItems="center">
						<S.Title>Contact</S.Title>

						<S.Content>
							<Link href="/">edittrax@protonmail.com</Link>
						</S.Content>
					</Box>
				</Grid>
			</Grid>

			<S.Container>
				<span>NFT Created by&nbsp;<a href="https://tell.ie/bai_ee/link-in-bio">Bai-ee</a></span>
				<img src="/assets/edit-trax/handshake.png" alt="" />
				<span>Marketplace Created by&nbsp;<a href="https://minterverse.io/about">Minterverse</a></span>
			</S.Container>
		</S.Wrapper>
	);
};

export default Footer;
