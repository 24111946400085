export default {
	landings: {
		title: 'Landings',
		id: 'landing-pages',
		children: {
			services: {
				groupTitle: 'Services',
				pages: [
					{
						title: 'Coworking',
						href: '/coworking',
					},
					{
						title: 'Rental',
						href: '/rental',
					},
					{
						title: 'Job Listing',
						href: '/job-listing',
					},
					{
						title: 'E-Learning',
						href: '/e-learning',
					},
					{
						title: 'E-commerce',
						href: '/e-commerce',
					},
					{
						title: 'Expo',
						href: '/expo',
					},
				],
			},
			apps: {
				groupTitle: 'Apps',
				pages: [
					{
						title: 'Desktop App',
						href: '/desktop-app',
					},
					{
						title: 'Mobile App',
						href: '/mobile-app',
					},
				],
			},
			web: {
				groupTitle: 'Web',
				pages: [
					{
						title: 'Marketing',
						href: '/',
					},
					{
						title: 'Overview',
						href: '/',
					},
					{
						title: 'Basic',
						href: '/web-basic',
					},
					{
						title: 'Service',
						href: '/service',
					},
					{
						title: 'Startup',
						href: '/startup',
					},
					{
						title: 'Enterprise',
						href: '/enterprise',
					},
					{
						title: 'Cloud Hosting',
						href: '/cloud-hosting',
					},
					{
						title: 'Agency',
						href: '/agency',
					},
					{
						title: 'Design Company',
						href: '/design-company',
					},
					{
						title: 'Logistics',
						href: '/logistics',
					},
				],
			},
		},
	},
	pages: {
		title: 'Pages',
		id: 'supported-pages',
		children: {
			pages: {
				groupTitle: 'NAVIGATE',
				pages: [
					{
						title: 'Marketplace',
						href: '/marketplace',
					},
					{
						title: 'Explore',
						href: '/explore',
					},
					{
						title: 'Account',
						href: '/account',
						isPrivate: true,
					},
				],
			},
			policy: {
				groupTitle: 'LEGAL STUFF',
				pages: [
					{
						title: 'Terms & Conditions',
						href: '/terms',
					},
					{
						title: 'Cookies',
						href: '/cookies-policy',
					},
					{
						title: 'Privacy',
						href: '/privacy-policy',
					},
				],
			},
			company: {
				groupTitle: 'Company',
				pages: [
					{
						title: 'About Us',
						href: '/about',
					},
					{
						title: 'Contact Us',
						href: '/contact-us',
					},
					{
						title: 'FAQ',
						href: '/faq',
					},
				],
			},
			blog: {
				groupTitle: 'Tutorials',
				pages: [
					{
						title: '360 Tour Overview',
						href: '/tutorials/tour_overview',
					},
					{
						title: 'Navigation Hotspots',
						href: '/tutorials/nav_hotspots',
					},
					{
						title: 'Show All Tutorials',
						href: '/tutorials',
					},
				],
			},
			joinUs: {
				groupTitle: 'Join Us',
				pages: [
					{
						title: 'Sign In',
						href: '/sign-in',
						showLoggedIn: false,
					},
					{
						title: 'Sign Up',
						href: '/sign-up',
						showLoggedIn: false,
					},
					{
						title: 'Reset Password',
						href: '/password-reset',
					},
				],
			},
		},
	},
	account: {
		title: 'ACCOUNT',
		id: 'account',
		children: {
			account: {
				groupTitle: 'Account',
				pages: [
					{
						title: 'Account',
						href: '/my/profile'
					}
				]
			},
			settings: {
				groupTitle: 'Settings',
				pages: [
					{
						title: 'General',
						href: '/account/?pid=general',
					},
					{
						title: 'Security',
						href: '/account/?pid=security',
					},
					{
						title: 'Notifications',
						href: '/account/?pid=notifications',
					},
					{
						title: 'Billing',
						href: '/account/?pid=billing',
					},
				],
			},
			signup: {
				groupTitle: 'Sign up',
				pages: [
					{
						title: 'Simple',
						href: '/signup-simple',
					},
					{
						title: 'Cover',
						href: '/signup-cover',
					},
				],
			},
			signin: {
				groupTitle: 'Sign in',
				pages: [
					{
						title: 'Simple',
						href: '/signin-simple',
					},
					{
						title: 'Cover',
						href: '/signin-cover',
					},
				],
			},
			password: {
				groupTitle: 'Password reset',
				pages: [
					{
						title: 'Simple',
						href: '/password-reset-simple',
					},
					{
						title: 'Cover',
						href: '/password-reset-cover',
					},
				],
			},
			error: {
				groupTitle: 'Error',
				pages: [
					{
						title: 'Simple',
						href: '/not-found',
					},
					{
						title: 'Cover',
						href: '/not-found-cover',
					},
				],
			},
		},
	},
};