import React, { useEffect, useState } from 'react';

import { ipfsUriToGatewayUrl } from 'lib/util/ipfs';
import { Image } from 'components/atoms';

import config from '../../../config.json';

import * as S from './styles';

interface HandleFileTypeProps {
    src: string;
    srcWithParams?: string;
    formats: {
        uri: string;
        hash: string;
        mimeType: string;
        fileSize: number;
        fileName: string;
        duration: string;
    }[];
    artifactUri: string;
}


export default function HandleFileType({ src, srcWithParams, formats, artifactUri }: HandleFileTypeProps) {
    const [obj, setObj] = useState<{ url: string; type: string } | null>(null);

    useEffect(() => {
        if (!obj) {
            (async () => {
                let blob;
                
                try {
                    if (src !== undefined) {
                        blob = await fetch(src).then(r => r.blob());
                        setObj({ url: URL.createObjectURL(blob), type: blob.type });
                    }
                } catch (e) {
                    console.log(e);
                }            
            })();
        }
    }, []);

    if (!obj) {
        return <></>;
    }

    return (
        <S.Container>
            { (/^video\/.*/.test(obj.type)) ? (
                <video
                    preload="metadata"
                    onClick={e => e.preventDefault()}
                    onLoadedMetadata={e => { (e.target as HTMLVideoElement).currentTime = .05; }}
                    muted
                    controls
                >
                    <source src={obj.url} type={obj.type} />
                </video>
            ) : (formats?.length && ['model/gltf-binary', 'model/gltf+json'].includes(formats[0].mimeType)) ? (
                <model-viewer 
                    auto-rotate 
                    rotation-per-second="30deg" 
                    camera-controls 
                    src={obj.url} 
                />
            ) : (formats?.length && formats[0].mimeType.includes('zip')) ? (
                (<iframe src={srcWithParams || src} title={'iframe-viewer'}></iframe>)            
            ) : (
                <Image src={ipfsUriToGatewayUrl(config, artifactUri)} lazy={false} />
            )}
        </S.Container>
    );
}
