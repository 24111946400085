import React, { useState, useEffect } from 'react';
import { Link } from 'wouter';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { MdPlayCircleOutline } from 'react-icons/md';

import { ipfsUriToGatewayUrl } from 'lib/util/ipfs';
import { Config } from 'lib/system';

import { Image } from 'components/atoms';
import { CardProduct } from 'components/organisms';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
    image: {
        objectFit: 'cover',
        height: 405,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
          height: 405,
          width: '100%'
        }    
    },
    video: {
        width: '100%',
    },
    blogContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    list: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          alignItems: 'start',
          flexDirection: 'column',
        }
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          justifyContent: 'center',
        }
    },
    cardProduct: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRadius: 0,

        '& .card-product__content': {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
        
        [theme.breakpoints.down('sm')]: {
          height: 'auto',
    
          '& .card-product__content': {
            padding: theme.spacing(1)
          }
        }
    },                        
}));

type CardTokenProps = {
    hasTitle?: boolean;
    config: Config;
} & Token;

export default function CardToken(props: CardTokenProps) {
    const { config, hasTitle = false, title, artifactUri, displayUri, sale, edition_number, number_of_editions, tokenId, contractAddress } = props;

    const classes = useStyles();

    const url = `/custom-token-detail/${contractAddress!}/${tokenId}`;
    const src = ipfsUriToGatewayUrl(config, artifactUri);
    const [obj, setObj] = useState<{ url: string; type: string } | null>(null);

    useEffect(() => {
        (async () => {
            let blob;
            
            try {
                blob = await fetch(src).then(r => r.blob());
                setObj({ url: URL.createObjectURL(blob), type: blob.type });
            } catch (e) {
                console.log(e);
            }            
        })();
    }, [src]);

    const CardMediaContent = () => {
        if (!obj) {
            return <></>;
        }

        if (/^video\/.*/.test(obj.type)) {
            const hasPreviewImage = !!(artifactUri !== displayUri);
            const imageSrc = ipfsUriToGatewayUrl(config, displayUri);

            return (
                <Link href={url} >
                { hasPreviewImage ? (
                    <S.PreviewBox>
                        <MdPlayCircleOutline size={40} color="#d1d1d1" />
                        <Image src={imageSrc} className={classes.image} lazyProps={{ width: '100%', height: '100%' }}/>
                    </S.PreviewBox>
                ) : (
                    <video
                        preload="metadata"
                        onClick={e => e.preventDefault()}
                        onMouseEnter={e => e.currentTarget.play()}
                        onMouseLeave={e => e.currentTarget.pause()}
                        onLoadedMetadata={e => { (e.target as HTMLVideoElement).currentTime = .05; }}
                        muted
                        controls
                        className={clsx(classes.image, classes.video)}
                    >
                        <source src={obj.url} type={obj.type} />
                    </video>
                )}
                </Link>
            );
        }

        const imageSrc = ipfsUriToGatewayUrl(config, displayUri);

        return (
            <Link href={url} >
                <Image src={imageSrc} className={classes.image} lazyProps={{ width: '100%', height: '100%' }}/>
            </Link>
        );
    }
    
    const CardContent = () => (
        <>
            { hasTitle && (
            <div className={classes.blogContent}>
                <Box>
                    <S.FlexLink href={url}>
                        <Typography variant="body2" color="textPrimary">
                            {title.slice(0, 20)}
                        </Typography>

                        { sale && (
                        <S.Sale variant="body1" color="textPrimary">
                            {sale.price}

                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" id="Layer_1" data-name="Layer 1" viewBox="0 0 1169.87 1593"><title>tezos-logo-01</title><g id="Layer_2" data-name="Layer 2"><path d="M755.68,1593q-170.51,0-248.91-82.14a253.6,253.6,0,0,1-78.15-177,117.39,117.39,0,0,1,13.69-58.5A101.21,101.21,0,0,1,479.64,1238a130.22,130.22,0,0,1,116.24,0A99.55,99.55,0,0,1,633,1275.36a115,115,0,0,1,14.18,58.5,111.73,111.73,0,0,1-19.91,68.45,92.78,92.78,0,0,1-47.31,34.62,129.18,129.18,0,0,0,74.67,46.55,370,370,0,0,0,101.8,14.68,226.91,226.91,0,0,0,128.19-38.33,224,224,0,0,0,83.63-113.25,492,492,0,0,0,27.38-169.5,465.07,465.07,0,0,0-29.87-176.23,217.54,217.54,0,0,0-86.37-109.52,229.68,229.68,0,0,0-124.43-35.59,236.75,236.75,0,0,0-107.78,36.59L567.26,932.4V892.33L926.43,410.5H428.62v500A178.9,178.9,0,0,0,456,1012.8a94.34,94.34,0,0,0,83.63,40.07,139.85,139.85,0,0,0,82.63-29.12,298.38,298.38,0,0,0,69.2-71.19,24.86,24.86,0,0,1,9-11.94,18.4,18.4,0,0,1,12-4.48,41.55,41.55,0,0,1,23.4,9.95,49.82,49.82,0,0,1,12.69,33.85,197.86,197.86,0,0,1-4.48,24.89,241,241,0,0,1-85.38,106,211.78,211.78,0,0,1-119.76,36.38q-161.67,0-224-63.72A238.67,238.67,0,0,1,253.2,909.25V410.5H0V317.6H254.38V105.78L196.14,47.5V0h169l63.48,32.86V317.6l657.6-2,65.47,65.71L748.46,786.5a271,271,0,0,1,76.16-18.42A330.1,330.1,0,0,1,972,810.15a302.7,302.7,0,0,1,126.95,113.29,399.78,399.78,0,0,1,57.25,136.65,575.65,575.65,0,0,1,13.69,117,489.39,489.39,0,0,1-49.78,216.79,317.92,317.92,0,0,1-149.35,149.35A483.27,483.27,0,0,1,755.68,1593Z" /></g></svg>
                        </S.Sale>
                        )}
                    </S.FlexLink>
                </Box>
            </div>
            )}
        </>
    );

    return (
        <S.Container>
            <CardProduct
                withShadow
                liftUp
                className={classes.cardProduct}
                mediaContent={
                    <Link href={url}>
                        <CardMediaContent />
                    </Link>
                }
                hasContent={hasTitle}
                cardContent={<CardContent />}
            />

            { edition_number && number_of_editions > 1 && (
            <S.Ribbon>
                {edition_number} of {number_of_editions}
            </S.Ribbon>
            )}
        </S.Container>
    );
}
