import { Box, CardMedia } from '@material-ui/core';
import styled, { css } from 'styled-components';

type WrapperProps = {
	themeMode: string | boolean | (() => void);
}

export const Wrapper = styled.div<WrapperProps>`
	${({ themeMode }) => css`
		a {
			text-decoration: none;
			color: ${themeMode === 'light' ? '#000' : '#fff'};
		}	
	`}
`;

export const Share = styled(Box)`
    border: 1px solid rgb(230, 230, 230);
    border-radius: 4px;
    box-sizing: border-box;
`;

export const MuiCardMedia = styled(CardMedia)`
	position: relative;
	display: flex;
	align-items: flex-end;

	min-height: 280px;
	overflow: hidden;
	
	img {
		position: absolute;

		top: 0;
		left: 0;
		width: 100%;
		height: auto;

		transition: transform .8s ease;
		transform: scaleX(1) scaleY(1) translateZ(0);		

		&:hover {
			transition: transform 1.5s ease-out;
			transform: scaleX(1.1) scaleY(1.1) translateZ(0);		
		}	
	}
`;