import React, { useState, useEffect, MouseEvent } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Box } from '@material-ui/core';

import { Nft } from 'lib/nfts/decoders';
import { useSelector } from 'reducer';
import { handleOwnedNfts, handleOwnedNfts_Hic, getTotalCollection, getTokenSalesByOwner } from 'services/collections';
import { getFilteredTokensForSale } from 'services/tokens';

import { CardSkeleton, Section } from 'components/organisms';
import { Pagination } from '@material-ui/lab';
import TokenSelectionItem from '../../../../components/organisms/TokenSelectionItem';
import APP_CONFIG from 'config/app_config';
import api from '../../../../services/api';


const useStyles = makeStyles(theme => ({
  sectionAlternate: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    '& .section-alternate__content': {
    },
  },
  button: {
    minWidth: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 420,
    },
  },
	tabRoot: {
		width: '100%',
	},
	tab: {
		marginBottom: theme.spacing(3),
		width: 'fit-content',
		
		'& .MuiTab-textColorPrimary': {
			color: theme.palette.text.primary,
		},
		
		'& .Mui-selected': {
			backgroundColor: 'transparent',
			fontWeight: 'bolder',
			color: theme.palette.primary.dark,
		}
	},
}));

const handleClick = (event: MouseEvent<HTMLElement>, item: Nft | null): void => {
  console.log(`in tokenSelectionHandleClick, item: ${JSON.stringify(item)}`)
  if (window.parent) {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(`got urlParams: ${urlParams}`);
    const targetParam = urlParams.get('target');
    console.log(`got targetParam: ${targetParam}`);
    console.log(`in token selection click, got tokenData metadata formats: ${JSON.stringify(item?.metadata?.formats)}`);
    const formats = item?.metadata?.formats
    if (formats && formats[0]) {
      const format = formats[0];
      const mimeType= format?.mimeType;
      if (mimeType === 'application/zip') {

      }
    }
    const messageData = {
      target: targetParam,
      tokenData: item
    }
    console.log(`in tokenSelectionHandleClick, item now: ${JSON.stringify(item)}`)
    window.parent.postMessage(JSON.stringify(messageData), "*");
  }
}

const Result = ({ address, owner, type, className, ...rest }: ViewComponentProps): JSX.Element => {
  console.log(`in Result, type: ${type}`)
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true, });

  const limit = 100;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const [totalKeys, setTotalKeys] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [nftTokens, setNftTokens] = useState<Nft[]>([]);

  const { system } = useSelector(s => s);
  const { config } = system;

  useEffect(() => {
    (async () => {
      console.log(`in TokenSelection Result, type: ${type}, address: ${address}`);
      if (address !== null) {
        const total = await getTotalCollection();
        setTotalKeys(total);

        if (type === 'sale') {
          let owner = system.tzPublicKey ? system.tzPublicKey : '';
          const [tokens]  = await Promise.all([
            getTokenSalesByOwner({ owner: owner, system }),
          ]);
          // console.log(`in token selection results, sale tokens: ${JSON.stringify(tokens)}`)
          let filteredTokens = filterTokens(tokens);
          setNftTokens(filteredTokens);

        } else if (type === 'minted') {
          const urlParams = new URLSearchParams(window.location.search);
          const minterParam = urlParams.get('minter');
          const minter = minterParam ? minterParam : 'orbix360';
          console.log(`in TokenSelection Result, minter: ${minter}`);
          console.log(`about to call handleOwnedNFTs, address: ${address}, owner: ${owner}`)
          const tokens = minter === 'orbix360' ?
            await handleOwnedNfts(system, address, owner, limit, (pageNumber - 1) * limit) :
            await handleOwnedNfts_Hic(system, address, owner, limit, (pageNumber - 1) * limit);
          // console.log(`in token selection results, tokens: ${JSON.stringify(tokens)}`)
          let filteredTokens = filterTokens(tokens);
          setNftTokens(filteredTokens);

        } else if (type === 'sale_all') {
          const filteredTokens  = await getFilteredTokensForSale({ limit, offset: 0 });
          setNftTokens(filteredTokens.object);
        } else if (type === 'minted_all') {
          const { data: { object }} = await api.post('/tokens/actives', {
            filter: {
              limit,
              offset: 0,
              orderBy: 'tokenId',
              descending: true,
            }
          });
          setNftTokens(object);
        }

        setIsLoading(false);
      }
    })();
  }, [address]);

  function filterTokens(tokens: any) {
    let filteredTokens = [];
    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];
      // console.log(`in filterTokens, token: ${JSON.stringify(token.metadata.formats)}`)
      if (token.metadata && token.metadata.formats && token.metadata.formats.length > 0) {
        const mimeType = token.metadata.formats[0].mimeType;
        console.log(`in filterTokens, mimeType: ${mimeType}`)
        if (mimeType &&
          (mimeType.indexOf('video') >= 0 || mimeType.indexOf('image') >= 0 ||  mimeType.indexOf('gltf') >= 0  ||  mimeType.indexOf('zip') >= 0)
        ) {
          filteredTokens.push(token);
        }
      }
    }
    return filteredTokens;
  }

  async function handlePagination(e: any, page: number) {
    setIsLoading(true);
    
    const tokens = await handleOwnedNfts(system, address, owner, limit, (page - 1) * limit);
    setNftTokens(tokens);
    setPageNumber(page);

    window.scrollTo(0, 0);

    setIsLoading(false);
  }

  console.log(`in Result, totalKeys: ${totalKeys}, limit: ${limit}, min`);
  // const urlParams = new URLSearchParams(window.location.search);
  // const minterParam = urlParams.get('minter');
  // const minter = minterParam ? minterParam : 'orbix360';
  // const ipfsGateway = minter === 'orbix360' ? config.ipfsGateway : 'https://ipfs.io';

  const numPages = Math.ceil(totalKeys / limit)
  if (system.tzPublicKey) {
    return (
      <div className={className} {...rest}>
        <Section className={classes.sectionAlternate}>
          <>
            {isLoading && (
              <Grid container spacing={4}>
                {[...Array(8).keys()].map((_, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index} data-aos="fade-up">
                    <CardSkeleton />
                  </Grid>
                ))}
              </Grid>
            )}

            {!isLoading && (
              <Grid container spacing={isMd ? 4 : 2}>
                {nftTokens.length > 0 && nftTokens.map((item: Nft, index: number) => (
                  <Grid item xs={12} sm={6} md={3} key={index} data-aos="fade-up">
                    <div style={{ cursor: 'pointer' }} onClick={e => handleClick(e, item)}>
                      <TokenSelectionItem {...item} hasTitle={true} config={config} />
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}

            {false && (
              <Grid container spacing={isMd ? 4 : 2}>
                <Grid item xs={12} container justify="center">
                  <Box mt={2}>
                    <Pagination
                      count={numPages}
                      page={pageNumber}
                      shape="rounded"
                      color="primary"
                      onChange={handlePagination}
                      disabled={isLoading}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </>
        </Section>
      </div>
    );
  } else {
    return (
      <div className={className} style={{ maxWidth: '200px', height: '100vh', lineHeight: '100vh', margin: 'auto' }}{...rest}>
        <p style={{ lineHeight: '1.5', verticalAlign: 'middle', display: 'inline-block' }}>Wallet not connected.<br/><br/>
        Please <a href={`${APP_CONFIG.SHORT_URL_ROOT}`} target={'_blank'} rel="noopener noreferrer" style={{ textDecoration: 'underline'}}>click here</a> to open Orbix360 Minter page and connect wallet.</p>
      </div>
    )
  }
};

export default Result;