import React from 'react';
import { Minimal } from 'layouts';
import WithLayout from 'WithLayout';
import { handleTitleAndScrollToTop } from 'utils';
import { EditTraxMarketplace } from 'views';

const EditTraxMarketplacePage = (): JSX.Element => {
	handleTitleAndScrollToTop("Edit Trax Marketplace");

	return (<WithLayout component={EditTraxMarketplace} layout={Minimal} />);
};

export default EditTraxMarketplacePage;