import { Grid } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Link } from 'wouter';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        ${theme.breakpoints.down(960)} {
            section {
                padding-top: 0 !important;
            }
        }
        
        section {
            padding-left: 0;
        }

        .MuiPaper-elevation1 {
            box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 40%), 0px 1px 1px 0px rgb(0 0 0 / 44%), 0px 1px 3px 0px rgb(0 0 0 / 40%);
        }

        .MuiTypography-colorTextPrimary:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    `}
`;

export const RowGrid = styled(Grid)`
    ${({ theme }) => css`
        display: flex;
        padding: ${theme.spacing(2)}px;
        
        &:hover {
            background-color: ${theme.palette.alternate.main};
        };
    `}
`

export const UserLink = styled(Link)`
    .MuiTypography-colorTextPrimary:hover {
        text-decoration: underline;
    }
`;