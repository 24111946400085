import React from 'react';
import { findEditTraxContracts } from 'services/custom-contracts';
import { getUrlQueryParams } from 'utils';

import BaseToken from '../BaseToken';

import * as S from './styles';

const Marketplace = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
    const pageNumber = Number(getUrlQueryParams()['page']) || 1;
    const limit = 12, filter: Filter = { limit, offset: (pageNumber - 1) * limit, orderBy: 'tokenId', descending: true };

    return (
        <S.Container>
            <S.Title>GENESIS COLLECTION</S.Title>

            <BaseToken
                handleChange={async () => await findEditTraxContracts(['download-mechanism','alpha-test','burnt','boxxed'])}
                handleChangeProps={{playerNames: ['acid-beach']}}
                hasPagination={false}
                limit={limit}
                filter={filter}
                pageNumber={pageNumber}
            />

        </S.Container>
    );
};

export default Marketplace;