import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		height: '100%',
		position: 'relative',
		overflow: 'hidden',
		background: theme.palette.primary.dark,
	},
	sectionPadding: {
		padding: `${theme.spacing(5, 8)} !important`,
	},
	textWhite: {
		color: 'white',
	},
	title: {
		fontWeight: 'bold',
	},
}));

const HeroSection = ({ className, title, align = "left", ...rest }: ViewComponentProps): JSX.Element => {
	const classes = useStyles();
	return (
		<div className={clsx(classes.root, className)} {...rest}>
			<Section className={classes.sectionPadding}>
				<SectionHeader
					title={title}
					align={align}
					disableGutter
					titleProps={{ className: clsx(classes.title, classes.textWhite), variant: 'h3' }}
					subtitleProps={{ className: classes.textWhite }}
				/>
			</Section>
		</div>
	);
};

export default HeroSection;