import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Thumbnail = styled.img`
    ${({ theme }) => css`
        width: 226px;
        height: 226px;
        
        ${theme.breakpoints.down('sm')} {
            align-self: center;
        }
    `}
`;

export const ThumbBox = styled.div`
    ${({ theme }) => css`
        display: flex;

        ${theme.breakpoints.down('sm')} {
            justify-content: center;
        }
    `}
`;

export const NameTagsBox = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        ${theme.breakpoints.down('sm')} {
            align-items: center;
        }
    `}
`;

export const CollaborationsBox = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;        

        h4 {
            display: flex;
        }

        ${theme.breakpoints.down('sm')} {
            align-items: center;
        }
    `}
`;

export const Content = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;

        margin-top: 25px;
        margin-bottom: 10px;

        span, li {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;        
            color: #000;
        }

        h6 {
            font-size: 12px;
            color: #222529;
            line-height: 28px;
            
            span {
                font-size: 12px;
            }

            a {
                text-decoration: underline;
            }
        }
        
        ${theme.breakpoints.down('sm')} {
            align-items: center;

            span {
                text-align: center;
            }

            > span {
                text-align:justify ;
            }
        }
    `}
`;

export const SectionName = styled.h4`
    font-family: 'mathias';
    font-size: 18px;
    font-weight: 400;

    color: #222529;
    margin-bottom: 14px;

    a {
        cursor: pointer;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: #d1d1d1;
        }
    }

    & + strong {
        font-size: 12px;
        color: #222529;
    }
`;

export const Profile = styled.div`
    display: flex;
    position: relative;
    align-items: center;

    border-radius: 30px;
    background-color: #C4C4C4;

    width: fit-content;
    min-width: 168px;
    height: 35px;

    & + & {
        margin-top: 16px;
    }
`;

export const Avatar = styled.img`
    position: absolute;

    width: 35px;
    height: 35px;

    border-radius: 50%;    
    background: #000;
`;

export const Name = styled.p`
    color: #222529;

    padding-left: 45px;
    padding-right: 16px;

    font-family: 'Poppins';
    font-size: 10px;
    font-weight: normal;
`;

export const Title = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;

        margin-top: 32px;
        margin-bottom: 30px;  
        color: #212529;

        font-family: 'mathias';

        font-style: normal;
        font-size: 46px; 
        line-height: 100%;   

        h2 {
            font-weight: 400;
            text-transform: uppercase;
        }
        
        h6 {
            font-weight: 100;
            text-transform: uppercase;
            color: #c4c4c4;
        }
        
        ${theme.breakpoints.down('sm')} {
            text-align: center;
        }
    `}
`;

export const GridNameTags = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`;

export const Tag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    background: #C4C4C4;
    border-radius: 14px;    

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
    color: #000;
    text-transform: uppercase;

    width: fit-content;
    padding: 8px 20px;
    min-width: 80px;
`;

export const List = styled.ul`
    li {
        margin-left: 24px;
    }
`;

export const Checkers = styled.div`
    display: flex;
    margin-top: 12px;

    img + img { 
        margin-left: 24px;
    }
`;

export const Unlock = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;

        border-radius: 10px;

        padding: 34px;
        border: 1px solid #E2E8F0;

        span {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;      
            
            padding-left: 34px;
        }

        ${theme.breakpoints.down('sm')} {
            font-size: 10px;
            line-height: 14px;            
        }
    `}
`;

export const Description = styled.span`
    font-family: 'Poppins';
`;