import React, { useState } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import { createAlchemyWeb3, Nft } from '@alch/alchemy-web3';
import { ethers } from 'ethers';

import { checkIfUserHasSpecificToken, getSyncedTokensFromCollection } from 'services/ethereum-service';

import { SectionHeader } from "components/molecules";
import { CardBase, SectionAlternate } from "components/organisms";

import * as S from './styles';

declare global {
	interface Window { 
        ethereum: any; 
    }
}

export default function Wrapper(): JSX.Element {
    // const collectionAddress = '0x87F3d81C19a737fFc784Ae3841F7Fa603ab09f82';
    const collectionAddress = '0x88B48F654c30e99bc2e4A1559b4Dcf1aD93FA656';
    const tokenContract = '0x88B48F654c30e99bc2e4A1559b4Dcf1aD93FA656';
    const tokenId = '0x8ec70ed1c5f9df688520c05128a4c207af290ac5000000000000010000000064';
    const [nfts, setNfts] = useState<Nft[]>([]);
    const [hasAnyTokenFromCollection, setHasAnyTokenFromCollection] = useState(false);

    async function handleCheckEthereumDropToken() {
        console.log(`***** in handleCheckEthereumDropToken`);
        const { object: hasToken } = await checkIfUserHasSpecificToken(tokenContract, tokenId);
        
        if (!!hasToken) {
            setHasAnyTokenFromCollection(true);
            alert("You have drops to sync!");

            return;
        }

        alert("You don't have any tokens to sync!");
    }

    async function handleSyncTokens() {
        const syncedTokensFromWallet = await getSyncedTokensFromCollection(collectionAddress);
        setNfts(syncedTokensFromWallet);
    }

    function handleImageUrl(imageUrl: string) {
        if (imageUrl.includes('ipfs')) {
            return `https://gateway.thirdweb.dev/ipfs/${imageUrl.replace('ipfs://', '')}`;
        }

        return imageUrl;
    }

    return (
        <SectionAlternate>
        <>
            <Button 
                variant="contained" 
                type="button" 
                color="primary" 
                size="large"
                onClick={handleCheckEthereumDropToken}
            >
                Check Wallet
            </Button>

            { hasAnyTokenFromCollection && (
            <Button 
                variant="contained" 
                type="button" 
                color="primary" 
                size="large"
                onClick={handleSyncTokens}
                style={{ marginLeft: 20 }}
            >
                Sync Wallet
            </Button>
            )}

            <S.Wrapper>
                <S.Container>
                    <S.GridContainer>
                    { nfts?.map(nft => (
                        <S.GridCell>
                            <S.AssetLoaded>
                                <S.Article>
                                    <S.AssetAnchor>
                                        <S.AssetContainer>
                                            <img src={handleImageUrl(nft?.metadata?.image || 'https://via.placeholder.com/300')} alt="" />
                                        </S.AssetContainer>
                                    </S.AssetAnchor>
                                </S.Article>
                            </S.AssetLoaded>
                        </S.GridCell>
                    ))}
                    </S.GridContainer>
                </S.Container>
            </S.Wrapper>
        </>
        </SectionAlternate>
    );
}