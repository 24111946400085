import React from 'react';
import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import { MdClose } from 'react-icons/md';

import { Section } from 'components/organisms';
import { SectionHeader } from 'components/molecules';

import { FileUpload } from './components';

const HeaderContainer = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .section-header {
        margin-bottom: 0;
    }    

    ${({ theme }) => css`    
        svg {
            cursor: pointer;
            color: ${theme.palette.error.main};
        }
    `}
`;

export default function SandBoxView() {
	return (
        <Section>
            <>
                <HeaderContainer item xs={12}>
                    <SectionHeader title="sandbox" align="left" />

                    <MdClose size={32} title="Cancel" />
                </HeaderContainer>

                <Grid container justifyContent="center">
                    <Grid item xs={12} md={12}>
                        <FileUpload />
                    </Grid>
                </Grid>
            </>
        </Section>
	);
};
