import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(() => ({
	image: {
		maxWidth: 420,
	},
}));

const Story = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
	const classes = useStyles();

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	return (
		<div className={className} {...rest}>
			<Grid container justifyContent="space-between" spacing={isMd ? 4 : 2} direction={isMd ? 'row' : 'column-reverse'}>
				<Grid item container alignItems="center" justifyContent="flex-start" xs={12} md={6}>
					<div>
						<SectionHeader
							title="What is Minterverse?"
							subtitle=""
							align="left"
							disableGutter
							subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
						/>
						Minterverse empowers the next evolution of web3 by allowing creators to deploy and sell interactive software as NFTs.<br/>
						<br/>
						Interactive NFTs could be such things as:<br/>
						<br/>
						<ul style={{ marginLeft: '20px' }}>
							<li>Interactive software with features accessible only by the owner of the NFT
								<ul style={{ marginLeft: '20px' }}>
									<li>Unlockable media assets</li>
									<li>Unlockable software features</li>
								</ul>
							</li>
							<li>Generative interactive NFTs</li>
							<li>Virtual Reality / Augmented Reality NFTs</li>
						</ul>


						<br/>
						<br/>
						<SectionHeader
							title="Why mint with Minterverse?"
							subtitle=""
							align="left"
							disableGutter
							subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
						/>

						Minterverse provides creators tools and templates to create the next generation of NFTs. <br/>
						<br/>
						Platform capabilities include:<br/>
						<br/>
						<ul style={{ marginLeft: '20px' }}>
							<li>NFT ownership verification</li>
							<li>Custom NFT smart contract templates</li>
							<li>Virtual / Augmented reality creation tools</li>
							<li>NFT project web page templates</li>
						</ul>



						<br/>
						<br/>
						<SectionHeader
							title="How does Minterverse work?"
							subtitle=""
							align="left"
							disableGutter
							subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
						/>
						Minterverse provides a viewer for interactive NFTs that receives the wallet address of the connected user.
						Minterverse also provides code that can be injected into the NFT to verify that this wallet address is an owner of the NFT.<br/>
						<br/>
						Minterverse provides web-based 3D authoring tools that allow a creator to create Virtual Reality spaces and mint them as NFTs.<br/>
						<br/>
						Minterverse provides Tezos smart contract templates to generate custom contracts with features such as mint-on-demand
						(get paid for the mint without having to put the NFT up for sale in a marketplace).<br/>

					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default Story;
