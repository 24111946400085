import React from 'react';
import { Grid } from '@material-ui/core';

import { Section } from 'components/organisms';
import { Wrapper } from './components';

export default function SyncEthereumView() {
    return (
      <Section style={{ maxWidth: '100%' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Wrapper />
          </Grid>
        </Grid>
      </Section>
    );
}
