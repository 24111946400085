import { useEffect, useState } from "react";
import { useLocation } from "wouter";
import ReactGA from "react-ga";

import APP_CONFIG from 'config/app_config';

const usePageTracking = () => {
    const [location] = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            console.log("Initializing GA");
            ReactGA.initialize(APP_CONFIG.GOOGLE_ANALYTICS_KEY);
        }
        
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location);
        }
    }, [initialized, location]);
};

export default usePageTracking;