import { Button as MuiButton } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const GTCOHeaderNavbar = styled.nav`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        padding: 0.5rem 1rem;
        background-color: white;
        
        top: 0;
        right: 0;
        left: 0;
        
        z-index: 11;
        
        transition: background-color 0.3s ease-in-out;
        
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;

        box-shadow: 2px 2px 10px 3px rgba(118,118,118,0.47);
    `}
`;

export const Container = styled.div`    
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;

        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        img {
            max-width: 50px;
        }

        ${theme.breakpoints.up(1200)} {
            max-width: 1080px;
            padding-left: 30px;
            padding-right: 30px;
        }    
    `}    

`;

export const ConnectButton = styled(MuiButton)`
    text-transform: none;
    white-space: nowrap;
    font-family: 'mathias';
    & > span > svg {
        margin-left: 10px;
        margin-right: 10px;
    }
`;

export const Button = styled.button`
    display: inline-block;
    font-family: 'mathias';
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;  

    //TODO: implements others buttons types
    //btn-dark
    color: #fff;
    background-color: black;
    border-color: black;
    
    &:hover {
        text-decoration: none;
        color: #858586;

        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
    }
`;