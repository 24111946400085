import React, { createRef } from 'react';

import { SelectedFile } from 'reducer/slices/createNft';

import { Image } from 'components/atoms';

export default function FilePreview({ file }: { file: SelectedFile }) {
  const canvasRef = createRef<HTMLCanvasElement>();

  function handleLoadedData(e: React.SyntheticEvent<HTMLVideoElement, Event>) {
    const canvas = canvasRef.current;

    if (!canvas) {
      return console.error('`canvasRef` current element is null');
    }

    const video = e.currentTarget;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    var canvasContext = canvas.getContext('2d');

    if (!canvasContext) {
      return console.error('`2d` canvas context not supported');
    }

    canvasContext.drawImage(video, 0, 0);
    const type = 'image/png';

    canvas.toBlob(blob => {
      if (!blob) {
        return console.error('Could not convert canvas to blob');
      }
    }, type);
  }

  if (/^image\/.*/.test(file.type)) {
    return (<Image src={file.objectUrl} width="100%" height="100%" objectfit="scale-down" />);
  }

  if (/^video\/.*/.test(file.type)) {    
    return (
      <>
        <video controls muted onLoadedData={e => handleLoadedData(e)}>
          <source src={file.objectUrl} type={file.type} />
        </video>

        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </>
    );
  }

  if (file.type === 'model/gltf+json' || file.type === 'model/gltf-binary') {
    return (
      <>
        <model-viewer camera-controls auto-rotate data-js-focus-visible src={file.objectUrl} class="upload-preview" style={{ margin: '0 auto' }} />
      </>
    );
  }

  if (file.type.includes('zip')) {
    return (
      <>
        <div>zip: {file.name}</div>
      </>
    );
  }

  return null;
}