import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { useLocation } from 'wouter';

import { useSelector } from 'reducer';

import { getUrlQueryParams } from 'utils';

import api from 'services/api';
import { getTotalActiveTokens } from 'services/tokens';

import { CardSkeleton, Section, Pagination } from 'components/organisms';
import CardToken from '../CardToken';
import * as S from './styles';

const useStyles = makeStyles(theme => ({
  sectionAlternate: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    '& .section-alternate__content': {
    },
    maxWidth: '100%',
  },
}));

const Result = ({ address, ownedOnly, className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true, });

  const [_, setLocation] = useLocation();

  const limit = 24;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const [totalKeys, setTotalKeys] = useState(0);
  const [pageNumber, setPageNumber] = useState(Number(getUrlQueryParams()['page']) || 1);

  const [tokens, setTokens] = useState<Token[]>([]);

  const { system } = useSelector(s => s);
  const { config } = system;

  useEffect(() => {
    (async () => {
      const { data: { error, object }} = await api.post('/tokens/actives', { 
        filter: {
          limit,
          offset: (pageNumber - 1) * limit,
          orderBy: 'tokenId',
          descending: true,
        }
      });
      
      if (!error) {
        const { tokens, total } = object;

        setTokens(tokens);
        setTotalKeys(total);
      }

      setIsLoading(false);
    })();
  }, []);

  async function handlePagination(e: any, page: number) {  
    // const items = { page };
    // const urlParams = new URLSearchParams(items).toString();
    
    setLocation(`/explore?page=${page}`);
    setIsLoading(true);

    const { data: { object }} = await api.post('/tokens/actives', { 
      filter: {
        limit,
        offset: (page - 1) * limit,
        orderBy: 'tokenId',
        descending: true,
      }
    });

    const { tokens, total } = object;
    
    setTokens(tokens);
    setTotalKeys(total);
    setPageNumber(page);

    window.scrollTo(0, 0);    
    
    setIsLoading(false);
  }

  const numPages = Math.ceil(totalKeys / limit);

  return (
    <S.Container className={className} {...rest}>
      <Section className={classes.sectionAlternate}>
        <>
          { isLoading && (
            <Grid container spacing={4}>
            { [...Array(18).keys()].map((skeleton, index) => (
              <Grid item xs={12} sm={6} md={2} key={index} data-aos="fade-up">
                <CardSkeleton />
              </Grid>
            ))}
            </Grid>
          )}

          { !isLoading && (
          <Grid container spacing={isMd ? 4 : 2}>
            { tokens.length > 0 && tokens.map((item: Token, index: number) => (
              <Grid item xs={12} sm={6} lg={2} md={3} key={index} data-aos="fade-up">
                <CardToken {...item} config={config} />
              </Grid>
            ))}
          </Grid>
          )}

          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} container justifyContent="center">
              <Pagination 
                numPages={numPages}
                pageNumber={pageNumber} 
                handlePagination={handlePagination} 
                isLoading={isLoading}
                showLastButton={!(numPages - 2 >= pageNumber)}
              />
            </Grid>
          </Grid>
        </>
      </Section>
    </S.Container>
  );
};

export default Result;