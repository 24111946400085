import React, { useState } from 'react';
import { Button, Fade, Grid, TextField, Typography, } from '@material-ui/core';
import { transferTokenAction } from 'reducer/async/actions';
import { useDispatch } from 'reducer';
import { ContentModal } from 'views/CreateNFT/components';

import * as S from './styles';

interface TransferTokenProps {
    contract: string;
    tokenId: number;
    open: boolean;
    handleClose: () => void;
}

export default function TransferToken({ contract, tokenId, open, handleClose }: TransferTokenProps) {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [toAddress, setToAddress] = useState('');
    const dispatch = useDispatch();

    function handleCloseModal() {
        setOpenModal(false);
        handleClose();
    }

    return (
        <>
            <S.MuiModal open={open} onClose={handleClose} aria-labelledby="modal-transfer-title" aria-describedby="modal-transfer-description" closeAfterTransition>
                <Fade in={open}>
                    <S.ModalContent bgcolor="#fff">
                        <Grid container spacing={4}>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Typography variant="h4" component="h2">
                                    <strong>Transfer Token</strong>
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    <strong>To Address</strong>
                                </Typography>

                                <TextField
                                    autoFocus={true}
                                    placeholder="To Address"
                                    variant="outlined"
                                    size="medium"
                                    name="amount"
                                    fullWidth
                                    type="text"
                                    value={toAddress}
                                    onChange={(e) => setToAddress(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button variant="contained" type="button" color="primary" size="large" onClick={() => { setOpenModal(true); handleClose() }}>
                                    Transfer
                                </Button>
                            </Grid>
                        </Grid>
                    </S.ModalContent>
                </Fade>
            </S.MuiModal>    

            <ContentModal 
                isOpen={openModal} 
                method="transferToken"
                dispatchThunk={() => dispatch(transferTokenAction({ contract, tokenId, to: toAddress }))}
                onClose={() => handleCloseModal()}
                dispatchOnOpen={true}	 
                pendingAsyncMessage="Transferring token..." 
            />
        </>
    );
}
