import { Main } from 'layouts';
import React from 'react';
import { MyItems } from 'views';
import WithLayout from 'WithLayout';

export interface MyItemsProps {
    minter: string;
}

export default function MyItemsPage ({ minter }: MyItemsProps): JSX.Element {
    document.title = `Minter: ${minter}`;

    return <WithLayout component={MyItems} layout={Main} minter={minter} />;
}