import api from "./api";

const PATH = '/tokens';

export async function saveTokenOnDatabase(tokens: any[]) {
    const parsedTokens = tokens.map((token) => {
        const { sale } = token;

        return {
            ...token.metadata,
            owner: sale ? sale.value.seller : token.owner,
            edition_number: +token?.edition_number || 1,
            editions_id: +token.metadata.editions_id,
            number_of_editions: +token.metadata.number_of_editions,
            token_id: token.id,
            sale: sale && {
                id: +sale.id,
                address: sale.value.sale_data.sale_token.fa2_address,
                saleId: +sale.key,
                price: +sale.value.sale_data.price / 1000000,
                amount: +sale.value.sale_data.amount,
                mutez: +sale.value.sale_data?.mutez,
                fees: +sale.value.sale_data.fees / 100,
                royalties: +sale.value.sale_data.royalties,
                royalties_split_map: sale.value.sale_data.royalties_split_map,
                fees_split_map: sale.value.sale_data.fees_split_map,
                sales_split_map: sale.value.sale_data.sales_split_map,
            },
            seller: sale ? sale.value.seller : undefined,
        }
    });
    console.log(`about to post to path ${PATH}, tokens: `);
    console.log({ parsedTokens });
    return await api.post(`${PATH}`, { tokens: parsedTokens });
}

export async function getFilteredActiveTokens(filter: Filter) {
    const { data: { object, error, message }} = await api.post(`${PATH}/actives`, { filter });

    return { object, error, message };
}

export async function getTotalActiveTokens() {
    const { data: { object: total }} = await api.get(`${PATH}/actives-total`);

    return total as number;
}

export async function getFilteredTokensForSale(filter: Filter) {
    const { data: { object, error, message }} = await api.post(`${PATH}/for-sale`, { filter });

    return { object, error, message };
}

export async function findGenerators(filter?: Filter) {
    const { data: { object, error, message }} = await api.post(`${PATH}/find-generators`, { filter });

    return { object, error, message };
}

export async function getTotalForSaleTokens() {
    const { data: { object: total }} = await api.get(`${PATH}/for-sale-total`);

    return total as number;
}

export async function getTokenById(tokenId: string) {
    const { data: { object }} = await api.get(`${PATH}/${tokenId}`);

    return object as Token;
}

export async function getFilteredEditions(filter: Filter, editionId: string, creator: string) {
    const { data: { object }} = await api.post(`${PATH}/find-editions/${editionId}`, { filter });

    return object;
}

export async function getFilteredCategories(filter: Filter, category: string) {
    const { data: { object }} = await api.post(`${PATH}/find-category/${category}`, { filter });

    return object;
}

export async function getFilteredTags(filter: Filter, tag: string) {
    const { data: { object }} = await api.post(`${PATH}/find-tag/${tag}`, { filter });

    return object;
}

export async function findTokensByCollectionNameAndWallet(filter: Filter, collectionName: string, wallet: string) {
    const { data: { object }} = await api.post(`${PATH}/find-collection`, { filter, collectionName, wallet });

    return object;
}

export async function findByContractAndTokenId(contract: string, tokenId: string) {
    const { data: { object }} = await api.get(`${PATH}/find-by-contract/${contract}/${tokenId}`);

    return object;
}

export async function findTokensByIds(tokensIds: number[], contractAddress: string, filter?: Filter) {
    const { data: { object }} = await api.post(`${PATH}/find-by-ids/${contractAddress}`, { tokensIds, filter });

    return object;
}

export async function updateTokenAfterSale(tokenId: number, owner: string) {
    // console.log(`in updateTokenAfterSale for tokenId: ${tokenId}`);
    const { data } = await api.put(`${PATH}/sales/update-owner/${tokenId}`, { owner }); 

    return data;
}

export async function addTokenForSale(tokenId: number, sale: Sale) {
    const { data } = await api.post(`${PATH}/sales/add-for-sale/${tokenId}`, { sale });
    
    return data;
}

export async function cancelSale(tokenId: number) {
    const { data } = await api.put(`${PATH}/sales/cancel-sale/${tokenId}`);
    
    return data;
}

export async function burnToken(tokenId: number) {
    const { data } = await api.put(`${PATH}/burn-token/${tokenId}`);

    return data;
}
