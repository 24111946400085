import axios from 'axios';
import { isLeft } from 'fp-ts/lib/Either';

import { store } from 'reducer';

import { getContractNfts, getContractOwnedNfts, getContractOwnedNfts_Hic } from 'lib/nfts/queries';
import { SystemWithToolkit, SystemWithWallet } from '../lib/system';
import * as D from 'lib/nfts/decoders';

import { nftMapper } from 'utils';

import config from '../config.json';
import { saveTokenOnDatabase } from './tokens';
import { getActiveSales } from './sales';

import api from './api';

const PATH = '/collections';

export async function handleNfts(system: SystemWithToolkit | SystemWithWallet, address: string, limit?: number, offset?: number, firstEditionOnly?: boolean) {
    console.log(`******* in collections handleNfts`);
    const tokens = await getContractNfts(system, address, limit, offset, firstEditionOnly);
    console.log(`******* in collections handleNfts, tokens.length: ${tokens.length}`);

    // const mktAddress = system.config.contracts.marketplace.fixedPrice.tez;
    
    // const promises = [], allSales = {} as {[x:string]: any};
    // for (let i = 0; i < 10; i += 100) {
    //     const filteredTokens = await getContractNfts(system, address, 10, i);

    //     if (filteredTokens.length) {
    //         const tokensIds = filteredTokens.map(({ id }) => id.toString());
    //         const tokenSales = await getActiveSales(mktAddress, tokensIds, 10, i);
        
    //         tokenSales.forEach((sale: any) => {
    //             const value = sale.value as SaleData;
    //             allSales[value.sale_data.sale_token.token_id] = sale;
    //         });
        
    //         const parsedTokens = filteredTokens.map((token) => ({
    //             ...token,
    //             sale: allSales[token.id.toString()]
    //         }));

    //         console.log({ parsedTokens });

    //         saveTokenOnDatabase(parsedTokens);
    //         // promises.push(saveTokenOnDatabase(parsedTokens));
    //     }
    // }

    //  const response = await Promise.all(promises);
    //  console.log({ response });

    console.log({ tokens });

    return tokens;
}

export async function handleOwnedNfts(system: SystemWithToolkit | SystemWithWallet, address: string, owner: string, limit?: number, offset?: number) {
    console.log(`in handleOwnedNfts`);
    const tokens = await getContractOwnedNfts(system, address, owner, limit, offset);

    return tokens;
}

export async function handleOwnedNfts_Hic(system: SystemWithToolkit | SystemWithWallet, address: string, owner: string, limit?: number, offset?: number) {
    console.log(`in handleOwnedNfts_Hic`);
    const tokens = await getContractOwnedNfts_Hic(system, address, owner, limit, offset);

    return tokens;
}

export async function getTotalCollection() {
    const burnedTokens = ["tz1burnburnburnburnburnburnburjAYjjX","tz1burnburnburnburnburnburnburjAYjjX"];
    const { data } = await axios.get(`${config.tzkt.api}/v1/contracts/${config.contracts.nftFaucet}/bigmaps/assets.ledger/keys?value.ni=${burnedTokens}&limit=10000&select=value`);

    return data.length;

    // const { data } = await axios.get(`${config.tzkt.api}/v1/contracts/${config.contracts.nftFaucet}/storage`);
    // https://api.tzkt.io/v1/contracts/KT1Nf6V7fje6ELNgD6hkKfc3SXaDNVFAifEX/storage
    // return Number(data.assets.next_token_id);
    // TODO: return next_token_id value
    // return 200;
}

export async function getCollectionsByCreator({ creator, system }: { creator: string, system: SystemWithToolkit | SystemWithWallet }) {
    const address = config.contracts.nftFaucet;

    const { data } = await axios.get(`${config.tzkt.api}/v1/contracts/${address}/bigmaps/assets.token_data/keys?value.creator=${creator}&value.collection_name.ne=null&sort.desc=id`);
    const decoded = D.TokenDataBigMap.decode(data);

    if (isLeft(decoded)) {
        throw Error('Failed to decode `getTokenMetadata` response');
    }

    const collectionsArray = decoded.right.filter(({ value: { collection_name }}) => !!collection_name).map(({ value: { collection_name }}) => collection_name);
    const uniqueCollections = [...new Set(collectionsArray)];

    const collections = Promise.all(
        uniqueCollections.map(async collection_name => { 
            return { 
                name: collection_name, 
                items: await getCollectionByName({ collection_name, system, creator })
            };
        })
    );

    return collections;
}

export async function getTokensByCreator({ creator, system }: { creator: string, system: SystemWithToolkit | SystemWithWallet }) {
    const address = config.contracts.nftFaucet;

    const { data } = await axios.get(`${config.tzkt.api}/v1/contracts/${address}/bigmaps/assets.token_data/keys?value.creator=${creator}&sort.desc=id`);
    const decoded = D.TokenDataBigMap.decode(data);

    if (isLeft(decoded)) {
        throw Error('Failed to decode `getTokenMetadata` response');
    }

    if (decoded.right.length === 0) {
        return [];
    }

    const tokenIds = decoded.right.map(({ key }) => key);

    return await nftMapper(system, tokenIds);
}

export async function getCollectionByName({ collection_name, creator, system }: { collection_name: string, creator: string, system: SystemWithToolkit | SystemWithWallet }) {
    console.log(`in getCollectionByName, collection_name: ${collection_name}`)
    const address = config.contracts.nftFaucet;

    const { data } = await axios.get(`${config.tzkt.api}/v1/contracts/${address}/bigmaps/assets.token_data/keys?limit=12&sort.desc=id&value.creator=${creator}&value.collection_name=${collection_name}`);
    const decoded = D.TokenDataBigMap.decode(data);

    if (isLeft(decoded)) {
        throw Error('Failed to decode `getTokenMetadata` response');
    }

    if (decoded.right.length === 0) {
        return [];
    }
    const tokens = decoded.right;
    console.log(`in getCollectionByName, tokens: ${JSON.stringify(tokens)}`)

    const tokenIds = tokens.map(({ key }) => key);

    return await nftMapper(system, tokenIds);
}

export async function getCollectionByTag({ tag, system }: { tag: string, system: SystemWithToolkit | SystemWithWallet }) {
    console.log(`in getCollectionByTag, tag: ${tag}`);
    const address = config.contracts.nftFaucet;
    const tagSpaceFix = tag.replace(' ', '_').replace('%20', '_');
    const { data } = await axios.get(`${config.tzkt.api}/v1/contracts/${address}/bigmaps/assets.token_data/keys?limit=12&sort.desc=id&value.tags.${tagSpaceFix}=tag`);

    const decoded = D.TokenDataBigMap.decode(data);

    if (isLeft(decoded)) {
        throw Error('Failed to decode `getTokenMetadata` response');
    }

    if (decoded.right.length === 0) {
        return [];
    }

    const tokenIds = decoded.right.map(({ key }) => key);

    return await nftMapper(system, tokenIds);
}

export async function getTokenSalesByCreator({ creator, system }: { creator: string, system: SystemWithToolkit | SystemWithWallet }) {
    const address = system.config.contracts.marketplace.fixedPrice.tez;
    const fixedPriceStorage = D.FixedPriceSaleStorage.decode(await system.tzkt.getContractStorage(address));

    if (isLeft(fixedPriceStorage)) {
        throw Error('Failed to decode `getFixedPriceSales` bigMap ID');
    }

    const salesId = fixedPriceStorage.right.sales;

    const { data } = await axios.get(`${config.tzkt.api}/v1/bigmaps/${salesId}/keys?sort.desc=id&value.seller=${creator}&active=true`);
    const decoded = D.FixedPriceSaleBigMap.decode(data);

    if (isLeft(decoded)) {
        throw Error('Failed to decode `getTokenMetadata` response');
    }

    if (decoded.right.length === 0) {
        return [];
    }

    const tokenIds = decoded.right.map(({ value: { sale_data: { sale_token: { token_id }}}}) => token_id);

    return await nftMapper(system, tokenIds);
}

export async function getTokenSalesByOwner({ owner, system }: { owner: string, system: SystemWithToolkit | SystemWithWallet }) {
    const address = system.config.contracts.marketplace.fixedPrice.tez;
    const fixedPriceStorage = D.FixedPriceSaleStorage.decode(await system.tzkt.getContractStorage(address));

    if (isLeft(fixedPriceStorage)) {
        throw Error('Failed to decode `getFixedPriceSales` bigMap ID');
    }

    const salesId = fixedPriceStorage.right.sales;

    const { data } = await axios.get(`${config.tzkt.api}/v1/bigmaps/${salesId}/keys?sort.desc=id&value.seller=${owner}&active=true`);
    const decoded = D.FixedPriceSaleBigMap.decode(data);

    if (isLeft(decoded)) {
        throw Error('Failed to decode `getTokenMetadata` response');
    }

    if (decoded.right.length === 0) {
        return [];
    }

    const tokenIds = decoded.right.map(({ value: { sale_data: { sale_token: { token_id }}}}) => token_id);

    return await nftMapper(system, tokenIds);
}

export async function getPurchasedToken(system: SystemWithToolkit | SystemWithWallet, address: string, owner: string) {
    const tokens = await getContractOwnedNfts(system, address, owner);
    const ownedTokens = tokens.map(({ id }) => id.toString());

    if (!ownedTokens.length) {
        return [];
    }

    const { data } = await axios.get(`${config.tzkt.api}/v1/contracts/${address}/bigmaps/token_data/keys?key${ownedTokens.length > 1 ? '.in' : ''}=${ownedTokens.toString()}&value.creator.ne=${owner}`);
    const decoded = D.TokenDataBigMap.decode(data);

    if (isLeft(decoded)) {
        throw Error('Failed to decode `getTokenMetadata` response');
    }
   
    if (decoded.right.length === 0) {
        return [];
    }

    const tokenIds = decoded.right.map(({ key }) => key);

    return await nftMapper(system, tokenIds);
}

export async function getTokenById(system: SystemWithToolkit | SystemWithWallet, tokenId: string) {
    return (await nftMapper(system, [tokenId]))[0];    
}

export async function getCuratedTokensByWallet(wallet: string) {
    const { tzkt: { api }} = config;
    const { getState } = store;
    const { system } = getState();

    const fixedPriceStorage = D.FixedPriceSaleStorage.decode(await system.tzkt.getContractStorage(system.config.contracts.marketplace.fixedPrice.tez));

    if (isLeft(fixedPriceStorage)) {
        throw Error('Failed to decode `getFixedPriceSales` bigMap ID');
    }

    const fixedPriceBigMapId = fixedPriceStorage.right.sales;

    const { data } = await axios.get(`${api}/v1/bigmaps/${fixedPriceBigMapId}/keys?value.sale_data.sales_split_map.${wallet}.as=*&value.sale_data.creator.ne=${wallet}&active=true&sort.desc=id`);
    const decoded = D.FixedPriceSaleBigMap.decode(data);

    if (isLeft(decoded)) {
        throw Error('Failed to decode `getTokenMetadata` response');
    }

    const tokenIds = decoded.right.map(({ value: { sale_data: { sale_token: { token_id }}}}) => token_id);

    if (!tokenIds.length) return [];

    return await nftMapper(system, tokenIds);
}

export async function findCollectionsByOwner(owner: string) {
    const { data: { object }} = await api.get(`${PATH}/find-by-owner/${owner}`);

    return object as CollectionModel[];
}