import React, { useState } from 'react';
import { useLocation } from 'wouter';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { MdClose } from 'react-icons/md';

import { useDispatch, useSelector } from 'reducer';
import { mintTokenAction } from 'reducer/async/actions';
import { clearForm } from 'reducer/slices/createNft';

import ErrorButton from 'components/atoms/ErrorButton';
import { FilePreview } from 'components/CreateNonFungiblePage/FilePreview';
import { ContentModal } from '..';

import * as S from './styles';

export default function ConfirmationPreview({ handleBackStep }: ViewComponentProps) {
	const dispatch = useDispatch();
    
    const createNft = useSelector(s => s.createNft);
	const { 
        selectedFile, 
        displayImageFile, 
        fields: { name, description, collection_name, category }, 
        editions, 
        royalties, 
        tags, 
        royaltiesSharing,
        isGenerator,
    } = createNft;

    const typesForDisplayImage = ['zip', 'glb', 'mp4'];

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [, setLocation] = useLocation();

    window.scrollTo(0, 0);

	return (
		<>
			<Box mb={2}>
                <S.HeaderContainer item xs={12}>
                    <Typography variant="h5" color="textPrimary">
                        <strong>Confirm Details</strong>
                    </Typography>

                    <MdClose size={32} title="Cancel" onClick={() => { dispatch(clearForm()); setLocation('/explore') }} />
                </S.HeaderContainer>
			</Box>

			<Grid container spacing={4}>
				<Grid item xs={12}>
                    <FilePreview file={selectedFile!} />
                </Grid>

                { displayImageFile && typesForDisplayImage.some(type => selectedFile!.name.includes(type)) && (
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h6" color="textPrimary">
                            <strong>Image preview</strong>
                        </Typography>

                        <FilePreview file={displayImageFile!} />
                    </Box>
                </Grid>
                )}

				<Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h6" color="textPrimary">
                            <strong>Asset Name</strong>
                        </Typography>

                        <Typography variant="body1" color="textPrimary">
                            {name}
                        </Typography>
                    </Box>
                </Grid>

				<Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h6" color="textPrimary">
                            <strong>Collection Name</strong>
                        </Typography>

                        <Typography variant="body1" color="textPrimary">
                            {collection_name}
                        </Typography>
                    </Box>
                </Grid>

				<Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h6" color="textPrimary">
                            <strong>Generator token</strong>
                        </Typography>

                        <Typography variant="body1" color="textPrimary">
                            {isGenerator ? 'Yes' : 'No'}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h6" color="textPrimary">
                            <strong>Category</strong>
                        </Typography>

                        <Typography variant="body1" color="textPrimary">
                            {category}
                        </Typography>
                    </Box>
                </Grid>

				<Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h6" color="textPrimary">
                            <strong>Tags</strong>
                        </Typography>

                        { tags && tags.length > 0 && (
                        <S.Container>
                            <S.TagContainer>
                            {tags.map((tag: string, index) => <S.Tag key={`tag-${index}-${tag}`}>{tag}</S.Tag>)}
                            </S.TagContainer>
                        </S.Container>
                        )}
                    </Box>
                </Grid>

				<Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h6" color="textPrimary">
                            <strong>Description</strong>
                        </Typography>

                        <Typography variant="body1" color="textPrimary">
                            {description}
                        </Typography>
                    </Box>
                </Grid>

				<Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h6" color="textPrimary">
                            <strong>Editions</strong>
                        </Typography>

                        <Typography variant="body1" color="textPrimary">
                            {editions}
                        </Typography>
                    </Box>
                </Grid>

				<Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h6" color="textPrimary">
                            <strong>Royalties</strong>
                        </Typography>

                        <Typography variant="body1" color="textPrimary">
                            {royalties}%
                        </Typography>
                    </Box>
                </Grid>

                { royaltiesSharing && (
				<Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h6" color="textPrimary">
                            <strong>Royalties Sharing</strong>
                        </Typography>

                        <Box display="grid" gridTemplateColumns={'65% 25%'}>
                            <Typography variant="body2"><strong>Address</strong></Typography>
                            <Typography variant="body2"><strong>Percentage (%)</strong></Typography>
                        </Box>

                        { royaltiesSharing.map(({ walletAddress, royalties }, index) => (
                        <Box display="grid" gridTemplateColumns={'65% 25%'} key={`grid-sharing-${walletAddress}`}>
                            <Typography variant="body2">{ `${walletAddress} ${index === 0 ? '(you)' : ''}`}</Typography>
                            <Typography variant="body2">{ royalties.toFixed(2) }</Typography>
                        </Box>
                        ))}
                    </Box>
                </Grid>
                )}

				{/* <Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h6" color="textPrimary">
                            <strong>Metadata</strong>
                        </Typography>

                        <Typography variant="body1" color="textPrimary">
                            Name
                        </Typography>
                    </Box>
                </Grid> */}

				<Grid item xs={12}>
                    <Button variant="contained" type="button" color="primary" size="large" onClick={() => setOpenModal(true)}>
						Create
					</Button>

					<ErrorButton type="button" color="primary" size="large" onClick={handleBackStep}>
						Back
					</ErrorButton>
				</Grid>

				<ContentModal 
					isOpen={openModal} 
					method="mintToken"
					dispatchThunk={() => dispatch(mintTokenAction())}
					onComplete={() => dispatch(clearForm())}
					afterClose={() => setLocation('/explore')}
					onClose={() => setOpenModal(false)}
					dispatchOnOpen={true}	 
					pendingAsyncMessage={
						<>
							<strong>Opening wallet...</strong>
							<br />
							<Typography variant="subtitle1">
								<span role="img" aria-label="lightbulb">
									🌱
								</span>{' Minting on Tezos produces 1,500,000 times less CO2 emissions than Ethereum.'}							
							</Typography>
						</>
					} 
				/>
            </Grid>
		</>
	);
}