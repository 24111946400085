import styled from 'styled-components';

export const CustomLogo = styled.img`
	width: 300px;
	height: 50px;
	margin: 2rem 0;
`;

export const CustomNadir = styled.img`
	width: 300px;
	height: 300px;

	margin: 2rem 0;
`;