import React from 'react';

import { EditTrax } from 'views';
import { Minimal } from 'layouts';
import WithLayout from 'WithLayout';

import { handleTitleAndScrollToTop } from 'utils';

const EditTraxPage = ({ player }: ViewComponentProps): JSX.Element => {
	handleTitleAndScrollToTop("EditTrax - Viewer");

	const favicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
	
	if (favicon) {
		favicon.href = '/assets/edit-trax/et_header.png';
	}

	return (<WithLayout component={EditTrax} layout={Minimal} player={player} branding={'EDIT TRAX'}/>);
};

export default EditTraxPage;