import React, { useState } from 'react';
import { Link } from 'wouter';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, List, ListItem, Grid, Typography, Hidden } from '@material-ui/core';

import { CardBase, Section } from 'components/organisms';
import { General, Custom, Notifications } from './components';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
	section: {
		'& .section-alternate__content': {
			marginTop: theme.spacing(-5),
			position: 'relative',
			zIndex: 1,
		},
		'& .card-base__content': {
			padding: theme.spacing(2),
			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(3),
			},
		},
	},
	menu: {
		height: 'auto',
		background: theme.topbar,
	},
	list: {
		display: 'inline-flex',
		// overflow: 'auto',
		flexWrap: 'nowrap',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			flexDirection: 'column',
			marginRight: theme.spacing(-3),
			marginLeft: theme.spacing(-3),
		},
	},
	listItemNotAllowed: {
		cursor: 'now',
	},
	listItem: {
		marginRight: theme.spacing(2),
		flex: 0,
		[theme.breakpoints.up('md')]: {
			paddingLeft: theme.spacing(3),
			borderLeft: '2px solid transparent',
		},
	},
	listItemActive: {
		[theme.breakpoints.up('md')]: {
			borderLeft: `2px solid ${theme.palette.primary.dark}`,
		},
		'& .menu__item': {
			color: theme.palette.text.primary,
		},
	},
}));

const subPages = [
	{
		id: 'general',
		href: '/account?pid=general',
		title: 'General',
	},	
	// {
	// 	id: 'billing',
	// 	href: '/account?pid=billing',
	// 	title: 'Billing Information',
	// },
	// {
	// 	id: 'notifications',
	// 	href: '/account?pid=notifications',
	// 	title: 'Notifications',
	// },
	// {
	// 	id: 'custom',
	// 	href: '/account?pid=custom',
	// 	title: 'Custom',
	// },
];

interface TabPanelProps {
	children: JSX.Element;
	value: string | string[] | number | null;
	index: string | string[] | number | null;
};

const TabPanel = ({ children, value, index, ...other }: TabPanelProps): JSX.Element => (
	<Box component="div" hidden={value !== index} {...other}>
		{value === index && children}
	</Box>
);

const Account = (): JSX.Element => {
	const classes = useStyles();

	const [isPremium] = useState(true);

	let pageId = new URLSearchParams(window.location.search).get('pid') || 'general';

	return (
		<S.Wrapper>
			<Section className={classes.section}>
				<Grid container spacing={4}>
					<Grid item xs={12} md={3}>
						<CardBase withShadow align="left" className={classes.menu}>
							<List disablePadding className={classes.list}>
							{subPages.map((item, index) => {
								return (
									(item.id === 'custom' && !isPremium) ? (
									<ListItem
										key={`subpages-${index}-${item.id}`}
										component={'a'}
										className={clsx(classes.listItem, pageId === item.id ? classes.listItemActive : {})}
										href="#"
										disabled
										disableGutters
									>
										<Typography variant="subtitle1" noWrap color="textSecondary" className="menu__item">
											{ (item.id === 'custom' && !isPremium) ? 'Custom (Premium only)' : item.title}
										</Typography>
									</ListItem>
									) :
									<Link href={item.href}>
										<ListItem
											key={`subpages-${index}-${item.id}`}
											component={'a'}
											href={item.href}
											className={clsx(classes.listItem, pageId === item.id ? classes.listItemActive : {})}
											disabled={!!(item.id === 'custom' && !isPremium)}
											disableGutters
										>
											<Typography variant="subtitle1" noWrap color="textSecondary" className="menu__item">
												{ (item.id === 'custom' && !isPremium) ? 'Custom (Premium only)' : item.title}
											</Typography>
										</ListItem>
									</Link>
								)
							})}
							</List>
						</CardBase>
					</Grid>

					<Grid item xs={12} md={9}>
						<CardBase withShadow align="left" className={classes.menu}>
							<>
								<TabPanel value={pageId} index={'general'}>
									<General />
								</TabPanel>

								<Hidden smDown smUp>
									<TabPanel value={pageId} index={'notifications'}>
										<Notifications />
									</TabPanel>

									<TabPanel value={pageId} index={'custom'}>
										<Custom />
									</TabPanel>
								</Hidden>
							</>
						</CardBase>
					</Grid>
				</Grid>
			</Section>
		</S.Wrapper>
	);
};

export default Account;