import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import Logo from '../Logo';

const Wrapper = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;

        @-webkit-keyframes fadeOut {
            0% {opacity: 1;}
            100% {opacity: 0;}
        }
        
        @keyframes fadeOut {
            0% {opacity: 1;}
            100% {opacity: 0;}
        }
        
        svg, img {
            max-width: 300px;
            animation-name: fadeOut;
        }
        
        .MuiCircularProgress-colorPrimary {
            color: ${theme.palette.text.primary};
        }
        
        transition: opacity 500ms ease-in-out;
        animation-name: fadeOut;
        /* opacity: 0.1; */
    `}
`;

const Loader = (): JSX.Element => {
    return (
        <Wrapper>
            <img src="/assets/edit-trax/temp.GIF" alt="Edit Trax splash screen with digital faces"/>
            {/* <Logo /> */}
        </Wrapper>
    );
}

export default Loader;