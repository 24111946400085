import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

import * as S from './style';

const Story = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	return (
		<S.Container className={className} {...rest}>
			<Grid container spacing={isMd ? 4 : 2}>
				<Grid item xs={12}>
					<SectionHeader
						title="What is an NFT?"
						align="left"
						disableGutter
						subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
					/>

					<Typography>
						<a href="https://en.wikipedia.org/wiki/Non-fungible_token" target="_blank" rel="noopener noreferrer">
							<strong>(From Wikipedia)</strong>							
						</a>
						&nbsp;A Non-Fungible Token is a unique and non-interchangeable unit of data stored on a digital ledger (also known as a blockchain). NFTs can be associated with easily-reproducible items such as photos, videos, audio, and other types of digital files as unique items (analogous to a certificate of authenticity), and use blockchain technology to give the NFT a verified and public proof of ownership. Copies of the original file are not restricted to the owner of the NFT, and can be copied and shared like any file. The lack of interchangeability (fungibility) distinguishes NFTs from blockchain cryptocurrencies, such as Tezos or Bitcoin.
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<SectionHeader
						title="What is a Virtual Collectible on Orbix360?"
						subtitle="A Virtual Collectible (or just “Collectible) is an NFT associated with digital artwork (e.g., a virtual reality scene, an augmented reality object, a photo or video…) that is displayed and/or for sale on the Orbix360 marketplace."
						align="left"
						disableGutter
						subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
						/>
				</Grid>

				<Grid item xs={12}>
					<SectionHeader
						title="I don't have a crypto wallet, where can I get one?"
						subtitle="You'll need a crypto wallet to mint, buy, or sell a collectible, and it’s easy to sign up for one with some of these popular options below. Orbix360 supports all of these choices, and has been extensively tested with the Kukai wallet."
						align="left"
						disableGutter
						subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
					/>

					<Typography>
						<S.List>
							<li>
								<a href="https://docs.kukai.app/learn-kukai/new-wallet">
									<strong>Kukai Wallet</strong>
								</a> (desktop & web wallet)
							</li>

							<li>
								<a href="https://www.youtube.com/watch?v=S8_tL8PfCts&t=176s">
									<strong>Temple Wallet</strong>
								</a> (browser extension wallet)
							</li>

							<li>
								<a href="https://spirewallet.com">
									<strong>Spire Wallet</strong>
								</a> (browser extension wallet)
							</li>

							<li>
								<a href="https://airgap.it/help-center/">
									<strong>Airgap Wallet</strong>
								</a> (mobile wallet)
							</li>							
						</S.List>
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<SectionHeader
						title="I want to buy a collectible, how do I get Tezos?"
						subtitle="Once you have a crypto wallet, if you intend to buy a collectible, you’ll need Tezos. You can buy Tezos using fiat currencies (e.g., US Dollars, Euro…) or other crypto currencies on a crypto currency exchange. Here are links to some popular options:"
						align="left"
						disableGutter
						subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
					/>

					<Typography>
						<S.List>
							<li>
								<a href="http://binance.com" target="_blank" rel="noopener noreferrer">
									<strong>Binance (tutorial)</strong>
								</a>
							</li>

							<li>
								<a href="https://crypto.com/app" target="_blank" rel="noopener noreferrer">
									<strong>Crypto.com</strong>
								</a>
							</li>

							<li>
								<a href="https://tzkt.io/buy-tezos" target="_blank" rel="noopener noreferrer">
									<strong>TzKT</strong>
								</a>
							</li>

							<li>
								<a href="https://www.moonpay.com" target="_blank" rel="noopener noreferrer">
									<strong>MoonPay</strong>
								</a>
							</li>

							<li>
								<a href="https://www.coinbase.com/signup" target="_blank" rel="noopener noreferrer">
									<strong>Coinbase</strong>
								</a>
							</li>							
						</S.List>
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<SectionHeader
						title="How do I connect my wallet to Orbix360?"
						align="left"
						disableGutter
						subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
					/>

					<Typography>
						At top right of the&nbsp;
						<a href="https://minter.orbix360.com/" style={{textDecoration: 'underline'}}>
								<strong>Orbix360 Marketplace</strong>
						</a> screen is a button labelled “Connect Wallet."
						<br />
					</Typography>

					<img src="/assets/connect-wallet.png" alt="" />
					<br />

					<Typography>
						Press this button and select the wallet type you have:
						<br />
					</Typography>

					<img src="/assets/connect-wallet-2.png" alt="" />
					<br />

					<Typography>
						Based on the type of wallet you use, the connection process will differ from here.
						<br />
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<SectionHeader
						title="I can’t connect my wallet to Orbix360"
						subtitle="Orbix360 has noted occasional issues when connecting wallets to the Orbix360 NFT Marketplace. These issues are usually resolved by using the Chrome Browser."
						align="left"
						disableGutter
						subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
						/>
				</Grid>

				<Grid item xs={12}>
					<SectionHeader
						title="I’m not an artist, can I make a collectible?"
						align="left"
						disableGutter
						subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
					/>

					<Typography>
						Yes you can! An <a href="http://orbix360.com" target="_blank" rel="noopener noreferrer"><strong>Orbix360 Creator</strong></a> account is free, and, following our tutorials, you can create a virtual reality space and fill it with, for example, photos you take with your smartphone or some of our pre-built objects and backgrounds. You can then export your creation and “mint" an NFT on Orbix360. The collectible will be listed as owned by your wallet.						
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<SectionHeader
						title="How do I sell my collectible on Orbix360?"
						subtitle="On Orbix360, when viewing the NFTs owned by your wallet, you will have the option to list one or more of them for sale. You will be transferring the object to the Orbix360 Marketplace according to the terms of the Orbix360 Marketplace Contract you create by setting a sales price (don’t worry, you can press a button to take it back at any time prior to a sale.) The Orbix360 Marketplace Contract also allows you to set and receive a royalty on the re-sale of your collectible when that sale takes place on the Orbix360 Marketplace. Orbix360 receives an industry-standard 2.5% commission on any sale that takes place on the Orbix360 Marketplace."
						align="left"
						disableGutter
						subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
						/>
				</Grid>

				<Grid item xs={12}>
					<SectionHeader
						title="What can I earn selling collectibles?"
						subtitle="We’ve all read about the famous artists and their multi-million dollar NFT sales. Like a lot of artwork, a virtual collectible’s value is in the eye of the beholder (your potential buyers.) If you were lucky enough to have a reputation as an artist, your work would be sought after by your followers, and your asking price might reflect that anticipated demand. With new artists, this is a trickier question to answer. Take a look at some of the other collectibles listed on the Orbix360 Marketplace of a similar nature to yours, and see how they are priced. Consider making a series of similar items with a small change between each member of a series (change a color or an object within a scene) and list them as a limited series. If you create ten members of a series, list the first two or three and adjust the price of the later members as you learn more about their value."
						align="left"
						disableGutter
						subtitleProps={{ color: 'textPrimary', variant: 'body1' }}
						/>
				</Grid>
			</Grid>
		</S.Container>
	);
};

export default Story;
