import { Nft } from "@alch/alchemy-web3";
import api from "./api";

const PATH = '/ethereum-mapping';

export async function ownedTokensByEthereumWallet() {
    const { data } = await api.get(`${PATH}/owned-tokens`);
    
    return data;
}

export async function checkIfUserHasSpecificToken(tokenContract: string, tokenId: string) {
    const { data: { error, message, object }} = await api.get(`${PATH}/check-token/${tokenContract}/${tokenId}`);
    
    return { error, message, object };
}

export async function tezosWalletToDrop() {
    const { data } = await api.get(`${PATH}/call-oracle`);
    
    return data;
}

export async function getSyncedTokensFromCollection(collection: string) {
    const { data: { object }} = await api.get(`${PATH}/synced-tokens/${collection}`);
    
    return object as Nft[];
}