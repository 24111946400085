import styled, { css } from 'styled-components';

export const Container = styled.div`
    .card-base {
        max-height: 380px;
        height: auto !important;

        padding: 32px 0;
        overflow-y: auto;
    }

    .card-base__content {
        padding-top: 0;
        padding-bottom: 0;
    }

`;

export const UploadContainer = styled.div`
    ${({ theme }) => css`
        width: 100%;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23C4C4C4FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='20' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 10px;
        padding: ${theme.spacing(2, 4)};
    `}
`;

export const UploadContent = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;      
        align-items: center;
        justify-content: center;  

        > * {
            margin: ${theme.spacing(1, 0)};

            &:last-child {
                margin-bottom: 0;
            }
        }

    `}
`;

export const UploadText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const UploadIcon = styled.img`
    width: 110px;
    height: 110px;
`;

export const GeneratorsContainer = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;

        img {
            border-radius: 16px;
            height: 150px;
        }

        ${theme.breakpoints.down('sm')} {
            grid-template-columns: 1fr;
        }

        .selected {
            border: 3px solid #377dff;
        }
    `}
`;

export const ImageBox = styled.div`
    ${({ theme }) => css`
        position: relative;

        > svg {
            position: absolute;
            top: 8px;
            right: 16px;
        }
    `}
`;
