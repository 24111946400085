import React from 'react';
import styled from 'styled-components';

import { getUrlQueryParams } from 'utils';
import { getCollaborationsTokens } from 'services/users';
  
import BaseToken from 'templates/BaseToken';
import { Cover } from './components';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;  

/**
  * @param editionId 
  * @param creator 
*/
export default function CuratedView(props: ViewComponentProps) {
  const { curator } = props;

  const pageNumber = Number(getUrlQueryParams()['page']) || 1;
  const limit = 18, filter: Filter = { limit, offset: (pageNumber - 1) * limit, orderBy: 'tokenId', descending: true };

  return (
    <Wrapper>
      <Cover curator={props.curator} />

      <BaseToken 
        handleChange={async () => await getCollaborationsTokens(filter, curator)}
        handleChangeProps={{filter, curator}}
        limit={limit}
        filter={filter}
        pageNumber={pageNumber}
      />

      {/* <Result {...props} /> */}
    </Wrapper>
  );
}
