import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Section, HeroSimpleBackground } from 'components/organisms';
import { Link } from 'wouter';

import * as S from './styles';

const Hero = ({ className, ...rest }: ViewComponentProps): JSX.Element => (
  <div className={className} {...rest}>
    <HeroSimpleBackground backgroundImage="/assets/banner-bg.svg">
      <Section narrow>
        <>
          <SectionHeader
            title={
              <span>
                Create, Mint, Discover,<br/>Collect, and Sell<br/>extraordinary{' '}
                art pieces
              </span>
            }
            titleVariant="h3"
            subtitle="Orbix360 is a green NFT platform built for Metaverse artists to push the limits of what Digital Collectibles can be."
            ctaGroup={[
              <S.Container>
                <Link href="/explore">
                  <Button color="primary" variant="contained" size="large">
                    Get started
                  </Button>
                </Link>
                
                <Link href="/faq">
                  <Button color="primary" variant="contained" size="large">
                    Learn more
                  </Button>
                </Link>
              </S.Container>
            ]}
            disableGutter
          />
        </>
      </Section>
    </HeroSimpleBackground>
  </div>
);

export default Hero;
