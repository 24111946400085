import React, { useEffect, useState } from 'react';
import { Nft } from 'lib/nfts/decoders';
import { Config } from 'lib/system';

import { ipfsUriToGatewayUrl } from 'lib/util/ipfs';
import { Link } from 'wouter';

import * as S from './styles';
import { useSelector } from '../../../reducer';

interface MediaContentProps {
    token: Nft;
    config: Config;
    useDisplayImage?: boolean;
}

export default function MediaContent({ token, config, useDisplayImage = false }: MediaContentProps) {
    const { artifactUri, displayUri, id, address } = token;
    const src = ipfsUriToGatewayUrl(config, artifactUri);
    const imageSrc = ipfsUriToGatewayUrl(config, displayUri);
    
    const [obj, setObj] = useState<{ url: string; type: string } | null>(null);
    const system = useSelector(s => s.system);
    const srcWithParams = `${src}?viewer=${system.tzPublicKey}&creator=test`
    console.log(`srcWithParams: ${srcWithParams}`)
    
    const HandleImage = () => {
        return (
            <Link href={`/collection/${address}/token/${id}`}>
                <S.AtomImage src={imageSrc} lazyProps={{ width: '100%', height: '100%' }} />
            </Link>
        );    
    }
    
    useEffect(() => {
        (async () => {
            let blob;
            
            try {
                blob = await fetch(src).then(r => r.blob());
                setObj({ url: URL.createObjectURL(blob), type: blob.type });
            } catch (e) {
                console.log(e);
            }
        })();
    }, [src]);
    
    
    if (!obj) {
        return <></>;
    }

    useDisplayImage = !(/^video\/.*/.test(obj.type));

    if (useDisplayImage) {
        return <HandleImage />
    }

    if (/^video\/.*/.test(obj.type)) {
        return (
            <S.Video
                preload="metadata"
                onClick={e => e.preventDefault()}
                onMouseEnter={e => e.currentTarget.play()}
                onMouseLeave={e => e.currentTarget.pause()}
                onLoadedMetadata={e => { (e.target as HTMLVideoElement).currentTime = .05; }}
                muted
                controls
            >
                <source src={obj.url} type={obj.type} />
            </S.Video>
        );
    }

    if (token.metadata.formats?.length) {
        if (['model/gltf-binary', 'model/gltf+json'].includes(token.metadata.formats[0].mimeType!)) {
            return (
                <S.ModelViewer>
                    <model-viewer
                        auto-rotate
                        rotation-per-second="30deg"
                        camera-controls
                        src={obj.url}
                    />
                </S.ModelViewer>
            );
        } else if (token.metadata.formats[0].mimeType === 'application/zip') {
            return (<iframe src={srcWithParams} title={'iframe-viewer'}></iframe>);
        }
    }

    return <HandleImage />
}
