import * as firebase from 'firebase/app';
import * as Auth from 'firebase/auth';
import APP_CONFIG from 'config/app_config';

if (typeof window !== undefined && !firebase.getApps().length) {
	firebase.initializeApp({
		apiKey: APP_CONFIG.FIREBASE_API_KEY,
		authDomain: APP_CONFIG.FIREBASE_AUTH_DOMAIN,
		projectId: APP_CONFIG.FIREBASE_PROJECT_ID,
	}); 
}


export const auth = Auth;

export default firebase;