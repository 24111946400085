import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Result } from './components';

import { useDispatch, useSelector } from 'reducer';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;  

export default function ExploreView() {
    const { system, collections } = useSelector(s => s);
    const dispatch = useDispatch();
  
    const globalCollection = collections.collections[collections.globalCollection];
  
    useEffect(() => {
      if (!globalCollection) {
        return;
      }
  
      if (collections.selectedCollection === null) {
        return;
      }
    }, [globalCollection, collections.selectedCollection, collections.globalCollection, dispatch]);
  
    useEffect(() => {
      if (system.status === 'WalletConnected') {
      }
    }, [system.status, dispatch]);
  
    return (
      <Wrapper>
        <Result address={system.config.contracts.nftFaucet} />
      </Wrapper>
    )
}
