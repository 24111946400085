import { darken, Typography } from "@material-ui/core";
import styled, { css } from "styled-components";
import { Link } from "wouter";

export type RibbonColors = 'primary' | 'secondary';
export type RibbonSizes = 'normal' | 'small';

const wrapperModifiers = {
	color: () => css`
		background-color: #303030;

		&::before {
			border-left-color: ${darken("#dbdbdb", 0.2)};
			border-top-color: ${darken("#dbdbdb", 0.2)};
		}
	`,

	normal: () => css`
		font-size: 14px;
		padding: 0 12px;
		height: 32px;
		right: -20px;

		&::before {
			top: 32px;
			border-top-width: 1rem;
			border-right-width: 20px;
		}
	`,
}

export const Container = styled.div`
    position: relative;
`;

export const Sale = styled(Typography)`
    ${({ theme }) => css`
        display: flex;
        align-items: center;

        svg {
            width: 14px;
            height: 14px;
            
            fill: ${theme.palette.text.primary};
        }        
    `}
`;

export const FlexLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
`;

export const Ribbon = styled.div`
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #fff;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        border-style: solid;
        border-left-width: 0rem;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-bottom-width: 1rem;
    }

    ${wrapperModifiers.color()};
    ${wrapperModifiers.normal()};
`;