import React, { useCallback, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, FormControlLabel, Checkbox, Button, Divider } from '@material-ui/core';
import api from 'services/api';
import useAuth from 'hooks/auth';

const useStyles = makeStyles(() => ({
	titleCta: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));

interface Notification {
	[key: string]: string;
}

const Notifications = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
	const classes = useStyles();
	const { token } = useAuth();

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	const headers = {
		'Authorization': `Bearer ${token}`,
	};

	const [notifications, setNotifications] = useState<Notification>({});

	useCallback(() => {
		(async () => {
			const response = await api.get('/account/profile/data', { headers });

			if (response.status === 200) {
				const notificationsResponse: string[] = response.data.notification_settings;

				const reducedNotifications: Notification = notificationsResponse.reduce(function (acc: Notification, next) {
					acc[next] = next;

					return acc;
				}, {});

				setNotifications(reducedNotifications);
			}
		})();
	}, []);

	function handleChekcbox(notificationKey: string) {
		if (notifications[notificationKey]) {
			const newNotifications = JSON.parse(JSON.stringify(notifications));
			delete newNotifications[notificationKey];

			setNotifications(newNotifications);
		} else {
			setNotifications({ ...notifications, [notificationKey]: notificationKey });
		}
	}

	function handleResetAll() {
		const notificationsReset = {
			liked: 'liked',
			commented: 'commented',
			followed: 'followed',
			featured: 'featured',
		};

		setNotifications({ ...notificationsReset });
	}

	async function handleUpdateNotifications() {
		try {
			const notificationsKeys = Object.keys(notifications);
			const response = await api.post('/account/profile/field', {
				value: notificationsKeys,
				field: 'notification_settings'
			}, { headers });

			alert("Notifications settings updated");

			console.log(response);
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<div className={className} {...rest}>
			<Grid container spacing={isMd ? 4 : 2}>
				<Grid item xs={12}>
					<div className={classes.titleCta}>
						<Typography variant="h6" color="textPrimary">Notifications</Typography>
						<Button variant="outlined" color="primary" onClick={handleResetAll}>Reset all</Button>
					</div>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom>Emails</Typography>
					<Typography variant="caption" gutterBottom>You will recieve emails in your business email address</Typography>

					<div>
						<div>
							<FormControlLabel control={<Checkbox defaultChecked={true} color="primary" onChange={() => handleChekcbox('liked')} checked={!!(notifications?.liked)} />} label="Notify me when someone likes my photo" />
						</div>

						<div>
							<FormControlLabel control={<Checkbox defaultChecked={true} color="primary" onChange={() => handleChekcbox('commented')} checked={!!(notifications?.commented)} />} label="Notify me when another user leaves a comment on my post or on a thread I commented on" />
						</div>

						<div>
							<FormControlLabel control={<Checkbox defaultChecked={true} color="primary" onChange={() => handleChekcbox('followed')} checked={!!(notifications?.followed)} />} label="Notify me when another user starts following me" />
						</div>
					</div>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom>Information, features</Typography>
					<Typography variant="caption" gutterBottom>You will recieve emails in your business email address</Typography>

					<div>
						<div>
							<FormControlLabel control={<Checkbox defaultChecked={false} color="primary" onChange={() => handleChekcbox('featured')} checked={!!(notifications?.featured)} />} label="Notify me when another user starts following me" />
						</div>
					</div>
				</Grid>

				<Grid item container justify="flex-start" xs={12}>
					<Button variant="contained" color="primary" size="large" onClick={handleUpdateNotifications}>Save</Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default Notifications;
