import React from 'react';
import { Editions } from 'views';
import { Main } from 'layouts';
import WithLayout from 'WithLayout';
import { handleTitleAndScrollToTop } from 'utils';

const EditionsPage = (props: ViewComponentProps): JSX.Element => {
	handleTitleAndScrollToTop("Minter - Editions");
	
	return (<WithLayout component={Editions} layout={Main} {...props} />)
};

export default EditionsPage;