import React, { useState, useEffect } from 'react';
import { Link } from 'wouter';
import { Avatar, Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Nft } from 'lib/nfts/decoders';
import { ipfsUriToGatewayUrl } from 'lib/util/ipfs';
import { Config } from 'lib/system';
import { useSelector } from 'reducer';

import { Image } from 'components/atoms';
import { CardProduct } from '..';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
    image: {
        objectFit: 'cover',
        height: 300,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
          height: 250,
          width: '100%'
        }    
    },
    video: {
        width: '100%',
    },
    blogContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    list: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          alignItems: 'start',
          flexDirection: 'column',
        }
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          justifyContent: 'center',
        }
    },
    cardProduct: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRadius: theme.spacing(1),
        '& .card-product__content': {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
          height: 'auto',
    
          '& .card-product__content': {
            padding: theme.spacing(1)
          }
        }
    },                        
}));

type CardTokenProps = {
    hasTitle?: boolean;
    config: Config;
} & Nft;

export default function TokenSelectionItem(props: CardTokenProps) {
    const { config, hasTitle = false, title, artifactUri, displayUri, sale } = props;

    const classes = useStyles();
    const { collections: { globalCollection: address }} = useSelector(s => s);

    console.log(`in TokenSelectionItem, about to call ipfsUriToGatewayUrl`);
    const src = ipfsUriToGatewayUrl(config, artifactUri);
    console.log(`in TokenSelectionItem, src: ${src}`);
    const [obj, setObj] = useState<{ url: string; type: string } | null>(null);

    useEffect(() => {
        console.log(`in TokenSelectionItem useEffect, src: ${src}`);
        (async () => {
            console.log(``)
            let blob;
            console.log(`in TokenSelectionItem useEffect async, src: ${src}`);
            try {
                blob = await fetch(src).then(r => r.blob());
                setObj({ url: URL.createObjectURL(blob), type: blob.type });
            } catch (e) {
                console.log(e);
            }            
        })();
    }, [src]);

    const CardMediaContent = () => {
        if (!obj) {
            return <></>;
        }

        if (/^video\/.*/.test(obj.type)) {
            return (
                <video
                    preload="metadata"
                    onClick={e => e.preventDefault()}
                    onMouseEnter={e => e.currentTarget.play()}
                    onMouseLeave={e => e.currentTarget.pause()}
                    onLoadedMetadata={e => { (e.target as HTMLVideoElement).currentTime = .05; }}
                    muted
                    controls
                    className={clsx(classes.image, classes.video)}
                >
                    <source src={obj.url} type={obj.type} />
                </video>
            );
        }

        const imageSrc = ipfsUriToGatewayUrl(config, displayUri);

        return (
                <Image src={imageSrc} className={classes.image} lazyProps={{ width: '100%', height: '100%' }}/>
        );
    }
    
    const CardContent = () => (
        <div className={classes.blogContent}>
            { hasTitle && (
            <>
                <Box>
                        <Typography variant="h6" color="textPrimary">
                            {title.slice(0, 20)}
                        </Typography>

                        { sale && (
                        <Typography variant="body1" color="textSecondary">
                            {`${sale.price} ꜩ`}
                        </Typography>
                        )}
                </Box>
            </>
            )}

            <div style={{ flexGrow: 1 }} />

            <Divider className={classes.divider} />

            <Box className={classes.list}>
                <Box className={classes.avatarContainer}>
                    <S.FlexLink href={`/creator/${props.metadata.minter}`}>
                        <Avatar src={`https://services.tzkt.io/v1/avatars2/${props.metadata.minter}`} className={classes.avatar} />

                        <Typography variant="body2" color="textPrimary" title={props.metadata.minter}>
                            { `${props.metadata.minter?.slice(0, 16) ?? props.owner.slice(0, 16)}...` }
                        </Typography>
                    </S.FlexLink>
                </Box>
            </Box>
        </div>
    );
    console.log(`in TokenSelectionItem`)
    return (
        <>
            <CardProduct
                withShadow
                liftUp
                className={classes.cardProduct}
                mediaContent={
                        <CardMediaContent />
                }
                cardContent={<CardContent />}
            />
        </>
    );
}
