import useAuth from 'hooks/auth';
import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'reducer';
import { mintEditTraxTokenAction } from 'reducer/async/actions';

import { ConnectButton } from '..';

import * as S from './styles';

const Hero = ({ contract, className, isOwner, ownedToken, isThirdParty, ...rest }: ViewComponentProps & { contract: Contracts }): JSX.Element => {
	const { user } = useAuth();
	const system = useSelector(s => s.system);
	const dispatch = useDispatch();

	const [isMinting, setIsMinting] = useState<boolean>(false);

	// @ts-ignore
	// let playerConfig = playerConfigs[playerName];
	let artifactUri = contract?.metadata?.artifactUri;
	let artifactUrl = artifactUri?.replace('ipfs://', 'https://orbix360.mypinata.cloud/ipfs/')
	let src = `${artifactUrl}/?viewer=${system.tzPublicKey}&creator=${contract.metadata?.minter || ''}&contract=${contract.contractAddress}&objkt=${ownedToken}`;
	if (contract?.slug == 'download-mechanism') src = `https://orbix360.mypinata.cloud/ipfs/Qmam1WSJyrNnqtsmFxcyYhA8b2ySod39xTG8SCPgRigrqf/?creator=tz1cpiv1qgjzNsMbqHYyUdH8XzZ672bjdm2E&objkt=781874&viewer=${system.tzPublicKey}`;
	if (contract?.slug == 'alpha-test') src = `https://orbix360.mypinata.cloud/ipfs/QmaLQJCQ2HW514EUpzRfwKa7GuhFxXAHXKXgj9rxyZ26m1/?creator=tz1cpiv1qgjzNsMbqHYyUdH8XzZ672bjdm2E&objkt=612561&viewer=${system.tzPublicKey}`;
	if (contract?.slug == 'burnt') src = `https://orbix360.mypinata.cloud/ipfs/QmZ7DbkrLinWghpbDw2Ebn3FvJfPAn6yrJWjzkDe2bUxib/?creator=tz1cpiv1qgjzNsMbqHYyUdH8XzZ672bjdm2E&objkt=781875&viewer=${system.tzPublicKey}`;
	if (contract?.slug != 'download-mechanism' && contract?.slug != 'alpha-test' && contract?.slug != 'burnt') src = 'https://droppreview.on.fleek.co/';

	const handleMint = (): void => {
		console.log(`******* in handleMint, isOwner: ${isOwner}`);
		if (!isOwner) {
			setIsMinting(true);

			dispatch(mintEditTraxTokenAction({ contract: contract }));

			setIsMinting(false);
		}
	};

	const handleBuy= (): void => {
		if (contract.slug == 'download-mechanism') {
			window.open('https://hic.af/o/781874', '_blank');
		}
		if (contract.slug == 'alpha-test') {
			window.open('https://hic.af/o/612561', '_blank');
		}
		if (contract.slug == 'burnt') {
			window.open('https://hic.af/o/781875', '_blank');
		}
	};

	window.addEventListener('message', async (event) => {
		// Do we trust the sender of this message?  (might be
		// different from what we originally opened, for example).
		//if (event.origin !== "http://example.com")
		//    return;
		if (event.data && typeof event.data === 'string' && event.data.indexOf('{') >= 0) {
			let eventData = null;

			try {
				eventData = JSON.parse(event.data);
			} catch (e) {
				console.log('error parsing event data');
			}
			
			if (eventData) {
				console.log(`in message event listener, eventData.name: ${eventData.name}`);

				if (eventData.name === 'get-wallet') {
					console.log(`in message event listener, get-wallet, system.tzPublicKey: ${system.tzPublicKey}`);
					const messageData = {
						name: 'wallet-info',
						data: {
							viewer: system.tzPublicKey,
							contract: contract.contractAddress,
							token_id: ownedToken
						}
					}

					var iframe = (document?.querySelector('#nft-viewer-iframe') as HTMLIFrameElement)
					if (iframe != null && iframe.contentWindow != null) {
						iframe.contentWindow.postMessage(JSON.stringify(messageData), "*");
					}
				}
			}
		}
	});

	useEffect(() => {
	});

	return (
		<S.Container>
            <iframe src={src} title="iframe" id={'nft-viewer-iframe'} />

			{ contract.slug != 'download-mechanism' && contract.slug != 'alpha-test' && contract.slug != 'burnt' && (
			<S.ButtonContainer>
				{ !user ?
					<ConnectButton text={!user ? 'Connect To Collect' : ''} /> :
					<S.MintButton onClick={handleMint} disabled={isMinting || isOwner}>
						{ isMinting ? 'Minting...' : isOwner ? 'Owned' : 'Mint' }
					</S.MintButton>
				}
			</S.ButtonContainer>
			)}

			 { (contract.slug == 'download-mechanism' || contract.slug == 'alpha-test' || contract.slug == 'burnt') && (
			<S.MintButton onClick={handleBuy} disabled={isMinting}>
				{'COLLECT'}
			</S.MintButton>
			)}
		</S.Container>
	);
};

export default Hero;