import React, { useState } from 'react';
import { Link } from 'wouter';
import { Box, Grid, Modal } from '@material-ui/core';
import { sendEditTraxContactForm } from 'services/mail';

import * as S from './styles';

export default function About(): JSX.Element {
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    const [fullname, setFullname] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    async function handleContactForm() {
        setIsLoadingForm(true);

        try {
            if (!fullname || !email || !message) {
                alert("You need to provide the required fields");
                return false;
            }

            const response = await sendEditTraxContactForm(fullname, email, message);

            if (!response.error) {
                alert("Message sent successfully! Thank you");

                setEmail('');
                setFullname('');
                setMessage('');
                setIsLoadingForm(false);
                
                return true;
            }
            
            alert("Message wasn't sent successfully! Please try again");
            setIsLoadingForm(false);
            return false;
        } catch (error: any) {
            console.log({ error });
            setIsLoadingForm(false);
            return false;            
        }
    }

    return (
        <S.Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <S.ModalBox>
                    <S.Image>                
                        <img src={`/assets/edit-trax/content/content-01.jpg`} alt="" />
                    </S.Image> 
                </S.ModalBox>
            </Modal>

            <Grid container>
                <Grid item xs={12} md={5}>
                    <S.Title>Handbook</S.Title>

                    <S.Sections>
                        <a href="https://www.figma.com/proto/uKDW61BiLP9oiVznvvAVXu/Handbook_EditTrax?page-id=0%3A1&node-id=2%3A1167&viewport=361%2C319%2C0.02&scaling=scale-down" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/edit-trax/handbook_2.png" alt="Handbook with EditTrax logo" />
                        </a>
                    </S.Sections>
                </Grid>

                <Grid item xs={12} md={2} />

                <Grid item xs={12} md={5}>
                    <S.Title>Marketplace</S.Title>

                    <S.Sections>
                        <Link href="/marketplace/edit-trax/">
                            <img src="/assets/edit-trax/marketplace_2.png" alt="Marketplace with EditTrax logo" />
                        </Link>
                    </S.Sections>
                </Grid>

                <Grid item xs={12} md={5}>
                    <S.Title>Github</S.Title>

                    <S.Sections>
                        <a href="https://github.com/Bai-ee/ET00_Player_Code" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/edit-trax/github_2.png" alt="Github opensource link with EditTrax logo" />
                        </a>
                    </S.Sections>
                </Grid>

                <Grid item xs={12} md={2} />

                <Grid item xs={12} md={5}>
                    <S.SubmitBox>
                        <S.Title>Contact</S.Title>

                        <Box display="flex" marginBottom='10px'>
                            <input
                                placeholder="Full name"
                                name="fullname"
                                value={fullname}
                                onChange={(e) => setFullname(e.target.value)}
                            />

                            <input
                                placeholder="E-mail"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Box>

                        <textarea
                            placeholder="Message"
                            name="description"
                            rows={6}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />

                        <S.RoundButton onClick={handleContactForm} disabled={isLoadingForm}>Submit</S.RoundButton>
                    </S.SubmitBox>
                </Grid>
            </Grid>
        </S.Container>
    );
}