import React from 'react';
import { Switch, Route } from 'wouter';
import { Flex } from '@chakra-ui/react';

import CreateNonFungiblePage from 'components/CreateNonFungiblePage';
import CollectionsCatalog from 'components/Collections/Catalog';
import CollectionDisplay from 'components/Collections/Catalog/CollectionDisplay';
import CollectionsTokenDetail from 'components/Collections/TokenDetail';
import MarketplaceCatalog from 'components/Marketplace/Catalog';
import Creator from 'components/Creator';

import Header from 'components/common/Header';
import Notifications from 'components/common/Notifications';

import { 
    Home, 
    Collection, 
    Explore,
    Ethereum,
    CollectionDetail, 
    Collections, 
    MyItems, 
    CreateNFT,
    CreateCustomContract,
    About,
    EditTrax,
    Faq,
    Docs,
    ContactUs, 
    Marketplace, 
    Search, 
    TokenSelection, 
    Categories, 
    Account, 
    Editions, 
    Curated,
    Contracts,
    EditTraxMarketplace,
    CustomTokenDetail,
    Sandbox,
    SignMessage,
    SyncEthereum
} from 'pages';

export default function Routes() {
    return (
        <Switch>
            {/* START Material Routes */}
          <Route path="/edit-trax/:player">
            {({player}) => <EditTrax player={player} />}
          </Route>
          <Route path="/" component={Home} />
            <Route path="/marketplace" component={Marketplace} />
            <Route path="/collections" component={Collections} />
            <Route path="/explore" component={Explore} />

            <Route path="/collection/:contractAddress/token/:tokenId">
                {({ contractAddress, tokenId }) => (<Collection contractAddress={contractAddress} tokenId={parseInt(tokenId)} />)}
            </Route>
            
            <Route path="/creator/:minter">
                {({ minter }) => <MyItems minter={minter} />}
            </Route>

            <Route path="/collection-detail/:creator/:collection">
                {({ collection, creator }) => <CollectionDetail collection={collection} creator={creator} />}
            </Route>

            <Route path="/editions/:creator/:editionId">
                {({ editionId, creator }) => <Editions creator={creator} editionId={editionId} />}
            </Route>

            <Route path="/curated-gallery/:curator">
                {({ curator }) => <Curated curator={curator} />}
            </Route>

            <Route path="/token-selection" component={TokenSelection} />

            <Route path="/categories/:category">
                {({ category }) => <Categories category={category} />}
            </Route>

            <Route path="/account" component={Account} />            
            <Route path="/create" component={CreateNFT} />

            <Route path="/custom-contract" component={CreateCustomContract} />

            <Route path="/search" component={Search} />
            <Route path="/sign-message" component={SignMessage} />
            <Route path="/owned-ethereum" component={Ethereum} />
            <Route path="/sync-ethereum" component={SyncEthereum} />

            <Route path="/marketplace/edit-trax" component={EditTraxMarketplace} />

            <Route path="/custom-token-detail/:contractAddress/:tokenId">
                {({ contractAddress, tokenId }) => (<CustomTokenDetail contractAddress={contractAddress} tokenId={parseInt(tokenId)} />)}
            </Route>
            <Route path="/token-detail/:contractAddress" component={EditTraxMarketplace} />

            <Route path="/contracts/:contractId">
              {({contractId}) => <Contracts contractId={contractId} />}
            </Route>

            <Route path="/c/:username/:slug">
              {({ username, slug }) => <Contracts username={username} slug={slug} />}
            </Route>
            
            <Route path="/about" component={About} />
            <Route path="/sandbox" component={Sandbox} />
            <Route path="/faq" component={Faq} />
            <Route path="/docs" component={Docs} />
            <Route path="/contact-us" component={ContactUs} />
            {/* END Material Routes */}

            <Flex pos="absolute" w="100%" h="100%">
                <Flex justifyContent="space-between" width="100%" flexDir="column">
                    <Header />

                    <Route path="/previous" component={MarketplaceCatalog} />
                    <Route path="/previous/create" component={CreateNonFungiblePage} />            
                    <Route path="/previous/collections" component={CollectionsCatalog} />            
                    <Route path="/previous/marketplace" component={MarketplaceCatalog} />
                    
                    <Route path="/previous/creator/:minter">
                        {({ minter }) => <Creator minter={minter} />}
                    </Route>
                    
                    <Route path="/previous/collection/:contractAddress">
                        {({ contractAddress }) => (
                            <CollectionDisplay address={contractAddress} ownedOnly={false} />
                        )}
                    </Route>
                    
                    <Route path="/previous/collection/:contractAddress/token/:tokenId">
                        {({ contractAddress, tokenId }) => (
                            <CollectionsTokenDetail contractAddress={contractAddress} tokenId={parseInt(tokenId)} />
                        )}
                    </Route>
                    
                    <Notifications />
                </Flex>
            </Flex>                        
        </Switch>
    );
}