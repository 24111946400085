"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tas = void 0;
var bignumber_js_1 = require("bignumber.js");
var taquito_1 = require("@taquito/taquito");
var createStringTypeTas = function () {
    return function (value) { return value; };
};
var createBigNumberTypeTas = function () {
    return function (value) { return new bignumber_js_1.BigNumber(value); };
};
function asMap(value) {
    var m = new taquito_1.MichelsonMap();
    if (Array.isArray(value)) {
        var vArray = value;
        vArray.forEach(function (x) { return m.set(x.key, x.value); });
    }
    else {
        var vObject_1 = value;
        Object.keys(vObject_1).forEach(function (key) { return m.set(key, vObject_1[key]); });
    }
    return m;
}
var asBigMap = function (value) { return asMap(value); };
function add(a, b) {
    return a.plus(b);
}
function subtract(a, b) {
    return a.minus(b);
}
/** tas: Tezos 'as' casting for strict types */
exports.tas = {
    address: createStringTypeTas(),
    bytes: createStringTypeTas(),
    contract: createStringTypeTas(),
    timestamp: function (value) { return new Date(value).toISOString(); },
    int: createBigNumberTypeTas(),
    nat: createBigNumberTypeTas(),
    mutez: createBigNumberTypeTas(),
    tez: createBigNumberTypeTas(),
    map: asMap,
    bigMap: asBigMap,
    // Operations
    add: add,
    subtract: subtract,
    // To number
    number: function (value) { return Number(value + ''); },
};
