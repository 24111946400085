import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        .section {
            padding: ${theme.spacing(3)}px;
            padding-top: ${theme.spacing(1)}px;

            ${theme.breakpoints.up('md')} {
                padding-top: ${theme.spacing(5)}px,
                padding-bottom: ${theme.spacing(5)}px,
            }
        }    
    `}
`;
