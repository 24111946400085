import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		height: '100%',
		position: 'relative',
		overflow: 'hidden',
		background: 'gray',
	},
	textWhite: {
		color: 'white',
	},
	title: {
		fontWeight: 'bold',
	},
}));

const Hero = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
	const classes = useStyles();

	return (
		<S.Wrapper className={clsx(classes.root, className)} {...rest}>
			<Section>
				<SectionHeader
					title="Profile Settings"
					subtitle="Change profile information and settings"
					align="left"
					disableGutter
					titleProps={{ className: clsx(classes.title, classes.textWhite), variant: 'h3', }}
					subtitleProps={{ className: classes.textWhite, }}
				/>
			</Section>
		</S.Wrapper>
	);
};

export default Hero;
