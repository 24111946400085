import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { Nft } from 'lib/nfts/decoders';
import { useSelector } from 'reducer';
import { getCollectionByName } from 'services/collections';

import { CardToken, Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  sectionAlternate: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    '& .section-alternate__content': {
    },
  },
}));

const Result = ({ collection, creator, tokens, className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true, });

  const limit = 12;

  const [totalKeys, setTotalKeys] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState(1);

  const [nftTokens, setNftTokens] = useState<Nft[]>(tokens || []);

  const { system } = useSelector(s => s);
  const { config } = system;

  async function handlePagination(e: any, page: number) {
    setIsLoading(true);

    const tokens = await getCollectionByName({ collection_name: collection, creator, system });
    setNftTokens(tokens);
    setPageNumber(page);

    window.scrollTo(0, 0);

    setIsLoading(false);
  }

  const numPages = Math.ceil(totalKeys / limit);

  return (
    <div className={className} {...rest}>
      <Section className={classes.sectionAlternate}>
        <>
          <Grid container spacing={isMd ? 4 : 2}>
            {nftTokens.length > 0 && nftTokens.map((item, index: number) => (
              <Grid item xs={12} sm={6} md={3} key={index} data-aos="fade-up">
                {/* <CardToken {...item} hasTitle={true} config={config} /> */}
              </Grid>
            ))}
          </Grid>

          {/* 
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} container justify="center">
              <Box mt={2}>
                <Pagination
                  count={numPages}
                  page={pageNumber}
                  shape="rounded"
                  color="primary"
                  onChange={handlePagination}
                  disabled={isLoading}
                />
              </Box>
            </Grid>
          </Grid> 
          */}
        </>
      </Section>
    </div>
  );
};

export default Result;