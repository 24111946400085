import React from 'react';
import styled from 'styled-components';

import { findTokensByCollectionNameAndWallet } from 'services/tokens';
import { getUrlQueryParams } from 'utils';

import BaseToken from 'templates/BaseToken';
import { Cover } from './components';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;  

export default function CollectionDetailView(props: ViewComponentProps) {
  const collection = decodeURI(props.collection), creator = props.creator;

  const pageNumber = Number(getUrlQueryParams()['page']) || 1;
  const limit = 12, filter: Filter = { limit, offset: (pageNumber - 1) * limit, orderBy: 'tokenId', descending: true };

  return (
    <Wrapper>
      <Cover collectionName={collection} creator={creator} />

      <BaseToken 
        handleChange={async () => await findTokensByCollectionNameAndWallet(filter, collection, creator)}
        handleChangeProps={{filter, collection, creator}}
        limit={limit}
        filter={filter}
        pageNumber={pageNumber}
      />
    </Wrapper>
  );
}
