import React from 'react';

import { MIMETYPE } from 'utils/constants';

import { Image } from 'components/atoms';
import { HTMLElement } from './components';

interface MediaTypesProps {
    mimeType: string;
    artifactUri?: string;
    displayUri?: string;
    previewUri?: string;
}

export default function MediaTypes({ mimeType, artifactUri, displayUri, previewUri }: MediaTypesProps) {
    switch(mimeType) {
        case MIMETYPE.BMP:
        case MIMETYPE.GIF:
        case MIMETYPE.JPEG:
        case MIMETYPE.PNG:
        case MIMETYPE.TIFF:
        case MIMETYPE.WEBP:
            return (
                <Image src={artifactUri} lazy={false} />
            );
            
        case MIMETYPE.MP4:
        case MIMETYPE.OGV:
        case MIMETYPE.QUICKTIME:
        case MIMETYPE.WEBM:
            return (
                <video
                    preload="metadata"
                    onClick={e => e.preventDefault()}
                    onLoadedMetadata={e => { (e.target as HTMLVideoElement).currentTime = .05; }}
                    muted
                    controls
                    src={artifactUri}
                />
            );
        /* HTML ZIP */
        case MIMETYPE.IPFS_DIRECTORY_MIMETYPE:
        case MIMETYPE.ZIP:
        case MIMETYPE.ZIP1:
        case MIMETYPE.ZIP2:
            return (
                <HTMLElement 
                    preview={true} 
                    previewUri={previewUri}
                />
            );
        default:
            return (<>Unknown Media</>);
    }
}
