import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Section } from 'components/organisms';
import { Hero, Counter, Details } from './components';

import { playerConfigs } from '../EditTrax/data';
import { getContractBySlugAndUsername, getCustomContractBySlugOrId } from 'services/custom-contracts';
import { Loader } from 'components/atoms';
import { useSelector } from 'reducer';
import { Params } from 'lib/service/tzkt';
import useAuth from 'hooks/auth';
import axios from 'axios';
import { findByContractAndTokenId } from '../../services/tokens';

export type EditTraxtTokenProps = typeof playerConfigs['alpha-test'];

type ContractsProps = {
    contractId: string;
    username?: string;
    slug?: string;
};

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const BoxedPage = styled.div`
    ${({ theme }) => css`
        margin: 0 auto;
        margin-bottom: 6rem;
        position: relative;
        border-radius: 5px;

		${theme.breakpoints.up(1200)} {
			width: 1024px;
		}

        ${theme.breakpoints.up('sm')} {
            padding-top: 30px;
        }

        ${theme.breakpoints.down('sm')} {
            margin-bottom: 0;
        }
	`}
`;

export default function Contracts({ contractId, username, slug }: ContractsProps) {    
	const system = useSelector(s => s.system);
	const tzkt = system.tzkt;

    const { user } = useAuth();

    const [contract, setContract] = useState<Contracts>({} as Contracts);
    const [isLoading, setIsLoading] = useState<boolean>(true);
	const [ownedToken, setOwnedToken] = useState<string>('');
    const [operationHash, setOperationHash] = useState<string>('');

	let playerConfig: EditTraxtTokenProps = playerConfigs['alpha-test'];

    useEffect(() => {
        setIsLoading(true);

        (async () => {
            let response;

            response =  (!!contractId) ? await getCustomContractBySlugOrId(contractId) : await getContractBySlugAndUsername(username!, slug!);
            const contractData = response.object as Contracts;

			let params: Params = {
				limit: '1',
				offset: '0',
				'value.eq': user.wallet!,
				// select: 'value'
			};

            // https://api.ghostnet.tzkt.io/v1/operations/transactions?target=KT1FzyQi1ZmMNiuopP9f7MdXbKs1ayvYUM97&%22parameter.entrypoint%22=%22mint%22&%22parameter.value.mint_token_params.token_metadata.token_id=0&select=hash
            const tzktResponse = await tzkt.getContractBigMapKeys(contractData.contractAddress, 'ledger', params);
            const ownedTokensLength = tzktResponse.length;
            const atLeastOneOwnedToken = ownedTokensLength > 0;

            if (atLeastOneOwnedToken) {
                setOwnedToken(tzktResponse[0].key)
                const token = await findByContractAndTokenId(contractData.contractAddress, tzktResponse[0].key);
                // console.log(`got owned token: ${JSON.stringify(token)}`);
                
                const { data } = await axios.get(`${system.config.tzkt.api}/v1/operations/transactions?target=${contractData.contractAddress}&entrypoint=mint&sender=${token.metadata.minter}`);
                // console.log(`******* got owned token operation hash: ${JSON.stringify(data)}`);

                if (data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        // console.log(`in loop, getting data: ${JSON.stringify(data[i].parameter.value.mint_tokens_param[0].token_metadata)}`);
                        if (data[i].parameter.value.mint_tokens_param[0].token_metadata.token_id === tzktResponse[0].key) {
                            setOperationHash(data[i].hash);
                            break;
                        }
                    }
                }
            }

            setContract(contractData);
            setIsLoading(false);
        })();
    }, []);

    if (isLoading) {
        return <Loader />
    }

    return (
        <Wrapper>
            <BoxedPage>
                <Hero contract={contract} operationHash={operationHash} artifactUri={contract.artifactUri} ownedToken={ownedToken} />

                <Section>
                    <Counter contract={contract} />
                </Section>

				<Section>
					<Details token={playerConfig} contract={contract} />
				</Section>
            </BoxedPage>
        </Wrapper>
    );
}
