import { Grid } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const GridTab = styled(Grid)`
    ${({ theme }) => css`
        display: flex;
        width: 100%;

        .MuiInputBase-root {
            border: 1px solid #e1e1e1;
            padding: 8px 16px;
            border-radius: 4px;
            margin-bottom: 24px;
            min-width: 200px;
        }

        @media screen and (max-width: 1237px) {
            overflow: auto;
            margin-bottom: 16px;
        }         
    `}
`;
