import axios from 'axios';
import config from '../config.json';

// TODO: set api URL based on environment
const api = axios.create({
	baseURL: config.ipfsApi,
	// baseURL: 'http://localhost:3325/',
});

// TODO: set api URL based on environment
export const withoutAuthApi = axios.create({
	baseURL: config.ipfsApi,
});

api.interceptors.request.use(function (config) {
	if (typeof window !== 'undefined') {
		const token = localStorage.getItem("@minter:jwt");

		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
	}

	return config;
}, (error) => console.log(error));

export default api;