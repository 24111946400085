import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        .section {
            ${theme.breakpoints.up('sm')} {
                padding: ${theme.spacing(8)}px !important;
            }
        }
    `}    
`;
