import React, { MouseEvent, useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'wouter';
import { mintEditTraxTokenAction } from 'reducer/async/actions';

import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from 'web3modal';
import { ethers, providers } from 'ethers';

import { makeStyles, darken } from '@material-ui/core/styles';
import { 
	Toolbar, 
	Box, 
	Hidden, 
	List, 
	ListItem, 
	Typography, 
	IconButton, 
	Button, 
	ListItemText, 
	Popover, 
	ListItemAvatar, 
	Avatar, 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogContentText, 
	DialogActions, 
	Divider 
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import { Image, DarkModeToggler } from 'components/atoms';
import ErrorButton from 'components/atoms/ErrorButton';

import { connectWallet, disconnectWallet } from 'reducer/async/wallet';
import { useSelector, useDispatch } from 'reducer';

import useAuth from 'hooks/auth';
import { SystemWithWallet } from 'lib/system';
import APP_CONFIG from 'config/app_config';

import { linkEthereumWallet, verifyEthereumWallet } from 'services/users';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
	flexGrow: {
		flexGrow: 1,
	},
	icon: {
		width: '2.rem',
		height: '2.rem',
		background: 'transparent'
	},
	navigationContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	navigationNftContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		'& > li': {
			padding: 0,
		}
	},
	topbar: {
		backgroundColor: theme.topbar,
	},
	toolbar: {
		zIndex: 999,
		width: '100%',
		margin: '0 auto',
		padding: theme.spacing(0, 2),
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(1*1.5, 5),
		},
	},
	navLink: {
		color: theme.palette.text.primary,
		'&:hover': {
			color: darken(theme.palette.text.primary, 0.2),
		},
	},
	listItemAvatar: {
		minWidth: 'auto',
		background: 'rgb(231, 231, 231)',
    	padding: '2px',
		borderRadius: '50%',
		marginRight: theme.spacing(2),
	},
	avatarList: {
		'&:hover': {
			cursor: 'pointer',
		}
	},
	listItem: {
		cursor: 'pointer',
		'&:hover > .menu-item, &:hover svg': {
			color: theme.palette.primary.dark,
		},
		'&.menu-item--no-dropdown': {
			paddingRight: 0,
		},
		width: 'max-content',
	},
	listItemBorder: {
		paddingRight: 18,
		borderRight: '2px solid #f1f1f1',
	},
	listItemNoPadding: {
		paddingLeft: 0,
	},
	listItemActive: {
		'&> .menu-item': {
			color: theme.palette.primary.dark,
		},
	},
	listItemText: {
		flex: '0 0 auto',
		marginRight: theme.spacing(2),
		whiteSpace: 'nowrap',
	},
	listItemButton: {
		textTransform: 'none',
		whiteSpace: 'nowrap',
		'&> span > svg': {
			marginLeft: 10,
			marginRight: 10,
		},
	},
	listItemIcon: {
		minWidth: 'auto',
	},
	navActiveLink: {
		fontWeight: 'bold',
		fontSize: 16,
		borderBottom: `2px solid ${theme.palette.type === 'dark' ? '#fff' : '#000'}`,
		borderRadius: 0,
	},
	navInactiveLink: {
		color: '#888888',
		fontSize: 16,
	},
	popover: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(1),
		border: theme.spacing(2),
		boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.25)',
		minWidth: 200,
		marginTop: theme.spacing(4),
	},
	iconButton: {
		marginLeft: theme.spacing(2),
		padding: 0,
		'&:hover': {
			background: 'transparent',
		},
	},
	expandOpen: {
		transform: 'rotate(180deg)',
		color: theme.palette.primary.dark,
	},
	listWalletAddress: {
		'&:hover': {
			textDecoration: 'underline',
		}
	},
	logoImage: {
		display: 'inline',
		width: '28px',
		height: '28px',
		// paddingRight: '5px'
	},
	menu: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	menuItem: {
		marginRight: theme.spacing(5),
		'&:last-child': {
			marginRight: 0,
		},
	},
	menuGroupItem: {
	},
	menuGroupTitle: {
		textTransform: 'uppercase',
	},
	disconnectButton: {
		background: theme.palette.common.white,
		'&:hover': {
			background: darken(theme.palette.common.white, 0.2),
		}
	},
	whiteColorButton: {
		color: theme.palette.common.white,
	},
	walletIcon: {
		color: theme.palette.text.primary,
	}
}));

interface Props {
	className?: string;
	onSidebarOpen: Function,
	pages: PagesProps;
	themeMode: string;
	themeToggler: Function;
	branding: string;
};

const Topbar = ({ themeMode, themeToggler, branding, onSidebarOpen, pages, className, ...rest }: Props): JSX.Element => {
	const classes = useStyles();

	const system = useSelector(s => s.system);
	const dispatch = useDispatch();  
	const { user, signIn, signOut, setUser } = useAuth();

	const logoSrc = themeMode === 'light' ? '/assets/logo.svg' : '/assets/logo.svg';
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [openedPopoverId, setOpenedPopoverId] = useState<string | null>(null);
	const [location, setLocation] = useLocation();
	const [openDisconnect, setOpenDisconnect] = useState<boolean>(false);

	const handleClick = (event: MouseEvent<HTMLElement>, popoverId: string | null): void => {
		setAnchorEl(event.target);
		setOpenedPopoverId(popoverId);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
		setOpenedPopoverId(null);
	};

	async function connectWithMetamask() {
		if (!window.ethereum) {
			alert("You don't have metamask installed");
			return false;
		}

        const res = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const userData = await verifyEthereumWallet(res[0]);

		if (!userData) {
			alert("Wallet not linked with a Tezos account yet");
			console.log("Wallet not linked");

			return false;
		}

		setUser(userData);
    }

	async function linkWithMetamask() {
        const res = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const userData = await linkEthereumWallet(user.wallet, res[0]);

		if (!userData) {
			alert("Wallet not found");
			return false;
		}

		setUser(userData);
		alert("Wallet linked successfully");
    }

	const handleNewWalletUnverifiedClick = (): void => {
		setOpenedPopoverId(null);
		let goToVerify = window.confirm(`You must verify your account by connecting to Twitter before you can mint.\n\nGo to Profile page to confirm now?`);
		if (goToVerify) {
			window.location.href = '/account';
		} else {
			// do nohing
		}
	};

	async function handleConnectWallet() {
		// const provider = new WalletConnectProvider({
		// 	infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
		// });

		// const web3Modal = new Web3Modal({
		// 	network: "mainnet",
		// 	providerOptions: {
		// 		walletConnect: {
		// 			package: WalletConnectProvider,
		// 			options: {
		// 				infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
		// 			}
		// 		}
		// 	}
		// });

		// const instance = await web3Modal.connect();

		// const provider = new ethers.providers.Web3Provider(instance);
		// const signer = provider.getSigner();
		// console.log({ signer });		

		// const provider = new WalletConnectProvider({
		// 	rpc: {
		// 		1: "https://mainnet.mycustomnode.com",
		// 		3: "https://ropsten.mycustomnode.com",
		// 		100: "https://dai.poa.network",
		// 		1729: "https://rpc.ghostnet.teztnets.xyz"
		// 		// ...
		// 	},
		// });

		// await provider.enable();

		// const web3Provider = new providers.Web3Provider(provider);
		// console.log({ web3Provider });

		// Normal login process
		const response = await dispatch(connectWallet());
		const payload = response.payload as SystemWithWallet;
		
		if (payload && payload.status === "WalletConnected") {
			const wallet = payload.tzPublicKey!;
			await signIn({ wallet });
		}
	}

	async function handleDisconnectWallet() {
		if (system.wallet) {
			await dispatch(disconnectWallet()); 
		}
		
		signOut();
		setLocation('/marketplace');
	}

	const DisplayWalletInfo = (): JSX.Element => {
		return (
			<S.WalletWrapper className={classes.menu}>
				<div className={classes.menuItem}>
					<List disablePadding>
						<ListItem disableGutters>
							<Typography variant="body1" className={clsx(classes.navLink, 'submenu-item')}  onClick={handleClose} style={{ fontWeight: 'bold' }}>
								{`Network: ${system.config.network}`}
							</Typography>
						</ListItem>

						<Divider />

						<ListItem disableGutters data-aos="fade-up" style={{ padding: 0 }}>
							<a href={`${APP_CONFIG.WALLET_ADDRESS}/${user?.wallet || system.tzPublicKey}`} target="_blank" rel="noopener noreferrer" className={classes.listWalletAddress}>
								<ListItemText primary={user?.username || system.tzPublicKey} primaryTypographyProps={{ variant: 'subtitle1', color: 'textPrimary' }} />
							</a>
						</ListItem>

						<ListItem disableGutters>
							<Link href="/account">
								<Typography variant="body1" component={'a'} href="/account" className={clsx(classes.navLink, 'submenu-item')} color="textSecondary" onClick={handleClose}>
									Edit Profile
								</Typography>
							</Link>
						</ListItem>

						{ !user?.ethereumWallet && (
						<ListItem disableGutters>
							<Button 
								variant="outlined" 
								color="primary" 
								component="a" 
								className={clsx(classes.listItemButton, classes.disconnectButton)} 
								onClick={() => linkWithMetamask()}
							>
								Link Ethereum
							</Button>
						</ListItem>
						)}

						{ user?.ethereumWallet && (
						<ListItem disableGutters>
							<Link href="/owned-ethereum">
								<Typography variant="body1" component={'a'} href="/account" className={clsx(classes.navLink, 'submenu-item')} color="textSecondary" onClick={handleClose}>
									Owned Ethereum Nfts
								</Typography>
							</Link>
						</ListItem>
						)}

						<ListItem disableGutters className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
							<DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />
						</ListItem>

						<ListItem disableGutters>
							<Button 
								variant="outlined" 
								color="primary" 
								component="a" 
								className={clsx(classes.listItemButton, classes.disconnectButton)} 
								onClick={() => setOpenDisconnect(true)}
							>
								Disconnect
							</Button>
						</ListItem>

						<Dialog
							open={openDisconnect}
							onClose={() => setOpenDisconnect(false)}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">Disconnect Wallet</DialogTitle>

							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									Are you sure about disconnecting your wallet?
								</DialogContentText>
							</DialogContent>

							<DialogActions>
								<ErrorButton variant="contained" onClick={() => setOpenDisconnect(false)} autoFocus>
									No
								</ErrorButton>

								<Button 
									variant='contained' 
									color="primary" 
									onClick={handleDisconnectWallet}
								>
									Disconnect
								</Button>                
							</DialogActions>
						</Dialog>
					</List>
				</div>
			</S.WalletWrapper>
		);
	};

	let isWhitelisted = false;
	if (system.tzPublicKey) {
		const systemWithWallet : SystemWithWallet = system;
		isWhitelisted = systemWithWallet.whitelisted;
		// @ts-ignore
		/* const networkWhitelist = whitelist[network];
		if (networkWhitelist.includes(system.tzPublicKey)) {
			isWhitelisted = true
		} */
	}

	isWhitelisted = true;

	let isEditTrax = location === '/edit-trax';
	// if (window.branding === 'EDIT TRAX') {
	// 	isEditTrax = true;
	// }

	return (
		<Box className={classes.topbar}>
			<Toolbar disableGutters className={classes.toolbar} {...rest}>
				<S.LogoContainer>
					<Link href="/">
						<a href="/" title="Orbix 360º">
							<Image className={classes.logoImage} src={logoSrc} alt="Orbix 360º" lazy={false} />
							{branding}
						</a>
					</Link>
				</S.LogoContainer>

				<div className={classes.flexGrow} />

				<Hidden smDown>
					<List disablePadding className={classes.navigationContainer}>
						<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
							<Link href="/faq">
								<Button component="a" href="/faq" className={clsx(location === '/faq' ? classes.navActiveLink : classes.navInactiveLink, classes.listItemButton)}>
									FAQs
								</Button>
							</Link>
						</ListItem>

						<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
							<Link href="/marketplace">
								<Button component="a" href="/marketplace" className={clsx(location === '/marketplace' ? classes.navActiveLink : classes.navInactiveLink, classes.listItemButton)}>
									Marketplace
								</Button>
							</Link>
						</ListItem>

						<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
							<Link href="/explore">
								<Button component="a" href="/explore" className={clsx(location === '/explore' ? classes.navActiveLink : classes.navInactiveLink, classes.listItemButton)}>
									Explore
								</Button>
							</Link>
						</ListItem>

						{ (user || system.status === 'WalletConnected') && (
						<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
							<Link href={`/creator/${user?.wallet || system.tzPublicKey}`}>
								<Button className={clsx(location === `/creator/${user?.wallet || system.tzPublicKey}` ? classes.navActiveLink : classes.navInactiveLink, classes.listItemButton)}>
									Profile
								</Button>
							</Link>
						</ListItem>
						)}

						<ListItem onClick={e => handleClick(e, 'nfts')} className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
							<Button className={clsx(classes.navInactiveLink, classes.listItemButton)}>
								Tools
							</Button>
						</ListItem>

						<Popover
							elevation={1}
							id={'nfts'}
							open={openedPopoverId === 'nfts'}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
							transformOrigin={{ vertical: 'top', horizontal: 'center' }}
							classes={{ paper: classes.popover }}
						>
							<div>
								<List disablePadding className={classes.navigationNftContainer}>
									<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')} onClick={handleClose}>
										<a href={`${APP_CONFIG.ORBIX_SHORT_URL_ROOT}templates?#nft-tools`} target="_blank" rel="noopener noreferrer">
											<Button>
												NFT Templates
											</Button>
										</a>
									</ListItem>
								</List>
							</div>
						</Popover>

						{ (user || system.status === 'WalletConnected') && (
						<>
							{ isWhitelisted && !isEditTrax &&  (
							<>
								<ListItem className={clsx(classes.listItem, classes.listItemBorder)}>
									<Link href="/create">
										<Button variant="contained" color="primary" className={clsx(classes.listItemButton)}>
											Mint
										</Button>
									</Link>
								</ListItem>
							</>
							)}

							{ !isWhitelisted &&  (
								<>
									<ListItem className={clsx(classes.listItem, classes.listItemBorder)}>
										<Button variant="contained" onClick={handleNewWalletUnverifiedClick} color="primary" className={clsx(classes.listItemButton)}>
											Mint
										</Button>
									</ListItem>
								</>
							)}

							<>
								<ListItem disableGutters onClick={e => handleClick(e, 'wallet')} className={classes.avatarList} style={{ marginLeft: 16 }}>
									<ListItemAvatar className={classes.listItemAvatar}>
										<Avatar src={user?.avatarUrl || `https://services.tzkt.io/v1/avatars2/${user?.wallet || system.tzPublicKey}`} className={classes.icon} />
									</ListItemAvatar>
								</ListItem>

								<Popover
									elevation={1}
									id={'account'}
									open={openedPopoverId === 'wallet'}
									anchorEl={anchorEl}
									onClose={handleClose}
									anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
									transformOrigin={{ vertical: 'top', horizontal: 'center' }}
									classes={{ paper: classes.popover }}
								>
									<div>
										<DisplayWalletInfo />
									</div>
								</Popover>
							</>
						</>
						)}
											
						{ (!user && system.status !== 'WalletConnected') && (
						<>
							<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
								<Button variant="contained" color="primary" className={clsx(classes.listItemButton)} onClick={handleConnectWallet}>
									Connect Wallet
								</Button>
							</ListItem>

							<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
								<Button 
									variant="contained" 
									color="primary" 
									className={clsx(classes.listItemButton)} 
									onClick={connectWithMetamask}
								>
									Ethereum
								</Button>
							</ListItem>
						</>
						)}
					</List>
				</Hidden>

				<Hidden mdUp>
					<IconButton className={classes.iconButton} onClick={() => onSidebarOpen()} aria-label="Menu">
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</Box>
	);
};

export default Topbar;