/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { isLink } from 'utils';
import { Link } from 'wouter';

import * as S from './styles';

/**
 * Component to display avatar view
 *
 * @param {*} props
 */

interface AvatarProps {
  avatarUrl?: string;
  link: string;
  title?: string;
  isTarget?: boolean;
}

const Avatar = ({ link, title, isTarget = false, avatarUrl = '', ...rest }: AvatarProps): JSX.Element => {
  const isAvatarLink = isLink(avatarUrl);

  return (
    <div {...rest}>
      <S.Avatar>
          <S.AvatarImage hasAvatarUrl={!!isAvatarLink} avatarColor={avatarUrl}>
            <img src={avatarUrl} alt="" />
          </S.AvatarImage>
          
          <S.Title>
            { isTarget ? (
            <a href={link} target={isTarget ? '_blank' : ''} rel="noopener noreferrer">
              {title}
            </a>
            )
            :
            (
              <Link href={link}>
                {title}
              </Link>
            )}
          </S.Title>
      </S.Avatar>
    </div>
  );
};

export default Avatar;
