// 5653709367279616, 5639187545980928, 5687518477418496, 5752733260840960
export const playerConfigs =
  {
    'download-mechanism': {
      contractId: '5700935217053696',
      contract: 'KT18mAwqbZ1mdGVYEvatwcpjQR9GnxaGDQbT',
      artistName: 'Bai-ee',
      artistThumbnail: '/assets/edit-trax/players/square_download_mechanism.jpg',
      rootUrl: '',
      unlockableContentImage: '/assets/edit-trax/players/unlockable_download_mechanism.png',
      'background': '#000',
      'tags': ['chicago','rave','underground','music','house','acid'],
      'contributors': [
        {
          name: 'Bai-ee',
          avatar: '/assets/edit-trax/bai_ee_wallet_avatar.svg'
        },
        {
          name: 'Minterverse',
          avatar: '/assets/eye_bw.png'
        }
      ],
      tokenMetadata: {}
    },
    'alpha-test': {
      contractId: '5726336844824576',
      contract: 'KT1C57TiYfxzE6LVKS4mhkU7GGrjGbfc2eYv',
      artistName: 'Bai-ee',
      artistThumbnail: '/assets/edit-trax/players/square_alpha_test.JPG',
      rootUrl: '',
      unlockableContentImage: '/assets/edit-trax/players/unlockable_alpha_test.PNG',
      'background': '#fff',
      'tags': ['download', 'opensource', 'interactive', 'music', 'stream'],
      'contributors': [
        {
          name: 'Bai-ee',
          avatar: '/assets/edit-trax/bai_ee_wallet_avatar.svg'
        },
        {
          name: 'Minterverse',
          avatar: '/assets/eye_bw.png'
        },
      ],
      tokenMetadata: {}
    },
    'burnt': {
      contractId: '5680089660391424',
      contract: 'KT1U9gVEa1bag1pNGmTpSuPRQMBEHNJc2RmT',
      artistName: 'Bai-ee',
      artistThumbnail: '/assets/edit-trax/players/square_burnt.JPG',
      rootUrl: '',
      unlockableContentImage: '/assets/edit-trax/players/unlockable_burnt.png',
      'background': 'linear-gradient(180deg, #E28200 0.76%, #FFAE40 100%)',
      'tags': ['edittrax'],
      'contributors': [
        {
          name: 'Bai-ee',
          avatar: '/assets/edit-trax/bai_ee_wallet_avatar.svg'
        },
        {
          name: 'Minterverse',
          avatar: '/assets/eye_bw.png'
        }
      ],
      tokenMetadata: {}
    },
    'boxxed': {
      contractId: '5707679053905920',
      contract: 'KT1T6wzRYQSQrgURrXfojwCuJm6bjUhKUL21',
      artistName: 'Bai-ee',
      artistThumbnail: '/assets/edit-trax/players/square_boxxed.JPG',
      rootUrl: '',
      unlockableContentImage: '/assets/edit-trax/players/unlockable_boxxed.png',
      'background': '#000',
      'tags': ['edittrax', 'music', 'player'],
      'contributors': [
        {
          name: 'Bai-ee',
          avatar: '/assets/edit-trax/bai_ee_wallet_avatar.svg'
        },
        {
          name: 'Minterverse',
          avatar: '/assets/eye_bw.png'
        }
      ],
      tokenMetadata: {}
    },
  };

