import React from 'react';
import styled, { css } from 'styled-components';

import { MyItemsProps } from 'pages/my-items';

import { Hero, Result } from './components';

import { Section } from 'components/organisms';

const SectionWrapper = styled(Section)`
    ${({ theme }) => css`   
        ${theme.breakpoints.up(1300)} {
            padding: 32px 0 64px;
        }

        ${theme.breakpoints.down(1300)} {
            padding-top: 32px;
        }
    `}

    min-height: 350px;
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;  

export default function MyItemsView({ minter }: MyItemsProps) {
    return (
        <Wrapper>
            <Hero minter={minter} />

            <SectionWrapper>
                <Result minter={minter} />
            </SectionWrapper>
        </Wrapper>
    );
}
