import React, { KeyboardEvent, useEffect, useState } from 'react';
import { 
	Box, 
	Button, 
	CircularProgress, 
	FormControl, 
	Grid, 
	Hidden, 
	IconButton, 
	InputLabel, 
	makeStyles, 
	MenuItem, 
	Modal, 
	Select, 
	Step,
	StepIconProps,
	StepLabel,
	Stepper,
	Switch, 
	TextField, 
	Tooltip, 
	Typography 
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { MdClose, MdHelpOutline, MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { Check, Close } from '@material-ui/icons';

import { findCollectionsByOwner } from 'services/collections';
import formatNumber from 'utils/formatNumber';

import { useSelector, useDispatch } from 'reducer';
import { updateEditions, updateRoyalties, updateTags, updateField, updateRoyaltiesSharing, updateGenerator } from 'reducer/slices/createNft';
import { mintTokenAction } from 'reducer/async/actions';

import { categories } from 'views/CreateNFT/data';
import * as S from './styles';

export type RoyaltSharingProps = {
	walletAddress?: string; 
	royalties?: string;
};

const useStyles = makeStyles({
	active: {
		color: '#1976d2',
	},
	circle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 25,
		height: 25,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
	completed: {
		color: '#1976d2',
		zIndex: 1,
		fontSize: 18,
	},
	error: {
		color: '#d32f2f',
		zIndex: 1,
		fontSize: 18,
	},
	white: {
		color: '#fff',
	}		
});

export default function MintForm({ handleNextStep }: ViewComponentProps) {
	const dispatch = useDispatch();
	const classes = useStyles();

	const steps = ["Validating", "Uploading to IPFS", "Minting", "Complete"];

	const { createNft: state, system } = useSelector(s => s);
	const { 
		fields: { name, description, collection_name, category: categoryName }, 
		royalties, 
		tags: tagsList, 
		royaltiesSharing, 
		selectedFile,
		displayImageFile,
		activeStep
	} = state;

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [checkedRoyalty, setCheckedRoyalty] = useState<boolean>(!!royaltiesSharing?.length);
	const [formDescription, setFormDescription] = useState<string>(description || '');
	const [collectionName, setCollectionName] = useState<string>(collection_name || '');
	const [category, setCategory] = useState<string>(categoryName || '');
	const [formName, setFormName] = useState<string>(name || '');
	const [royaltiesList, setRoyaltiesList] = useState<RoyaltSharingProps[]>(royaltiesSharing ? 
		royaltiesSharing.map(({ walletAddress, royalties }) => ({ walletAddress, royalties: royalties.toFixed(2).toString() })) 
		: []
	);
	const [royaltiesToken, setRoyaltiesToken] = useState<string>(royalties?.toString() || '10.00');
	const [tag, setTag] = useState<string>('');
	const [tags, setTags] = useState<string[]>(tagsList || []);
	const [openModal, setOpenModal] = useState<boolean>(false);

	const [errorMessage, setErrorMessage] = useState({
		editions: '',
		royalties: '',
	});

	const [royaltiesSharingError, setRoyaltiesSharingError] = useState<{[x: number]: string}>({});
	const [collections, setCollections] = useState<string[]>([]);
	const [hasError, setHasError] = useState<boolean>(false);

	useEffect(() => {
		dispatch(updateEditions(1));
		dispatch(updateGenerator(true));

		if (!royalties) dispatch(updateRoyalties(10));

		(async () => {
			const response = await findCollectionsByOwner(system.tzPublicKey!);

			const collectionsNames = response.map(({ name }) => name);
			setCollections(collectionsNames);
		})();

		const delayDebounceFunction = setTimeout(() => {
			handleRoyaltiesSharing();
		}, 3000);

		return () => clearTimeout(delayDebounceFunction);
	}, []);

	function getStepContent(step: number) {
		switch (step) {
			case 0:
				return 'Validating token fields';
			case 1:
				return 'Uploading content files to IPFS';
			case 2:
				return 'Minting token to blockchain';
			case 3:
				return 'Request complete successfully!';
		}
	}

	function handleCreateTag(event: KeyboardEvent) {
		if (tags.length >= 5) {
			return false;
		}

		const splittedTag = tag.split(/[,\s]+/).filter(item => !!item.trim());
		const replacedTrimmedTag = tag.trim().replaceAll(',', '').toLowerCase();

		if (splittedTag.length > 1) {
			const newTags = [...tags, ...splittedTag];

			setTags(newTags);
			setTag('');
			dispatch(updateTags(newTags));				
		} else if ([13, 32, 188].includes(event.keyCode) && replacedTrimmedTag.length) {
			const newTags = [...tags, replacedTrimmedTag];

			setTags(newTags);
			setTag('');

			if (newTags.length === 5) {
				dispatch(updateTags(newTags));				
			}
		}
	}
	
	function handleDeleteTag(index: number) {		
		let newTags = [...tags];
		newTags.splice(index, 1);
		
		setTags([...newTags]);
		dispatch(updateTags(newTags));
	}

	function replacedParsedValue(value: string) {
		return value
			.toString()
			.replace(/[^0-9.]+/g, '')
			.replace(/[R$.]*/g, '')
			.replace(',', '.')
			.trim();
	}
		
	function formatValueWithDecimals(value: number) {
		return Number((Math.round((value / 100) * 100) / 100).toFixed(2));
	}

	function formatValue(value: number) {
		return new Intl.NumberFormat('en-EN', {
			minimumFractionDigits: 2,
		}).format(value);
	}

	function handleFormattedRoyalties(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		errorMessage.royalties = '';
		const replaced = replacedParsedValue(e.target.value);
		const formattedValue = formatValueWithDecimals(Number(replacedParsedValue(replaced)));

		if (isNaN(formattedValue)) {
			return false;
		}

		if (formattedValue > 30) {
			setErrorMessage({ ...errorMessage, royalties: "Royalties are limited up to 30%" });
			return false;		
		}
				
		setRoyaltiesToken(formatValue(formattedValue));
	}

	function handleCategoryChange(event: any) {
		setCategory(event.target.value as string)
	}
	
	function handleAddRoyalties() {
		setRoyaltiesList([...royaltiesList, {}]);
	}

	function handleDeleteRoyalties(index: number) {
		if (royaltiesSharingError[index]) {
			setRoyaltiesSharingError({ ...royaltiesSharingError, [index]: '' });
		}

		const newRoyaltiesList = royaltiesList;
		royaltiesList.splice(index, 1);

		setRoyaltiesList([...newRoyaltiesList]);
		handleRoyaltiesSharing();
	}

	function handleRoyaltiesList(index: number, prop: 'walletAddress' | 'royalties', value: string) {		
		const newRoyaltiesList = royaltiesList;

		if (prop === 'walletAddress') {						
			delete royaltiesSharingError[index];

			const filteredRoyaltiesListSharing = royaltiesList.filter(({ walletAddress }) => walletAddress === value);
			setRoyaltiesSharingError({ ...royaltiesSharingError });

			if (value === system.tzPublicKey!) {
				setRoyaltiesSharingError({ ...royaltiesSharingError, [index]: "You can not add your own wallet" });
			} else if (filteredRoyaltiesListSharing.length) {
				setRoyaltiesSharingError({ ...royaltiesSharingError, [index]: 'Wallet address already used' });
			}
			
			newRoyaltiesList[index][prop] = value;
		} else {
			const formattedRateValue = formatNumber(value);
			newRoyaltiesList[index][prop] = !isNaN(Number(formattedRateValue)) ? formattedRateValue.toString() : '';
		}
		
		setRoyaltiesList([...newRoyaltiesList]);
	}

	function handleRoyaltiesSharing() {
		if (royaltiesList.length >= 1) {
			const newRoyaltiesList = royaltiesList;
			const value = 100 - royaltiesList.slice(1).reduce((acc, { royalties }, index) => {
				const accumulator = acc + Number(royalties || 0); 
	
				if (accumulator >= 100) {
					newRoyaltiesList[index + 1].royalties = (100 - acc).toFixed(2).toString();
	
					return 100;
				}
	
				return accumulator;
			}, 0);
	
			newRoyaltiesList[0].royalties = value.toFixed(2).toString();
			setRoyaltiesList([...newRoyaltiesList]);
		}
	}

	function handleCheckedRoyaltySharing(event: React.ChangeEvent<HTMLInputElement>) {
		const isChecked = event.target.checked
		setCheckedRoyalty(isChecked);

		if (isChecked) {
			if (!royaltiesList.length) {
				const startedNewArray = [
					{ walletAddress: system.tzPublicKey!, royalties: '100'  },
					{ walletAddress: '', royalties: ''  }
				];
				setRoyaltiesList([...royaltiesList, ...startedNewArray]);
	
				return;
			}
		}

		setRoyaltiesList([]);
	}

	async function handleCreateToken() {
		if (![name, royaltiesToken].every(item => !!item)){
			alert("Fields marked with (*) are mandatory");
			return false;
		}

		if (Object.keys(royaltiesSharingError).length) {
			alert("You have errors on your royalty sharing map");
			return false;
		}

		if (!Object.values(errorMessage).every(item => !item)) {
			alert("You have errors on your form");
			return false;
		}

		if (!selectedFile) {
			alert("You need to upload a file");
			return false;
		} else {
			const type = selectedFile.name.split('.')[1].trim();

			if (['zip', 'glb'].includes(type) && !displayImageFile) {
				alert("You need to upload a display image for this format");
				return false;
			}
		}

		if (royaltiesList.length > 0) {
			const hasEmptyFields = royaltiesList.some(({ walletAddress, royalties }) => !walletAddress || !royalties);
			const parsedRoyaltiesList = royaltiesList.map(({ walletAddress, royalties }) => { 
				return { 
					walletAddress: walletAddress!, 
					royalties: Number(royalties)
				}
			});
			
			if (hasEmptyFields) {
				alert("You need to fill up all royalties fields");
				return 	
			}

			dispatch(updateRoyaltiesSharing(parsedRoyaltiesList));
		}
		
		setOpenModal(true);
		setIsLoading(true);
		
		const response = await dispatch(mintTokenAction());
		const payload = response.payload as any;

		if (!response.type.includes('reject')) {
			localStorage.setItem('lastTokenId', payload.tokenId);
			
			// handleNextStep();
		} else {
			setHasError(true);
			setIsLoading(false);
			setOpenModal(false);
		}
	}

	function handleCompleteMint() {
		setIsLoading(false);
		setOpenModal(false);

		handleNextStep();
	}

	function StepIcon(props: StepIconProps) {
		const { active, icon } = props;

		return (
			<div>
			{ 	
				(activeStep! >= icon!) ? <Check className={classes.completed} /> : 
				active ? <CircularProgress color="primary" size={25} /> : 
				hasError && activeStep === icon ? <Close className={classes.error} /> :
				<div className={classes.circle}><span className={classes.white}>{icon}</span></div>
			}				
			</div>
		);
	}

	return (
		<S.Container>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography variant="h6" color="textPrimary">
						<strong>Asset Name*</strong>
					</Typography>

					<TextField
						placeholder="Item name"
						variant="outlined"
						size="medium"
						name="fullname"
						fullWidth
						type="text"
						value={formName}
						onBlur={() => dispatch(updateField({ name: 'name', value: formName }))}
						onChange={(e) => setFormName(e.target.value)}
					/>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" color="textPrimary">
						<strong>Collection Name</strong>
					</Typography>

					<Autocomplete 
						value={collectionName}
						onChange={(event: any, newValue: string | null) => {
							setCollectionName(newValue || '');
						}}		
						options={['', ...collections]}
						getOptionSelected={(option: string, value: string) => option === value}				
						renderInput={(params) => {
							return (
								<TextField
									{...params}

									placeholder="Collection name"
									variant="outlined"
									size="medium"
									name="collection-name"
									fullWidth
									type="text"
									value={collectionName}
									onBlur={() => dispatch(updateField({ name: 'collection_name', value: collectionName }))}
									onChange={(e) => setCollectionName(e.target.value)}
								/>							
							);
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" color="textPrimary">
						<strong>Category</strong>
					</Typography>

					<FormControl fullWidth>
						<InputLabel style={{ marginLeft: 16 }} >Select the category</InputLabel>
						<Select
							value={category}
							label="Select the category"
							onChange={handleCategoryChange}
							onBlurCapture={() => dispatch(updateField({ name: 'category', value: category }))}
							variant='outlined'
							fullWidth 
						>
						{ categories.map((category, index) => (
							<MenuItem key={`category-${index}`} value={category}>{category}</MenuItem>
						))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" color="textPrimary">
						<strong>Tags List (separate with space or comma)</strong>
					</Typography>

					<div>
						<S.TagContainer>
							{tags.map((tag, index) => (
								<S.Tag key={`tag-${index}`}>
									{tag} <MdClose onClick={() => handleDeleteTag(index)} />
								</S.Tag>
							))}
							<S.TagInput 
								placeholder={ tags.length >= 5 ? '' : 'Add Tag'}
								disabled={tags.length >= 5} 
								value={tag} 
								onChange={(e) => { tags.length < 5 && setTag(e.target.value) }} 
								onKeyUp={handleCreateTag} 
								onBlur={() => dispatch(updateTags(tags))}
							/>
						</S.TagContainer>
					</div>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" color="textPrimary">
						<strong>Description</strong>
					</Typography>

					<TextField
						placeholder="Your description"
						variant="outlined"
						name="description"
						fullWidth
						multiline
						rows={6}
						value={formDescription}
						onBlur={() => dispatch(updateField({ name: 'description', value: formDescription }))}
						onChange={(e) => setFormDescription(e.target.value)}
					/>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" color="textPrimary">
						<strong>Royalties (%)</strong>
					</Typography>

					<TextField
						placeholder="Royalties"
						variant="outlined"
						size="medium"
						name="royalties"
						fullWidth
						type="text"
						error={!!errorMessage.royalties}
						helperText={errorMessage.royalties}
						value={royaltiesToken}
						onBlur={() => { 
							dispatch(updateRoyalties(Number(royaltiesToken))); 
							setErrorMessage({ ...errorMessage, royalties: '' })
						}}
						onChange={(e) => handleFormattedRoyalties(e)}
					/>

					<Hidden>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Typography>
							Apply royalty sharing
							<Tooltip title="Royalty may be shared with co-creator(s)">
								<IconButton>
									<MdHelpOutline />
								</IconButton>
							</Tooltip>
						</Typography>

						<Switch color="primary" checked={checkedRoyalty} onChange={handleCheckedRoyaltySharing} />
					</Box>

					{ checkedRoyalty && (
					<Box display="flex" flexDirection="column">
						{ royaltiesList.map((item, index) => (
						<S.RoyaltyContainer key={index}>
							<Box display="flex" flexDirection="column">
								<Typography variant="subtitle1" color="textPrimary">
									<strong>Share with</strong>
								</Typography>

								<TextField
									placeholder="Wallet Address"
									variant="outlined"
									size="medium"
									name={`wallet-address-${index}`}
									type="text"
									error={!!royaltiesSharingError[index]}
									helperText={royaltiesSharingError[index]}
									onChange={(e) => handleRoyaltiesList(index, 'walletAddress', e.target.value)}
									value={item.walletAddress}
									disabled={index === 0}
								/>
							</Box>

							<Box display="flex" flexDirection="column">
								<Typography variant="subtitle1" color="textPrimary">
									<strong>Rate (%)</strong>
								</Typography>

								<TextField
									placeholder="Rate"
									variant="outlined"
									size="medium"
									name={`wallet-royalty-${index}`}
									type="text"
									value={royaltiesList[index].royalties}
									onChange={(e) => handleRoyaltiesList(index, 'royalties', e.target.value)}
									onBlur={handleRoyaltiesSharing}
									disabled={index === 0}
								/>
							</Box>

							{ !(item.walletAddress === system.tzPublicKey!) && (
							<Box display="flex" alignItems="center" justifyContent="center" ml={-4} pt={4} pb={4}>
								<IconButton onClick={() => handleDeleteRoyalties(index)}>
									<MdRemoveCircleOutline />
								</IconButton>
							</Box>
							)}
						</S.RoyaltyContainer>								
						))}

						<Box display="flex" alignItems="center" justifyContent="center" mt={2}>
							<IconButton onClick={handleAddRoyalties}>
								<MdAddCircleOutline />
							</IconButton>
						</Box>
					</Box>
					)}
					</Hidden>
				</Grid>

				<Grid item xs={12}>
					<Button 
						disabled={isLoading}
						variant="contained" 
						type="button" 
						color="primary" 
						size="large" 
						onClick={handleCreateToken}
					>
						{ isLoading && <CircularProgress size={20} style={{ marginRight: 12 }} /> }
						{ isLoading ? 'Minting' : 'Mint' }
					</Button>
				</Grid>

				<Modal open={openModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Box bgcolor="#fff" width={600} minHeight={300} display="flex" alignItems="center" justifyContent="center">
						<Box display="flex" flexDirection="column" px={4} py={10} width={'100%'}>
							<Stepper alternativeLabel nonLinear activeStep={activeStep || 0}>
							{ steps.map((step, index) => (
								<Step key={`step=${step}-${index}`}>
									<StepLabel StepIconComponent={StepIcon}>{ step }</StepLabel>
								</Step>
							))}
							</Stepper>

							<Box mt={3} display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ gap: 24 }}>
								<Typography variant="subtitle1">
									<span>{getStepContent(activeStep || 0)}</span>							
								</Typography>

								{ activeStep === 4 && (
									<Button
										variant="contained" 
										type="button" 
										color="primary" 
										size="large" 
										onClick={handleCompleteMint}
									>
										Next Step
									</Button>
								)}
							</Box>
						</Box>
					</Box>
				</Modal>
			</Grid>
		</S.Container>
	);
}