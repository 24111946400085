import styled, { css } from 'styled-components';
import { Box, Button, Modal } from '@material-ui/core';

export const MuiModal = styled(Modal)`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;

        ${theme.palette.type === 'dark' && css`
            .MuiButton-containedPrimary {
                color: #fff;
            }
        `}
    `}
`;

export const ModalContent = styled(Box)`
    ${({ theme }) => css`
        width: 700px;
        height: auto;

        padding: 32px 48px;

        ${theme.palette.type === 'dark' && css`
            .MuiInputBase-root {
                border: 1px solid #000;
                color: ${theme.palette.alternate.main};
            }        
        `}
    `}
`;

export const RoyaltiesButton = styled(Button)<{ active?: boolean }>`
    ${({ active = false }) => css`
        ${ active && css`
            border: 1px solid #5A2D81;
            background-color: #eddff9;
        `}

        &:hover {
            background-color: #eddff9;
        }
    `}
`;

export const RoyaltyContainer = styled.div<{ isCustom?: boolean }>`
    ${({ isCustom = false }) => css`
        display: grid;
        grid-template-columns: ${isCustom ? '75% 15% 10%' : '75% 25%'};
        grid-gap: 16px;
        margin-top: 16px;    
    `}
`;