import React, { useEffect, useState } from 'react';

import { Box, Button, CircularProgress, Grid, Hidden, TextField, Tooltip, Typography } from '@material-ui/core';
import { MdCameraAlt, MdCancel, MdEdit, MdSave } from 'react-icons/md';

import { Avatar } from 'components/organisms';
import api from 'services/api';

import useAuth from 'hooks/auth';

import * as S from './styles';

interface CuratedProps {
    curator: string;
}

export default function Cover({ curator }: CuratedProps): JSX.Element {
    const { user, setUser } = useAuth();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(false);
    const [isOwner, setIsOwner] = useState<boolean>(true);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const [avatarUrl, setAvatarUrl] = useState<string>('');
    const [coverUrl, setCoverUrl] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const [isHoverAvatar, setIsHoverAvatar] = useState<boolean>(false);
    
    useEffect(() => {
        if (user && (user.wallet === curator)) {
            setAvatarUrl(user?.curatedGallery?.avatarUrl || '');
            setCoverUrl(user?.curatedGallery?.imageUrl || '');
            setDescription(user?.curatedGallery?.description || '');
        }

        setIsOwner(user.wallet === curator);
        
		if (curator) {
			(async () => {
				setIsLoading(true);
                
				const { data: { user } } = await api.get(`/users/${curator}`);
                
				if (user && user.curatedGallery) {
                    setAvatarUrl(user.curatedGallery?.avatarUrl);
					setCoverUrl(user.curatedGallery?.imageUrl);
                    setDescription(user.curatedGallery?.description);
				}

				setIsLoading(false);
			})();
		}
    }, []);

    async function handleCoverChange(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files;

        if (file) {
            setIsLoading(true);

            const formData = new FormData();
            formData.append('field', 'cover');
            formData.append('cover', file[0]);

            try {
                const { data: { error, object, message } } = await api.post(`/users/update-curated/${curator}`, formData);
    
                if (error) {
                    alert(message);
                    return false;
                }
    
                Object.assign(user, object);
    
                setCoverUrl(object.curatedGallery.imageUrl);
                setUser(user);
            } catch (error) {
                console.log({ error });
                alert("Sorry, error on uploading file");                
            }
            
            setIsLoading(false);                
        }
    }

    async function handleAvatarChange(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files;

        if (file) {
            setIsAvatarLoading(true);

            const formData = new FormData();
            formData.append('field', 'avatar');
            formData.append('avatar', file[0]);

            try {
                const { data: { error, object, message } } = await api.post(`/users/update-curated/${curator}`, formData);
    
                if (error) {
                    alert(message);
                    return false;
                }
    
                Object.assign(user, object);
    
                setAvatarUrl(object.curatedGallery.avatarUrl);
                setUser(user);                
            } catch (error) {
                console.log({ error });
                alert("Sorry, error on uploading file");                
            }

            setIsAvatarLoading(false);
        }
    }

    async function handleSaveDescription() {
        setIsLoading(true);

        if (description) {
            const { data: { error, message } } = await api.post(`/users/update-curated/${curator}`, { description });

            if (error) {
                alert(message);
                return false;
            }

            setIsEditMode(false);
            setIsLoading(false);
        }
    }

    return (
        <>
            <S.Wrapper>
                <S.BannerContent>
                    { !isLoading && <img src={coverUrl} alt="" /> }
                    
                    <CircularProgress size={72} hidden={!isLoading} />

                    { isOwner && (
                    <label htmlFor="uploadCover">
                        <Tooltip title="Recommended 1400x300px">
                            <Button color="default" variant="contained" component="span" disabled={isLoading}>
                                <MdCameraAlt size={20} />{ coverUrl ? 'Edit Cover Photo' : 'Add Cover Photo'}
                            </Button>     
                        </Tooltip>

                        <input 
                            type="file" 
                            id="uploadCover" 
                            name="uploadCover" 
                            style={{ display: 'none' }} 
                            onChange={(e) => handleCoverChange(e)} 
                        />
                    </label>
                    )}
                </S.BannerContent>
            </S.Wrapper>

            <Box position="relative">
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <S.Content>
                            <S.AvatarContainer onMouseEnter={() => setIsHoverAvatar(true)} onMouseLeave={() => setIsHoverAvatar(false)} isHoverAvatar={isHoverAvatar}>
                                <S.Avatar>
                                    <S.AvatarImage hasAvatarUrl={!!avatarUrl}>
                                        { !isAvatarLoading && <img src={avatarUrl} alt="" /> }

                                        {/* <Avatar title={curator} link={`/creator/${curator}`} avatarUrl={avatarUrl} /> */}

                                        <CircularProgress size={40} hidden={!isAvatarLoading} />

                                        { isOwner && (
                                        <Tooltip title="Recommended 300x300px">
                                            <label 
                                                htmlFor="uploadAvatar" 
                                                style={{ display: isHoverAvatar ? 'flex' : 'none' }} 
                                                onMouseEnter={() => setIsHoverAvatar(true)} 
                                                onMouseLeave={() => setIsHoverAvatar(false)}
                                            >
                                                <span>
                                                    <MdCameraAlt size={20} color="#fff" />
                                                </span>

                                                <input 
                                                    type="file" 
                                                    id="uploadAvatar" 
                                                    name="uploadAvatar" 
                                                    style={{ display: 'none' }} 
                                                    onChange={(e) => handleAvatarChange(e)} 
                                                    />
                                            </label>
                                        </Tooltip>
                                        )}
                                    </S.AvatarImage>
                                </S.Avatar>
                            </S.AvatarContainer>
                        </S.Content>
                    </Grid>
                    
                    <Hidden smDown smUp>
                        <S.DescriptionGrid item xs={12}>
                            <S.Description>
                                { isEditMode ? (
                                <>
                                    <TextField
                                        placeholder="Your description"
                                        variant="outlined"
                                        name="description"
                                        fullWidth
                                        multiline
                                        rows={6}
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />

                                    <Box>
                                        <S.WhiteButton variant="contained" onClick={() => handleSaveDescription()} disabled={isLoading}>
                                            <MdSave size={20} />Save description
                                        </S.WhiteButton>

                                        <S.CancelButton variant="contained" onClick={() => setIsEditMode(false)} disabled={isLoading}>
                                            <MdCancel size={20} />Cancel
                                        </S.CancelButton>
                                    </Box>
                                </>
                                ) : (
                                <>
                                    { description && (<Typography style={{ textAlign: 'center' }}>{ description }</Typography>)}

                                    { isOwner && (
                                    <S.WhiteButton color="default" variant="contained" onClick={() => setIsEditMode(true) }>
                                        <MdEdit size={20} />{ description ? 'Edit description' : 'Add description' }
                                    </S.WhiteButton>
                                    )}
                                </>
                                )}
                            </S.Description>
                        </S.DescriptionGrid>
                    </Hidden>
                </Grid>
            </Box>
        </>
    );
}