import { MichelsonMap } from '@taquito/taquito';
import { Fa2MultiNftAssetCode, Fa2MultiNftFaucetCode, Fa2MultiNftFaucetOnDemandCode, EnglishAuctionTezCode } from '../../smart-contracts';
import { Buffer } from 'buffer';
import { SystemWithWallet } from '../system';
import { uploadIPFSJSON } from '../util/ipfs';
import { NftMetadata } from './decoders';
import config from '../../config.json';
import slugify from 'slugify';

const globalCollection = config.contracts.nftFaucet;

function toHexString(input: string) {
  return Buffer.from(input).toString('hex');
}

export async function createFaucetContract(system: SystemWithWallet, name: string) {
  const metadataMap = new MichelsonMap<string, string>();
  const resp = await uploadIPFSJSON(system.config.ipfsApi, {
    name,
    description: 'An OpenMinter base collection contract.',
    interfaces: ['TZIP-012', 'TZIP-016', 'TZIP-020'],
    tokenCategory: 'collectibles'
  });

  metadataMap.set('', toHexString(resp.data.ipfsUri));

  return await system.toolkit.wallet
    .originate({
      code: Fa2MultiNftFaucetCode.code,
      storage: {
        assets: {
          ledger: new MichelsonMap(),
          next_token_id: 0,
          operators: new MichelsonMap(),
          token_metadata: new MichelsonMap()
        },
        metadata: metadataMap
      }
    })
    .send();
}

export async function createFaucetOnDemandContract(system: SystemWithWallet, name: string, description: string, price: number, limit: number) {
  const metadataMap = new MichelsonMap<string, string>();
  // TODO: provide contract name and description for on demand contract
  console.log(`*** in createFaucetOnDemandContract, about to call uploadIPFSJSON`);
  const resp = await uploadIPFSJSON(system.config.ipfsApi, {
    name: 'Orbix360 Minter',
    description: 'Orbix360 mint on demand contract',
    interfaces: ['TZIP-012', 'TZIP-016', 'TZIP-020'],
    tokenCategory: 'collectibles'
  });

  metadataMap.set('', toHexString(resp.data.ipfsUri));
  console.log(`*** in createFaucetOnDemandContract, about to call originate`);

  return await system.toolkit.wallet
    .originate({
      code: Fa2MultiNftFaucetOnDemandCode.code,
      storage: {
        assets: {
          ledger: new MichelsonMap(),
          next_token_id: 0,
          operators: new MichelsonMap(),
          token_metadata: new MichelsonMap(),
          token_data: new MichelsonMap(),
          next_editions_id: 0,
          editions_data: new MichelsonMap(),
        },
        metadata: metadataMap,
        price: price * 1000000,
        limit: limit,
        end_time: new Date(new Date().setFullYear(new Date().getFullYear() + 100)),
        creator_address: system.tzPublicKey,
        creator_percentage: 0,
        collaborator_1_address: system.tzPublicKey,
        collaborator_1_percentage: 9700,
        collaborator_2_address: system.tzPublicKey,
        collaborator_2_percentage: 0,
        platform_fee_address: system.tzPublicKey,
        platform_fee_percentage: 300,
      }
    })
    .send();
}

export async function createAuctionsContract(system: SystemWithWallet, name: string, description: string) {
  const metadataMap = new MichelsonMap<string, string>();
  // TODO: provide contract name and description for on demand contract
  const resp = await uploadIPFSJSON(system.config.ipfsApi, {
    name: 'Orbix360 Auctions',
    description: 'Orbix360 auctions contract',
    interfaces: ['TZIP-012', 'TZIP-016', 'TZIP-020'],
    tokenCategory: 'collectibles'
  });

  metadataMap.set('', toHexString(resp.data.ipfsUri));

  return await system.toolkit.wallet
    .originate({
      code: EnglishAuctionTezCode.code,
      storage: {
          admin: {
            admin: system.tzPublicKey,
            paused: false
          },
          auctions: new MichelsonMap(),
          current_id: 0,
          max_auction_time: 100,
          max_config_to_start_time : 100,
          metadata: metadataMap,
      }
    })
    .send();
}

export async function createAssetContract(system: SystemWithWallet, metadata: Record<string, string>) {
  const metadataMap = new MichelsonMap<string, string>();
  const resp = await uploadIPFSJSON(system.config.ipfsApi, {
    description: 'An OpenMinter assets contract.',
    interfaces: ['TZIP-012', 'TZIP-016', 'TZIP-020'],
    tokenCategory: 'collectibles',
    ...metadata
  });

  metadataMap.set('', toHexString(resp.data.ipfsUri));

  return await system.toolkit.wallet
    .originate({
      code: Fa2MultiNftAssetCode.code,
      storage: {
        assets: {
          ledger: new MichelsonMap(),
          next_token_id: 0,
          operators: new MichelsonMap(),
          token_metadata: new MichelsonMap()
        },
        admin: {
          admin: system.tzPublicKey,
          pending_admin: null,
          paused: false
        },
        metadata: metadataMap
      }
    })
    .send();
}

export async function mintToken( system: SystemWithWallet, address: string, metadata: NftMetadata ) {
  const contract = await system.toolkit.wallet.at(globalCollection);
  const storage = await contract.storage<any>();

  const token_id = storage.assets.next_token_id;
  const token_info = new MichelsonMap<string, string>();
  const extras = new MichelsonMap<string, string>();
  const resp = await uploadIPFSJSON(system.config.ipfsApi, {
    ...metadata,
    decimals: 0,
    isBooleanAmount: true
  });
  const artifactUri = metadata.artifactUri ? metadata.artifactUri : ''
  token_info.set('', toHexString(resp.data.ipfsUri));
  token_info.set('artifact_uri', toHexString(artifactUri));
  extras.set('creator', toHexString(system.tzPublicKey));
  extras.set('collection_name', toHexString('Something'));
  extras.set('royalties', toHexString('1000'));

  return contract.methods
    .mint([
      {
        owner: system.tzPublicKey,
        token_metadata: {
          token_id,
          token_info,
          extras
        }
      }
    ])
    .send();
}

interface MintData {
  owner: string;
  token_metadata: {
    token_id: number;
    token_info: MichelsonMap<string, string>;
  };
  token_data: {
    token_id: number;
    extras: MichelsonMap<string, string>;
    name: string;
    creator: string;
    collection_name: string;
    category: string;
    tags: MichelsonMap<string, string>;
    edition_number: number;
    number_of_editions: number;
  };
}

export async function mintTokens(system: SystemWithWallet, address: string, metadata: NftMetadata[], amount: number) {
  const contract = await system.toolkit.wallet.at(address);
  const storage = await contract.storage<any>();

  const token_id = storage.assets.next_token_id;
  const editions_id = storage.assets.next_editions_id;
  const mints: MintData[] = [];

  const parsedTokens: NftMetadata[] = [];

  let edition_number = 1;
  let name = '';

  console.log(`in mint_tokens, metadata length: ${metadata.length}`)
  for (const [index, meta] of metadata.entries()) {
    console.log(`in mintTokens loop, token_id: ${token_id}, index: ${index}`)
    
    if (metadata.length > 0) {
      meta.editions_id = editions_id;
    }
    
    name = meta.name ? meta.name : '';

    const token_info = new MichelsonMap<string, string>();
    const extras = new MichelsonMap<string, string>();
    const tags = new MichelsonMap<string, string>();
    const resp = await uploadIPFSJSON(system.config.ipfsApi, { ...meta, decimals: 0, isBooleanAmount: true });
    
    token_info.set('', toHexString(resp.data.ipfsUri));
    token_info.set('edition_number', toHexString(String(edition_number)))
    token_info.set('number_of_editions', toHexString(String(metadata.length)))

    if (metadata.length > 1) {
      extras.set('editions_id', String(editions_id));
      token_info.set('editions_id', toHexString(String(editions_id)))
    }

    let metaTags: string | any[] = []
    if (meta && meta.tags) {
      metaTags = meta.tags;
    }

    for (let i = 0; i < metaTags.length; i++) {
      let tag = slugify(metaTags[i], '_');
      tags.set(tag, 'tag');
    }

    parsedTokens.push({
      ...meta,
      owner: system.tzPublicKey,
      edition_number,
      number_of_editions: metadata.length,
      token_id: Number(token_id) + Number(index),
      editions_id,
    });

    // example of querying by token info properties:
    // https://api.hangzhou2net.tzkt.io/v1/contracts/KT1R1YG6K6x5momdLSvPwrZ29X1jdzANvSjw/bigmaps/assets.token_metadata/keys?limit=12&sort.desc=id&value.token_info.collection_name=536f6d657468696e67&value.token_info.edition_number=31
    // collection query:  https://api.hangzhou2net.tzkt.io/v1/contracts/KT1GKxQKtGhzsfVh6bhwkkNvGwbqTNDgm4Zj/bigmaps/assets.token_data/keys?limit=12&sort.desc=id&value.collection_name=anaglyph&value.creator=tz29miNB1d1p9LrKaExXcvit92VzdbPVHpnR
    // tag query:  https://api.hangzhou2net.tzkt.io/v1/contracts/KT1GKxQKtGhzsfVh6bhwkkNvGwbqTNDgm4Zj/bigmaps/assets.token_data/keys?limit=12&sort.desc=id&value.tags.emoji=tag
    mints.push({
      owner: system.tzPublicKey,
      token_metadata: {
        token_id: Number(token_id) + Number(index),
        token_info: token_info
      },
      token_data: {
        token_id: Number(token_id) + Number(index),
        extras: extras,
        name: name,
        creator: system.tzPublicKey,
        collection_name: meta.collection_name?.trim() || '',
        category: meta.category?.trim() || '',
        tags: tags,
        edition_number: edition_number,
        number_of_editions: metadata.length,
      }
    });

    edition_number++;
  }

  localStorage.setItem('parsedTokens', JSON.stringify(parsedTokens));

  const editions_info = new MichelsonMap<string, string>();
  editions_info.set('editions_name', toHexString(String(name)));
  console.log(`about to mint, mints: ${JSON.stringify(mints)}, editions_info: ${JSON.stringify(editions_info)}`);
  
  return contract.methods.mint(mints, editions_info).send({ amount: amount });
}

export async function transferToken( system: SystemWithWallet, contractAddress: string, tokenId: number, toAddress: string ) {
  const contract = await system.toolkit.wallet.at(contractAddress);
  return contract.methods
    .transfer([
      {
        from_: system.tzPublicKey,
        txs: [{ to_: toAddress, token_id: tokenId, amount: 1 }]
      }
    ])
    .send();
}

export async function listTokenForSale(
  system: SystemWithWallet,
  marketplaceContract: string,
  tokenContract: string,
  tokenId: number,
  salePrice: number,
  saleQty: number,
  creator: string,
  royalties: number,
  tokenMetadata: any,
  sales_list_map: { address: string; percentage: number; }[]
) {
  console.log(`in listTokenForSale, royalties: ${royalties}, tokenMetadata.royalties_split_map: ${JSON.stringify(tokenMetadata?.royalties_split_map)}`)
  const contractM = await system.toolkit.wallet.at(marketplaceContract);
  const contractT = await system.toolkit.wallet.at(tokenContract);
  const marketplaceFeeAddress = config.contractOpts.marketplace.fee.address;

  const batch = system.toolkit.wallet
    .batch([])
    .withContractCall(
      contractT.methods.update_operators([
        {
          add_operator: {
            owner: system.tzPublicKey,
            operator: marketplaceContract,
            token_id: tokenId
          }
        }
      ])
    );

  const sellSchema = contractM.parameterSchema.ExtractSchema()['sell'];

  if (sellSchema.hasOwnProperty('sale_token_param_tez')) {
    console.log(`sale_token_param_tez exists`)
    batch.withContractCall( contractM.methods.sell(salePrice, tokenContract, tokenId) );
  } else {
    console.log(`sale_token_param_tez doesn't exist, saleQty: ${saleQty}`)
    const sales_split_map = new MichelsonMap<string, number>();

    if (sales_list_map && sales_list_map.length > 0) {
      for (let i = 0; i < sales_list_map.length; i++) {
        const { address, percentage } = sales_list_map[i];
        sales_split_map.set(address, percentage);
      }
    } else {
      sales_split_map.set(system.tzPublicKey, 10000)
    }

    const royalties_split_map = new MichelsonMap<string, number>();
    for (let i = 0; i < tokenMetadata.royalties_split_map.length; i++) {
      royalties_split_map.set(tokenMetadata.royalties_split_map[i].address, tokenMetadata.royalties_split_map[i].percentage);
    }

    const fees_split_map = new MichelsonMap<string, number>();
    fees_split_map.set(marketplaceFeeAddress, 10000);
    // set markeplace fee to zero since we are adding fee in the royalty map instead

    const tags_map = new MichelsonMap<string, string>();
    const extras_map = new MichelsonMap<string, string>();

    const marketPlaceFee = 250;
    batch.withContractCall(
      contractM.methods.sell(
        tokenContract,
        tokenId,
        salePrice,
        saleQty,
        marketPlaceFee,
        fees_split_map,
        royalties,
        royalties_split_map,
        sales_split_map,
        creator,
        tokenMetadata.name ? tokenMetadata.name : '',
        tokenMetadata.collection_name ? tokenMetadata.collection_name : '',
        tokenMetadata.category ? tokenMetadata.category : '',
        tags_map,
        tokenMetadata.edition_number ? Number(tokenMetadata.edition_number) : 1,
        tokenMetadata.number_of_editions ? Number(tokenMetadata.number_of_editions) : 1,
        extras_map
      )
    );
  }

  return batch.send();
}

export async function cancelTokenSaleLegacy(system: SystemWithWallet, marketplaceContract: string, tokenContract: string, tokenId: number) {
  const contractM = await system.toolkit.wallet.at(marketplaceContract);
  const contractT = await system.toolkit.wallet.at(tokenContract);
  const batch = system.toolkit.wallet
    .batch([])
    .withContractCall(
      contractM.methods.cancel(system.tzPublicKey, tokenContract, tokenId)
    )
    .withContractCall(
      contractT.methods.update_operators([
        {
          remove_operator: {
            owner: system.tzPublicKey,
            operator: marketplaceContract,
            token_id: tokenId
          }
        }
      ])
    );
  return batch.send();
}

export async function cancelTokenSale( system: SystemWithWallet, marketplaceContract: string, tokenContract: string, tokenId: number, saleId: number ) {
  const contractM = await system.toolkit.wallet.at(marketplaceContract);
  const contractT = await system.toolkit.wallet.at(tokenContract);
  const batch = system.toolkit.wallet
    .batch([])
    .withContractCall( contractM.methods.cancel(saleId, tokenContract, tokenId) )
    .withContractCall(
      contractT.methods.update_operators([
        {
          remove_operator: {
            owner: system.tzPublicKey,
            operator: marketplaceContract,
            token_id: tokenId
          }
        }
      ])
    );
  
  return batch.send();
}

export async function approveTokenOperator( system: SystemWithWallet, contractAddress: string, tokenId: number, operatorAddress: string ) {
  const contract = await system.toolkit.wallet.at(contractAddress);
  
  return contract.methods
    .update_operators([
      {
        add_operator: {
          owner: system.tzPublicKey,
          operator: operatorAddress,
          token_id: tokenId
        }
      }
    ])
    .send();
}

export async function removeTokenOperator( system: SystemWithWallet, contractAddress: string, tokenId: number, operatorAddress: string ) {
  const contract = await system.toolkit.wallet.at(contractAddress);

  return contract.methods
    .update_operators([
      {
        remove_operator: {
          owner: system.tzPublicKey,
          operator: operatorAddress,
          token_id: tokenId
        }
      }
    ])
    .send();
}

export async function buyTokenLegacy( system: SystemWithWallet, marketplaceContract: string, tokenContract: string, tokenId: number, tokenSeller: string, salePrice: number ) {
  const contract = await system.toolkit.wallet.at(marketplaceContract);
  console.log(`in buyTokenLegacy, about to call buy`);
  
  return contract.methods
    .buy(tokenSeller, tokenContract, tokenId)
    .send({ amount: salePrice });
}

export async function buyToken( system: SystemWithWallet, marketplaceContract: string, saleId: number, tokenContract: string, tokenId: number, salePrice: number ) {
  const contract = await system.toolkit.wallet.at(marketplaceContract);
  console.log(`in buyToken, about to call buy for saleId: ${saleId}, salePrice: ${salePrice}`)
  
  return contract.methods
    .buy(saleId, tokenContract, tokenId, salePrice * 1000000)
    .send({ amount: salePrice });
}