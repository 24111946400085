// const FIREBASE_CONFIG = JSON.parse('process.env.FIREBASE_CONFIG');
// PROJECT_ID: FIREBASE_CONFIG.projectId === undefined minter'orbix360-qa' : FIREBASE_CONFIG.projectId,
type FirebaseProp = 'minter-devel' | 'minter-qa' | 'minter-prod';
let firebaseProjectId: FirebaseProp = 'minter-qa';

if (typeof window !== 'undefined') {
	firebaseProjectId = (window.location.href.includes('minter-qa') || window.location.href.includes('localhost')) ? 'minter-qa' : 'minter-prod';
}

// Change variables to one object
const orbixConfig = {
	'orbix360-minter': {
		FIREBASE_MESSENGER_IDS: '678105175789',
		FIREBASE_API_KEYS: 'AIzaSyAYq3pjdse5EWDQesetrc4-DMrKNIT3RPM',
		FIREBASE_AUTH_DOMAINS: 'auth.qa.orbix360.com',
		ORBIX_UIDS: 'ZIGqF6yhmEZl3yjaK4dEF8ajipl1',
		SHORT_URL_ROOTS: 'https://dev.orbix360.com',
		STRIPE_PUBLISHABLE_KEYS: 'pk_test_zBPdrH6rxl3LCqsrBmskeZIX',
		STRIPE_SECRET_KEYS: 'sk_test_cZlkTKnc0Qwvr75VFdWXv6LE',
		BUCKET_NAMES: 'orbix360-devel-images',
		GOOGLE_ANALYTICS_KEY: 'UA-101425575-2',
	},
	'minter-qa': {
		FIREBASE_MESSENGER_IDS: '1094879774810',
		FIREBASE_API_KEYS: 'AIzaSyDMiBwCPfKAfBnI_8ylSNku8l2RV9qCJ70',
		FIREBASE_AUTH_DOMAINS: 'auth.qa.orbix360.com',
		ORBIX_UIDS: 'ZIGqF6yhmEZl3yjaK4dEF8ajipl1',
		SHORT_URL_ROOTS: 'https://qa.orbix360.com',
		STRIPE_PUBLISHABLE_KEYS: 'pk_test_zBPdrH6rxl3LCqsrBmskeZIX',
		STRIPE_SECRET_KEYS: 'sk_test_cZlkTKnc0Qwvr75VFdWXv6LE',
		BUCKET_NAMES: 'orbix360-qa-images',
		GOOGLE_ANALYTICS_KEY: 'UA-101425575-2',
	},
	'minter-prod': {
		FIREBASE_MESSENGER_IDS: '500821872894',
		FIREBASE_API_KEYS: 'AIzaSyBTa6N8hXmFWhvh_JlA5MkNnf9-997KfLI',
		FIREBASE_AUTH_DOMAINS: 'auth.orbix360.com',
		ORBIX_UIDS: 'sn4iLJyuI5Y6LMBmIQrqSelGxJK2',
		SHORT_URL_ROOTS: 'https://orbix360.com',
		STRIPE_PUBLISHABLE_KEYS: 'pk_live_QHrclGUwpGdReAoeDSJ1lAcs',
		STRIPE_SECRET_KEYS: 'sk_live_2LT7AU2TfaBzZjkFTaSqbITr',
		BUCKET_NAMES: 'orbix360-prod-images',
		GOOGLE_ANALYTICS_KEY: 'UA-101425575-1',
	},
};

const STRIPE_SECRET_KEYS = {
	'orbix360-minter': 'sk_test_cZlkTKnc0Qwvr75VFdWXv6LE',
	'minter-qa': 'sk_test_cZlkTKnc0Qwvr75VFdWXv6LE',
	'minter-prod': 'sk_live_2LT7AU2TfaBzZjkFTaSqbITr',
};

const SHORT_URL_ROOTS = {
	'orbix360-minter': 'https://minter-qa.orbix360.com/',
	'minter-qa': 'https://minter-qa.orbix360.com/',
	'minter-prod': 'https://minter.orbix360.com/',
};

const ORBIX_SHORT_URL_ROOTS = {
	'orbix360-minter': 'https://qa.orbix360.com/',
	'minter-qa': 'https://qa.orbix360.com/',
	'minter-prod': 'https://orbix360.com/',
};

const WALLET_ADDRESSES = {
	'minter-qa': 'https://ghostnet.tzkt.io',
	'minter-prod': 'https://tzkt.io',
};

const GOOGLE_ANALYTICS_KEYS = {
	'orbix360-minter': 'UA-101425575-2',
	'minter-qa': 'UA-101425575-2',
	'minter-prod': 'UA-101425575-1',
};

const PROJECT_ID: FirebaseProp = firebaseProjectId;
const GOOGLE_ANALYTICS_KEY = GOOGLE_ANALYTICS_KEYS[PROJECT_ID];

const APP_CONFIG = {
	SHORT_URL_ROOT: SHORT_URL_ROOTS[PROJECT_ID],
	ORBIX_SHORT_URL_ROOT: ORBIX_SHORT_URL_ROOTS[PROJECT_ID],
	WALLET_ADDRESS: WALLET_ADDRESSES[PROJECT_ID],
	ORBIX_UIDS: {
		'orbix360-minter': 'ZIGqF6yhmEZl3yjaK4dEF8ajipl1',
		'minter-qa': 'ZIGqF6yhmEZl3yjaK4dEF8ajipl1',
		'minter-prod': 'sn4iLJyuI5Y6LMBmIQrqSelGxJK2',
	},
	SENDGRID_KEY: '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',
	ENCRYPTION_KEY: 'jWr1f6LliK',
	STRIPE_PUBLISHABLE_KEYS: {
		'orbix360-minter': 'pk_test_zBPdrH6rxl3LCqsrBmskeZIX',
		'minter-qa': 'pk_test_zBPdrH6rxl3LCqsrBmskeZIX',
		'minter-prod': 'pk_live_QHrclGUwpGdReAoeDSJ1lAcs',
	},
	BUCKET_URL_ROOT: `https://storage.googleapis.com/${orbixConfig[PROJECT_ID].BUCKET_NAMES}`,
	BUCKET_NAME: orbixConfig[PROJECT_ID],
	LOGIN_COOKIE_VERSION: 'v2',
	LOGIN_COOKIE_NAME: '__session',
	STRIPE_SECRET_KEY: STRIPE_SECRET_KEYS[PROJECT_ID],
	FIREBASE_API_KEY: 'AIzaSyDCRB8mtyMoeVrY3o2BVezT7jBAQ_uGcPo',
	FIREBASE_AUTH_DOMAIN: 'auth.minter-qa.orbix360.com',
	FIREBASE_PROJECT_ID: 'orbix360-minter-qa',
	GOOGLE_ANALYTICS_KEY: GOOGLE_ANALYTICS_KEY,
};

export default APP_CONFIG;
