import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { CardProduct } from '..';

const useStyles = makeStyles(theme => ({
    image: {
        objectFit: 'cover',
        height: 300,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
          height: 250,
          width: '100%'
        }    
    },
    blogContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    list: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          alignItems: 'start',
          flexDirection: 'column',
        }
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          justifyContent: 'center',
        }
    },
    cardProduct: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRadius: theme.spacing(1),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,

        '& .card-product__content': {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },

        [theme.breakpoints.down('sm')]: {
          height: 'auto',
    
          '& .card-product__content': {
            padding: theme.spacing(1)
          }
        }
    },
                        
}));

export default function CardSkeleton() {
    const classes = useStyles();

    const CardMediaContent = () => {
        return <Skeleton className={classes.image} animation="wave" variant="rect" />;
    }

    const CardContent = () => (
        <div className={classes.blogContent}>
            <Skeleton animation="wave" height={18} />
        </div>
    );

    return (
        <CardProduct
            withShadow
            liftUp
            className={classes.cardProduct}
            mediaContent={<CardMediaContent />}
            cardContent={<CardContent />}
        />
    );
}
