import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Box, Typography, Hidden, Divider } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { Nft } from 'lib/nfts/decoders';

import { CardSkeleton, Section } from 'components/organisms';
import { getCuratedTokensByWallet } from 'services/collections';

const useStyles = makeStyles(theme => ({
  sectionAlternate: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    '& .section-alternate__content': {
    },
  },
  button: {
    minWidth: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 420,
    },
  },
  tabRoot: {
    width: '100%',
  },
  tab: {
    marginBottom: theme.spacing(3),
    width: 'fit-content',

    '& .MuiTab-textColorPrimary': {
      color: theme.palette.text.primary,
    },

    '& .Mui-selected': {
      backgroundColor: 'transparent',
      fontWeight: 'bolder',
      color: theme.palette.primary.dark,
    }
  },
}));

const Result = ({ curator, className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true, });

  const limit = 12;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [totalKeys] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [nftTokens, setNftTokens] = useState<Nft[]>([]);

  useEffect(() => {
    document.title = `Collaborations: ${curator}`;
 
    (async () => {
      const tokens = await getCuratedTokensByWallet(curator);
      setNftTokens(tokens);
      setIsLoading(false);
    })();
  }, []);

  async function handlePagination(e: any, page: number) {
    setIsLoading(true);

    const tokens = await getCuratedTokensByWallet(curator);
    setNftTokens(tokens);
    setPageNumber(page);

    window.scrollTo(0, 0);

    setIsLoading(false);
  }

  const numPages = Math.ceil(totalKeys / limit);

  return (
    <div className={className} {...rest}>
      <Section className={classes.sectionAlternate}>
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} data-aos="fade-up">
              <Typography variant="h4">Collaborations</Typography>
            </Grid>

            <Divider />
          </Grid>

          {isLoading && (
            <Grid container spacing={4}>
              {[...Array(8).keys()].map((skeleton, index) => (
                <Grid item xs={12} sm={6} md={3} key={index} data-aos="fade-up">
                  <CardSkeleton />
                </Grid>
              ))}
            </Grid>
          )}

          {!isLoading && (
            <Grid container spacing={isMd ? 4 : 2}>
              {nftTokens.length > 0 && nftTokens.map((item, index: number) => (
                <Grid item xs={12} sm={6} md={3} key={index} data-aos="fade-up">
                  {/* <CardToken {...item} hasTitle={true} config={config} /> */}
                </Grid>
              ))}

              { !nftTokens.length && (
                <Box display="flex" alignItems="center" justifyContent="center" minHeight={500} minWidth="100%">
                  <Typography>
                    <strong>No results found</strong>
                  </Typography>
                </Box>
              )}
            </Grid>
          )}

          <Hidden smDown smUp>
            <Grid container spacing={isMd ? 4 : 2}>
              <Grid item xs={12} container justify="center">
                <Box mt={2}>
                  <Pagination
                    count={numPages}
                    page={pageNumber}
                    shape="rounded"
                    color="primary"
                    onChange={handlePagination}
                    disabled={isLoading}
                  />
                </Box>
              </Grid>
            </Grid>
          </Hidden>
        </>
      </Section>
    </div>
  );
};

export default Result;