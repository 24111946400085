import React, { useState } from 'react';
import useLocation from 'wouter/use-location';

import { useDispatch } from 'reducer';
import { mintCustomContractTokenAction } from 'reducer/async/actions';

import { HandleFileType } from 'components/organisms';
import * as S from './styles';

const Hero = ({ className, isOwner, contract, operationHash, templateId = 'hic', ownedToken, afterMint, ...rest }: ViewComponentProps): JSX.Element => {
	const dispatch = useDispatch();

    const [_, setLocation] = useLocation();

	const [isMinting, setIsMinting] = useState<boolean>(false);
	const customContract = contract as Contracts, metadata = contract.metadata;

	let src = customContract.artifactUri.replace('ipfs://', 'https://orbix360.mypinata.cloud/ipfs/');

    async function handleMint() {
        setIsMinting(true);

        const result = await dispatch(mintCustomContractTokenAction({ contract: customContract }));
        setIsMinting(false);

        if (result.meta.requestStatus === "fulfilled") {
            setLocation(`/collection/${metadata?.contractAddress}/token/${metadata.token_id}`);
        }
	};

	return (
		<S.Container>
			<HandleFileType src={src} formats={metadata.formats} artifactUri={customContract.artifactUri} />

			<S.MintButton onClick={async () => await handleMint()} disabled={isMinting}>
				{ isMinting ? 'Minting...' : 'Mint' }

			</S.MintButton>
		</S.Container>
	);
};

export default Hero;

