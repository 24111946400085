import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Avatar, Box, Divider, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from 'reducer';
import { ipfsUriToGatewayUrl } from 'lib/util/ipfs';

import { Image } from 'components/atoms';
import { CardProduct } from 'components/organisms';
import { SelectedFile } from 'reducer/slices/createNft';

const useStyles = makeStyles(theme => ({
    image: {
        objectFit: 'cover',
        transition: 'all .1s',
        height: 300,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
          height: 250,
          width: '100%'
        }    
    },
    video: {
        width: '100%',
    },
    blogContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    list: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          alignItems: 'start',
          flexDirection: 'column',
        }
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          justifyContent: 'center',
        }
    },
    cardProduct: {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
		width: 300,
        borderRadius: theme.spacing(1),
        '& .card-product__content': {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
          height: 'auto',
    
          '& .card-product__content': {
            padding: theme.spacing(1)
          }
        }
    },
}));

const TokenPreview = () => {
	const classes = useStyles();
	const { createNft: state, system: { config, tzPublicKey } } = useSelector(s => s);
    const [token, setToken] = useState(state);
	// const { selectedFile, displayImageFile, fields: { name }} = state;

    const selectedFileRef = useRef<SelectedFile | null>(null);
    const [selectedFile, setSelectedFile] = useState<SelectedFile | null>(null);
    const [displayImageFile, setDisplayImageFile] = useState<SelectedFile | null>(null);
    const [name, setName] = useState<string | null>(null);

    useEffect(() => {
        // console.log({ selectedFile, displayImageFile, name });
        
        if (selectedFileRef?.current?.objectUrl !== token.selectedFile?.objectUrl) {
            console.log(`Diferente: selectedFile`);
            console.log(selectedFileRef);
            console.log({ selectedFile, tokenFile: token.selectedFile });
            setSelectedFile(prevState => {
                console.log(prevState);
                return token.selectedFile
            });
        }
        
        if (displayImageFile !== token.displayImageFile) {
            console.log(`Diferente: displayImageFile`);
            setDisplayImageFile(token.displayImageFile);
        }
        
        if (name !== token.fields.name) {
            console.log(`Diferente: name`);
            setName(token.fields.name);
        }

        selectedFileRef.current = token.selectedFile;
    }, [displayImageFile, selectedFile, token, name]);

    // useMemo(() => {
    //     console.log("atualizei");
    //     console.log({ selectedFile, name });
    //     setDisplayImageFile(state.displayImageFile);
    //     setName(state.fields.name);
    // }, [displayImageFile, name]);

    const MediaContent = useCallback(() => {
		if (!selectedFile || (['zip', 'glb'].includes(selectedFile.type) && !displayImageFile)) {
			return <Skeleton className={classes.image} style={{ transform: 'none' }} />
		}

		if (/^video\/.*/.test(selectedFile?.type)) {
			return (
				<video
					preload="metadata"
					onClick={e => e.preventDefault()}
					onMouseEnter={e => e.currentTarget.play()}
					onMouseLeave={e => e.currentTarget.pause()}
					onLoadedMetadata={e => { (e.target as HTMLVideoElement).currentTime = .05; }}
					muted
					controls
					className={clsx(classes.image, classes.video)}
				>
					<source src={selectedFile.objectUrl} type={selectedFile.type} />
				</video>
			);
		}

		const imageSrc = ipfsUriToGatewayUrl(config, displayImageFile?.objectUrl ?? selectedFile.objectUrl);

		return <Image src={imageSrc} className={classes.image} lazyProps={{ width: '100%', height: '100%' }} defaultSource={imageSrc} />;
    }, [selectedFile]);

	const CardMediaContent = () => {
		if (!selectedFile || (['zip', 'glb'].includes(selectedFile.type) && !displayImageFile)) {
			return <Skeleton className={classes.image} style={{ transform: 'none' }} />
		}

		if (/^video\/.*/.test(selectedFile?.type)) {
			return (
				<video
					preload="metadata"
					onClick={e => e.preventDefault()}
					onMouseEnter={e => e.currentTarget.play()}
					onMouseLeave={e => e.currentTarget.pause()}
					onLoadedMetadata={e => { (e.target as HTMLVideoElement).currentTime = .05; }}
					muted
					controls
					className={clsx(classes.image, classes.video)}
				>
					<source src={selectedFile.objectUrl} type={selectedFile.type} />
				</video>
			);
		}

		const imageSrc = ipfsUriToGatewayUrl(config, displayImageFile?.objectUrl ?? selectedFile.objectUrl);

		return <Image src={imageSrc} className={classes.image} lazyProps={{ width: '100%', height: '100%' }} defaultSource={imageSrc} />;
	}


    const CardContent = () => (
        <div className={classes.blogContent}>
            <>
                <Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" flex={1}>
                        <Typography variant="h6" color="textPrimary">
                            { name ? name.slice(0, 20) : '' }
                        </Typography>

                        {/* <Typography variant="body1" color="textSecondary">
                            1 ꜩ
                        </Typography> */}
                    </Box>
                </Box>
            </>

            <div style={{ flexGrow: 1 }} />

            <Divider className={classes.divider} />

			{ tzPublicKey && (
            <Box className={classes.list}>
                <Box className={classes.avatarContainer}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" flex={1}>
                        <Avatar src={`https://services.tzkt.io/v1/avatars2/${tzPublicKey}`} className={classes.avatar} />

                        <Typography variant="body2" color="textPrimary" title={tzPublicKey}>
                            { `${tzPublicKey.slice(0, 16)}...` }
                        </Typography>
                    </Box>
                </Box>
            </Box>
			)}
        </div>
    );

	return (
		<>
			<Box mb={2}>
				<Typography variant="h6" color="textPrimary">
					<strong>Preview item</strong>
				</Typography>
			</Box>

			<CardProduct
				withShadow
				liftUp
				className={classes.cardProduct}
				mediaContent={<MediaContent />}
				cardContent={<CardContent />}
			/>
		</>
	);
}

export default React.memo(TokenPreview);