import React, { useState } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
// import { useAddress, useDisconnect, useMetamask } from "@thirdweb-dev/react";

import { ethers } from 'ethers';

import { SectionHeader } from "components/molecules";
import { CardBase, SectionAlternate } from "components/organisms";
import useAuth from 'hooks/auth';

declare global {
	interface Window { 
        ethereum: any; 
    }
}

export default function Sign(): JSX.Element {
    const { user } = useAuth();

    const [message, setMessage] = useState('Message test');
    const [signature, setSignature] = useState('');

    async function handleSignMessage() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();

        const signature = await signer.signMessage(message);

        setSignature(signature);
        console.log({ provider, signer, signature, address });
    }

    return (
        <SectionAlternate>
            <>
                <SectionHeader title="Sign Messages" />

                <CardBase>
                    <Box display="flex" flexDirection="column" alignItems="center" width="100%" style={{ gap: 24 }}>
                        <TextField
                            placeholder="Message"
                            variant="outlined"
                            name="message"
                            fullWidth
                            multiline
                            rows={6}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />

                        <label htmlFor="">Signed Message</label>

                        <TextField
                            placeholder="Signed Message"
                            variant="outlined"
                            name="signed-message"
                            fullWidth
                            disabled
                            multiline
                            rows={6}
                            value={signature}
                        />

                        <Button 
                            variant="contained" 
                            type="button" 
                            color="primary" 
                            size="large"
                            onClick={handleSignMessage}
                        >
                            Sign
                        </Button>
                    </Box>
                </CardBase>
            </>
        </SectionAlternate>
    );
}