import { MIMETYPE } from "./constants";

export default function getMimeType(file) {
    return new Promise((resolve) => {
        const filereader = new FileReader();

        filereader.onloadend = function (e) {
            if (e.target.readyState === FileReader.DONE) {
                const uint = new Uint8Array(e.target.result);
                let bytes = [];

                uint.forEach((byte) => {
                    bytes.push(byte.toString(16));
                });

                const hex = bytes.join('').toUpperCase();

                let mimeType;

                switch (hex) {
                    case '7BA2020':
                        mimeType = MIMETYPE.GLTF;
                        break
                    case '676C5446':
                        mimeType = MIMETYPE.GLB;
                        break
                    default:
                        mimeType = 'Unknown MimeType';
                }

                resolve(mimeType);
            }
        }

        filereader.onerror = () => resolve('Unknown MimeType');
        filereader.readAsArrayBuffer(file.slice(0, 4));
    })
}

export function isHTML(mimeType) {
    return (
        mimeType === MIMETYPE.ZIP ||
        mimeType === MIMETYPE.ZIP1 ||
        mimeType === MIMETYPE.ZIP2
    );
}