import { createAsyncThunk } from '@reduxjs/toolkit';
import { State } from '../index';
import { getNftAssetContract, getContractNfts, getMarketplaceNfts, getWalletNftAssetContracts, MarketplaceNftLoadingData, loadMarketplaceNft, getNftAssetContracts, getHistoryNfts,  } from '../../lib/nfts/queries';
import { Nft, AssetContract } from '../../lib/nfts/decoders';
import { ErrorKind, RejectValue } from './errors';

type Opts = { state: State; rejectValue: RejectValue };

export const getNftAssetContractQuery = createAsyncThunk< AssetContract, string, Opts>('query/getNftAssetContract', async (address, api) => {
  const { getState, rejectWithValue } = api;
  const { system } = getState();
  console.log(`in async getNftAssetContractQuery`);
  try {
    console.log(`in async getNftAssetContractQuery, about to call getNftAssetContract`);
    return await getNftAssetContract(system, address);
  } catch (e) {
    return rejectWithValue({
      kind: ErrorKind.GetNftAssetContractFailed,
      message: `Failed to retrieve asset contract: ${address}`
    });
  }
});

export const getHistoryNftsQuery = createAsyncThunk< AssetContract, string, Opts>('query/getHistoryNfts', async (address, api) => {
  const { getState, rejectWithValue } = api;
  const { system } = getState();

  try {
    console.log(`in async getHistoryNftsQuery, about to call getHistoryNfts`);
    const nftTransactionHistory = await getHistoryNfts(system, address);
    return await getHistoryNfts(system, address);
  } catch (e) {
    return rejectWithValue({
      kind: ErrorKind.GetNftAssetContractFailed,
      message: `Failed to retrieve asset contract: ${address}`
    });
  }
});

interface ContractNftsQueryProps {
  address: string;
  limit?: number;
  offset?: number;
}

export const getContractNftsQuery = createAsyncThunk<{ address: string; tokens: Nft[] }, ContractNftsQueryProps, Opts>(
  'query/getContractNfts',
  async ({ address, limit, offset }, { getState, rejectWithValue }) => {
    const { system, collections } = getState();
    
    try {
      const tokens = await getContractNfts(system, address, limit, offset);
      return { address, tokens };
    } catch (e) {
      return rejectWithValue({
        kind: ErrorKind.GetContractNftsFailed,
        message: `Failed to retrieve contract nfts from: ${
          collections.collections[address]?.metadata?.name ?? address
        }`
      });
    }
  }
);

export const getWalletAssetContractsQuery = createAsyncThunk<AssetContract[], undefined, Opts>(
  'query/getWalletNftAssetContracts',
  async (_, { getState, rejectWithValue }) => {
    const { system } = getState();
    if (system.status !== 'WalletConnected') {
      return rejectWithValue({
        kind: ErrorKind.WalletNotConnected,
        message:
          "Could not retrieve wallet's asset contracts: no wallet connected"
      });
    }

    try {
      return await getWalletNftAssetContracts(system);
    } catch (e) {
      console.log(e);
      return rejectWithValue({
        kind: ErrorKind.GetWalletNftAssetContractsFailed,
        message: "Failed to retrieve wallet's asset contracts"
      });
    }
  }
);

export const getAssetContractsQuery = createAsyncThunk<AssetContract[], string, Opts>(
  'query/getNftAssetContracts',
  async (address, { getState, rejectWithValue }) => {
    const { system } = getState();
    if (system.status !== 'WalletConnected') {
      return rejectWithValue({
        kind: ErrorKind.WalletNotConnected,
        message:
          "Could not retrieve wallet's asset contracts: no wallet connected"
      });
    }

    try {
      return await getNftAssetContracts(system, address);
    } catch (e) {
      console.log(e);
      return rejectWithValue({
        kind: ErrorKind.GetNftAssetContractsFailed,
        message: "Failed to retrieve asset contracts"
      });
    }
  }
);

export const getMarketplaceNftsQuery = createAsyncThunk<{ tokens: MarketplaceNftLoadingData[] }, string, Opts>(
  'query/getMarketplaceNfts',
  async (address, { getState, rejectWithValue }) => {
    const { system } = getState();

    try {
      const tokens = await getMarketplaceNfts(system, address, 12, 0);

      for (const i in tokens) {
        tokens[i] = await loadMarketplaceNft(system, tokens[i]);
      }

      return { tokens };
    } catch (e) {
      return rejectWithValue({
        kind: ErrorKind.GetMarketplaceNftsFailed,
        message: `Failed to retrieve marketplace nfts from: ${address}`
      });
    }
  }
);

interface MarketplaceNftsQueryProps {
  limit?: number;
  offset?: number;
  address: string;
}

export const loadMoreMarketplaceNftsQuery = createAsyncThunk<{ tokens: MarketplaceNftLoadingData[] }, MarketplaceNftsQueryProps, Opts>(
  'query/loadMoreMarketplaceNftsQuery',
  async ({ address, limit, offset }, { getState, rejectWithValue }) => {
    const { system } = getState();

    try {
      const tokens = await getMarketplaceNfts(system, address, limit, offset);
      const loadedTokens = await Promise.all(tokens.map(async (token) => await loadMarketplaceNft(system, token)));

      return { tokens: loadedTokens };
    } catch (e) {
      return rejectWithValue({
        kind: ErrorKind.GetMarketplaceNftsFailed,
        message: `Failed to load marketplace nfts`
      });
    }
  }
);
