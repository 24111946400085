import React, { useEffect, useState } from 'react';
import { Box, Grid, Hidden } from '@material-ui/core';

import { Section } from 'components/organisms';
import { ConfirmationPreview, FileUpload, Form, TokenPreview } from './components';

import { useDispatch, useSelector } from 'reducer';
import { clearForm, selectCollection } from 'reducer/slices/createNft';

import config from '../../config.json';
import { connectWallet } from 'reducer/async/wallet';
import { Loader } from 'components/atoms';

const globalCollection = config.contracts.nftFaucet;

export default function CreateNFTView() {
    const dispatch = useDispatch();
    const system = useSelector(s => s.system); 
    
    const [stepForm, setStepForm] = useState<number>(0);
    const isWalletConnected = system.status === 'WalletConnected';
    
    dispatch(selectCollection(globalCollection));

    const TokenPreviewMemoized = React.memo(() => <TokenPreview />);

    useEffect(() => {
        if (!isWalletConnected) {
            dispatch(connectWallet());
        }

        return () => {
            dispatch(clearForm());
        };      
    }, [isWalletConnected, dispatch]);

    if (!isWalletConnected) {
        return <Loader />
    }

    return (
        <Section>
            { stepForm === 0 ? (
            <Grid container justifyContent="center">
                <Grid item xs={12} md={8}>
                    <FileUpload />

                    <Form handleNextStep={() => setStepForm(1)} />
                </Grid>

                <Hidden smDown smUp>
                    <Grid item xs={12} md={4}>
                        <Box position="fixed">
                            <TokenPreviewMemoized />
                        </Box>
                    </Grid>
                </Hidden>
            </Grid>
            ) : (
            <Grid container justifyContent="center">
                <Grid item xs={12} md={8}>
                    <ConfirmationPreview handleBackStep={() => setStepForm(0)} />
                </Grid>
            </Grid>
            )}
        </Section>
    )
}
