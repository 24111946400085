import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;

        iframe,.image {
            width: 100%;
            height: calc(100vh - 230px);
            max-height: calc(100vh - 230px);
            border-radius: 0px;
            border: 0px solid white;
            display: flex;
            margin-top: 0px;  
            
            ${theme.breakpoints.down('sm')} {
                height: 60vh;
                
                #body {
                    overflow-y: auto !important;
                }                
            }
        }    
    `}  
`;
