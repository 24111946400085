import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Result } from './components';

import { useDispatch, useSelector } from 'reducer';
import { getNftAssetContractQuery, getWalletAssetContractsQuery } from 'reducer/async/queries';
import { selectCollection } from 'reducer/slices/collections';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;  

export default function TokenSelectionView() {
    const { system, collections } = useSelector(s => s);
    const dispatch = useDispatch();
  
    const globalCollection = collections.collections[collections.globalCollection];
  
   useEffect(() => {
      if (!globalCollection) {
        dispatch(getNftAssetContractQuery(collections.globalCollection));
        return;
      }
  
      if (collections.selectedCollection === null) {
        dispatch(selectCollection(collections.globalCollection));
        return;
      }
    }, [globalCollection, collections.selectedCollection, collections.globalCollection, dispatch]);

    useEffect(() => {
      console.log(`in TokenSelection`);
      if (system.status === 'WalletConnected') {
        dispatch(getWalletAssetContractsQuery());
      }
    }, [system.status, dispatch]);
  
    const selectedCollection = collections.selectedCollection;
     console.log(`in TokenSelection, selectedCollection: ${selectedCollection}`);

    const urlParams = new URLSearchParams(window.location.search);
    console.log(`got urlParams: ${urlParams}`);
    const type = urlParams.get('type');
    console.log(`got type: ${type}`);

    return (
      <Wrapper>
        <Result address={selectedCollection} owner={system.tzPublicKey} type={type}/>
      </Wrapper>
    )
}
