import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import ErrorButton from 'components/atoms/ErrorButton';
import { cancelTokenSaleAction } from 'reducer/async/actions';
import { useDispatch } from 'reducer';
import { ContentModal } from 'views/CreateNFT/components';

interface CancelTokenProps {
    open: boolean;
    handleClose: () => void;
    contract: string;
    tokenId: number;
    saleId: number;
    saleType: string;
}

export default function CancelToken({ open, handleClose, contract, tokenId, saleId, saleType }: CancelTokenProps) {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to cancel the sale?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <ErrorButton variant="contained" onClick={handleClose} autoFocus>
                        No
                    </ErrorButton>

                    <Button variant='contained' color="primary" onClick={() => { setOpenModal(true); handleClose()}}>Yes</Button>                
                </DialogActions>
            </Dialog>

            <ContentModal 
                isOpen={openModal} 
                method="cancelTokenSale"
                dispatchThunk={() => dispatch(cancelTokenSaleAction({ contract, tokenId, saleId, saleType }))}
                onClose={() => setOpenModal(false)}
                dispatchOnOpen={true}	 
                pendingAsyncMessage="Canceling token sale..." 
            />
        </>
    );
}
