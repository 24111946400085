import { Box, CardMedia, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

const centeredAbsolutePosition = css`
    position: absolute;        
    margin: auto;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

export const Wrapper = styled(Box)`
	.MuiIconButton-root {
		padding: 0;
		padding-left: 4px;
	}
`;

export const MuiCardMedia = styled(CardMedia)`
	width: 100px;
	height: 100px;

	padding: 8px;
    border: 1px dashed rgba(0, 0, 0, 0.12);
    border-radius: 50%;
`;

export const CardImage = styled(Box)`
	width: 100px;
	
	img {
		width: 100px;
		height: 100px;
		overflow: hidden;
		border-radius: 50%;

		padding: 4px;
		border: 1px dashed rgba(0, 0, 0, 0.12);
	}
`;

export const Title = styled(Typography)`
	font-size: 1.6rem;
	font-weight: 700;
	margin-top: 8px;
`;

export const Plan = styled(Typography)`
	${({ theme }) => css`
		strong {
			color: ${theme.palette.type === 'dark' ? '#fff' : '#000'};
		}	
	`}
`;

export const FileButton = styled.span`
	background: transparent;
	box-shadow: none;

	cursor: pointer;
`;

export const AvatarContainer = styled(Box)<{ hasAvatar?: boolean}>`
	${({ theme, hasAvatar = true }) => css`
		position: relative;

		width: 100px;
		height: 100px;
		border-radius: 50%;
		border: 2px solid #fff;	
		
		${!hasAvatar && css`
			background: #c5c5c5;
		`}

        .MuiCircularProgress-root {
            ${centeredAbsolutePosition};
        }
	`}
`;

export const CoverContainer = styled(Box)<{ hascover?: boolean}>`
	${({ theme, hascover = true }) => css`
		position: relative;

		width: 100%;
		height: 150px;
		border-radius: 8px;
		border: 2px solid #fff;	
		
		${!hascover && css`
			background: #dbdada;
		`}

		img {
			border-radius: 8px;
			height: 100%;
			width: 100%;
		}

        .MuiCircularProgress-root {
            ${centeredAbsolutePosition};
        }
	`}
`;

export const ProfileBanner = styled.div`
	${({ theme }) => css`
	
	`}
`;