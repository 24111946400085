import React from 'react';
import { Curated } from 'views';
import { Main } from 'layouts';
import WithLayout from 'WithLayout';
import { handleTitleAndScrollToTop } from 'utils';

const CuratedPage = (props: ViewComponentProps): JSX.Element => {
	handleTitleAndScrollToTop("Minter - Curated");
	
	return <WithLayout component={Curated} layout={Main} {...props} />;
};

export default CuratedPage;