import styled, { css } from "styled-components";

export const Wrapper = styled.div`
    ${({ theme }) => css`
        margin: 0;
        padding: 0;

        margin-top: 32px;
    `}
`;

export const Container = styled.div`
    ${({ theme }) => css`
        width: 100%;
    `}
`;

export const GridContainer = styled.div`
    ${({ theme }) => css`
        position: relative;
        width: 100%;
        max-width: 100%;
        height: 938px;
        max-height: 938px;

        display: flex;
        flex-wrap: wrap;
        gap: 32px;
    `}
`;

export const GridCell = styled.div`
    ${({ theme }) => css`
        width: 303px;
        /* position: absolute; */
        writing-mode: horizontal-tb;
        top: 0;
        left: 0;
    `}
`;

export const AssetLoaded = styled.div`
    ${({ theme }) => css`

    `}
`;

export const Article = styled.article`
    ${({ theme }) => css`
        width: 303px;
        display: flex;
        flex-direction: column;
        height: 100%;  
        border-radius: 10px;
        position: relative;
        z-index: 2;
        overflow: hidden;
        background-color: rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 8%) 0 4px 15px;
        transition: box-shadow 0.25s ease-in-out 0s;
    `}
`;

export const AssetAnchor = styled.a`
    ${({ theme }) => css`
        pointer-events: initial;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        border-radius: inherit;
    `}

    img {
        position: absolute;
        inset: 0px;
        box-sizing: border-box;
        padding: 0px;
        border: none;
        margin: auto;
        display: block;
        width: 0px;
        height: 0px;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        object-fit: contain;        
    }
`;

export const AssetContainer = styled.div`
    ${({ theme }) => css`
        height: 303px;
        width: 303px;

        overflow: hidden;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    `}
`;

export const BodyCard = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        width: 100%;
        height: 150px;

        padding: 12px 16px;
    `}
`;