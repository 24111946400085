import React from 'react';

import BaseToken from 'templates/BaseToken';
import { getFilteredEditions } from 'services/tokens';
import { getUrlQueryParams } from 'utils';

export default function EditionsView(props: ViewComponentProps) {
    const { creator, editionId } = props;
    
    const pageNumber = Number(getUrlQueryParams()['page']) || 1;
    const limit = 18, filter: Filter = { limit, offset: (pageNumber - 1) * limit, orderBy: 'edition_number', descending: false };

    return (
      <BaseToken 
        title="Results for this edition:" 
        handleChange={async () => await getFilteredEditions(filter, editionId, creator)}
        handleChangeProps={{filter, editionId, creator}}
        limit={limit}
        filter={filter}
        pageNumber={pageNumber}
      />
    );
}
