import React from 'react';
import { CreateCustomContract } from 'views';
import { Main } from 'layouts';
import WithLayout from 'WithLayout';
import { handleTitleAndScrollToTop } from 'utils';

const CreateCustomContractPage = ({ contractType }: ViewComponentProps): JSX.Element => {
	handleTitleAndScrollToTop("Minter - Create Custom Contract");
	
	return (<WithLayout component={CreateCustomContract} layout={Main} contractType={contractType} />)
};

export default CreateCustomContractPage;