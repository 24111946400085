import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Divider } from '@material-ui/core';

import { useSelector } from 'reducer';

import { HomeSkeleton, Result } from './components';
import { getFilteredTokensForSale, getTotalForSaleTokens } from 'services/tokens';

const Wrapper = styled.div`
  min-height: calc(100vh - 300px);
  width: 100%;
`;  

const MarketplacePage = (): JSX.Element => {
  const { system, marketplace: state } = useSelector(s => s);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalKeys, setTotalKeys] = useState(0);
  const [tokens, setTokens] = useState<Token[]>([]);

  const limit = 12;

  useEffect(() => {
    (async () => {
      const total = await getTotalForSaleTokens();
      setTotalKeys(total);

      const filteredTokens  = await getFilteredTokensForSale({ limit, offset: 0 });
      setTokens(filteredTokens.object);
      
      setIsLoading(false);
    })();
  }, []);

  return (
    <Wrapper>        
      { isLoading ? (
        <HomeSkeleton />
      ) : (
        <Result data={tokens} config={system.config} address={state.marketplace.address} totalKeys={totalKeys} />
      )}

      <Divider />
    </Wrapper>
  );
};

export default MarketplacePage;
