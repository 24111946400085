import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import { useDropzone } from 'react-dropzone';
import { MdClose } from 'react-icons/md';
import { Box, Grid, Typography } from '@material-ui/core';

import { useSelector, useDispatch } from 'reducer';
import { clearForm, SelectedFile, updateDisplayImageFile } from 'reducer/slices/createNft';
import { readFileAsDataUrlAction } from 'reducer/async/actions';

import FilePreview from '../FilePreview';
import { SectionHeader } from 'components/molecules';

import * as S from './styles';

export default function FileUpload({ file }: { file?: SelectedFile; }): JSX.Element {
  const state = useSelector(s => s.createNft);
  const { selectedFile, displayImageFile } = state;
  const dispatch = useDispatch();

  const convertToMB = 1024 * 1024;
  const typesForDisplayImage = ['zip', 'glb'];

  const [fileType, setFileType] = useState<string>(selectedFile ? selectedFile.name.split('.')[1].trim() : '');
  const [filePreview, setFilePreview] = useState<SelectedFile | null>(displayImageFile || null);
  const [, setLocation] = useLocation();

  useEffect(() => {
    let urlQueryParams: any = {};
    window.location.search.substring(1).split("&").forEach((item) => {
      urlQueryParams[item.split("=")[0]] = item.split("=")[1];
    });

    const fileUrlParam= urlQueryParams['u'];
    
    if (fileUrlParam) {
      const fileType = fileUrlParam.includes('zip') ? 'zip' : 'jpg';
      
      fetch(fileUrlParam).then((fetched) => {
        return fetched.blob()
      }).then((blob) => {
        // @ts-ignore
        const { type } = blob;
        const lastSlashIndex = fileUrlParam.lastIndexOf('/');
        const filename = fileUrlParam.substring(lastSlashIndex + 1);
        const file = new File([blob], `${filename}`, { type });

        if (file) {
          dispatch(readFileAsDataUrlAction({ ns: 'createNft', file }));
          handleFileType(file);
        }
      });
    }
   }, [dispatch])

  const onDrop = useCallback((files: File[]) => {
    if(files[0].size / convertToMB > 100) {
      alert("File size can't be larger than 100MB");

			return;
    }

    handleFileType(files[0]);
    dispatch(readFileAsDataUrlAction({ ns: 'createNft', file: files[0] }));
  }, [convertToMB, dispatch]);

  const onDropDisplayImage = useCallback((files: File[]) => { 
    handlePreviewChange(files[0]);
  }, [handlePreviewChange]);

  function handleFileType(file: File) {
    if (file){
      const type = file.name.split('.')[1].trim();

      if (typesForDisplayImage.includes(type)) {
        setFileType(type);
      } else {
        setFilePreview(null);
        setFileType('');
      }
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const file = event.target.files;
    
    if (file){
      handleFileType(file[0]);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handlePreviewChange(file: File) {
    if (file){
      const { name, type, size } = file, objectUrl = URL.createObjectURL(file);
      console.log(`name: ${name}, type: ${type}, size: ${size}`)
      console.log(`objectUrl: ${objectUrl}`)
      dispatch(updateDisplayImageFile({ name, type, size, objectUrl }));        
      setFilePreview({ name, type, size, objectUrl });
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 100 * 1024 * 1024,
    accept: [
      'image/*',
      'video/*',
      'model/gltf-binary',
      'model/gltf+json',
      '.gltf',
      '.glb',
      '.zip'
    ]
  });

  const { getRootProps: getRootPropsDisplayImage, getInputProps: getInputPropsDisplayImage } = useDropzone({ 
    onDrop: onDropDisplayImage, 
    maxFiles: 1, 
    maxSize: 30 * 1024 * 1024, 
    accept: ['image/*'] 
  });

  return (
    <S.Container>
      <Grid container spacing={4}>
        <S.HeaderContainer item xs={12}>
          <SectionHeader title="Create new item!" align="left" />

          <MdClose size={32} title="Cancel" onClick={() => { dispatch(clearForm()); setLocation('/explore') }} />
        </S.HeaderContainer>

        <Grid item xs={12}>
          <Typography variant="h6" color="textPrimary">
            <strong>Upload File*</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} {...getRootProps()}>
          <input {...getInputProps({ onChange: handleChange })}  />

          { !state.selectedFile ? (
          <S.UploadContainer>
            <S.UploadContent>              
              <Typography variant="subtitle1" color="textPrimary" style={{ textAlign: 'center' }}>
                <strong>
                  JPG, PNG, GIF, WEBP, SVG, MP4, WebM, Ogg, Gltf, Glb, Zip. 
                  <br />Max size 100 MB
                </strong>
              </Typography>

              <S.UploadIcon src="/assets/drag-drop.svg" />

              <S.UploadText>
                <Typography variant="subtitle1" color="textPrimary">
                  <strong>Drag and Drop File</strong>
                </Typography>

                <Typography variant="subtitle1" color="textPrimary">
                  or <strong>browse media on your device</strong>
                </Typography>
              </S.UploadText>              
            </S.UploadContent>
          </S.UploadContainer>
          ) : (
            <FilePreview file={state.selectedFile} />
          )}          
        </Grid>

        { typesForDisplayImage.includes(fileType) && (
        <Grid item xs={12} {...getRootPropsDisplayImage()}>
          <input {...getInputPropsDisplayImage()} />

          <>
            <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary">
                <strong>Display Image*</strong>
              </Typography>
            </Grid>

            <S.UploadContainer>
              { filePreview ? (
              <Box p={4}>
                <Box display="flex" justifyContent="center" alignItems="center" maxWidth="400px" maxHeight="400px" overflow="hidden">
                  <FilePreview file={filePreview} />
                </Box>
              </Box>
              ) : (
                <>
                  <label htmlFor="zip-display-image" style={{ width: '100%' }}>
                    <Box display="flex" justifyContent="center" alignItems="center" py={4} flex="1">
                      <Typography variant="subtitle1">
                        Add a display image for {typesForDisplayImage.map((type, index) => <strong key={`strong-types-${index}`}>{type} / </strong>)} files
                      </Typography>
                    </Box>          
                  </label>
                </>
              )}
            </S.UploadContainer>
          </>
        </Grid>
        )}
      </Grid>
    </S.Container>
  );
}