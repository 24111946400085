export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
    authorOccupation: 'Head of Operations, Parkfield Commerce',
    feedback:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];

export const services = [
  {
    icon: 'fas fa-layer-group',
    title: 'Approachable',
    subtitle:
      'You sell and buy artwork - it’s that simple.',
  },
  {
    icon: 'fab fa-sketch',
    title: 'Easy to use',
    subtitle:
      'No technology skill required.',
  },
  {
    icon: 'fas fa-code',
    title: 'Open',
    subtitle:
      "Your artwork and metaverse work alone or with big-tech properties.",
  },
];

export const features = {
  items: [
    {
      icon: 'far fa-images',
      title: 'Interactive Art',
      subtitle: 'ORBIX360 was developed to explore the limits of non-fungible technology through the lense of interaction, mixed reality and software as a service.Built on the Tezos blockchain (an energy efficient proof-of-stake network), ORBIX360 is an entry point for collectors of all levels.',
    },
    {
      icon: 'fas fa-vr-cardboard',
      title: 'Create an Interactive Collectible (NFT)',
      subtitle: 'Your artistic side wants to craft an AR/VR masterpiece you can mint as an NFT and sell to admiring fans.',
    },
    {
      icon: 'fas fa-layer-group',
      title: 'Break through constraints',
      subtitle: 'Mint Advanced NFTs',
    },
  ],
  properties: [
    'Our sign up is dead simple. We only require your basic company information',
    'We support bulk uploading via SQL, integrations with most data storage products',
    "Simply select where you'd like to transfer your data ",
  ],
};

export const promo = [
  {
    src: 'https://storage.googleapis.com/orbix360-qa-images/users/ZIGqF6yhmEZl3yjaK4dEF8ajipl1/5335349362425856/thumb_360blackjpg-1629388029233.jpg',
    srcSet: 'https://assets.maccarianagency.com/the-front/photos/coworking/place1@2x.jpg 2x',
    alt: '...',
  },
  {
    src: 'https://storage.googleapis.com/orbix360-qa-images/users/ZIGqF6yhmEZl3yjaK4dEF8ajipl1/6525710554365952/thumb_360blackjpg-1629380390800.jpg',
    srcSet: 'https://assets.maccarianagency.com/the-front/photos/coworking/place2@2x.jpg 2x',
    alt: '...',
  },
  {
    src: 'https://storage.googleapis.com/orbix360-qa-images/users/KvjCAnQmXIYgOZ4zx6B5hRPEhB02/4749409959018496/thumb_360imageinstas360projpg-1629426531591.jpg',
    srcSet: 'https://assets.maccarianagency.com/the-front/photos/coworking/place3@2x.jpg 2x',
    alt: '...',
  },
];

export const users = [
  {
    logo: 'https://services.tzkt.io/v1/avatars2/tz1i6RMk1GU8veE39MHY5MrCqGSo1Co4nWj1',
    name: 'Slack',
  },
  {
    logo: 'https://services.tzkt.io/v1/avatars2/tz1fmrfpWm2u5yJaVKgigSCzTnght6wixSrX',
    name: 'Mailchimp',
  },
  {
    logo: 'https://services.tzkt.io/v1/avatars2/tz2LTkFaYqZktgATUvbQJ4QHD1KXSLYiNAYN',
    name: 'Dropbox',
  },
  {
    logo: 'https://services.tzkt.io/v1/avatars2/tz2CT7iesabfjuARuTt92qJ9zfNzw3HXjqKV',
    name: 'Google Drive',
  },
  {
    logo: 'https://services.tzkt.io/v1/avatars2/tz1gomr1uqcvMjkXkeiHjma9uTvpBpAiaaQY',
    name: 'Google Ad Manager',
  },
  {
    logo: 'https://services.tzkt.io/v1/avatars2/tz1MYvWVL7PvQCDR5bR9jVmtsSnKBLhgrNYN',
    name: 'Atlassian',
  },
];