import api from "./api";

const PATH = '/email';

export async function sendEditTraxContactForm(name: string, email: string, msg: string, subject: string = 'Edit Trax Contact Form') {
    const { data: { object, error, message }} = await api.post(`${PATH}/send-to`, {
        name,
        email,
        message: msg, 
        subject
    });

    return { object, error, message };
}