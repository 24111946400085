import React, { useState } from 'react';
import { Box, Button, Grid, IconButton, TextField, Typography, } from '@material-ui/core';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';

import formatNumber from 'utils/formatNumber';

import { listTokenAction } from 'reducer/async/actions';
import { useDispatch, useSelector } from 'reducer';

import { ContentModal } from 'views/CreateNFT/components';
import { RoyaltSharingProps } from 'views/CreateNFT/components/Form';

import * as S from './styles';

interface SaleTokenProps {
    contract: string;
    tokenId: number;
    token: Token;
    open: boolean;
    handleClose: () => void;
}

export default function SaleToken({ contract, tokenId, token, open, handleClose }: SaleTokenProps) {
    const dispatch = useDispatch();
    const { system } = useSelector(s => s);
    const { royalties_split_map } = token;
    
	const [_, setCheckedRoyalty] = useState<boolean>(false);
    const [activeButton, setActiveButton] = useState<string>('off');
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [price, setPrice] = useState<string>('');
	const [royaltiesList, setRoyaltiesList] = useState<RoyaltSharingProps[]>([] as RoyaltSharingProps[]);
	const [royaltiesSharingError, setRoyaltiesSharingError] = useState<{[x: number]: string}>({})

    function handleCloseModal() {
        setPrice('');
        setOpenModal(false);
        handleClose();
    }

	function handleAddRoyalties() {
		setRoyaltiesList([...royaltiesList, {}]);
	}

	function handleDeleteRoyalties(index: number) {
		if (royaltiesSharingError[index]) {
            delete royaltiesSharingError[index];
            console.log(royaltiesSharingError);
			setRoyaltiesSharingError({ ...royaltiesSharingError });
		}

		const newRoyaltiesList = royaltiesList;
		royaltiesList.splice(index, 1);

		setRoyaltiesList([...newRoyaltiesList]);
        handleRoyaltiesSharing();
	}

	function handleRoyaltiesList(index: number, prop: 'walletAddress' | 'royalties', value: string) {
		const newRoyaltiesList = royaltiesList;

		if (prop === 'walletAddress') {						
			delete royaltiesSharingError[index];

			const filteredRoyaltiesListSharing = royaltiesList.filter(({ walletAddress }) => walletAddress === value);
			setRoyaltiesSharingError({ ...royaltiesSharingError });

			if (value === system.tzPublicKey!) {
				setRoyaltiesSharingError({ ...royaltiesSharingError, [index]: "You can not add your own wallet" });
			} else if (filteredRoyaltiesListSharing.length) {
				setRoyaltiesSharingError({ ...royaltiesSharingError, [index]: 'Wallet address already used' });
			}
			
			newRoyaltiesList[index][prop] = value;
		} else {
			const formattedRateValue = formatNumber(value);
			newRoyaltiesList[index][prop] = !isNaN(Number(formattedRateValue)) ? formattedRateValue.toString() : '';
		}
		
		setRoyaltiesList([...newRoyaltiesList]);
	}

	function handleRoyaltiesSharing() {
		if (royaltiesList.length >= 1) {
			const newRoyaltiesList = royaltiesList;
			const value = 100 - royaltiesList.slice(1).reduce((acc, { royalties }, index) => {
				const accumulator = acc + Number(royalties || 0); 
	
				if (accumulator > 99) {
					newRoyaltiesList[index + 1].royalties = (100 - 1 - acc).toFixed(2).toString();
	
					return 99;
				}
	
				return accumulator;
			}, 0);
	
			newRoyaltiesList[0].royalties = value.toFixed(2).toString();
	
			setRoyaltiesList([...newRoyaltiesList]);
		}
	}

	function handleCheckedRoyaltySharing(isChecked: boolean) {
		setCheckedRoyalty(isChecked);

		if (isChecked) {
			setRoyaltiesList([
				{ walletAddress: system.tzPublicKey!, royalties: '100'  },
				{ walletAddress: '', royalties: ''  }
			]);

			return;
		}

		setRoyaltiesList([]);
	}

    function handleOpenContentModal() {
		if (Object.keys(royaltiesSharingError).length) {
			alert("You have errors on split sharing map");

			return false;
		}

        if (!price) {
            alert("You need to set a price for sale");
            return false;
        }


        setOpenModal(true); 
        handleClose();
    }

    return (
        <>
            <S.MuiModal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <S.ModalContent bgcolor="#fff">
                    <Grid container spacing={4}>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant="h4" component="h2">
                                <strong>List for sale</strong>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6">
                                <strong>Price</strong>
                            </Typography>

                            <TextField
                                placeholder="Enter price"
                                variant="outlined"
                                size="medium"
                                name="amount"
                                fullWidth
                                type="text"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6">
                                <strong>Split sale proceeds</strong>
                            </Typography>

                            <Box display="flex" justifyContent="space-between">
                                <S.RoyaltiesButton 
                                    active={!!(activeButton === 'off')} 
                                    variant="outlined"
                                    type="button" 
                                    color="primary" 
                                    onClick={() => {setActiveButton('off'); handleCheckedRoyaltySharing(false)}}
                                >
                                    Off
                                </S.RoyaltiesButton>

                                { royalties_split_map && royalties_split_map?.length > 1 && (
                                <S.RoyaltiesButton 
                                    active={!!(activeButton === 'same')} 
                                    variant="outlined"
                                    type="button" 
                                    color="primary" 
                                    onClick={() => {setActiveButton('same'); handleCheckedRoyaltySharing(true)}}
                                >
                                    Same as Royalties split
                                </S.RoyaltiesButton>
                                )}

                                <S.RoyaltiesButton 
                                    active={!!(activeButton === 'custom')} 
                                    variant="outlined"
                                    type="button" 
                                    color="primary" 
                                    onClick={() => {setActiveButton('custom'); handleCheckedRoyaltySharing(true)}}
                                >
                                    Custom
                                </S.RoyaltiesButton>
                            </Box>                            
                        </Grid>

                        { activeButton === 'same' && (
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="column">
                            { royalties_split_map.map((item, index) => (
                                <S.RoyaltyContainer key={index}>
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="subtitle1">
                                            <strong>Share with</strong>
                                        </Typography>

                                        { item.address === system.tzPublicKey! ? (
                                        <Typography variant="subtitle1">
                                            <strong>Me</strong>
                                        </Typography>
                                        ) : (
                                        <TextField
                                            placeholder="Wallet Address"
                                            variant="outlined"
                                            size="medium"
                                            name={`wallet-address-${index}`}
                                            type="text"
                                            value={item.address}
                                            disabled
                                        />
                                        )}

                                    </Box>

                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="subtitle1">
                                            <strong>Rate(%)</strong>
                                        </Typography>

                                        <TextField
                                            placeholder="Rate"
                                            variant="outlined"
                                            size="medium"
                                            name={`wallet-royalty-${index}`}
                                            type="text"
                                            value={(+item.percentage / 100)}
                                            disabled
                                        />
                                    </Box>
                                </S.RoyaltyContainer>
                            ))}
                            </Box>
                        </Grid>
                        )}

                        { activeButton === 'custom' && (
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="column">
                                { royaltiesList.map((item, index) => (
                                <S.RoyaltyContainer key={index} isCustom={true}>
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="subtitle1">
                                            <strong>Share with</strong>
                                        </Typography>

                                        <TextField
                                            placeholder="Wallet Address"
                                            variant="outlined"
                                            size="medium"
                                            name={`wallet-address-${index}`}
                                            type="text"
                                            error={!!royaltiesSharingError[index]}
                                            helperText={royaltiesSharingError[index]}
                                            onChange={(e) => handleRoyaltiesList(index, 'walletAddress', e.target.value)}
                                            value={item.walletAddress}
                                            disabled={index === 0}
                                        />
                                    </Box>

                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="subtitle1">
                                            <strong>Rate(%)</strong>
                                        </Typography>

                                        <TextField
                                            placeholder="Rate"
                                            variant="outlined"
                                            size="medium"
                                            name={`wallet-royalty-${index}`}
                                            type="text"
                                            value={royaltiesList[index].royalties}
                                            onChange={(e) => handleRoyaltiesList(index, 'royalties', e.target.value)}
                                            onBlur={handleRoyaltiesSharing}
                                            disabled={index === 0}
                                        />
                                    </Box>

                                    { !(item.walletAddress === system.tzPublicKey!) && (
                                    <Box display="flex" alignItems="center" justifyContent="center" ml={-4} pt={4} pb={4}>
                                        <IconButton onClick={() => handleDeleteRoyalties(index)}>
                                            <MdRemoveCircleOutline color="#000" />
                                        </IconButton>
                                    </Box>
                                    )}
                                </S.RoyaltyContainer>								
                                ))}

                                <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                                    <IconButton onClick={handleAddRoyalties}>
                                        <MdAddCircleOutline color="#000" />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        )}

                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button variant="contained" type="button" color="primary" size="large" onClick={handleOpenContentModal}>
                                sell
                            </Button>
                        </Grid>
                    </Grid>
                </S.ModalContent>
            </S.MuiModal>    

            <ContentModal 
                isOpen={openModal} 
                method="listToken"
                dispatchThunk={() => dispatch(
                    listTokenAction({
                        contract,
                        tokenId,
                        token,
                        salePrice: Math.floor(Number(price) * 1000000),
                        creator: token?.metadata?.minter || '',
                        royalties: token.royalties ?? 0,
                        sales_list_map: 
                            activeButton === 'off' ? []  
                            : activeButton === 'same' 
                                ? royalties_split_map.map(({ address, percentage }) => ({ address, percentage: +percentage })) 
                                : royaltiesList.map(({ walletAddress, royalties }) => ({ address: walletAddress!, percentage: Number(royalties) * 100 })),
                    })
                )}
                onClose={() => handleCloseModal()}
                dispatchOnOpen={true}	 
                pendingAsyncMessage="Listing token for sale..." 
            />
        </>
    );
}
