import React from 'react';
import { Grid } from '@material-ui/core';

import { Section } from 'components/organisms';
import { Sign, Verify } from './components';

export default function SignMessageView() {
    return (
      <Section style={{ maxWidth: '100%' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Sign />
          </Grid>

          <Grid item xs={12} md={6}>
            <Verify />
          </Grid>
        </Grid>
      </Section>
    );
}
