import React from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';

import { CardSkeleton, Section } from 'components/organisms';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  hero: {
    backgroundColor: theme.palette.alternate.main,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 8),
    },
  },
  pagePaddingTop: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  sectionAlternate: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    '& .section-alternate__content': {
    },
  },
}));

const HomeSkeleton = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true, });

  return (
    <div>
      <Section className={classes.sectionAlternate}>
        <Grid container spacing={isMd ? 4 : 2}>
          { [...Array(12).keys()].map((skeleton, index) => (
            <Grid item xs={12} sm={6} md={2} key={index} data-aos="fade-up">
              <CardSkeleton />
            </Grid>
          ))}
        </Grid>
      </Section>
    </div>
  );
};

export default HomeSkeleton;