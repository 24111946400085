import React from 'react';

import BaseToken from 'templates/BaseToken';
import { getFilteredCategories } from 'services/tokens';
import { getUrlQueryParams } from 'utils';

export default function CategoryDetail(props: ViewComponentProps) {
  const { category } = props;

  const pageNumber = Number(getUrlQueryParams()['page']) || 1;
  const limit = 12, filter: Filter = { limit, offset: (pageNumber - 1) * limit, orderBy: 'tokenId', descending: true };

  return (
    <BaseToken
      title="Results for this Category:"
      handleChange={async () => await getFilteredCategories(filter, category)}
      handleChangeProps={{filter, category}}
      limit={limit}
      filter={filter}
      pageNumber={pageNumber}
    />
  )
}
