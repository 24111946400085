import styled from 'styled-components';

import { Button } from '../styles';

export const Title = styled.h4`
    line-height: 100%; 
    font-family: 'Work Sans', sans-serif;
    margin-top: 0;
    font-size: 24px;
    font-weight: 400;
    color: #000;
`;

export const LargeButton = styled(Button)`
    padding-top: 15px;
    padding-bottom: 18px;
    font-weight: bold;

    margin-top: 15px;
    width: 100%;
`;