import React, { useState } from 'react';
import { useLocation } from 'wouter';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';

import { getFilteredTokensForSale } from 'services/tokens';

import { Section, Pagination } from 'components/organisms';
import CreatorDisplay404 from 'views/MyItems/components/CreatorDisplay404/CreatorDisplay404';
import { CardToken } from '..';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
  sectionAlternate: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    maxWidth: '100%',
  },
}));

interface MarketplaceResultProps extends ViewComponentProps {
  tokens?: Token[];
} 

const Result = ({ data, address, config, hasMore, totalKeys, className, ...rest }: MarketplaceResultProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true });
  const limit = 12;

  const [_, setLocation] = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [tokens, setTokens] = useState<Token[]>(data);
  const [pageNumber, setPageNumber] = useState(1);

  if (tokens?.length === 0) {
    return (<CreatorDisplay404 ownedOnly={false} />);
  }

  async function handlePagination(e: any, page: number) {
    setLocation(`/marketplace?page=${page}`);
    setIsLoading(true);
    
    const nftTokens = await getFilteredTokensForSale({ limit, offset: (page - 1) * limit });
    setTokens(nftTokens.object);
    setPageNumber(page);

    window.scrollTo(0, 0);

    setIsLoading(false);
  }
  
  const numPages = Math.ceil(totalKeys / limit);

  return (
    <S.Wrapper className={className} {...rest}>
      <Section className={classes.sectionAlternate}>
        <>
          <Grid container spacing={isMd ? 4 : 2}>
            { tokens && tokens.map((item, index: number) => (
              <Grid item xs={12} sm={6} md={3} lg={2} key={index} data-aos="fade-up">
                <CardToken {...item} hasTitle={true} config={config} />
              </Grid>
            ))}

            { numPages > 0 && (
            <Grid container spacing={isMd ? 4 : 2}>
              <Grid item xs={12} container justifyContent="center">
                <Pagination 
                  numPages={numPages}
                  pageNumber={pageNumber} 
                  handlePagination={handlePagination} 
                  isLoading={isLoading} 
                  showLastButton={!(numPages - 2 >= pageNumber)}
                />
              </Grid>
            </Grid>
            )}
          </Grid>
        </>
      </Section>
    </S.Wrapper>
  );
};

export default Result;