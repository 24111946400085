import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    .MuiButtonBase-root + .MuiButtonBase-root {
        margin-top: 16px;
    }
`;
