import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const WalletWrapper = styled.div`
    ${({ theme }) => css`
    `}  
`;

export const LogoContainer = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;

        width: 200px;
        height: 28px;

        ${theme.breakpoints.up('md')} {
            width: 200px;
            height: 32px;
        }

        ${theme.breakpoints.down('sm')} {
            flex-direction: row;
            align-items: center;

            a:last-child {
                margin-left: ${theme.spacing(2)}px;
            }
        }

        ${theme.breakpoints.up(601)} {
            padding-left: ${theme.spacing(3)}px;
        }

		${theme.breakpoints.down(600)} {
			padding: ${theme.spacing(2)}px;
		}
    `}
`;