import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const GridContainer = styled(Grid)`
    span {
        display: flex !important;
        justify-content: end;
    }

    img {
        width: auto;
        max-height: 350px;
    }
`;
