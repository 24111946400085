import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from 'reducer';
import { Params } from 'lib/service/tzkt';

import { getCustomContractById } from 'services/custom-contracts';
import useAuth from 'hooks/auth';

// import { Loader } from 'components/atoms';
import { Section } from 'components/organisms';
import { Hero, Topbar, Footer, Counter, Details, Marketplace, About, Loader } from './components';
import { playerConfigs } from './data';

import * as S from './styles';

export type EditTraxtTokenProps = typeof playerConfigs['alpha-test'];

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		width: '100%',
	},
	sectionNoPaddingTop: {
		paddingTop: 0,
	},
	sectionPartners: {
		boxShadow: '0 5px 20px 0 rgba(90, 202, 157, 0.05)',
		'& .section-alternate__content': {
			paddingTop: theme.spacing(5),
			paddingBottom: theme.spacing(5),
		},
	},
	white: {
		background: 'white',
	},
	noPadding: {
		padding: 0,
	},
	backgroundBlack: {
		background: '#000',
	},
	sectionPadding32: {
		padding: 32,
	}
}));

interface Props {
	player: string;
	branding: string;
};

declare global {
	interface Window { branding: any; }
}

const EditTrax = ({ player, branding }: Props): JSX.Element => {
	const system = useSelector(s => s.system);
	const { user } = useAuth();
	const tzkt = system.tzkt;

	// @ts-ignore
	let playerConfig: EditTraxtTokenProps = playerConfigs[player];

	const [contract, setContract] = useState<Contracts>({} as Contracts);
	const [isOwner, setIsOwner] = useState<boolean>(false);
	const [isThirdParty] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [ownedToken, setOwnedToken] = useState<string>('');

	useEffect(() => {
		setIsLoading(true);

		(async () => {
			const response = await getCustomContractById(playerConfig.contractId);
			let contractData = response.object as Contracts;
			if (player == 'download-mechanism') {
				contractData.collectors = 0;
				contractData.volume = 0;
			}
			if (player == 'alpha-test') {
				contractData.collectors = 54;
				contractData.volume = 105;
			}
			if (player == 'burnt') {
				contractData.collectors = 0;
				contractData.volume = 0;
			}
			console.log(`**** in EditTrax useEffect, contractData: ${contractData}`);

			if (user?.wallet && player !== 'download-mechanism' && player !== 'alpha-test' && player !== 'burnt') {
				let params: Params = {
					limit: '1',
					offset: '0',
					'value.eq': user?.wallet,
				};

				const tzktResponse = await tzkt.getContractBigMapKeys(contractData?.contractAddress, 'ledger', params);
				const ownedTokensLength = tzktResponse.length;
				const atLeastOneOwnedToken = ownedTokensLength > 0;
				setIsOwner(atLeastOneOwnedToken);

				if (atLeastOneOwnedToken) {
					setOwnedToken(tzktResponse[0].key)
				}
			}

			setTimeout(() => {
				setContract(contractData);
				setIsLoading(false);
			}, 500)
		})();
	}, [player]);

	// TODO: set as react state instead of global variable
	window.branding = branding;
	const classes = useStyles();

	return (
		<S.Wrapper className={classes.root} isLoading={isLoading}>
			{isLoading ? <Loader /> : (
			<S.BoxedPage>
				<Topbar />
				
				<Hero isOwner={isOwner} contract={contract} ownedToken={ownedToken} isThirdParty={isThirdParty}/>

				<Section className={clsx(classes.noPadding, classes.backgroundBlack)}>
					<Counter contract={contract} />
				</Section>

				<Section className={classes.white}>
					<Details contract={contract} player={player} />
				</Section>

				<Section className={classes.noPadding}>
					<Marketplace />
				</Section>

				<Section className={clsx(classes.noPadding, classes.backgroundBlack)}>
					<About />
				</Section>

				<Section className={clsx(classes.noPadding, classes.backgroundBlack)}>
					<Footer />
				</Section>
			</S.BoxedPage>
			)}
		</S.Wrapper>
	);
};

export default EditTrax;
