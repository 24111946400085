import React, { useEffect, useRef } from 'react';
import { dataRUIToBuffer, prepareFilesFromZIP, validateFiles } from 'utils/html';

import * as S from './styles';

const uid = Math.round(Math.random() * 100000000).toString();

interface HTMLElementProps {
    artifactUri?: string;
    displayUri?: string;
    previewUri?: string;
    creator?: boolean;
    preview?: boolean;
    displayView?: boolean;
}

export default function HTMLElement({
    artifactUri,
    displayUri,
    previewUri,
    creator = false,
    preview = false,
    displayView = false
}: HTMLElementProps) {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const unpackedFiles = useRef(null)
    const unpacking = useRef(false)

    let _creator_ = false, _viewer_ = false, _objectId_ = false;

    const unpackZipFiles = async () => {
        unpacking.current = true;

        const buffer = dataRUIToBuffer(previewUri);
        const filesArr = await prepareFilesFromZIP(buffer);
        const files: any = {};

        (filesArr as any[]).forEach((f: any) => {
            files[f.path] = f.blob;
        });

        console.log({ files });

        unpackedFiles.current = files;

        const result = await validateFiles(unpackedFiles.current);

        unpacking.current = false;
    }

    if (preview && !unpackedFiles.current && !unpacking.current) {
        unpackZipFiles();
    }

    useEffect(() => {
        const handler = async (event: any) => {
            if (event.data !== uid) {
                return
            }

            iframeRef.current?.contentWindow?.postMessage(
                {
                    target: 'hicetnunc-html-preview',
                    data: unpackedFiles.current,
                },
                '*'
            );
        }

        window.addEventListener('message', handler);

        return () => window.removeEventListener('message', handler);
    }, [previewUri])


    return (
        <S.Container>
            <iframe
                ref={iframeRef}
                title="html-zip-embed"
                src={`https://hicetnunc2000.github.io/hicetnunc/gh-pages/html-preview/?uid=${uid}&creator=${_creator_}&viewer=${_viewer_}&objkt=${_objectId_}`}
                sandbox="allow-scripts allow-same-origin allow-modals"
                allow="accelerometer; camera; gyroscope; microphone; xr-spatial-tracking;"
            />
        </S.Container>
    );
}
