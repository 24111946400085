import api from "./api";
import { playerConfigs } from '../views/EditTrax/data';
export type EditTraxTokenProps = typeof playerConfigs['alpha-test'];

const PATH = '/custom-contracts';

export async function createCustomContract(contract: CustomContract) {
    // const formData = new FormData();

    // Object.entries(contract).forEach(([key, value]) => {
    //     formData.append(key, value);
    // });

    const { data: { object, error, message }} = await api.post(`${PATH}`, { contract });

    return { object, error, message };    
}

export async function updateCustomContract(contract: CustomContract) {
    const { data: { object, error, message }} = await api.put(`${PATH}`, { contract });

    return { object, error, message };    
}

export async function deleteCustomContract(customContractId: string) {
    const { data: { object, error, message }} = await api.delete(`${PATH}/${customContractId}`);

    return { object, error, message };    
}

export async function getCustomContractById(customContractId: string) {
    const { data: { object, error, message }} = await api.get(`${PATH}/${customContractId}`);

    return { object, error, message };    
}

export async function getCustomContractBySlugOrId(customContractKey: string) {
    const { data: { object, error, message }} = await api.get(`${PATH}/get-contract/${customContractKey}`);

    return { object, error, message };    
}

export async function getContractByTokenId(tokenId: string) {
    const { data: { object, error, message }} = await api.get(`${PATH}/get-by-tokenId/${tokenId}`);

    return { object, error, message };    
}

export async function getContractBySlugAndUsername(username: string, slug: string) {
    const { data: { object, error, message }} = await api.get(`${PATH}/get-by-slug/${username}/${slug}`);

    return { object, error, message };    
}

export async function findContractsByUserId(userId: string, filter?: Filter) {
    const { data: { object }} = await api.post(`${PATH}/find-by-user/${userId}`, { filter });

    return object;    
}

export async function findCustomContracts(filter?: Filter) {
    const { data: { object }} = await api.post(`${PATH}/find-contracts`, { filter });

    return object;    
}

export async function findContractsByIds(contractsIds: number[], filter?: Filter) {
    const { data: { object }} = await api.post(`${PATH}/find-by-ids`, { contractsIds, filter });
   // const { data: { object }} = await api.get(`${PATH}/find-by-ids`);

    return object;    
}
type PlayerConfigNameProps = 'acid-beach' | 'hush-hush' | 'rolla-disco' | 'ed-nine' | 'alpha-test';

export async function findEditTraxContracts(playerNames: string[], filter?: Filter) {
    console.log(`******* in findEditTraxContracts`)
    let contractIds : number[] = [];
    for (let i = 0; i < playerNames.length; i++) {
        const playerName : string = playerNames[i] as string;
        console.log(`******* in findEditTraxContracts, playerName: ${playerName}`);
        // @ts-ignore
        let player = playerConfigs[playerName];
        contractIds.push(player.contractId as number);
        console.log(`******* in findEditTraxContracts, player.contractId: ${player.contractId}`);

    }
    console.log(`******* in findEditTraxContracts, about to call: ${PATH}/find-by-ids, with contractIds: ${JSON.stringify(contractIds)}`);
    const { data: { object }} = await api.post(`${PATH}/find-by-ids`, { contractsIds: contractIds, filter });
    // const { data: { object }} = await api.get(`${PATH}/find-by-ids`);
    console.log(`******* in findEditTraxContracts, about to return: ${JSON.stringify(object)}`);

    return object;
}