import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';

import { useSelector } from 'reducer';

import { CardSkeleton } from 'components/organisms';
import { CardToken } from '../';

import * as S from './styles';

type ResultProps = {
    title?: string;
    tokens: Contracts[];
    totalPages: number;
    pageNumber: number;
    isLoading: boolean;
    handlePagination: (e: any, page: number) => void;
} & ViewComponentProps;

export default function Result({ title, tokens, totalPages, handlePagination, isLoading, pageNumber, className, ...rest }: ResultProps): JSX.Element {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true, });

    const { system: { config }} = useSelector(s => s);

    return (
        <S.Container className={className} {...rest}>
            <Grid container spacing={4}>
            { title && (
                <Grid item xs={12}>
                    <Typography variant="h4">{title}</Typography>
                </Grid>
            )}
            </Grid>

            {isLoading && (
            <Grid container spacing={4}>
            {[...Array(8).keys()].map((_, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <CardSkeleton />
                </Grid>
            ))}
            </Grid>
            )}

            {!isLoading && (
            <S.GridContainer container spacing={isMd ? 4 : 2}>
                {tokens.length > 0 && tokens.map((item, index: number) => (
                <S.GridCard item xs={12} sm={6} md={3} key={index}>
                    <CardToken {...{ ...item?.metadata, slug: item?.slug } as Token} hasTitle={false} config={config} />
                </S.GridCard>
                ))}

                {!tokens.length && (
                <Box display="flex" alignItems="center" justifyContent="center" minHeight={600} minWidth="100%">
                    <Typography>
                        <strong>No results found</strong>
                    </Typography>
                </Box>
                )}
            </S.GridContainer>
            )}
        </S.Container>
    );
}