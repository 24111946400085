import api from "./api";

const PATH = '/transactions';

export async function getTransactions(filter?: Filter) {
    const { data: { object, error, message }} = await api.post(`${PATH}/`, { filter });

    return { object, error, message };
}

export async function findTransactionsByCreator(creator: string, filter?: Filter) {
    const { data: { object, error, message }} = await api.post(`${PATH}/find-by-creator/${creator}`, { filter });

    return { object, error, message };
}

export async function findTransactionsByBuyer(buyer: string, filter?: Filter) {
    const { data: { object, error, message }} = await api.post(`${PATH}/find-by-buyer/${buyer}`, { filter });

    return { object, error, message };
}

export async function findTransactionsBySeller(seller: string, filter?: Filter) {
    const { data: { object, error, message }} = await api.post(`${PATH}/find-by-seller/${seller}`, { filter });

    return { object, error, message };
}

export async function addTransaction(transaction: Transaction) {
    const { data: { object, error, message }} = await api.post(`${PATH}/`, { transaction });

    return { object, error, message };
}