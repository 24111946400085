import React, { useState, ChangeEvent } from 'react';
import { Grid, Tab } from '@material-ui/core';

import { Section } from 'components/organisms';
import { TabPanel } from 'components/atoms';

import { Properties, History } from '..';

import * as S from './styles';

export default function Tabs({ system, token, transactions }: ViewComponentProps & { token: Token }): JSX.Element {
    const [pageId, setPageId] = useState(0);

    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setPageId(newValue);
    };

    return (
        <S.Wrapper>
            <Section>
                <>
                    <Grid item xs={12}>
                        <S.TabComponent variant="standard" textColor="primary" indicatorColor="primary" value={pageId} onChange={handleChange}>
                            <Tab label="History" id="History" aria-controls="History" />
                            {/* <Tab label="Info" id="info" aria-controls="info" /> */}
                        </S.TabComponent>
                    </Grid>

                    <Grid item xs={12}>
                        <TabPanel value={pageId} index={0}>
                            <History transactions={transactions} />
                        </TabPanel>

                        <TabPanel value={pageId} index={1}>
                            <Properties system={system} token={token} />
                        </TabPanel>
                    </Grid>
                </>
            </Section>
        </S.Wrapper>
    );    
}