import React, { useEffect, useState } from 'react';

import { Box, Button, CircularProgress, Grid, TextField, Tooltip, Typography } from '@material-ui/core';
import { MdCameraAlt, MdCancel, MdEdit, MdSave } from 'react-icons/md';

import { Avatar } from 'components/organisms';
import api from 'services/api';

import { useSelector } from 'reducer';

import * as S from './styles';

interface CoverProps {
    creator: string;
    collectionName: string;
}

export default function Cover({ creator, collectionName }: CoverProps): JSX.Element {
	const system = useSelector(s => s.system);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOwner, setIsOwner] = useState<boolean>(true);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const [avatarUrl, setAvatarUrl] = useState<string>('');
    const [coverUrl, setCoverUrl] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const [isHoverAvatar, setIsHoverAvatar] = useState<boolean>(false);

    useEffect(() => {
        setIsOwner(system.tzPublicKey === creator);

        (async () => {
            const { data: { error, object } } = await api.get<ApiStatus<Collection>>(`/collections/${creator}/${collectionName}`);

            if (!error) {
                setAvatarUrl(object?.avatarUrl || '');
                setCoverUrl(object.imageUrl);
                setDescription(object.description);
            }      
        })();
    }, [system.tzPublicKey])

    async function handleCoverChange(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files;

        if (file) {
            setIsLoading(true);

            const formData = new FormData();
            formData.append('file', file[0]);

            const { data: { error, object, message } } = await api.post(`/collections/update-info/${collectionName}`, formData);

            if (error) {
                alert(message);
                return false;
            }

            setTimeout(() => {
                setCoverUrl(object.imageUrl);
                setIsLoading(false);
            }, 2000);
        }
    }

    async function handleAvatarChange(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files;

        if (file) {
            // setIsLoading(true);

            const formData = new FormData();
            formData.append('avatar', file[0]);

            const { data: { error, object, message } } = await api.post(`/collections/update-info/${collectionName}`, formData);

            if (error) {
                alert(message);
                return false;
            }

            setTimeout(() => {
                setAvatarUrl(object.avatarUrl);
                setIsLoading(false);
            }, 2000);
        }
    }

    async function handleSaveDescription() {
        setIsLoading(true);

        if (description) {
            const { data: { error, message } } = await api.post(`/collections/update-info/${collectionName}`, { description });

            if (error) {
                alert(message);
                return false;
            }

            setIsEditMode(false);
            setIsLoading(false);
        }
    }

    return (
        <>
            <S.Wrapper>
                <S.BannerContent>
                    { !isLoading && <img src={coverUrl} alt="" /> }
                    
                    <CircularProgress size={72} hidden={!isLoading} />

                    { isOwner && (
                    <label htmlFor="uploadCover">
                        <Tooltip title="Recommended 1400x300px">
                            <Button color="default" variant="contained" component="span" disabled={isLoading}>
                                <MdCameraAlt size={20} />{ coverUrl ? 'Edit Cover Photo' : 'Add Cover Photo'}
                            </Button>     
                        </Tooltip>

                        <input 
                            type="file" 
                            id="uploadCover" 
                            name="uploadCover" 
                            style={{ display: 'none' }} 
                            onChange={(e) => handleCoverChange(e)} 
                        />
                    </label>
                    )}
                </S.BannerContent>
            </S.Wrapper>

            <Box position="relative">
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <S.Content>
                            <S.AvatarContainer onMouseEnter={() => setIsHoverAvatar(true)} onMouseLeave={() => setIsHoverAvatar(false)} isHoverAvatar={isHoverAvatar}>
                                <Avatar title={decodeURI(collectionName)} link={`/creator/${creator}`} avatarUrl={avatarUrl} />
                            </S.AvatarContainer>

                            { isOwner && (
                            <Tooltip title="Recommended 300x300px">
                                <label 
                                    htmlFor="uploadAvatar" 
                                    style={{ display: isHoverAvatar ? 'inline' : 'none' }} 
                                    onMouseEnter={() => setIsHoverAvatar(true)} 
                                    onMouseLeave={() => setIsHoverAvatar(false)}
                                >
                                    <span>
                                        <MdCameraAlt size={20} color="#fff" />
                                    </span>

                                    <input 
                                        type="file" 
                                        id="uploadAvatar" 
                                        name="uploadAvatar" 
                                        style={{ display: 'none' }} 
                                        onChange={(e) => handleAvatarChange(e)} 
                                        />
                                </label>
                            </Tooltip>
                            )}
                        </S.Content>
                    </Grid>
                    
                    <S.DescriptionGrid item xs={12}>
                        <S.Description>
                            { isEditMode ? (
                            <>
                                <TextField
                                    placeholder="Your description"
                                    variant="outlined"
                                    name="description"
                                    fullWidth
                                    multiline
                                    rows={6}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />

                                <Box>
                                    <S.WhiteButton variant="contained" onClick={() => handleSaveDescription()} disabled={isLoading}>
                                        <MdSave size={20} />Save description
                                    </S.WhiteButton>

                                    <S.CancelButton variant="contained" onClick={() => setIsEditMode(false)} disabled={isLoading}>
                                        <MdCancel size={20} />Cancel
                                    </S.CancelButton>
                                </Box>
                            </>
                            ) : (
                            <>
                                { description && (<Typography style={{ textAlign: 'center' }}>{ description }</Typography>)}

                                { isOwner && (
                                <S.WhiteButton color="default" variant="contained" onClick={() => setIsEditMode(true) }>
                                    <MdEdit size={20} />{ description ? 'Edit description' : 'Add description' }
                                </S.WhiteButton>
                                )}
                            </>
                            )}
                        </S.Description>
                    </S.DescriptionGrid>
                </Grid>
            </Box>
        </>
    );
}