import { Image } from "components/atoms";
import styled, { css } from "styled-components";

const modifier = css`
    object-fit: cover;
    height: 300px;
    cursor: pointer;
    width: 100%;
`;

export const Video = styled.video`
    ${({ theme }) => css`
        ${modifier};
    `};
`;

export const AtomImage = styled(Image)`
    ${({ theme }) => css`
        ${modifier};
    `};
`;

export const ModelViewer = styled.div`
    width: 100%;
    height: 100%;

    model-viewer {
        width: 100%;
        height: 100%;
    }        
`;
