import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, TextField, Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import api from 'services/api';

const useStyles = makeStyles(theme => ({
	form: {
		maxWidth: 550,
		margin: `0 auto`,
		'& .MuiTextField-root': {
			background: theme.palette.background.paper,
		},
		'& .MuiOutlinedInput-input': {
			background: theme.palette.background.paper,
		},
	},
	inputTitle: {
		fontWeight: 700,
		marginBottom: theme.spacing(1),
	},
}));

const Form = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
	const classes = useStyles();

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');

	async function handleSubmit() {
		if (![name, email, subject, message].every(field => field.length > 0)) {
			alert("You need to fill every field")
			return false;
		}

		try {
			const response = await api.post('/email/postContactEmail', {
				name, email, subject, message
			});

			if (response.status == 200) {
				alert("Form submitted!");
				return;
			}
		} catch (error) {
			// TODO warning error
			console.log(error);
		}
	}

	return (
		<div className={className} {...rest}>
			<SectionHeader
				title="Can't find the answer you need?"
				subtitle="Keep track of what's happening with your data, change permissions, and run reports against your data anywhere in the world. Keep track of what's happening with your data, change permissions."
				subtitleProps={{ variant: 'body1', color: 'textPrimary' }}
				data-aos="fade-up"
				align={isMd ? 'center' : 'left'}
			/>

			<div className={classes.form}>
				<Grid container spacing={isMd ? 4 : 2}>
					<Grid item xs={12} data-aos="fade-up">
						<Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
							Full name
						</Typography>

						<TextField
							placeholder="Your full name"
							variant="outlined"
							size="medium"
							name="fullname"
							fullWidth
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</Grid>

					<Grid item xs={12} data-aos="fade-up">
						<Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
							E-mail
						</Typography>

						<TextField
							placeholder="Your e-mail address"
							variant="outlined"
							size="medium"
							name="email"
							fullWidth
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Grid>

					<Grid item xs={12} data-aos="fade-up">
						<Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
							Subject
						</Typography>

						<TextField
							placeholder="Your e-mail address"
							variant="outlined"
							size="medium"
							name="subject"
							fullWidth
							type="text"
							value={subject}
							onChange={(e) => setSubject(e.target.value)}
						/>
					</Grid>

					<Grid item xs={12} data-aos="fade-up">
						<Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
							Message
						</Typography>

						<TextField
							placeholder="Your question about our services"
							variant="outlined"
							name="message"
							fullWidth
							multiline
							rows={4}
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
					</Grid>

					<Grid item container justifyContent="center" xs={12}>
						<Button variant="contained" type="submit" color="primary" size="large" onClick={handleSubmit}>
							submit
						</Button>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default Form;
