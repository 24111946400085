import React from 'react';

import { useLocation } from 'wouter';

import { connectWallet, disconnectWallet } from 'reducer/async/wallet';
import { useDispatch } from 'reducer';
import { SystemWithWallet } from 'lib/system';

import useAuth from 'hooks/auth';

import * as C from '../styles';

interface ButtonProps {
    text?: string;
}

export default function ConnectButton({ text }: ButtonProps) {
	const dispatch = useDispatch();  
	const { user, signIn, signOut } = useAuth();

	const [, setLocation] = useLocation();

	async function handleConnectWallet() {
		const response = await dispatch(connectWallet());
		const payload = response.payload as SystemWithWallet;
		
		if (payload && payload.status === "WalletConnected") {
			const wallet = payload.tzPublicKey!;
			await signIn({ wallet });
		}
	}

	async function handleDisconnectWallet() {
		await dispatch(disconnectWallet()); 
		signOut();

		window.location.reload();
	}

    return (
        <C.Button onClick={async () => user ? await handleDisconnectWallet() : await handleConnectWallet()}>
            {user ? 'Disconnect Wallet' : !!text ? text : 'Connect Wallet'}
        </C.Button>                
    )
}
