import axios from 'axios';
import { Config } from '../system';

export type Params = Record<string, string>;

function mkQueryParams(params: Params | undefined) {
  const finalParams = {...params };
  return new URLSearchParams(finalParams).toString();
}

export async function getBigMapKeys(config: Config, id: number, params?: Params) {
  let uri = `${config.tzkt.api}/v1/bigmaps/${id}/keys?${mkQueryParams(params)}`;
  const { data } = await axios.get(uri);

  return data;
}

export async function getBigMapUpdates(config: Config, params?: Params) {
  const uri = `${config.tzkt.api}/v1/bigmaps/updates?${mkQueryParams(params)}`;
  const { data } = await axios.get(uri);

  return data;
}

export async function getContracts(config: Config, params?: Params) {
  const uri = `${config.tzkt.api}/v1/contracts?${mkQueryParams(params)}`;
  const { data } = await axios.get(uri);

  return data;
}

export async function getContract(config: Config, address: string, params?: Params) {
  const uri = `${config.tzkt.api}/v1/contracts/${address}?${mkQueryParams(params)}`;
  const { data } = await axios.get(uri);

  return data;
}

export async function getContractBigMapKeys(config: Config, address: string, name: string, params?: Params) {
  let uri = `${config.tzkt.api}/v1/contracts/${address}/bigmaps/${name}/keys?${mkQueryParams(params)}`;
  console.log(`in getContractBigMapKeys, uri: ${uri}`)
  const { data } = await axios.get(uri);
  console.log(`in getContractBigMapKeys, data: ${JSON.stringify(data)}`)

  return data;
}

export async function getContractStorage(config: Config, address: string, params?: Params) {
  const uri = `${config.tzkt.api}/v1/contracts/${address}/storage?${mkQueryParams(params)}`;
  const { data } = await axios.get(uri);
  
  return data;
}

export async function getTransactionHistory(config: Config, address: string, params?: Params, limit?: number, offset?: number) {
  const uri = `${config.tzkt.api}/v1/operations/transactions?target=${address}`;
  const { data } = await axios.get(uri);
  
  return data;
}

export class TzKt {
  config: Config;

  constructor(config: Config) {
    this.config = config;
  }

  getBigMapKeys(id: number, params?: Params) {
    return getBigMapKeys(this.config, id, params);
  }

  getBigMapUpdates(params?: Params) {
    return getBigMapUpdates(this.config, params);
  }

  getContracts(params?: Params) {
    return getContracts(this.config, params);
  }

  getContract(address: string, params?: Params) {
    return getContract(this.config, address, params);
  }

  getContractBigMapKeys(address: string, name: string, params?: Params) {
    return getContractBigMapKeys(this.config, address, name, params);
  }

  getContractStorage(address: string, params?: Params) {
    return getContractStorage(this.config, address, params);
  }

  getTransactionHistory(address: string) {
    return getTransactionHistory(this.config, address, undefined);
  }
}
