import styled, { css } from 'styled-components';
import { Typography } from "@material-ui/core";

import { randomColor } from "utils";

export const Avatar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const AvatarImage = styled.div<{ hasAvatarUrl?: boolean, avatarColor?: string }>`
    ${({ theme, hasAvatarUrl = true, avatarColor = randomColor() }) => css`
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: 2px solid #fff;
        background: #c5c5c5;

        ${!hasAvatarUrl && css`
            > img {
                display: none;
            }

            background: ${avatarColor};
        `}
        
        img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
        }
        
        @media screen and (max-width: 1237px) {
            width: 120px;
            height: 120px;
            
            img {
                width: 120px;
                height: 120px;
            }
        }    
    `}
`;

export const Title = styled(Typography)`
    ${({ theme }) => css`
        font-size: ${theme.spacing(4)}px;
        font-weight: bold;

        a {
            display: flex;

            svg {
                margin-left: 8px;
            }
        }

        a:hover {
            text-decoration: underline;
        }


        ${theme.breakpoints.down(450)} {
            font-size: 16px;
        }
    `}
`;
