import React from 'react';

import { Minimal } from 'layouts';
import { CollectionItem } from 'views';
import WithLayout from 'WithLayout';

export type CustomTokenDetailPageProps = {
    contractAddress: string;
    tokenId: number;
}

export default function CustomTokenDetailPage ({ contractAddress, tokenId }: CustomTokenDetailPageProps): JSX.Element { 
    console.log({ contractAddress, tokenId })   
    return (
        <WithLayout 
            component={CollectionItem} 
            layout={Minimal} 
            contractAddress={contractAddress} 
            tokenId={tokenId} 
        />
    );
}