import React, { useEffect } from 'react';
// import { ChainId, ThirdwebProvider } from "@thirdweb-dev/react";

import { useSelector, useDispatch } from 'reducer';
import { reconnectWallet } from 'reducer/async/wallet';
import { SystemWithWallet } from 'lib/system';

import 'leaflet/dist/leaflet.css';
import 'swiper/css/swiper.min.css';
import 'aos/dist/aos.css';

import Routes from 'routes';
import useAuth from 'hooks/auth';

const supportedChainIds = [1, 4, 137];
const connectors = {
    injected: {},
}

export default function App() {
    const { signIn } = useAuth();
    const dispatch = useDispatch();
    
    const state = useSelector(s => s);
    let walletReconnectAttempted = state.system.walletReconnectAttempted;
        
    useEffect(() => {
        if (!walletReconnectAttempted) {
            (async () => {
                // console.log(`in App, about to call reconnectWallet`);

                const response = await dispatch(reconnectWallet());
                const payload = response.payload as SystemWithWallet;
		
                if (payload && payload.status === "WalletConnected") {
                    const wallet = payload.tzPublicKey!;
                    await signIn({ wallet });
                }        
            })();
        }        
    }, [walletReconnectAttempted, dispatch]);
  
    return (
        <>
            <Routes />                            
        </>
    );
}