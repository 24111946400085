import React from 'react';
import { Docs } from 'views';
import { Main } from 'layouts';
import WithLayout from 'WithLayout';
import { handleTitleAndScrollToTop } from 'utils';

const DocsPage = (): JSX.Element => {
	handleTitleAndScrollToTop("Minter - Docs");
	
	return (<WithLayout component={Docs} layout={Main} />)
};

export default DocsPage;