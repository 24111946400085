import styled, { css } from 'styled-components';

export const Container = styled.div`
    p {
        text-align: justify;
    }
`;

export const List = styled.ul`
    ${({ theme }) => css`
        margin-left: ${theme.spacing(3)}px;

        li {
            margin-left: ${theme.spacing(4)}px;

            a:hover {
                text-decoration: underline;
            }
        }
    `}
`;
