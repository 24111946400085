import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Wind } from 'react-feather';

interface CreatorDisplay404Props {
    ownedOnly: boolean;
}

export default function CreatorDisplay404({ ownedOnly }: CreatorDisplay404Props) {
    return (
        <Box width="100%" height="100%" display="flex" flex="1" flexDirection="column" alignItems="center" justifyContent="center">
            <Box px={20} py={10} textAlign="center" alignItems="center" borderRadius="5px" flexDirection="column" fontSize="xl" color="gray.400" >
                <Wind />

                <Box pt={5}>
                    <Typography variant="h6">
                        {ownedOnly ? 'No owned tokens to display' : 'No tokens to display'}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
