import React from 'react';
import { ContactPage } from 'views';
import { Main } from 'layouts';
import WithLayout from 'WithLayout';
import { handleTitleAndScrollToTop } from 'utils';

const ContactUs = (): JSX.Element => {
	handleTitleAndScrollToTop("Minter - Contact us");

	return <WithLayout component={ContactPage} layout={Main} />;
};

export default ContactUs;
