import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'wouter';
import { Box, Grid } from '@material-ui/core';

import { getUrlQueryParams } from 'utils';

import { Pagination, Section } from 'components/organisms';
import { Result } from './components';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

export type BaseTokenProps = {
    title?: string;
    hasUrl?: boolean;
    hasPagination?: boolean;
    pageNumber: number;
    handleChange?: (props: {[x: string]: any}) => any;
    handleChangeProps?: {[x: string]: any};
} & ViewComponentProps;

export default function BaseToken(props: BaseTokenProps) {
    const { title, handleChange, pageNumber, filter, limit, handleChangeProps, hasPagination = true, hasUrl = false } = props;

    const [location, setLocation] = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [page, setPage] = useState<number>(pageNumber);
    const [tokens, setTokens] = useState<Token[]>([]);
    const [totalTokens, setTotalTokens] = useState<number>(0);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            if (handleChange) {
                const response = await handleChange(handleChangeProps!);

                if (response) {
                    setTotalTokens(response?.total || 0);
                    setTokens(response?.tokens || []);
                }
            }

            setIsLoading(false);
        })();
    }, []);

    async function handlePagination(e: any, page: number) {
        setIsLoading(true);

        const params = getUrlQueryParams();
        params.page = page.toString();

        setLocation(`${location}?${new URLSearchParams(params).toString()}`);
        setPage(page);
        
        if (handleChange) {
            filter.offset = (page - 1) * limit;

            const response = await handleChange(handleChangeProps!);
            setTokens(response.tokens);
            window.scrollTo(0, 0);
        }

        setIsLoading(false);
    }

    const totalPages = Math.ceil((totalTokens || 1) / limit);

    return (
        <Wrapper>
            <Section>
                <>
                    <Result  
                        title={title}
                        hasUrl={hasUrl}
                        tokens={tokens}
                        totalPages={totalPages}
                        pageNumber={page}
                        isLoading={isLoading}
                        handlePagination={handlePagination}
                    />

                    { hasPagination && (
                    <Grid container spacing={4}>
                        <Grid item xs={12} container justify="center">
                            <Box mt={2}>
                                <Pagination
                                    numPages={totalPages}
                                    pageNumber={page}
                                    handlePagination={handlePagination}
                                    isLoading={isLoading}
                                    showLastButton={!(totalPages - 2 >= page)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    )}
                </>
            </Section>
        </Wrapper>
    );
}