import styled, { css } from 'styled-components';
import { Box, Modal } from '@material-ui/core';

export const MuiModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalContent = styled(Box)`
    ${({ theme }) => css`
        width: 500px;
        height: auto;

        padding: 32px 48px;

        ${theme.palette.type === 'dark' && css`
            .MuiInputBase-root {
                border: 1px solid #000;
                color: ${theme.palette.alternate.main};
            }        
        `}
    `}
`;