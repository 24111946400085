import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Box, Breadcrumbs, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { Nft } from 'lib/nfts/decoders';
import { useSelector } from 'reducer';
import { getCollectionByTag } from 'services/collections';

import { CardSkeleton, CardToken, Section } from 'components/organisms';
import { getUrlQueryParams } from 'utils';

const useStyles = makeStyles(theme => ({
  sectionAlternate: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    '& .section-alternate__content': {
    },
  },
  button: {
    minWidth: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 420,
    },
  },
  tabRoot: {
    width: '100%',
  },
  tab: {
    marginBottom: theme.spacing(3),
    width: 'fit-content',

    '& .MuiTab-textColorPrimary': {
      color: theme.palette.text.primary,
    },

    '& .Mui-selected': {
      backgroundColor: 'transparent',
      fontWeight: 'bolder',
      color: theme.palette.primary.dark,
    }
  },
}));

const Result = ({ collection, owner, className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true, });

  const limit = 12;
  const queryParams = getUrlQueryParams();
  const tag = queryParams['tag'];
  const tagSpaceFix = tag.replace('%20', ' ');

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [totalKeys, setTotalKeys] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [nftTokens, setNftTokens] = useState<Nft[]>([]);

  const { system } = useSelector(s => s);
  const { config } = system;

  useEffect(() => {
    document.title = `Search: ${tagSpaceFix}`;
    
    if (!!tag) {

    }

    (async () => {
      const tokens = await getCollectionByTag({ tag, system });
      setNftTokens(tokens);
      setIsLoading(false);
    })();
  }, []);

  async function handlePagination(e: any, page: number) {
    setIsLoading(true);

    const tokens = await getCollectionByTag({ tag, system });
    setNftTokens(tokens);
    setPageNumber(page);

    window.scrollTo(0, 0);

    setIsLoading(false);
  }

  const numPages = Math.ceil(totalKeys / limit);

  return (
    <div className={className} {...rest}>
      <Section className={classes.sectionAlternate}>
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} data-aos="fade-up">
              <Typography variant="h4">Results for: <strong>{tagSpaceFix}</strong></Typography>
            </Grid>
          </Grid>

          {isLoading && (
            <Grid container spacing={4}>
              {[...Array(8).keys()].map((skeleton, index) => (
                <Grid item xs={12} sm={6} md={3} key={index} data-aos="fade-up">
                  <CardSkeleton />
                </Grid>
              ))}
            </Grid>
          )}

          {!isLoading && (
            <Grid container spacing={isMd ? 4 : 2}>
              {nftTokens.length > 0 && nftTokens.map((item, index: number) => (
                <Grid item xs={12} sm={6} md={3} key={index} data-aos="fade-up">
                  {/* <CardToken {...item} hasTitle={true} config={config} /> */}
                </Grid>
              ))}
            </Grid>
          )}

          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} container justify="center">
              <Box mt={2}>
                <Pagination
                  count={numPages}
                  page={pageNumber}
                  shape="rounded"
                  color="primary"
                  onChange={handlePagination}
                  disabled={isLoading}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      </Section>
    </div>
  );
};

export default Result;