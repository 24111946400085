import React from 'react';
import { Ethereum } from 'views';
import { Main } from 'layouts';
import WithLayout from 'WithLayout';
import { handleTitleAndScrollToTop } from 'utils';

const EthereumPage = (): JSX.Element => {
	handleTitleAndScrollToTop("Minter - Ethereum");
	
	return (<WithLayout component={Ethereum} layout={Main} />)
};

export default EthereumPage;