import React, { useState, useEffect, ReactNode } from 'react';
import { Box, Modal } from '@material-ui/core';

import Content from './Content';
import { AsyncThunkActionResult } from 'components/common/modals/FormModal';
import { Method, setStatus, clearError } from 'reducer/slices/status';
import { useDispatch, useSelector } from 'reducer';


interface ContentModalProps {
    isOpen: boolean;
    method: Method;
    sync?: boolean;
    dispatchThunk: () => AsyncThunkActionResult;
    onClose: () => void;
    onComplete?: () => void;
    afterClose?: () => void;
    dispatchOnOpen: boolean;
    pendingAsyncMessage: ReactNode;
}

export default function ContentModal({ 
    isOpen, 
    method, 
    sync = false, 
    dispatchOnOpen = false, 
    dispatchThunk, 
    onComplete, 
    onClose, 
    afterClose, 
    pendingAsyncMessage 
}: ContentModalProps): JSX.Element {
    const [requestId, setRequestId] = useState<string | null>(null);
    const status = useSelector(s => s.status[method]);
    const dispatch = useDispatch();
    const notification = useSelector(s => s.notifications.find(n => n.requestId === requestId && n.status === 'pending' && !n.delivered));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSubmit = async () => {
        const result = dispatchThunk();
        setRequestId(result.requestId);
        dispatch(setStatus({ method, status: 'in_transit' }));
        const requestStatus = (await result).meta.requestStatus;

        if (requestStatus === 'fulfilled') {
            dispatch(setStatus({ method, status: sync ? 'complete' : 'ready' }));
            onComplete?.call(null);
        }
    };

    const hasError = status.error !== null;

    useEffect(() => {
        if (!requestId && isOpen && dispatchOnOpen && !hasError) {
            onSubmit();
        }
    }, [isOpen, dispatchOnOpen, hasError, onSubmit, requestId]);

    const closeAndReset = (withCallback = true) => {
        onClose();
        setRequestId(null);
        dispatch(clearError({ method }));
        withCallback && afterClose && afterClose();
    };

    const closeIfReadyOrComplete = () => {
        if (status.status !== 'in_transit' || hasError) {
            closeAndReset();
        }
    };

    useEffect(() => {
        if (!sync && isOpen && !hasError && notification) {
            return closeAndReset();
        } else if (!isOpen && hasError) {
            return () => {};
        }
    });

    return (
        <Modal open={isOpen} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box bgcolor="#fff" minWidth={400} minHeight={300} display="flex" alignItems="center" justifyContent="center">
                <Content 
                    sync={sync}
                    isOpen={isOpen}
                    status={status}
                    onClose={() => closeIfReadyOrComplete()}
                    onCancel={() => closeAndReset(false)}
                    onRetry={() => { dispatch(clearError({ method })); onSubmit() }}
                    pendingAsyncMessage={pendingAsyncMessage}
                />
            </Box>
        </Modal>
    );
}