import React, { FC, createContext, useCallback, useState, useContext, useEffect } from "react";
import api, { withoutAuthApi } from "services/api";
import { auth } from 'lib/firebase';

import APP_CONFIG from "config/app_config";

interface AuthState {
	token: string;
	user: User;
}

interface SignInCredentials {
	wallet: string;
	isEthereum?: boolean;
	captchaResponse?: string;
}

interface AuthContextData {
	user: User;
	token: string;
	setUser(user: User): void;
	signIn(credentials: SignInCredentials): Promise<void>;
	signUp(credentials: SignInCredentials): Promise<void>;
	signOut(): void;
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: FC = ({ children }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<AuthState>(() => {
		if (typeof window !== "undefined") {
			const token = localStorage.getItem("@minter:jwt"),
				  user = localStorage.getItem("@minter:user");

			return !!(token && user) ? { token, user: JSON.parse(user) } : {} as AuthState;
		}

		return {} as AuthState;
	});

	useEffect(() => {		
		if (data.user) {
			setIsLoading(true);

			const authentication = auth.getAuth();
			const unsubscribe = auth.onAuthStateChanged(authentication, async (user) => {
				if (user === null) {
					setData({} as AuthState);
					setIsLoading(false);
					return null;		
				}

				const token = await user.getIdToken(true);

				if (token === data.token) {
					setIsLoading(false);
					return user;
				}
				
				handleSetData(token, data.user);
				return user;
			});
	
			return () => unsubscribe();
		}
	}, []);

	const signIn = useCallback(async ({ wallet, isEthereum }: SignInCredentials) => {		
		const authentication = auth.getAuth();
		const email = `${wallet.toLowerCase()}@orbix360.com`, password = `@${APP_CONFIG.FIREBASE_API_KEY}_`;

		try {
			const { user } = await auth.signInWithEmailAndPassword(authentication, email, password);
			await handlePostLogin(await user.getIdToken());
		} catch (error) {
			console.log("User not found...creating user");			
			const { user } = await auth.createUserWithEmailAndPassword(authentication, email, password);

			// Setting the display name as user wallet to get this information on backend
			// because email is getting lowercase
			await auth.updateProfile(user, { displayName: wallet });
			
			await handlePostLogin(await user.getIdToken());
		}
	}, []);

	const signUp = useCallback(async ({ captchaResponse, wallet }: SignInCredentials) => {
		let token = "";

		await api.post('/users', {}, {
			headers: {
				'Authorization': `Bearer ${token}`,
				'captcha': captchaResponse,
			}
		});

		await handlePostLogin(token);
	}, []);

	async function handlePostLogin(token: string) {
		// TODO change to receive jwt token from backend
		const { data } = await withoutAuthApi.post('/users/post-login', {}, {
			headers: { 'Authorization': `Bearer ${token}`,}
		});

		handleSetData(token, data.response);			
	}

	function handleSetData(token: string, user: User) {
		localStorage.setItem("jwt", token);
		localStorage.setItem("@minter:jwt", token);
		localStorage.setItem("@minter:user", JSON.stringify(user));

		setData({ token, user });
	}

	const signOut = useCallback(async () => {
		localStorage.removeItem("jwt");
		localStorage.removeItem("@minter:jwt");
		localStorage.removeItem("@minter:user");

		setData({} as AuthState);
	}, []);

	const setUser = useCallback((user: User) => {
		handleSetData(localStorage.getItem("jwt")!, user);
	}, []);

	return (
		<AuthContext.Provider value={{ user: data.user, token: data.token, setUser, signIn, signUp, signOut }}>
			{ children }
		</AuthContext.Provider>
	);
}

export default function useAuth(): AuthContextData {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error('useAuth must be used within a Provider');
	}

	return context;
}