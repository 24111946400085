import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        padding: 0 32px;
        background: white;

        .section {
            padding: 32px 0;
        }    

        ${theme.breakpoints.down('sm')} {
            margin-top: 20px;
            padding: 24px;

            /* .card-product__media {
                height: 300px;
                
                img {
                    height: 300px;                    
                }
            } */
        }
    `}
`;

export const Title = styled.div`
    color: black;
    text-align: center;

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;  

    margin-bottom: 24px;
`;

export const Genres = styled.div`
    display: flex;
    overflow: auto;
`;

export const Genre = styled.div<{selected?: boolean}>`
    ${({ selected = false }) => css`
        display: flex;
        align-items: center;
        justify-content: center;

        color: #fff;        
        background: #333333;

        border: 1px solid #C4C4C4;
        border-radius: 1px;    

        padding: 24px 0;
        width: fit-content;
        min-width: 135px;

        text-transform: uppercase;

        box-sizing: border-box;

        font-style: normal;
        font-weight: 250;
        font-size: 18px;
        line-height: 100%;        

        & + & {
            margin-left: 12px;
        }

        ${selected && css`
            background: #FFFFFF;
            color: #000;
            font-weight: 400;
        `}    

        &:hover {
            cursor: pointer;
        }
    `}
`;
