/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useState, MouseEvent } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'wouter';

import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Typography, ListItemIcon, Divider, Button, IconButton, ListItemAvatar, Avatar, ListItemText, Popover } from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';
import { Facebook as FacebookIcon, Twitter as TwitterIcon, Instagram as InstagramIcon } from '@material-ui/icons';
import { SiDiscord } from 'react-icons/si';

import { connectWallet, disconnectWallet } from 'reducer/async/wallet';
import { useSelector, useDispatch } from 'reducer';
import { FaWallet } from 'react-icons/fa';
import APP_CONFIG from 'config/app_config';
import useAuth from 'hooks/auth';
import { DarkModeToggler } from 'components/atoms';

const useStyles = makeStyles(theme => ({
	root: {
	},
	listItem: {
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	listItemActive: {
		'&> .menu-item': {
			color: theme.palette.primary.dark,
		},
	},
	listWalletAddress: {
		'&:hover': {
			textDecoration: 'underline',
		}
	},
	navLink: {
		'&:hover': {
			color: theme.palette.primary.dark,
		},
	},
	icon: {
		width: 25,
		height: 25,
	},
	iconButton: {
		fontSize: 24,
		color: theme.palette.text.primary,
	},
	listItemBorder: {
		paddingRight: 18,
		borderRight: '2px solid #f1f1f1',
	},
	listItemButton: {
		whiteSpace: 'nowrap',
		'&> span > svg': {
			marginLeft: 10,
			marginRight: 10,
		},
	},
	listItemNoFlex: {
		flex: 'none',
	},
	listItemNoPadding: {
		paddingLeft: 0,
	},
	listItemAvatar: {
		minWidth: 'auto',
		marginLeft: theme.spacing(2),
	},
	listItemIcon: {
		minWidth: 'auto',
	},
	closeIcon: {
		justifyContent: 'flex-end',
		cursor: 'pointer',
	},
	menu: {
		display: 'flex',
	},
	menuItem: {
		marginRight: theme.spacing(8),
		'&:last-child': {
			marginRight: 0,
		},
	},
	menuGroupItem: {
		paddingTop: 0,
	},
	menuGroupTitle: {
		textTransform: 'uppercase',
	},
	divider: {
		width: '100%',
	},
	navActiveLink: {
		fontWeight: 'bold',
		fontSize: 16,
	},
	navInactiveLink: {
		color: '#888888',
		fontSize: 16,
	},
	navigationNftContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		'& > li': {
			padding: 0,
		}
	},
	popover: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(1),
		border: theme.spacing(2),
		boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
		minWidth: 200,
		marginTop: theme.spacing(2),
	},
	avatarList: {
		'&:hover': {
			cursor: 'pointer',
		}
	},
	expandOpen: {
		transform: 'rotate(180deg)',
		color: theme.palette.primary.dark,
	},
	socialIcon: {
		padding: 0,
		marginRight: theme.spacing(1),
		color: 'rgba(255,255,255,.6)',
		'&:hover': {
			background: 'transparent',
		},
		'&:last-child': {
			marginRight: 0,
		},
	},
	walletIcon: {
		color: theme.palette.text.primary,
	}
}));

interface Props {
	className?: string;
	onClose: Function;
	pages: PagesProps;
	themeMode: string;
	themeToggler: Function;
};

const SidebarNav = ({ themeMode, pages, onClose, className, themeToggler, ...rest }: Props): JSX.Element => {
	const classes = useStyles();
	const { user } = useAuth();

	const system = useSelector(s => s.system);
	const dispatch = useDispatch();  

	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [openedPopoverId, setOpenedPopoverId] = useState<string | null>(null);
	const [location, setLocation] = useLocation();

	const handleClick = (event: MouseEvent<HTMLElement>, popoverId: string | null): void => {
		setAnchorEl(event.target);
		setOpenedPopoverId(popoverId);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
		setOpenedPopoverId(null);
	};

	const DisplayWalletInfo = (): JSX.Element => {
		return (
			<div className={classes.menu}>
				<div className={classes.menuItem}>
					<List disablePadding>
						<ListItem disableGutters>
							<Typography variant="body1" className={clsx(classes.navLink, 'submenu-item')}  onClick={handleClose} style={{ fontWeight: 'bold' }}>
								{`Network: ${system.config.network}`}
							</Typography>
						</ListItem>

						<Divider />

						<ListItem disableGutters data-aos="fade-up" style={{ padding: 0 }}>
							<a href={`${APP_CONFIG.WALLET_ADDRESS}/${user?.wallet || system.tzPublicKey}`} target="_blank" rel="noopener noreferrer" className={classes.listWalletAddress}>
								<ListItemText primary={user?.username || system.tzPublicKey} primaryTypographyProps={{ variant: 'subtitle1', color: 'textPrimary' }} />
							</a>
						</ListItem>

						<ListItem disableGutters>
							<Link href="/account">
								<Typography variant="body1" component={'a'} href="/account" className={clsx(classes.navLink, 'submenu-item')} color="textSecondary" onClick={handleClose}>
									Edit Profile
								</Typography>
							</Link>
						</ListItem>

						<ListItem disableGutters className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
							<DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />
						</ListItem>

						<ListItem disableGutters>
							<Button 
								variant="outlined" 
								color="primary" 
								component="a" 
								className={classes.listItemButton} 
								onClick={async () => { await dispatch(disconnectWallet()); setLocation('/') }}
							>
								Disconnect
							</Button>
						</ListItem>
					</List>
				</div>
			</div>
		);
	};

	return (
		<List {...rest} className={clsx(classes.root, className)}>
			<ListItem className={classes.closeIcon} onClick={() => onClose()}>
				<ListItemIcon className={classes.listItemIcon}>
					<CloseIcon fontSize="small" />
				</ListItemIcon>
			</ListItem>

			<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
				<Link href="/marketplace">
					<Button className={clsx(location === '/marketplace' ? classes.navActiveLink : classes.navInactiveLink, classes.listItemButton)}>
						Marketplace
					</Button>
				</Link>
			</ListItem>

			<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
				<Link href="/explore">
					<Button className={clsx(location === '/explore' ? classes.navActiveLink : classes.navInactiveLink, classes.listItemButton)}>
						Explore
					</Button>
				</Link>
			</ListItem>

			<ListItem onClick={e => handleClick(e, 'nfts')} className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
				<Button className={clsx(classes.navInactiveLink, classes.listItemButton)} style={{ justifyContent: 'flex-start' }}>
					Tools
				</Button>
			</ListItem>

			<Popover
				elevation={1}
				id={'nfts'}
				open={openedPopoverId === 'nfts'}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				classes={{ paper: classes.popover }}
			>
				<div>
					<List disablePadding className={classes.navigationNftContainer}>
						<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')} onClick={handleClose}>
							<a href={`${APP_CONFIG.ORBIX_SHORT_URL_ROOT}templates?#nft-tools`} target="_blank" rel="noopener noreferrer">
								<Button>
									NFT Templates
								</Button>
							</a>
						</ListItem>
					</List>
				</div>
			</Popover>

			{ (user || system.status === 'WalletConnected') && (
				<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
					<Link href={`/creator/${system.tzPublicKey}`}>
						<Button component="a" href={`/creator/${system.tzPublicKey}`} className={clsx(location === `/creator/${system.tzPublicKey}` ? classes.navActiveLink : classes.navInactiveLink, classes.listItemButton)}>
							Profile
						</Button>
					</Link>
				</ListItem>
			)}

			{ system.status === 'WalletConnected' && (
			<>
				<ListItem className={clsx(classes.listItem, classes.listItemBorder)}>
					<Link href="/create">
						<Button variant="contained" color="primary" className={classes.listItemButton}>
							Mint
						</Button>
					</Link>
				</ListItem>

				<>
					<ListItem disableGutters onClick={e => handleClick(e, 'wallet')} className={classes.avatarList} style={{ marginLeft: 16 }}>
						<ListItemAvatar className={classes.listItemAvatar}>
							<Avatar src={user?.avatarUrl || `https://services.tzkt.io/v1/avatars2/${user?.wallet || system.tzPublicKey}`} className={classes.icon} />
						</ListItemAvatar>
					</ListItem>

					<Popover
						elevation={1}
						id={'account'}
						open={openedPopoverId === 'wallet'}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						transformOrigin={{ vertical: 'top', horizontal: 'center' }}
						classes={{ paper: classes.popover }}
					>
						<div>
							<DisplayWalletInfo />
						</div>
					</Popover>
				</>
			</>
			)}
										
			{ system.status !== 'WalletConnected' && (
			<ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
				<Button variant="contained" color="primary" className={classes.listItemButton} onClick={() => dispatch(connectWallet())}>
					Connect Wallet
				</Button>
			</ListItem>
			)}

			<ListItem className={classes.listItem}>
				<Divider className={classes.divider} />
			</ListItem>

			<ListItem>
				<IconButton className={classes.socialIcon}>
					<a href="https://www.facebook.com/orbix360" target="_blank" rel="noopener noreferrer">
						<FacebookIcon className={classes.iconButton} />
					</a>
				</IconButton>

				<IconButton className={classes.socialIcon}>
					<a href="https://instagram.com/orbix360" target="_blank" rel="noopener noreferrer">
						<InstagramIcon className={classes.iconButton} />
					</a>
				</IconButton>

				<IconButton className={classes.socialIcon}>
					<a href="https://twitter.com/orbix360" target="_blank" rel="noopener noreferrer">
						<TwitterIcon className={classes.iconButton} />
					</a>
				</IconButton>

				<IconButton className={classes.socialIcon}>
					<a href="https://discord.com/invite/jejK5j3" rel="noopener noreferrer">
						<SiDiscord className={classes.iconButton} />
					</a>
				</IconButton>
			</ListItem>
		</List>
	);
};

export default SidebarNav;
