import React, { useState, useEffect } from 'react';
import { Link } from 'wouter';
import { Button, CircularProgress, Hidden, Typography, Grid, Tooltip } from '@material-ui/core';

import { Twitter as TwitterIcon } from '@material-ui/icons';
import { MdCameraAlt } from 'react-icons/md';
import { GoGear, GoVerified } from 'react-icons/go';

import { useSelector } from 'reducer';
import { isLink } from 'utils';

import APP_CONFIG from 'config/app_config';
import useAuth from 'hooks/auth';

import { getUserByWallet, updateUserFiles } from 'services/users';

import * as S from './styles';

export default function Hero({ minter }: ViewComponentProps): JSX.Element {
	const system = useSelector(s => s.system);
    const isOwner = system.tzPublicKey === minter;

    const { user, setUser } = useAuth();
    
    const [isHoverAvatar, setIsHoverAvatar] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(false);
    
    const [avatarUrl, setAvatarUrl] = useState<string>('');
    const [coverUrl, setCoverUrl] = useState<string>('');
    const [name, setName] = useState<string>(minter);
    const [twitter, setTwitter] = useState<string>('');
    const [bio, setBio] = useState<string>('');

	useEffect(() => {
        if (user && (user.wallet === minter)) {
            setAvatarUrl(user?.avatarUrl);
            setCoverUrl(user?.coverImageUrl);
            setName(user?.username || minter);
            setTwitter(user?.twitter);
            setBio(user?.bio || '');
        }

		if (minter) {
			(async () => {
				setIsLoading(true);
                
                const user = await getUserByWallet(minter);
                
				if (user) {
                    setAvatarUrl(user?.avatarUrl);
					setCoverUrl(user?.coverImageUrl);
                    setName((user?.username || user?.displayName) || minter);
                    setTwitter(user?.twitter);
                    setBio(user?.bio || '');
				}

				setIsLoading(false);
			})();
		}
	}, [minter]);
        
    const isAvatarLink = isLink(avatarUrl);

    async function handleCoverChange(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files;

        if (file) {
            setIsLoading(true);

            const formData = new FormData();
            formData.append('field', 'cover');
            formData.append('file', file[0]);

            try {
                const { error, object, message } = await updateUserFiles(formData, minter);
    
                if (error) {
                    alert(message);
                    return false;
                }

                Object.assign(user, object);
    
                setCoverUrl(object.coverImageUrl);
                setUser(user);
                setIsLoading(false);
            } catch (error) {
                console.log({ error });
                setIsLoading(false);
                alert("Sorry, error on uploading file");
            }
        }
    }

    async function handleAvatarChange(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files;

        if (file) {
            setIsAvatarLoading(true);

            const formData = new FormData();
            formData.append('field', 'avatar');
            formData.append('file', file[0]);

            try {
                const { error, object, message } = await updateUserFiles(formData, minter);
    
                if (error) {
                    alert(message);
                    return false;
                }
    
                Object.assign(user, object);
    
                setAvatarUrl(object.avatarUrl);
                setUser(user);
                setIsAvatarLoading(false);                
            } catch (error) {
                console.log({ error });
                setIsLoading(false);
                alert("Sorry, error on uploading file");                
            }
        }
    }

    return (
        <>
            <S.Wrapper>
                <S.BannerContent>
                    { !isLoading && <img src={coverUrl} alt="" /> }
                        
                    <CircularProgress size={72} hidden={!isLoading} />

                    { isOwner && (
                    <label htmlFor="uploadCover">
                        <Tooltip title="Recommended 1400x300px">
                            <Button color="default" variant="contained" component="span" disabled={isLoading}>
                                <MdCameraAlt size={20} />{ coverUrl ? 'Edit Cover Photo' : 'Add Cover Photo'}
                            </Button>     
                        </Tooltip>

                        <input 
                            type="file" 
                            id="uploadCover" 
                            name="uploadCover" 
                            style={{ display: 'none' }} 
                            onChange={(e) => handleCoverChange(e)} 
                        />
                    </label>
                    )}
                </S.BannerContent>
            </S.Wrapper>

            <S.Details>
                <Grid container direction="column">
                    <Grid item xs={12}>
                        <S.Content>
                            <S.AvatarContainer onMouseEnter={() => setIsHoverAvatar(true)} onMouseLeave={() => setIsHoverAvatar(false)} isHoverAvatar={isHoverAvatar}>
                                <S.Avatar>
                                    <S.AvatarImage hasAvatarUrl={!!isAvatarLink} avatarColor={avatarUrl}>
                                        { !isAvatarLoading && <img src={avatarUrl} alt="" /> }

                                        <CircularProgress size={40} hidden={!isAvatarLoading} />

                                        { isOwner && (
                                        <Tooltip title="Recommended 300x300px">
                                            <label 
                                                htmlFor="uploadAvatar" 
                                                style={{ display: isHoverAvatar ? 'flex' : 'none' }} 
                                                onMouseEnter={() => setIsHoverAvatar(true)} 
                                                onMouseLeave={() => setIsHoverAvatar(false)}
                                            >
                                                <span>
                                                    <MdCameraAlt size={20} color="#fff" />
                                                </span>

                                                <input 
                                                    type="file" 
                                                    id="uploadAvatar" 
                                                    name="uploadAvatar" 
                                                    style={{ display: 'none' }} 
                                                    onChange={(e) => handleAvatarChange(e)} 
                                                    />
                                            </label>
                                        </Tooltip>
                                        )}
                                    </S.AvatarImage>   
                                </S.Avatar>
                            </S.AvatarContainer>
{/* 
                            <S.Title>
                                <a href={`${APP_CONFIG.WALLET_ADDRESS}/${minter}`} target="_blank" rel="noopener noreferrer">
                                    <HiLink size={20} />
                                    { `${minter.slice(0, 8)}...${minter.slice(-8)}` }
                                </a>
                            </S.Title> */}
                        </S.Content>

                        <S.Cover>
                            <S.Followers>
                                <S.UserName>
                                    <a href={`${APP_CONFIG.WALLET_ADDRESS}/${minter}`} target="_blank" rel="noopener noreferrer">
                                        <Typography variant="h4">
                                            <strong>{ name }</strong>
                                       </Typography>
                                    </a>

                                    { twitter && <GoVerified size={32} />}
                                </S.UserName>

                                { bio && (<Typography>{ bio }</Typography>)}

                                <Hidden smDown smUp>
                                    <Typography>25 Following</Typography>
                                    <Typography>318 Followers</Typography>
                                </Hidden>

                                { twitter && (
                                <S.Icon>
                                    <a href={`https://twitter.com/${twitter}`} target={`_blank`}>
                                        <TwitterIcon />
                                    </a>
                                </S.Icon>              
                                )}
                            </S.Followers>

                            { isOwner && (
                            <Link href="/account">
                                <Button variant="contained" color="primary" size="small">
                                    <GoGear />Edit Profile
                                </Button>
                            </Link>
                            )}
                        </S.Cover>
                    </Grid>
                </Grid>
            </S.Details>
        </>
    );
}