import { Box, Tabs, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Link } from 'wouter';

export const Wrapper = styled(Box)`
    ${({ theme }) => css`
        section {
            padding-top: 0;
            max-width: 100%;
        }

        .MuiTab-textColorPrimary.Mui-selected {
            background-color: transparent !important;
            font-weight: bolder !important;
            color: ${theme.palette.text.primary} !important;
            border-radius: unset !important;
        }

        .MuiTabs-indicator: {
            background-color: ${theme.palette.text.primary} !important;
        }        
    `}
`;

export const TabComponent = styled(Tabs)`
    ${({ theme }) => css`
        margin-bottom: ${theme.spacing(3)}px;
        width: fit-content;
        border-bottom: 1px solid ${theme.palette.text.primary};

        .MuiTab-textColorPrimary: {
            color: ${theme.palette.text.secondary};
        }

        .Mui-selected: {
            background-color: transparent;
            font-weight: bolder;
            color: ${theme.palette.text.primary};
            border-radius: unset;
        }

        .MuiTabs-indicator: {
            background-color: ${theme.palette.text.primary} !important;
        }        
    `}
`;

export const AuthorName = styled(Typography)`
    ${({ theme }) => css`
        display: flex;
        margin-top: 4px;

        svg {
            margin-right: 8px;
            color: ${theme.palette.text.primary};
        }    

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    `}
`;

export const StyledLink = styled(Link)`
    ${({ theme }) => css`
        &:hover {
            text-decoration: underline;
        }
    `}
`;