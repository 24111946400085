import React from 'react';
import clsx from 'clsx';
import { Link } from 'wouter';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, IconButton, Grid, List, ListItem, Divider } from '@material-ui/core';

import { Twitter as TwitterIcon, Instagram as InstagramIcon } from '@material-ui/icons';
import { SiDiscord, SiFacebook } from 'react-icons/si';

import { DarkModeToggler, Image } from 'components/atoms';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
	listRoot: {
		display: 'flex',
		flex: 1,
		'&.MuiListItem-root': {
			alignItems: 'unset !important',
		},
	},
	listItemRoot: {
		alignItems: 'unset',
	},
	logoContainerItem: {
		paddingTop: 0,
	},
	logoContainer: {
		width: 240,
		height: 44,
		marginTop: 16,
		marginBottom: 16,
	},
	logoImage: {
		width: '100%',
		height: '100%',
	},
	groupTitle: {
		textTransform: 'uppercase',
		color: theme.palette.primary.dark,
		marginBottom: theme.spacing(1),
	},
	socialIcon: {
		padding: 0,
		marginRight: theme.spacing(1),
		color: theme.palette.text.primary,
		'&:hover': {
			background: 'transparent',
		},
		'&:last-child': {
			marginRight: 0,
		},
	},
	icon: {
		width: 32,
		height: 32,
		borderRadius: '50%',
		background: theme.palette.type === 'dark' ? '#cacaca' : '#898989',
		padding: 6,
		color: theme.palette.button.primary,
		marginRight: 8,
	},
	menuListContainer: {
		padding: '0 !important',
	},
	menu: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 1fr)'
		}
	},
	menuItem: {
		margin: theme.spacing(2),
		marginLeft: 0,
		'&:last-child': {
			marginBottom: 0,
		},
	},
	menuGroupItem: {
		paddingTop: 0,
		paddingBottom: theme.spacing(2),
		color: theme.palette.text.primary,
		'&:last-child': {
			paddingBottom: 0,
		},
	},
	menuGroupTitle: {
		textTransform: 'uppercase',
		color: theme.palette.text.primary,
	},
	divider: {
		width: '100%',
	},
	navLink: {
		// color: theme.palette.text.primary,
	},
}));

interface Props {
	className?: string;
	pages: PagesProps;
	themeMode: string;
	themeToggler: Function;
};

const Footer = ({ pages, themeMode, themeToggler, className, ...rest }: Props): JSX.Element => {
	const classes = useStyles();

	const logoSrc = themeMode === 'light' ? '/assets/logo-black.svg' : '/assets/logo-white.svg';
	const supportedPages = pages.pages;

	const MenuGroup = ({ item }: MenuGroupProps): JSX.Element => (
		<List disablePadding className={classes.menuItem}>
			{item.pages.map((page, i) => (
				<ListItem disableGutters key={`menu-group-${i}`} className={classes.menuGroupItem}>
					<Link href={page.href}>
						<Typography variant="body2" component={'a'} href={page.href} className={clsx(classes.navLink, 'submenu-item')}>
							{page.title}
						</Typography>
					</Link>
				</ListItem>
			))}
		</List>
	);

	const SupportedPages = (): JSX.Element => {
		const { company, pages } = supportedPages.children;

		return (
			<>
				<Grid item xs={12} md={3}>
					<Box display="flex" flexDirection="column">
						<div className={classes.logoContainer}>
							<Link href="/">
								<a href="/" title="Orbix 360º">
									<Image className={classes.logoImage} src={logoSrc} alt="thefront" lazy={false} />
								</a>
							</Link>
						</div>

						<Box display="flex">
							<IconButton className={classes.socialIcon}>
								<a href="https://www.facebook.com/orbix360" target="_blank" rel="noopener noreferrer">
									<SiFacebook className={classes.icon} />
								</a>
							</IconButton>

							<IconButton className={classes.socialIcon}>
								<a href="https://instagram.com/orbix360" target="_blank" rel="noopener noreferrer">
									<InstagramIcon className={classes.icon} />
								</a>
							</IconButton>

							<IconButton className={classes.socialIcon}>
								<a href="https://twitter.com/orbix360" target="_blank" rel="noopener noreferrer">
									<TwitterIcon className={classes.icon} />
								</a>
							</IconButton>

							<IconButton className={classes.socialIcon}>
								<a href="https://discord.com/invite/jejK5j3" target="_blank" rel="noopener noreferrer">
									<SiDiscord className={classes.icon} />
								</a>
							</IconButton>
						</Box>
					</Box>					
				</Grid>

				<Grid item xs={12} md={2}>
					<MenuGroup item={pages!} />
				</Grid>

				<Grid item xs={12} md={2}>
					<MenuGroup item={company} />
				</Grid>

				<Grid item xs={12} md={2}>
					<List disablePadding className={classes.menuItem} style={{ display: 'flex' }}>
						<DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />
					</List>
				</Grid>
			</>
		);
	};

	return (
		<S.Wrapper {...rest}>
			<S.FooterContainer>
				<S.Container container spacing={4} >
					<SupportedPages />
				</S.Container>
			</S.FooterContainer>

			<Divider />
			
			<S.FooterContainer>
				<S.Container container spacing={4}>
					<Grid item md={12}>
						<Box display="flex" justifyContent="center" padding={2}>
							<Typography variant="body1">Orbix 360. All Rights Reserved.</Typography>
						</Box>
					</Grid>
				</S.Container>
			</S.FooterContainer>
		</S.Wrapper>
	);
};

export default Footer;
