import React from 'react';
import { Box } from '@material-ui/core';

interface TabPanelProps {
	children: JSX.Element;
	value: string | string[] | number | null;
	index: string | string[] | number | null;
};

const TabPanel = ({ children, value, index, ...other }: TabPanelProps): JSX.Element => (
	<Box component="div" hidden={value !== index} {...other}>
		{children}
	</Box>
);

export default TabPanel;
