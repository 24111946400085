import React, { useState, useEffect, useRef } from 'react';
import { FaExpand } from 'react-icons/fa';

import { ipfsUriToGatewayUrl } from 'lib/util/ipfs';
import { useSelector } from 'reducer';

import { Section } from "components/organisms";
import { Image } from 'components/atoms';

import * as S from './styles';

declare global {
    interface Element {
        webkitRequestFullscreen(): void;
    }
}

export default function LeftSide({ config, token, ownedToken, contractAddress }: ViewComponentProps): JSX.Element {
    const src = ipfsUriToGatewayUrl(config, token?.artifactUri);
    const [obj, setObj] = useState<{ url: string; type: string } | null>(null);
    
    const imageRef = useRef<HTMLImageElement | null>(null);
    const modelRef = useRef<HTMLElement | null>(null);
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const system = useSelector(s => s.system);
    const srcWithParams = `${src}?viewer=${system.tzPublicKey}&creator=${token?.metadata?.minter || ''}&contract=${contractAddress}&objkt=${ownedToken || token?.tokenId}&name=${token?.metadata?.name}`

    function handleExpandClick() {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (imageRef.current) {
            (isSafari) ? imageRef.current.webkitRequestFullscreen() : imageRef.current.requestFullscreen();
        }

        if (modelRef.current) {
            (isSafari) ? modelRef.current.webkitRequestFullscreen() : modelRef.current.requestFullscreen();
        }

        if (iframeRef.current) {
            (isSafari) ? iframeRef.current.webkitRequestFullscreen() : iframeRef.current.requestFullscreen();
        }
    }

    useEffect(() => {
        (async () => {
            let blob;
            
            try {
                blob = await fetch(src).then(r => r.blob());
                setObj({ url: URL.createObjectURL(blob), type: blob.type });
            } catch (e) {
                console.log(e);
            }            
        })();
    }, []);

    if (!obj) return <>Object not found</>;

    const HandleFileType = () => {
        if (/^video\/.*/.test(obj.type)) {
            return (
                <video
                    preload="metadata"
                    onClick={e => e.preventDefault()}
                    onLoadedMetadata={e => { (e.target as HTMLVideoElement).currentTime = .05; }}
                    muted
                    controls
                >
                    <source src={obj.url} type={obj.type} />
                </video>
            );
        }

        if (token.metadata.formats?.length) {
            if (['model/gltf-binary', 'model/gltf+json'].includes(token.metadata.formats[0].mimeType)) {
                return (
                    <model-viewer 
                        auto-rotate 
                        rotation-per-second="30deg" 
                        camera-controls 
                        src={obj.url} 
                        class={token.class} 
                        ref={modelRef}
                    />
                );             
            } else if (token.metadata.formats[0].mimeType.includes('zip')) {
                return (<iframe src={srcWithParams} title={'iframe-viewer'} ref={iframeRef}></iframe>);
            }        
        }

        const imageSrc = ipfsUriToGatewayUrl(config, token.displayUri);

        return <Image src={imageSrc} ref={imageRef} lazy={false} />;
    }

    return (
        <S.Wrapper>
            <Section>
                <S.BoxContainer>
                    <HandleFileType />

                    { !(/^video\/.*/.test(obj.type)) && (
                    <S.BoxExpand title="Expand to see in fullscreen" onClick={handleExpandClick}>
                        <FaExpand size={24} color="#fff" />
                    </S.BoxExpand>
                    )}
                </S.BoxContainer>
            </Section>
        </S.Wrapper>
    );
}