import _ from 'lodash';
import { isLeft } from 'fp-ts/lib/Either';

import { fromHexString, getFixedPriceSalesBigMap, getTokenMetadataBigMap } from 'lib/nfts/queries';
import { SystemWithToolkit, SystemWithWallet } from 'lib/system';
import * as D from 'lib/nfts/decoders';

import config from '../config.json';
import { Params } from 'lib/service/tzkt';

type QueryParams = {
  [x: string]: string;
}

export function getUrlQueryParams() {
  let urlQueryParams: QueryParams = {};
  window.location.search.substring(1).split("&").forEach((item) => {
    if (item !== '') {
      urlQueryParams[item.split("=")[0]] = item.split("=")[1];
    }
  });

  return urlQueryParams;
}

export async function nftMapper(system: SystemWithToolkit | SystemWithWallet, tokenIds: string[]) {
  const address = config.contracts.nftFaucet;
  const path = 'assets.ledger';
  let params: Params = {
    'sort.desc': 'id',
    'value.ni': '["tz1burnburnburnburnburnburnburjAYjjX","tz1burnburnburnburnburnburnburjAYjjX"]',
  };
  
  if (tokenIds.length) {
    params[`key${tokenIds.length > 1 ? '.in' : ''}`] = tokenIds.toString();
  }

  const data = await system.tzkt.getContractBigMapKeys(address, path, params);
  const decoded = D.LedgerBigMap.decode(data);

  if (isLeft(decoded)) {
    throw Error('Failed to decode `getLedger` response');
  }

  const ledgerTokenIds: string[] = [], ownedTokens = {} as {[x:string]: string};
  decoded.right.forEach(({ key, value}) => {
    ledgerTokenIds.push(key);
    ownedTokens[key] = value;
  });

  if (!ledgerTokenIds.length) {
    return [];
  }

  const tokens = await getTokenMetadataBigMap(system.tzkt, address, ledgerTokenIds.join(','));

  const mktAddress = system.config.contracts.marketplace.fixedPrice.tez;
  const tokenSales = await getFixedPriceSalesBigMap(system.tzkt, mktAddress, tokenIds.toString());

  return Promise.all(
    tokens.map(
      async (token): Promise<D.Nft> => {
        const { token_id: tokenId, token_info: tokenInfo } = token.value;

        const decodedInfo = _.mapValues(tokenInfo, fromHexString) as any;
        const resolvedInfo = await system.resolveMetadata(decodedInfo[''], address);
        const metadata = { ...decodedInfo, ...resolvedInfo.metadata };

        const saleData = tokenSales.find(v => v.value.sale_data.sale_token.fa2_address === address && v.value.sale_data.sale_token.token_id === tokenId);

        const sale = saleData && {
          id: saleData.id,
          seller: saleData.value.seller,
          price: Number.parseInt(saleData.value.sale_data.price, 10) / 1000000,
          mutez: Number.parseInt(saleData.value.sale_data.price, 10),
          saleToken: {
            address: saleData.value.sale_data.sale_token.fa2_address,
            tokenId: Number.parseInt(saleData.value.sale_data.sale_token.token_id)
          },
          saleId: saleData.value.isLegacy ? 0 : Number.parseInt(saleData.key),
          type: saleData.value.isLegacy ? 'fixedPriceLegacy' : 'fixedPrice'
        };

        return {
          id: parseInt(tokenId, 10),
          owner: (saleData) ? saleData.value.seller : (ownedTokens[tokenId] || metadata.creators[0]),
          title: metadata.name,
          description: metadata.description,
          artifactUri: metadata.artifactUri,
          displayUri: metadata.displayUri,
          edition_number: metadata.edition_number,
          number_of_editions: metadata.number_of_editions,
          edition_id: metadata.edition_id,
          metadata: metadata,
          sale
        };
      }
    )
  );
} 

export function handleTitleAndScrollToTop(title: string) {
  document.title = title;
  window.scrollTo(0, 0);
}

export function randomColor() {
  const hex = (Math.random() * 0xFFFFFF << 0).toString(16);

  return `#${hex}`;
}

// Receives an string and returns whether is a link or not
export function isLink(value: string): boolean {
  const regex = /(https?:\/\/[^\s]+)/g;

  return regex.test(value);
}

export function slugify(text: string): string {
  return text.replace(/["'()]*/g, '').split(' ').join('-').toLowerCase();
}
