import { Button } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const GTCOHeaderNavbar = styled.nav`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        padding: 0.5rem 1rem;
        background-color: white;
        
        top: 0;
        right: 0;
        left: 0;
        
        z-index: 11;
        
        transition: background-color 0.3s ease-in-out;
        
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;  
    `}
`;

export const Container = styled.div`    
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;

        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        img {
            max-width: 50px;
        }

        ${theme.breakpoints.up(1200)} {
            max-width: 1080px;
            padding-left: 30px;
            padding-right: 30px;
        }    
    `}    

`;

export const ConnectButton = styled(Button)`
    text-transform: none;
    white-space: nowrap;
    font-family: 'mathias';
    & > span > svg {
        margin-left: 10px;
        margin-right: 10px;
    }
`;
