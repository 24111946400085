import React from 'react';
import { Grid } from '@material-ui/core';

import * as S from './styles';
import * as C from '../styles';

const Counter = ({ contract, className, ...rest }: ViewComponentProps): JSX.Element => {
	return (
		<S.Wrapper>
			<C.Container>
				<C.SectionContent>
					<Grid container>
						<S.CounterItem item xs={6} sm={6} md={3}>
							<i className="lnr lnr-heart"></i>
							<span className="number" data-from="0" data-to="38" data-refresh-interval="100">{contract.price}</span>
							<h4>PRICE</h4>
						</S.CounterItem>

						<S.CounterItem item xs={6} sm={6} md={3}>
							<i className="lnr lnr-users"></i>
							<span className="number" data-from="0" data-to="34" data-refresh-interval="100">{contract.limit}</span>
							<h4>EDITIONS</h4>
						</S.CounterItem>

						<S.CounterItem item xs={6} sm={6} md={3}>
							<i className="lnr lnr-briefcase"></i>
							<span className="number" data-from="0" data-to="32" data-refresh-interval="100">{contract.collectors}</span>
							<h4>COLLECTORS</h4>
						</S.CounterItem>

						<S.CounterItem item xs={6} sm={6} md={3}>
							<i className="lnr lnr-rocket"></i>
							<span className="number" data-from="0" data-to="29" data-refresh-interval="100">{contract.volume}</span>
							<h4>VOLUME</h4>
						</S.CounterItem>
					</Grid>
				</C.SectionContent>
			</C.Container>
		</S.Wrapper>
	);
};

export default Counter;
