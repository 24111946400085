import React from 'react';
import useLocation from 'wouter/use-location';

import { Main } from 'layouts';
import { Home } from 'views';
import WithLayout from 'WithLayout';

export default function HomePage (): JSX.Element {
    document.title = "Minterverse";

    const { host } = window.location;
    const [, setLocation] = useLocation();

    if (host.includes('edittrax.io')) {
        document.title = 'EditTrax';
        if (!host.includes('/edit-trax/')) {
            setLocation('/edit-trax/alpha-test');
        }
    }
    
    return <WithLayout component={Home} layout={Main} />;
}