import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { useLocation } from 'wouter';

import { Nft } from 'lib/nfts/decoders';
import { useSelector } from 'reducer';

import { handleNfts, getTotalCollection } from 'services/collections';
import { getUrlQueryParams } from 'utils';

import { CardSkeleton, CardToken, Section, Pagination } from 'components/organisms';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
  sectionAlternate: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    '& .section-alternate__content': {
    },
    maxWidth: '100%',
  },
}));

const Result = ({ address, ownedOnly, className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true, });

  const [_, setLocation] = useLocation();

  const limit = 24;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const [totalKeys, setTotalKeys] = useState(0);
  const [pageNumber, setPageNumber] = useState(Number(getUrlQueryParams()['page']) || 1);

  const [nftTokens, setNftTokens] = useState<Nft[]>([]);

  const { system } = useSelector(s => s);
  const { config } = system;

  useEffect(() => {
    (async () => {
      if (address !== null) {
        const total = await getTotalCollection();
        setTotalKeys(total);
        console.log(`in Collections result, about to call handleNfts`);
        const tokens = await handleNfts(system, address, limit, (pageNumber - 1) * limit, true);
        let editionOneTokens = []
        for (let i = 0; i < tokens.length; i++) {
          if (!tokens[i].edition_number || tokens[i].edition_number === '1') {
            editionOneTokens.push(tokens[i]);
          }
        }
        setNftTokens(editionOneTokens);

        setIsLoading(false);
      }
    })();
  }, [address]);

  async function handlePagination(e: any, page: number) {  
    // const items = { page };
    // const urlParams = new URLSearchParams(items).toString();
    
    setLocation(`/collections?page=${page}`);
    setIsLoading(true);
    
    const tokens = await handleNfts(system, address, limit, (page - 1) * limit, true);
    setNftTokens(tokens);
    setPageNumber(page);

    window.scrollTo(0, 0);    
    
    setIsLoading(false);
  }

  const numPages = Math.ceil(totalKeys / limit);

  return (
    <S.Container className={className} {...rest}>
      <Section className={classes.sectionAlternate}>
        <>
          { isLoading && (
            <Grid container spacing={4}>
            { [...Array(18).keys()].map((skeleton, index) => (
              <Grid item xs={12} sm={6} md={2} key={index} data-aos="fade-up">
                <CardSkeleton />
              </Grid>
            ))}
            </Grid>
          )}

          { !isLoading && (
          <Grid container spacing={isMd ? 4 : 2}>
            { nftTokens.length > 0 && nftTokens.map((item: Nft, index: number) => (
              <Grid item xs={12} sm={6} lg={2} md={3} key={index} data-aos="fade-up">
                {/* <CardToken {...item} config={config} /> */}
              </Grid>
            ))}
          </Grid>
          )}

          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} container justifyContent="center">
              <Pagination 
                numPages={numPages}
                pageNumber={pageNumber} 
                handlePagination={handlePagination} 
                isLoading={isLoading}
                showLastButton={!(numPages - 2 >= pageNumber)}
              />
            </Grid>
          </Grid>
        </>
      </Section>
    </S.Container>
  );
};

export default Result;