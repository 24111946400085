import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        max-width: 600px;
        
        padding: ${theme.spacing(4, 0, 8)};

        
        .MuiCheckbox-colorSecondary {
            color: #bababa;

            .MuiSvgIcon-root {
                width: 1.5em;
                height: 1.5em;
            }
        }

        .MuiStepper-root {
            padding: 0;
        }
    `}
`;

export const TagContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;

        padding: 18.5px 14px;

        border: 1px solid;
        border-color: ${theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)'};
        border-radius: 4px;    
    `}
`;

export const Tag = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;

        color: ${theme.palette.type === 'dark' ? theme.palette.alternate.dark : theme.palette.text.primary};

        padding: 4px 8px;
        border: 1px solid #E2E8F0;
        margin: 5px;

        border-radius: 12px;
        background: #f2f2f2;

        svg {
            font-size: 16px;
            margin-left: 5px;

            &:hover {
                cursor: pointer;
            }
        }    
    `}
`;

export const TagInput = styled.input`
    flex: 1;
    font-size: 16px;
    outline: none;
    border: 0;

    font: inherit;
    background: none;    
`;

export const RoyaltyContainer = styled.div`
    display: grid;
    grid-template-columns: 65% 25% 10%;
    grid-gap: 16px;
    margin-top: 16px;
`;
