import styled from "styled-components";

export const Container = styled.div`
    iframe {
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 230px);
        border-radius: 0px;
        border: 0px solid white;
        display: flex;
        margin-top: 0px;  
    }
`;