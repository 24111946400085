import styled, { css } from 'styled-components';

export const inputButtonStyles = css`
    color: #fff;        
    background: #333333;

    border: 1px solid #C4C4C4;
    border-radius: 1px;    

    padding: 16px 0;
    width: fit-content;
    min-width: 135px;

    text-transform: uppercase;

    box-sizing: border-box;

    font-style: normal;
    font-weight: 250;
    font-size: 18px;
    line-height: 100%;

    &:hover {
        cursor: pointer;
    }
`;

export const Button = styled.button`
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;  

    //TODO: implements others buttons types
    //btn-dark
    color: #fff;
    background-color: black;
    border-color: black;
    
    &:hover {
        text-decoration: none;
        color: #858586;

        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
    }
`;

export const Container = styled.div`
    ${({ theme }) => css`
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;    

        ${theme.breakpoints.up(576)} {
            max-width: 540px;
        }    

        ${theme.breakpoints.up(768)} {
            max-width: 720px;
        }

        ${theme.breakpoints.up(992)} {
            max-width: 960px;
        }

        ${theme.breakpoints.up(1200)} {
            max-width: 1080px;
            padding-left: 30px;
            padding-right: 30px;                
        }
    `}
`;

export const SectionContent = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;  
`;

export const TitleWrap = styled.div`
    text-align: center;  
`;

export const SectionTitle = styled.h2`
    margin-bottom: 30px;  
    color: #212529;
    
    font-weight: 300;
    font-size: 36px; 
    line-height: 1.2em;   
`;

export const RecButton = styled.button<{selected?: boolean}>`
    ${({ selected = false }) => css`
        display: flex;
        align-items: center;
        justify-content: center;

        ${inputButtonStyles};

        & + & {
            margin-left: 12px;
        }

        ${selected && css`
            background: #FFFFFF;
            color: #000;
            font-weight: 400;
        `}    
    `}
`;