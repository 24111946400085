import React from 'react';

import BaseToken from 'templates/BaseToken';

import { getFilteredTags } from 'services/tokens';
import { getUrlQueryParams } from 'utils';

export default function SearchView() {
    const tag = getUrlQueryParams()['tag'];
    const tagSpaceFix = tag && tag.replace('%20', ' ');

    const pageNumber = Number(getUrlQueryParams()['page']) || 1;
    const limit = 12, filter: Filter = { limit, offset: (pageNumber - 1) * limit, orderBy: 'tokenId', descending: true };

    return (
      <BaseToken
        title={`Results for: ${tagSpaceFix}`}
        handleChange={async () => await getFilteredTags(filter, tagSpaceFix)}
        handleChangeProps={{filter, tagSpaceFix}}
        limit={limit}
        filter={filter}
        pageNumber={pageNumber}
      />
    );
}
