import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled.div`
    ${({ theme }) => css`
        .section {
            padding: ${theme.spacing(3)}px;
            padding-top: ${theme.spacing(1)}px;

            ${theme.breakpoints.up('md')} {
                padding-top: ${theme.spacing(5)}px,
                padding-bottom: ${theme.spacing(5)}px,
            }
        }    

        .card-product {
            border-radius: 0 !important;
            height: 450px;
        }
    `}
`;

export const GridContainer = styled(Grid)`
    ${({ theme }) => css`
        display: grid !important;
        grid-template-columns: 275px repeat(auto-fill, 275px) !important;

        ${theme.breakpoints.down('sm')} {
            display: flex !important;
        }
    `}
`;

export const GridCard = styled(Grid)`
    ${({ theme }) => css`
        padding-left: 8px;
        padding-right: 8px;
    `}
`;