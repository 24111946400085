import styled, { css } from "styled-components";

import myFontURL from "../../fonts/MonumentExtended-Regular.otf";

export const fontFaces = css`
    @font-face {
        font-family: 'mathias';
        src: url(${myFontURL}) format('opentype');
        font-weight: 700;
        font-style: normal;
        font-size: 1.6rem;
    }
`;

export const Wrapper = styled.div<{isLoading?: boolean}>`  
    ${({ isLoading = false }) => css`
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background: black;

        position: relative;        
        
        font-family: 'mathias';
        font-weight: 700;
        font-size: 1.6rem;  
        
        ${!isLoading && css`
            padding: 16px;
        `};

        &::after {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: fixed;
            opacity: 8;
            z-index: -1;
        }        
    `}  
`;

export const BoxedPage = styled.div`
    ${({ theme }) => css`
        margin: 0 auto;
        margin-bottom: 6rem;
        position: relative;
        border-radius: 5px;

		${theme.breakpoints.up(1200)} {
			width: 1136px;
		}

        ${theme.breakpoints.up('sm')} {
            padding-top: 30px;
        }

        ${theme.breakpoints.down('sm')} {
            margin-bottom: 0;
        }
	`}
`;
