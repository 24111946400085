import React from 'react';
import clsx from 'clsx';
import { Link } from 'wouter';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button } from '@material-ui/core';

import { SectionHeader } from 'components/molecules';
import { MediaContent } from 'components/organisms';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  hero: {
    backgroundColor: theme.palette.alternate.main,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 8),
    },
  },
}));

const Hero = ({ token, config, className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true, });
  let subtitle = token ? `Current price: ${token.sale.price}ꜩ` : '';
  if (token.edition_number && token.number_of_editions > 1) {
    subtitle = `(${token.edition_number} of ${token.number_of_editions}) ${subtitle}`;
  }
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      { token && (
      <div className={classes.hero}>
        <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2} direction={isMd ? 'row' : 'column-reverse'}>
          <Grid item container alignItems="center" xs={12} md={4} data-aos={'fade-up'}>
            <SectionHeader
              title={token.title}
              subtitle={subtitle}
              ctaGroup={[
                <Link href={`/collection/${token.address}/token/${token.id}`}>
                  <Button variant="contained" color="primary" size="large">
                    View
                  </Button>
                </Link>,
              ]}
              align="left"
              titleVariant="h3"
            />
          </Grid>

          <S.GridContainer item container justifyContent="center" alignItems="flex-start" xs={12} md={8} data-aos={'fade-up'}>
            <MediaContent token={token} config={config} useDisplayImage={true} />
          </S.GridContainer>
        </Grid>
      </div>
      )}
    </div>
  );
};

export default Hero;
