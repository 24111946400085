import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { playerConfigs } from '../../data';
import APP_CONFIG from 'config/app_config';

import * as S from './styles';

export type EditTraxtTokenProps = typeof playerConfigs['alpha-test'];

const Details = ({ token, contract, className, player, ...rest }: ViewComponentProps & { contract: Contracts }): JSX.Element => {
    const editTraxTokens = playerConfigs[player as keyof typeof playerConfigs];

    const creators = contract.collaborators
    const tags = contract.metadata?.tags || [];

	return (
		<S.Container>
            <Box marginBottom={3}>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <S.ThumbBox>
                            <S.Thumbnail src={editTraxTokens.artistThumbnail} />
                        </S.ThumbBox>
                    </Grid>

                    <S.GridNameTags item xs={12} md={8}>
                        <S.NameTagsBox>
                            <S.Title>
                                <h2>BAI-EE</h2>
                                <h6>{contract?.title}</h6>
                            </S.Title>

                            { !!tags.length && (
                            <S.Tags>
                            {tags.map(tag => (
                                <S.Tag key={`tag-${tag}`}>{tag}</S.Tag>                        
                            ))}
                            </S.Tags>
                            )}
                        </S.NameTagsBox>
                    </S.GridNameTags>
                </Grid>
            </Box>

            <Grid container>
                <Grid item xs={12} md={4}>
                    <S.CollaborationsBox>
                        { creators && creators?.length > 0 && (
                          <S.Content>
                            <S.SectionName><strong>COLLABORATOR(S)</strong></S.SectionName>

                            { creators.map((creator, index) => (
                            <S.Profile key={`creator-${index}`}>
                                <S.Avatar src={creator.avatar} />
                                <S.Name>{creator.name}</S.Name>
                            </S.Profile>
                            ))}
                        </S.Content>
                          )}
                        <S.Content>
                            <h6>
                                <strong>MINTED</strong>
                                <span>{ ' ' + new Intl.DateTimeFormat('en-US').format(new Date(contract?.createdDate))}</span>
                            </h6>
                            
                            <h6>
                                <strong>
                                    <a 
                                        href={ 
                                            contract?.metadata?.artifactUri?.replace('ipfs://', 'https://orbix360.mypinata.cloud/ipfs/')
                                        } 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        LINK TO IPFS 
                                    </a>
                                </strong>
                            </h6>
                            
                            <h6>
                                <strong>
                                    <a 
                                        href={ `${APP_CONFIG.WALLET_ADDRESS}/${contract.contractAddress}`}
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        CONTRACT ADRESS 
                                    </a>
                                </strong>
                            </h6>                           
                        </S.Content>
                    </S.CollaborationsBox>
                </Grid>

                <Grid item xs={12} md={8}>
                    <S.Content>
                        { contract?.description && contract.slug !== 'alpha-test2' && (
                            <>
                                <S.SectionName>
                                    <strong>DESCRIPTION</strong>
                                </S.SectionName>
        
                                <S.Description>{contract.description}</S.Description>
                            </>
                        )}

                        { contract.slug === 'alpha-test2' && (
                          <>
                          <S.SectionName>
                              <strong>DESCRIPTION</strong>
                          </S.SectionName>

                          <S.Description>
                              <span>
                            ARTIST: BAI-EE<br/>
                            TRACK: ACID BEACH<br/>
                            LABEL: HOUSEPIT CHI<br/>
                            CATALOG: HPIT06<br/>
                            PLAYER CODE: ET001<br/><br/>
                            DOWNLOADS ENABLED IN SAFARI BROWSERS ONLY.<br/>
                            This is an in development ALPHA TEST / EditTrax.NFT<br/><br/>
                            "Acid Beach" is an original production created in the familiar style of Bai-ee's moniker Secret Studio. Written and produced as the B2 track off his first solo ep in 22 years, the Genesis EP releases on 12" vinyl and digital platforms worldwide in 2022, on Housepit CHI Records.<br/><br/>
                            Acid Beach will be the first track distributed as a collectible, digital audio workstation powered by EditTrax.NFT (ET001). This is a “test press” white-label version of the official player/track dropping Q1 2022.<br/><br/>
                            EditTrax was conceived, designed and developed by Bai-ee, as a decentralized distribution channel for music releasing on his and collaborators independent music labels.<br/><br/>
                            Those collecting this digital test press will be white-listed to mint the official track release, featuring completed collaborative artwork, additional preset edits, and utility AS WELL, as qualify to receive 1 of 5 vinyl test presses of Bai-ee's "Genesis" EP releasing Q1 2022.<br/><br/>
                            Find more information at:<br/>
                            EditTrax.NFT<br/>
                            Hpit.info
                          </span>
                          </S.Description>
                          </>
                        )}
                    </S.Content>

                    { !!editTraxTokens.unlockableContentImage && (
                    <S.Content>
                        <img src={editTraxTokens.unlockableContentImage} alt="Wav unlockable content" style={{ width: '100%' }}/>
                    </S.Content>
                    )}

                    <S.Content>
                        <S.SectionName><strong>RIGHTS</strong></S.SectionName>

                        <S.Description>
                            As the owner of this collectible, you are granted the right to perform its downloadable content in public. This includes playing in mix-tapes, online streams, social feeds, and live performances. You do NOT have the right to repackage downloaded content for resale or distribution. Assume NO other rights of ownership.
                        </S.Description>
                    </S.Content>

                </Grid>
            </Grid>
		</S.Container>
	);
};

export default Details;
