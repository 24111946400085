import { Button } from "@material-ui/core";
import styled, { css } from "styled-components";

export const MuiButton = styled(Button)`
    ${({ theme }) => css`
        border: 1px solid ${theme.palette.error.main};
        background: ${theme.palette.common.white};
        color: ${theme.palette.error.main};
        margin-left: ${theme.spacing(2)}px;
        
        &:hover {
            background-color: alpha(${theme.palette.error.main}, 0.04);
        }
    `}
`;
