import { Grid } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin-top: 12px;
`;

export const HeaderContainer = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .section-header {
        margin-bottom: 0;
    }    

    ${({ theme }) => css`    
        svg {
            cursor: pointer;
            color: ${theme.palette.error.main};
        }
    `}

`;

export const TagContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    padding: 10px;

    border: 1px solid;
    border-color: inherit;
    border-radius: 2px;
`;

export const Tag = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;

        padding: 4px 8px;
        border: 1px solid #ccc;
        margin: 5px;

        color: ${theme.palette.type === 'dark' ? theme.palette.alternate.dark : theme.palette.text.primary};
        border-radius: 12px;
        background: #f2f2f2;

        svg {
            font-size: 16px;
            margin-left: 5px;

            &:hover {
                cursor: pointer;
            }
        }    
    `}
`;

export const TagInput = styled.input`
    flex: 1;
    font-size: 16px;
    padding: 5px;
    outline: none;
    border: 0;

    padding-left: 8px;
`;
