import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Divider } from '@material-ui/core';

import { useSelector } from 'reducer';

import { HomeSkeleton, Result, Topbar } from './components';
import { findTokensByIds } from 'services/tokens';

const Wrapper = styled.div`
  ${({ theme }) => css`
    min-height: calc(100vh - 300px);
    width: 100%;
    background: #101010;
    overflow: hidden; 
    
    ${theme.breakpoints.down('sm')} {
      overflow-y: auto;
    }
  `}
`;  

const EditTraxMarketplaceView = (): JSX.Element => {
  const { system, marketplace: state } = useSelector(s => s);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalKeys, setTotalKeys] = useState(0);
  const [tokens, setTokens] = useState<Token[]>([]);

  useEffect(() => {
    (async () => {
      const response = await findTokensByIds([10, 11, 12, 13], 'KT1H7rmwXkg1eFpznBw1PxZTtBKw9XQhkzHH')      
      setTotalKeys(response?.total || 0);
      setTokens(response?.tokens || []);
      
      setIsLoading(false);
    })();
  }, []);

  return (
    <Wrapper>
      <Topbar />

      { isLoading ? (
        <HomeSkeleton />
      ) : (
        <Result 
          data={tokens} 
          config={system.config} 
          address={state.marketplace.address} 
          totalKeys={totalKeys} 
        />
      )}

      <Divider />
    </Wrapper>
  );
};

export default EditTraxMarketplaceView;
