import { Box, Typography } from "@material-ui/core";
import styled, { css } from 'styled-components';

import { SectionAlternate } from 'components/organisms';
import { randomColor } from "utils";

const buttonProps = css`
    background: #fff;
    text-transform: capitalize;
    border-radius: 6px;
    color: #000;
    border: none;

    svg {
        margin-right: 8px;
    }
`;

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        min-height: 220px;
        height: 250px;
        overflow: hidden;

        position: relative;

        svg {
            color: ${theme.palette.text.primary};
        }    
    `}
`;

export const Details = styled(Box)`
    ${({ theme }) => css`
        position: relative;
        /* max-width: ${theme.layout.contentWidth}px; */
        margin: 0 auto;

        ${theme.breakpoints.up(601)} {
            padding: ${theme.spacing(0, 8)};
        }

		${theme.breakpoints.down(600)} {
			padding: ${theme.spacing(0, 2)};
		}

        .MuiButtonBase-root {
            width: fit-content;

            svg {
                margin-right: ${theme.spacing(1)}px;
            }
        }    
    `}
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
`;

export const StyledSectionAlternate = styled(SectionAlternate)`
    .section-alternate__content {
        position: relative;
    }
    
    @media screen and (max-width: 1237px) {
        .section-alternate__content {
            height: 150px;
        }
    }
`;

export const HeroCover = styled.div`
    position: relative;
    min-height: 300px;
    max-height: 400px;
    background: url('/assets/hero-cover.png');
    background-size: cover;

    @media screen and (max-width: 1237px) {
        height: 175px;
    }    
`;

export const Profile = styled.div`
    display: flex;
    width: 100%;
    max-width: 1206px;

    position: absolute;
    top: -70px;
    left: 0;

    padding-left: 32px;

    @media screen and (max-width: 1237px) {
        padding-right: 40px;
    }
`;

export const Cover = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;

        ${theme.breakpoints.down(600)} {
            align-items: center;
            justify-content: center;
            flex-direction: column;
    
            .MuiButtonBase-root {
                margin-top: ${theme.spacing(2)}px;
            }
        }
    `}
`;

export const Avatar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const centeredAbsolutePosition = css`
    position: absolute;        
    margin: auto;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

export const AvatarImage = styled.div<{ hasAvatarUrl?: boolean, avatarColor?: string }>`
    ${({ theme, hasAvatarUrl = true, avatarColor = randomColor() }) => css`
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background: #c5c5c5;
        
        position: relative;
        
        ${!hasAvatarUrl && css`
        > img {
            display: none;
        }
        
        background: ${avatarColor};
        `}
        
        img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            border: 2px solid #fff;
        }

        label {
            align-items: center;
            justify-content: center;
            
            ${centeredAbsolutePosition};
        }

        .MuiCircularProgress-root {
            ${centeredAbsolutePosition};
        }
        
        ${theme.breakpoints.up('md')} {
            width: 200px;
            height: 200px;
            
            img {
                width: 200px;
                height: 200px;
            }
        }    

        ${theme.breakpoints.down('xs')} {
            width: 120px;
            height: 120px;
            
            img {
                width: 120px;
                height: 120px;
            }
        }
    `}
`;

export const Title = styled(Typography)`
    ${({ theme }) => css`
        font-size: ${theme.spacing(3)}px;
        margin-top: ${theme.spacing(2)}px;
        margin-left: ${theme.spacing(2)}px;

        a {
            display: flex;
            align-items: center;

            svg {
                margin-left: ${theme.spacing(1)}px;
            }
        }

        a:hover {
            text-decoration: underline;
        }

        ${theme.breakpoints.down(450)} {
            font-size: 16px;
            margin-left: 0;

            a > svg {
                margin-left: 0;
            }
        }
    `}
`;

export const ProfileName = styled(Typography)`
    font-size: 24px;
    font-weight: bold;

    a {
        display: flex;

        svg {
            margin-left: 8px;
        }
    }

    a:hover {
        text-decoration: underline;
    }

    ${({ theme }) => css`
        ${theme.breakpoints.down(450)} {
            font-size: 16px;
        }
    `}
`;

export const Followers = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: ${theme.spacing(3)}px;

        p {
            margin-bottom: ${theme.spacing(2)}px;
            max-width: 450px;
            font-size: 14px;
        }

        p + p {
            margin: 0 ${theme.spacing(2)}px;
        }
    `}
`;

export const BannerContent = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        width: 100%;
        height: 250px;
        min-height: 220px;
        
        background-color: ${theme.palette.divider};     

        > label {
            position: absolute;
            bottom: 16px;
            right: 60px;

            ${theme.breakpoints.down(530)} {
                top: 16px;
                bottom: 0;
            }
        }

        .MuiButtonBase-root {
            ${buttonProps};
        }

        ${theme.breakpoints.down('sm')} {
            min-height: 120px;
            height: 150px;
        }

        img {
            width: 100%;
        }

        svg {
            color: #000;
        }
    `}
`;

export const Content = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        width: fit-content;
        position: relative;
        
        margin-right: ${theme.spacing(6)}px;
        margin-top: -${theme.spacing(8)}px;
        
        ${theme.breakpoints.down(900)} {
            margin-top: -70px;
        }
        
        ${theme.breakpoints.down(750)} {
            margin-top: -100px;
        }
        
        ${theme.breakpoints.down(640)} {
            margin-top: -120px;
        }
        
        ${theme.breakpoints.down(600)} {
            flex-direction: column;
            margin-top: -120px;
        }
        
        ${theme.breakpoints.down(450)} {
            margin-top: -160px;
            padding-left: 0;
        }

        > label {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -55%);

            ${theme.breakpoints.down(400)} {
                top: 45%;
            }
            
            > span {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    `}
`;

export const AvatarContainer = styled.div<{ isHoverAvatar?: boolean }>`
    ${({ isHoverAvatar = false }) => css`
        bottom: -135px;

        ${isHoverAvatar && css`
            -webkit-filter: brightness(70%);
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            -o-transition: all 1s ease;
            -ms-transition: all 1s ease;
            transition: all 1s ease;        
        `}
    `}
`;

export const UserName = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        margin-bottom: ${theme.spacing(2)}px;
        color: ${theme.palette.text.primary};

        svg {
            margin-left: ${theme.spacing(1)}px;
        }

        ${theme.palette.type === 'light' && css`
            color: #000;
        `}
    `}
`;

export const Icon = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;

        width: ${theme.spacing(4)}px;
        height: ${theme.spacing(4)}px;
        padding: 6px;
        background: #898989;
        margin-right: ${theme.spacing(1)}px;
        border-radius: 50%;    
    
        svg {
            color: ${theme.palette.button.primary};
        }
    `}
`;