import React from 'react';
import { Section, SectionAlternate } from 'components/organisms';
import { Contact, FollowUs, Form, Hero } from './components';

const ContactPage = (): JSX.Element => (
	<div>
		<Hero />

		<Section>
			<Contact />
		</Section>

		<SectionAlternate>
			<Form />
		</SectionAlternate>

		<Section>
			<FollowUs />
		</Section>
	</div>
);

export default ContactPage;
