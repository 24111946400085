import React from 'react';
import styled, { css } from 'styled-components';
import { Box, CircularProgress, Typography } from '@material-ui/core';

const Wrapper = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 700px;
        
        .MuiCircularProgress-colorPrimary {
            color: ${theme.palette.text.primary};
        }
    `}
`;

const Loader = (): JSX.Element => {
    return (
        <Wrapper>
            <CircularProgress color="primary" />

            <Box mt={4}>
                <Typography variant="h3">Loading...</Typography>
            </Box>
        </Wrapper>
    );
}

export default Loader;