import React from 'react';

import { ConnectButton } from '..';

import * as S from './styles';

const Topbar = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
	return (
		<S.GTCOHeaderNavbar>
			<S.Container>
				<a href="https://iris-termite-e82.notion.site/EditTrax-WIKI-824dbe0144744143b43ee26f79f6c50a" target="_blank" rel="noopener noreferrer">
					<img src="/assets/edit-trax/et_new_logo.png" alt="" />
				</a>

				<ConnectButton />
			</S.Container>
		</S.GTCOHeaderNavbar>
	);
};

export default Topbar;
