import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, IconButton } from '@material-ui/core';
import { Facebook as FacebookIcon, Twitter as TwitterIcon, Instagram as InstagramIcon } from '@material-ui/icons';
import { SiDiscord } from 'react-icons/si';

import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
	map: {
		zIndex: 9,
	},
	icon: {
		fontSize: 24,
		color: theme.palette.text.primary,
	},
	socialIcon: {
		padding: 0,
		marginRight: theme.spacing(1),
		color: 'rgba(255,255,255,.6)',
		'&:hover': {
			background: 'transparent',
		},
		'&:last-child': {
			marginRight: 0,
		},
	},
}));

const FollowUs = ({ data, className, ...rest }: ViewComponentProps): JSX.Element => {
	const classes = useStyles();

	return (
		<div className={className} {...rest}>
			<div>
				<SectionHeader title="Follow Us" subtitleProps={{ variant: 'body1', color: 'textPrimary' }} data-aos="fade-up" align="left" />
				
				<List disablePadding>
					<ListItem disableGutters>
						<IconButton className={classes.socialIcon}>
							<a href="https://www.facebook.com/orbix360" target="_blank" rel="noreferrer">
								<FacebookIcon className={classes.icon} />
							</a>
						</IconButton>

						<IconButton className={classes.socialIcon}>
							<a href="https://instagram.com/orbix360">
								<InstagramIcon className={classes.icon} />
							</a>
						</IconButton>

						<IconButton className={classes.socialIcon}>
							<a href="https://twitter.com/orbix360" target="_blank" rel="noreferrer">
								<TwitterIcon className={classes.icon} />
							</a>
						</IconButton>

						<IconButton className={classes.socialIcon}>
							<a href="https://discord.com/invite/jejK5j3" target="_blank" rel="noreferrer">
								<SiDiscord className={classes.icon} />
							</a>
						</IconButton>
					</ListItem>
				</List>
			</div>
		</div>
	);
};

export default FollowUs;
