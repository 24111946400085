import React from 'react';

import { Main } from 'layouts';
import { Sandbox } from 'views';
import WithLayout from 'WithLayout';

export default function SandboxPage (): JSX.Element {
    document.title = 'Sandbox';

    return <WithLayout component={Sandbox} layout={Main} />;
}