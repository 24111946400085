import React from 'react';
import { Account } from 'views';
import { Main } from 'layouts';
import WithLayout from 'WithLayout';
import { handleTitleAndScrollToTop } from 'utils';

const AboutPage = (): JSX.Element => {
	handleTitleAndScrollToTop("Minter - Account");
	
	return (<WithLayout component={Account} layout={Main} />)
};

export default AboutPage;