import React, { useState, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { Paper } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import getTheme from 'theme';

import AOS from 'aos';
import Notifications from 'components/common/Notifications';

export const useDarkMode = () => {
	const [themeMode, setTheme] = useState('dark');
	const [mountedComponent, setMountedComponent] = useState(false);

	const setMode = (mode: string) => {
		window.localStorage.setItem('themeMode', mode);
		setTheme(mode);
	};

	const themeToggler = () => {
		themeMode === 'light' ? setMode('dark') : setMode('light');
	};

	useEffect(() => {
		const localTheme = window.localStorage.getItem('themeMode');
		localTheme ? setTheme(localTheme) : setMode('dark');
		setMountedComponent(true);
		AOS.refresh();
	}, []);

	useEffect(() => {
		AOS.refresh();
	}, [themeMode]);

	return [themeMode, themeToggler, mountedComponent];
};

interface Props {
	layout: any;
	component: any;
	// All other props
	[x: string]: any;
};

export default function WithLayout({ component: Component, layout: Layout, ...rest }: Props): JSX.Element {
	useEffect(() => {
		// Remove the server-side injected CSS.
		const jssStyles = document.querySelector('#jss-server-side');

		if (jssStyles && jssStyles.parentElement != null) {
			jssStyles.parentElement.removeChild(jssStyles);
		}

		AOS.init({
			once: true,
			delay: 50,
			duration: 500,
			easing: 'ease-in-out',
		});
	}, []);

	const [themeMode, themeToggler, mountedComponent] = useDarkMode();
	
	useEffect(() => {
		AOS.refresh();
	}, [mountedComponent]);

	const customTheme = getTheme(themeMode);

	return (
		<MuiThemeProvider theme={customTheme}>
			<ThemeProvider theme={customTheme}>
				<CssBaseline />
				
				<Paper elevation={0} style={{ borderRadius: 0 }}>
					<Layout themeMode={themeMode} themeToggler={themeToggler} {...rest}>
						<Component themeMode={themeMode} {...rest} />
					</Layout>
				</Paper>

				<Notifications />
			</ThemeProvider>
		</MuiThemeProvider>
	);
}