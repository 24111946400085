import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Typography } from '@material-ui/core';

import { useDispatch } from 'reducer';
import { SelectedFile } from 'reducer/slices/createNft';

import { MediaTypes } from 'components/organisms';
import { FilePreview } from 'views/CreateNFT/components';
import getMimeType, { isHTML } from 'utils/sanitise';

import * as S from './styles';

export default function FileUpload(): JSX.Element {
    const dispatch = useDispatch();

    const convertToMB = 1024 * 1024;
    const typesForDisplayImage = ['zip', 'glb'];
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const [file, setFile] = useState<any | null>(null);
    const [fileType, setFileType] = useState<string>('');
    const [filePreview, setFilePreview] = useState<SelectedFile | null>(null);
    const [selectedFile, setSelectedFile] = useState<SelectedFile | null>(null);

    const onFileChange = async (files: File[]) => {
        const file = files[0], title = file.name;

        // Read the file contents and file type and add it to the buffer
        const mimeType = file.type !== '' ? file.type : await getMimeType(file);
        const buffer = Buffer.from(await file.arrayBuffer());

        // set reader for preview
        const reader = new FileReader();
        
        reader.addEventListener('load', (e) => {
            console.log({ title, mimeType, file, buffer, reader: e.target?.result });
            console.log({ isHtml: isHTML(mimeType) });
            setFile({ title, mimeType, file, buffer, reader: e.target?.result });
        });

        reader.readAsDataURL(file);
    }

    const onDrop = useCallback(async (files: File[]) => {
        const file = files[0];

        if (file.size / convertToMB > 100) {
            alert("File size can't be larger than 100MB");

            return;
        }

        await onFileChange(files);

        const { name, type, size } = file, objectUrl = URL.createObjectURL(file);
        console.log(`name: ${name}, type: ${type}, size: ${size}`);
        console.log(`objectUrl: ${objectUrl}`);

        setFilePreview({ name, type, size, objectUrl });  
        setSelectedFile({ name, type, size, objectUrl });  
    }, [convertToMB, dispatch]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        maxSize: 100 * 1024 * 1024,
        accept: [
            'image/*',
            'video/*',
            'model/gltf-binary',
            'model/gltf+json',
            '.gltf',
            '.glb',
            '.zip'
        ]
    });

    return (
        <S.Container>
            <Grid container spacing={4} md={6}>
                <Grid item xs={12}>
                    <h1>The sandbox is a space in which you can drop a .zip of your project and see how it would behave when it will be minted</h1>
                </Grid>

                <Grid item xs={12} {...getRootProps()}>
                    <input {...getInputProps()} />

                    {/* { !selectedFile && ( */}
                    <S.UploadContainer>
                        <S.UploadContent>
                            <Typography variant="subtitle1" color="textPrimary" style={{ textAlign: 'center' }}>
                                <strong>Drag and Drop File</strong>
                            </Typography>

                            <S.UploadIcon src="/assets/drag-drop.svg" />

                            <S.UploadText>
                                <Typography variant="subtitle1" color="textPrimary">
                                    or <strong>browse media on your device</strong>
                                </Typography>
                            </S.UploadText>
                        </S.UploadContent>
                    </S.UploadContainer>
                    {/* )} */}
                </Grid>¨
            </Grid>

            { file && (
            <Grid container spacing={4} md={6}>
                <Grid item xs={12}>
                    <S.Preview>
                        <MediaTypes 
                            mimeType={file.mimeType} 
                            artifactUri={file.reader}
                            previewUri={file.reader}
                        />
                    </S.Preview>
                </Grid>
            </Grid>
            )}
        </S.Container>
    );
}