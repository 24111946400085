import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Divider, useMediaQuery } from '@material-ui/core';
import { Topbar, Footer, Sidebar } from './components';

import data from './data';

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
	},
}));

interface Props {
	children: ReactNode;
	themeToggler: Function;
	themeMode: string;
	branding: string;
};

const Main = ({ children, themeToggler, themeMode, branding }: Props): JSX.Element => {
	const classes = useStyles();

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	const pages = data;

	const [openSidebar, setOpenSidebar] = useState<boolean>(false);

	const handleSidebarOpen = (): void => {
		setOpenSidebar(true);
	};

	const handleSidebarClose = (): void => {
		setOpenSidebar(false);
	};

	const open = isMd ? false : openSidebar;

	return (
		<div className={clsx({[classes.root]: true})}>
			<Topbar onSidebarOpen={handleSidebarOpen} pages={pages} themeMode={themeMode} themeToggler={themeToggler} branding={branding}/>

			<Sidebar onClose={handleSidebarClose} open={open} themeMode={themeMode} themeToggler={themeToggler} variant="temporary" pages={pages} />

			<main>
				<Divider />

				{children}
			</main>

			<Footer themeMode={themeMode} pages={pages} themeToggler={themeToggler} />
		</div>
	);
};

export default Main;
