import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import dateFormat, { masks } from "dateformat";

type FirebaseProp = 'orbix360-minter-dev' | 'orbix360-minter-qa' | 'orbix360-minter-prod';
let firebaseProjectId: FirebaseProp = 'orbix360-minter-qa';

if (typeof window !== 'undefined') {
  if (window.location.href.includes('minter-qa.orbix360')) {
    firebaseProjectId = 'orbix360-minter-qa';
  } else if (window.location.href.includes('minter.orbix360')) {
    firebaseProjectId = 'orbix360-minter-prod';
  } else {
    firebaseProjectId = 'orbix360-minter-qa';
  }
}

export interface IpfsContent {
  cid: string;
  size: number;
  ipfsUri: string;
  url: string;
  publicGatewayUrl: string;
}

export interface IpfsResponse extends IpfsContent {
  thumbnail: IpfsContent;
  display: IpfsContent;
}

export async function uploadIPFSJSON(api: string, data: any) {
  console.log(`in uploadIPFSJSON, api: ${api}`)
  return axios.post<IpfsResponse>(`${api}/ipfs-json-upload`, data);
}

export async function uploadIPFSFile(api: string, file: File) {
  console.log(`in uploadIPFSFile, api: ${api}`)
  const formData = new FormData();
  formData.append('file', file);
  return axios.post<IpfsResponse>(`${api}/ipfs-file-upload`, formData);
}

export async function uploadIPFSFileHuge(api: string, file: File, createThumbnail: boolean) {
  // instanciate the module with a settings object
  console.log(`in uploadIPFSFileHuge, api: ${api}, file.name: ${file.name}`)
  let uuid = `${uuidv4()}`;
  const now = new Date();

// Basic usage
  let formattedDate = dateFormat(now, "yyyy-mm-dd");
// Saturday, June 9th, 2007, 5:46:21 PM
  let filePath = `tokens/${formattedDate}/${uuid}/${file.name}`
  console.log(`in uploadIPFSFileHuge, filePath: ${filePath}`)
  const formData = new FormData();
  formData.append('filepath', filePath);
  formData.append('filetype', file.type);
  const resumableUploadResponse = await axios.post<string>(`${api}/create_resumable_upload?p=${filePath}`, formData);
  const resumableUploadURI = resumableUploadResponse.data
  console.log(`in uploadIPFSFileHuge, resumableUploadURI: ${resumableUploadURI}`)
  const fileData = new FormData();
  fileData.append('file', file)
  const uploadResponse = await axios.put(resumableUploadURI,
    file,
    {
      headers: {
        "Content-Type": file.type
      }
    })
  console.log(`got uploadResponse: ${JSON.stringify(uploadResponse)}`)
  const fileUrl = `https://storage.googleapis.com/${firebaseProjectId}-uploads/${filePath}`
  const fileUrlData = new FormData();
  fileUrlData.append('fileurl', fileUrl);
  fileUrlData.append('filetype', file.type);
  const urlUploadResponse = await axios.post<IpfsResponse>(`${api}/ipfs-file-upload-from-url?t=${file.type}&n=${createThumbnail}&u=${fileUrl}`, fileUrlData);
  console.log(`in uploadIPFSFileHuge, urlUploadResponse: ${JSON.stringify(urlUploadResponse)}`);
  return urlUploadResponse;

}

export async function uploadIPFSImageWithThumbnail(api: string, file: File) {
  console.log(`in uploadIPFSImageWithThumbnail, api: ${api}`)
  const formData = new FormData();
  formData.append('file', file);
  console.log(`in uploadIPFSImageWithThumbnail`)
  return axios.post<IpfsResponse>(
    `${api}/ipfs-image-with-thumbnail-upload`,
    formData
  );
}

// URI Utils

export function isIpfsUri(uri: string) {
  return /^ipfs:\/\/.+/.test(uri);
}

export function ipfsUriToCid(uri: string) {
  const baseRegex = /^ipfs:\/\//;
  const ipfsRegex = new RegExp(baseRegex.source + '.+');
  if (ipfsRegex.test(uri)) {
    return uri.replace(baseRegex, '');
  }
  return null;
}

export type IpfsGatewayConfig = { ipfsGateway: string };
export function ipfsUriToGatewayUrl(config: IpfsGatewayConfig, uri: string) {
  const urlParams = new URLSearchParams(window.location.search);
  const minterParam = urlParams.get('minter');
  const minter = minterParam ? minterParam : 'orbix360';

  const ipfsGateway = minter === 'orbix360' ? config.ipfsGateway : 'https://ipfs.io';
  const cid = ipfsUriToCid(uri);

  return cid ? `${ipfsGateway}/ipfs/${cid}` : uri;
}

export function uriToCid(uri: string) {
  const ipfsUriCid = ipfsUriToCid(uri);
  if (ipfsUriCid) {
    return ipfsUriCid;
  }
  const baseRegex = /^https:\/\/.*\/ipfs\//;
  const httpRegex = new RegExp(baseRegex.source + '.+');
  if (httpRegex.test(uri)) {
    return uri.replace(baseRegex, '');
  }
  return null;
}
