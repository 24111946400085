import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';

import formatNumber from 'utils/formatNumber';

import { findByContractAndTokenId } from 'services/tokens';
import { createCustomContract } from 'services/custom-contracts';

import { useDispatch, useSelector } from 'reducer';
import { notifyFulfilled, notifyPending } from 'reducer/slices/notificationsActions';

import { createFaucetOnDemandContract } from 'lib/nfts/actions';
import { ipfsUriToGatewayUrl } from 'lib/util/ipfs';
import useAuth from 'hooks/auth';

import { Loader } from 'components/atoms';
import { HandleFileType } from 'components/organisms';

import config from '../../../../config.json';

import * as S from './styles';
import { slugify } from 'utils';

export interface GeneratorsTokens {
    tokenId: number;
    title: string
    creator: string;
    contractAddress: string
    artifactUri: string;
    displayUri: string;
    metadata: TokenMetadata;        
}

export default function Form() {
    const { user } = useAuth(), system = useSelector(s => s.system), dispatch = useDispatch();

    const contractsTypeObject = {
        'mint-on-demand': {
            type: 'mint-on-demand',
            text: 'Mint on demand',
            description: 'mint on demand contract'
        },
        'auction': {
            type: 'auction',
            text: 'Auctions',
            description: 'auctions contract'
        },
    };

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingApi, setIsLoadingApi] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);

    const [src, setSrc] = useState<string>('');
    const [contractType, setContractType] = useState<string>('mint-on-demand');
    const [description, setDescription] = useState<string>('');
    const [slug, setSlug] = useState<string>('');
    const [info, setInfo] = useState<string>('');
    const [limit, setLimit] = useState<number>(1);
    const [price, setPrice] = useState<string>('');
    const [token, setToken] = useState<Token>();

    useEffect(() => {
        setIsLoadingApi(true);

        if (!token) {
            (async () => {
                try {
                    const tokenId = localStorage.getItem('lastTokenId')!;
                    const token = await findByContractAndTokenId(system.config.contracts.nftFaucet, tokenId);
                    setToken(token);
    
                    if (token) {
                        setSrc(ipfsUriToGatewayUrl(config, token?.artifactUri));
                    }
                } catch (error) {
                    alert("Something went wrong. Please try again");                
                }
    
                setIsLoadingApi(false);
            })();
        }
    }, []);


    async function handleSubmit() {
        if (!token) {
            return false;
        }

        setIsLoading(true);

        setProgress(15);
        const { artifactUri, title, metadata } = token;
        const name = title;
        let newContractAddress = '';

        try {
            // @ts-ignore
            const op = await createFaucetOnDemandContract(system, name, description, 1, limit);
            const pendingMessage = `Creating new on demand contract ${name}`;
                        
            dispatch(notifyPending('requestId', pendingMessage));
            await op.confirmation();

            setProgress(30);
            
            const { address } = await op.contract();
            console.log(`got new contract address: ${address}`);
            setProgress(45);
            
            if (address) {
                newContractAddress = address;
                const fulfilledMessage = `Created new collection ${name} (${address})`;
                dispatch(notifyFulfilled('requestId', fulfilledMessage));
                setProgress(75);
                
                // TODO: Poll for contract availability on indexer
                // dispatch(getWalletAssetContractsQuery());
                //return { name, address };
                
                const customContract: CustomContract = {
                    contractAddress: newContractAddress,
                    contractType,
                    price: +price,
                    limit,
                    description,
                    info,
                    title,
                    slug,
                    owner: user.wallet,
                    metadata,
                    artifactUri
                };

                setProgress(85);
                
                const result = await createCustomContract(customContract);
                console.log({ createCustomContract_result: result });

                setProgress(100);

                setTimeout(() => {
                    alert("Custom contract successful created!");
                    window.location.href = `/contracts/${result.object._id}`;
                }, 200);
            }
        } catch (e: any) {
            console.log(`exception creating contract: ${e.message}`);
            console.log(`exception creating contract: ${e.stackTrace}`);
            setIsLoading(false);
            alert("Error creating contract. Sorry, please try again.");
            return false;
        }

        setIsLoading(false);
    }

    function handlePrice(value: string) {
        const formattedPrice = formatNumber(value, 4);

        if (!formattedPrice) {
            alert("Invalid price");
            return false;
        }

        setPrice(formattedPrice);
    }

    return (
		<S.Container>
        { !isLoadingApi ? (
			<Grid container spacing={4}>                
				<Grid item xs={12} style={{ display: 'none' }}>
                    <Typography variant="h6" color="textPrimary">
						<strong>Contract Type*</strong>
					</Typography>

                    <FormControl fullWidth >
                        <InputLabel style={{ marginLeft: 16 }}>Select the contract type*</InputLabel>

                        <Select
                            value={'mint-on-demand'}
                            label="Select the contract type*"
                            onChange={(e) => setContractType(e.target.value as string)}
                            variant='outlined'
                            fullWidth 
                        >
                        { Object.values(contractsTypeObject).map((contracts) => (
                            <MenuItem key={`contracts-${contracts.type}`} value={contracts.type}>{contracts.text}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </Grid>

                { isLoading && (
				<Grid item xs={12}>
                    <Box width="100%" mr={1}>
                        <Typography variant="body2" color="textPrimary">Loading...</Typography>
                    </Box>

                    <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                            <LinearProgress variant="determinate" value={progress} />
                        </Box>

                        <Box minWidth={35}>
                            <Typography variant="body2" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
                        </Box>
                    </Box>
				</Grid>
                )}

                { token && (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="textPrimary">
                            <strong>{token.title}</strong>
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box mt={5}>
                            <HandleFileType 
                                src={src}
                                formats={token.metadata.formats}
                                artifactUri={token.artifactUri}
                            />
                        </Box>
                    </Grid>
                </>
                )}

				<Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">
						<strong>Slug</strong>
					</Typography>

					<TextField
						placeholder="Slug"
						variant="outlined"
						name="slug"
                        size="medium"
						fullWidth
						value={slug}
                        onChange={(e) => setSlug(slugify(e.target.value))}
					/>
                </Grid>

				<Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">
						<strong>Description</strong>
					</Typography>

					<TextField
						placeholder="Your description"
						variant="outlined"
						name="description"
						multiline
						rows={6}
						fullWidth
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
                </Grid>

				<Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">
						<strong>Info</strong>
					</Typography>

					<TextField
						placeholder="Contract info"
						variant="outlined"
						name="info"
						fullWidth
						multiline
						rows={6}
						value={info}
						onChange={(e) => setInfo(e.target.value)}
					/>
                </Grid>
                
                { contractType === 'mint-on-demand' && (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="textPrimary">
                            <strong>Price*</strong>
                        </Typography>

                        <TextField
                            placeholder="Price"
                            variant="outlined"
                            size="medium"
                            name="fullname"
                            fullWidth
                            type="text"
                            value={price}
                            onChange={(e) => handlePrice(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" color="textPrimary">
                            <strong>Limit*</strong>
                        </Typography>

                        <TextField
                            placeholder="Limit"
                            variant="outlined"
                            size="medium"
                            name="fullname"
                            fullWidth
                            type="text"
                            value={limit}
                            onChange={(e) => setLimit(+e.target.value)}
                        />
                    </Grid>
                </>
                )}

				<Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <Button 
                            variant="contained" 
                            type="button" 
                            color="primary" 
                            size="large" 
                            onClick={handleSubmit} 
                            disabled={isLoading || !contractType.length}
                        >
                            Create Custom Contract
                        </Button>
                    </Box>
				</Grid>
            </Grid>
        ) : <Loader /> }
        </S.Container>
    );
}
