import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useLocation from 'wouter/use-location';
import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';

import { useSelector } from 'reducer';

import { findByContractAndTokenId } from 'services/tokens';

import { Topbar } from 'views/EditTraxMarketplace/components';

import { LeftSide, RightSide, Tabs } from './components';
import { Loader } from 'components/atoms';
import { Transaction } from './components/History';
import { getContractByTokenId } from 'services/custom-contracts';
import { Params } from 'lib/service/tzkt';
import useAuth from 'hooks/auth';

const MuiGrid = styled(Grid)`
  ${({ theme }) => css`  
    ${theme.breakpoints.up(911)} {
      min-height: calc(100vh - 100px);
    }
  `}
`;

export default function CollectionItemView({ contractAddress, tokenId }: ViewComponentProps) {
  const { system, status, marketplace: state } = useSelector(s => s);
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [contract, setContract] = useState<Contracts>();
  const [token, setToken] = useState<Token>();
	const [ownedToken, setOwnedToken] = useState<string>('');
  const [transactions, setTransactions] = useState<Transaction[]>();

  const [location, _] = useLocation();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const token = await findByContractAndTokenId(contractAddress, tokenId);
        setToken(token);

        if (token.isGenerator) {          
          const { object: contract } = await getContractByTokenId(token.tokenId);

          if (contract) {
            setContract(contract);

            let params: Params = {
              limit: '1',
              offset: '0',
              'value.eq': user.wallet
            };
  
            const tzktResponse = await system.tzkt.getContractBigMapKeys(contract.contractAddress, 'ledger', params);
            const ownedTokensLength = tzktResponse.length;
  
            if (ownedTokensLength > 0) {
              setOwnedToken(tzktResponse[0].key)
            }
          }
        }
  
        const creationTransaction: Transaction = {
          id: token.tokenId.toString(),
          timestamp: token.createdDate.toString(),
          sender: {
            address: (token.creator as User).wallet,
          },
          target: {
            address: (token.creator as User).wallet,
          },
          parameter: {
            entrypoint: "created",
            value: {
              price: '0',
              amount: '1'
            }
          },
        }; 
  
        const { data } = await axios.get(`${system.config.tzkt.api}/v1/operations/transactions?target=${state.marketplace.address}&hasInternals=true&parameter.sale_token.token_id=${token.tokenId}`);
        const formattedData = data.sort((a: any, b: any) => a.timestamp < b.timestamp ? 1 : (b.timestamp < a.timestamp ? -1 : 0));
        setTransactions([...[creationTransaction], ...formattedData]);        
      } catch (error) {
        console.log({ error });
        setIsLoading(false);
      }

      setIsLoading(false);
    })();
  }, [status]);

  window.scrollTo(0, 0);

  document.title = `Token Detail - ${token?.title || tokenId}`;

  return (
    <>
      { location.includes('custom-token') && <Topbar />}

      { isLoading && <Loader /> }

      { !isLoading && token && (
      <MuiGrid container>
        <Grid item xs={12} md={6}>
          <LeftSide config={system.config} token={token} ownedToken={ownedToken} contractAddress={token.isGenerator ? contract?.contractAddress : contractAddress} />
        </Grid>

        <Grid item xs={12} md={6}>
          <RightSide token={token} system={system} contract={contractAddress} tokenId={tokenId} />
        </Grid>
        
        <Grid item xs={12} md={12}>
          <Tabs token={token} system={system} transactions={transactions} />
        </Grid>
      </MuiGrid>
      )}
    </>
  );
}
