import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Wrapper = styled(Box)<{ showLastButton?: boolean}>`
    ${({ theme, showLastButton = false }) => css`
        margin-top: ${theme.spacing(2)}px;

        .MuiPaginationItem-textPrimary.Mui-selected {
            color: #fff;
        }

        .MuiPaginationItem-root {
            color: ${theme.palette.text.secondary};
        }

        ${!showLastButton && css`
            .MuiPagination-ul {
                li:nth-last-child(2) {
                    display: none;
                }
            }            
        `}
    `}  
`;
