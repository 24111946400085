import React, { createRef, useCallback, useState, ChangeEvent } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react';
import { useSelector, useDispatch } from '../../reducer';
import { readFileAsDataUrlAction } from '../../reducer/async/actions';
import { updateDisplayImageFile, SelectedFile } from '../../reducer/slices/createNft';

export function FilePreview({ file }: { file: SelectedFile }) {
  if (/^image\/.*/.test(file.type)) {
    return (<Image src={file.objectUrl} width="100%" height="100%" />);
  }

  if (/^video\/.*/.test(file.type)) {
    const canvasRef = createRef<HTMLCanvasElement>();
    return (
      <>
        <video
          controls
          muted
          onLoadedData={e => {
            const canvas = canvasRef.current;
            
            if (!canvas) {
              return console.error('`canvasRef` current element is null');
            }

            const video = e.currentTarget;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            
            var canvasContext = canvas.getContext('2d');
            
            if (!canvasContext) {
              return console.error('`2d` canvas context not supported');
            }
            
            canvasContext.drawImage(video, 0, 0);
            const type = 'image/png';
            
            canvas.toBlob(blob => {
              if (!blob) {
                return console.error('Could not convert canvas to blob');
              }

              // dispatch(updateDisplayImageFile({ objectUrl: URL.createObjectURL(blob), name: 'foo', size: blob.size, type: blob.type }));
            }, type);
          }}
        >
          <source src={file.objectUrl} type={file.type} />
        </video>

        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </>
    );
  }

  if (file.type === 'model/gltf+json' || file.type === 'model/gltf-binary') {
    return (
      <>
        <model-viewer camera-controls auto-rotate data-js-focus-visible src={file.objectUrl} class="upload-preview" />
      </>
    );
  }

  if (file.type === 'application/zip') {
    return (
      <>
        <div>zip: {file.name}</div>
      </>
    );
  }

  if (file.type === 'application/json') {
    return (
      <>
        <div>json: {file.name}</div>
      </>
    );
  }

  return null;
}

export default function FileUpload() {
  const state = useSelector(s => s.createNft);

  const [fileType, setFileType] = useState<string>('');
  const [filePreview, setFilePreview] = useState<SelectedFile | null>(null);

  const dispatch = useDispatch();

  const onDrop = useCallback((files: File[]) => { 
    dispatch(readFileAsDataUrlAction({ ns: 'createNft', file: files[0] }));
  }, [dispatch]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const file = event.target.files;

    if (file){
      const { type } = file[0];
      
      if (type.includes('zip')) {
        setFileType('zip');
      } else {
        setFilePreview(null);
        setFileType('');
      }
    }
  }
  
  async function handlePreviewChange(event: ChangeEvent<HTMLInputElement>) {
		const file = event.target.files;

    if (file){
      const { name, type, size } = file[0], objectUrl = URL.createObjectURL(file[0]);

      dispatch(updateDisplayImageFile({ name, type, size, objectUrl }));        
      setFilePreview({ name, type, size, objectUrl });
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 30 * 1024 * 1024,
    accept: [
      'image/*',
      'video/*',
      'model/gltf-binary',
      'model/gltf+json',
      '.gltf',
      '.glb',
      '.zip',
      '.json'
    ]
  });

  return (
    <Flex align="center" flexDir="column" width="100%" flex="1" pt={{ base: 2, md: 12 }}>
      <Heading size="lg" paddingBottom={8} textAlign="center">
        Upload your file
      </Heading>

      <Text fontSize="xs" color="brand.blue" fontFamily="mono" textAlign="center" pb={4}>
        JPG, PNG, GIF, WEBP, SVG, MP4, WebM, Ogg, Gltf, Glb, Zip. Max size 9 MB
      </Text>
    
      <Flex borderStyle="dashed" borderWidth="2px" borderColor="brand.lightBlue" borderRadius="3px" width="100%" justify="center" align="center" {...getRootProps()}>
        <Box as="input" {...getInputProps({ onChange: handleChange })}   />
        {state.selectedFile?.objectUrl ? (
          <Box p={4}>
            <Flex justify="center" align="center" maxWidth="400px" maxHeight="400px" overflow="hidden">
              <FilePreview file={state.selectedFile} />
            </Flex>
          </Box>
        ) : (
          <Flex borderColor="white" borderWidth="1px" flexDir="column" align="center" py={24} bg="brand.brightGray" flex="1">
            <Text fontSize={20} textAlign="center" paddingX={4}>
              Click or drag file to this area to upload
            </Text>

            <Text fontSize={18} color="brand.gray">
              Support for single file
            </Text>
          </Flex>
        )}
      </Flex>

      { fileType === 'zip' && (
      <Flex borderStyle="dashed" borderWidth="2px" borderColor="brand.lightBlue" borderRadius="3px" width="100%" justify="center" align="center" marginTop="16px">
        { filePreview ? (
          <Box p={4}>
            <Flex justify="center" align="center" maxWidth="400px" maxHeight="400px" overflow="hidden">
              <FilePreview file={filePreview} />
            </Flex>
          </Box>
        ) : (
          <>
            <input accept="image/*" id="zip-display-image" multiple type="file" hidden onChange={handlePreviewChange} />

            <label htmlFor="zip-display-image" style={{ width: '100%' }}>
              <Flex borderColor="white" borderWidth="1px" flexDir="column" align="center" py={24} bg="brand.brightGray" flex="1">
                <Text fontSize={20} textAlign="center" paddingX={4}>
                  Add a display image for zip files
                </Text>
              </Flex>          
            </label>
          </>
        )}
      </Flex>
      )}
    </Flex>
  );
}
