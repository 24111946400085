import { Grid } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        padding: 0 0 8px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        background: ${theme.palette.button.primary};

        ${theme.breakpoints.down(450)} {
            .MuiGrid-spacing-xs-4 {
                max-width: 100%;
            }
        }

        .MuiDivider-root {
            background-color: #cacaca;
        }
    `}
`;

export const FooterContainer = styled(Grid)`
    ${({ theme }) => css`    
        width: 100%;
        margin: 0 auto;
		padding: ${theme.spacing(0, 3)};

        @media screen and (max-width: 600px) {
            padding: 0;
        }

        .MuiGrid-spacing-xs-4 {
            width: 100%;
        }
    `}
`;

export const Container = styled(Grid)`
    ${({ theme }) => css`
        margin: 0;
        
        ${theme.breakpoints.down(450)} {
            flex-direction: column;
        }

        color: #dbdbdb;
    `}
`;
