import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { HeroShaped, Map } from 'components/organisms';

const useStyles = makeStyles(theme => ({
	mailText: {
		color: theme.palette.text.primary,
		textDecoration: 'underline',
	},
	map: {
		zIndex: 9,
	},
	icon: {
		background: 'transparent',
		borderRadius: 0,
	},
}));

const Contact = ({ data, className, ...rest }: ViewComponentProps): JSX.Element => {
	const classes = useStyles();

	const email = (
		<a href="mailto:contact@orbix360.com" className={classes.mailText}>contact@orbix360.com</a>
	);	

	return (
		<div className={className} {...rest}>
			<SectionHeader
				title="Contact us"
				subtitle="Do you have questions or want to discuss a partnership? Do you want to be part of a team of innovators reinventing media?"
				subtitleProps={{ variant: 'body1', color: 'textPrimary' }}
				data-aos="fade-up"
				align="left"
			/>

			<List disablePadding>
				<ListItem disableGutters data-aos="fade-up">
					<ListItemAvatar>
						<Avatar
							src="https://assets.maccarianagency.com/the-front/illustrations/contact-icon-mail.png"
							srcSet="https://assets.maccarianagency.com/the-front/illustrations/contact-icon-mail@2x.png 2x"
							className={classes.icon}
						/>
					</ListItemAvatar>

					<ListItemText
						primary="Email"
						secondary={email}
						primaryTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }}
						secondaryTypographyProps={{ variant: 'subtitle1', color: 'textPrimary' }}
					/>
				</ListItem>

				<ListItem disableGutters data-aos="fade-up">
					<ListItemAvatar>
						<Avatar
							src="https://assets.maccarianagency.com/the-front/illustrations/contact-icon-pin.png"
							srcSet="https://assets.maccarianagency.com/the-front/illustrations/contact-icon-pin@2x.png 2x"
							className={classes.icon}
						/>
					</ListItemAvatar>

					<ListItemText
						primary="Mailing Address"
						secondary="22 North 6th Street #9E, Brooklyn, NY 11249"
						primaryTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }}
						secondaryTypographyProps={{ variant: 'subtitle1', color: 'textPrimary' }}
					/>
				</ListItem>
			</List>
		</div>
	);
};
export default Contact;