import React from 'react';
import { Faq } from 'views';
import { Main } from 'layouts';
import WithLayout from 'WithLayout';
import { handleTitleAndScrollToTop } from 'utils';

const FaqPage = (): JSX.Element => {
	handleTitleAndScrollToTop("Minter - Faq");
	
	return (<WithLayout component={Faq} layout={Main} />)
};

export default FaqPage;