import React from 'react';
import { About } from 'views';
import { Main } from 'layouts';
import WithLayout from 'WithLayout';
import { handleTitleAndScrollToTop } from 'utils';

const AboutPage = (): JSX.Element => {
	handleTitleAndScrollToTop("Minter - About");
	
	return (<WithLayout component={About} layout={Main} />)
};

export default AboutPage;