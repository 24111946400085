import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		height: '100%',
	},
	dBlock: {
		display: 'block',
	},
}));

/**
 * Component to display the images
 *
 * @param {Object} propsSabe
 */
const Image = React.forwardRef<HTMLImageElement, ImageProps>(({ src, srcSet, alt = '...', lazy = true, lazyProps = { width: 'auto', height: 'auto' }, className, ...rest }: ImageProps, ref) => {
	const classes = useStyles();

	if (lazy) {
		return (
			<LazyLoadImage className={clsx('image', classes.root, classes.dBlock, className)} alt={alt} src={src} srcSet={srcSet} effect="opacity" {...lazyProps} {...rest} />
		);
	}

	return (<img className={clsx('image', classes.root, className)} ref={ref} alt={alt} src={src} srcSet={srcSet} {...rest} />);
});

export default Image;
