import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;    

    @media (min-width: 1200px) {
        max-width: 1080px;
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 576px) {
        max-width: 540px;
    }
`;

export const SectionContent = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;  
`;

export const TitleWrap = styled.div`
    text-align: center;  
`;

export const SectionTitle = styled.h2`
    margin-bottom: 30px;  
    color: #212529;
    
    font-weight: 300;
    font-size: 36px; 
    line-height: 1.2em;   
`;
