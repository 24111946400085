import { Box, Button, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        section {
            padding-bottom: ${theme.spacing(2)}px;
        }

        ${theme.breakpoints.down(960)} {
            section {
                padding-top: 0 !important;
            }
        }

        ${theme.palette.type === "dark" && css`
            .MuiButton-containedPrimary {
                color: #fff !important;
            }

            .MuiPaper-elevation1 {
                box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 40%), 0px 1px 1px 0px rgb(0 0 0 / 44%), 0px 1px 3px 0px rgb(0 0 0 / 40%);
            }        
        `}

        .MuiLink-underlineHover:hover {
            color: ${theme.palette.text.primary};
        }
        
        .MuiFab-primary {
            color: ${theme.palette.button.primary};
            background: ${theme.palette.text.primary};
        }
    `}
`;

export const Share = styled(Box)`
    display: flex;
    flex-direction: column;

    margin: 16px 0;
`;

export const ShareButton = styled.div`
    ${({ theme }) => css`
        margin-right: 8px;

        circle {
            fill: ${theme.palette.type === 'dark' ? '#444' : '#898989'};
        }    
    `}
`;

export const AuthorName = styled(Typography)`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        margin-top: 12px;

        svg {
            margin-right: 8px;
            color: ${theme.palette.text.primary};
        }    
    `}
`;

export const CancelButton = styled(Button)`
    ${({ theme }) => css`
        border-color: ${theme.palette.error.main};
        background: ${theme.palette.common.white};
        color: ${theme.palette.error.main};
        margin-left: ${theme.spacing(2)}px;
        
        &:hover {
            background-color: alpha(${theme.palette.error.main}, 0.04);
        }
    `}
`;

export const Tags = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        padding-top: ${theme.spacing(1)}px;    

        a {
            text-transform: capitalize;
        }
    `}
`;

export const Price = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        align-items: center;

        color: ${theme.palette.text.primary};

        svg {
            width: 14px;
            height: 14px;
        }
    `}
`;
