import React, { FC } from 'react';

import { AuthProvider } from './auth';

const AppProvider: FC = ({ children }) => {
	return (
		<>
			<AuthProvider>
				{ children }
			</AuthProvider>
		</>
	);
}

export default AppProvider;