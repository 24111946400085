export const team = [
	{
		title: 'CTO / Co-Founder',
		authorPhoto: {
			src: 'https://orbix360.com/images/team_img1.jpg',
			srcSet: 'https://orbix360.com/images/team_img1.jpg',
		},
		authorName: 'Leonardo Malave',
		description: 'Leo has 20 years of technology consulting and web development experience at leading agencies such as Sapient, Razorfish, and Huge. He holds a BS in Electrical Engineering from MIT and an MBA from NYU Stern.',
	},
	{
		title: 'CEO / Co-Founder',
		authorPhoto: {
			src: 'https://orbix360.com/images/team_img3.jpg',
			srcSet: 'https://orbix360.com/images/team_img3.jpg',
		},
		authorName: 'Jake Bramhall',
		description: 'Jake is a 30-year experienced, business-focused IT professional with broad range of management acumen and an MAE degree from Princeton University.',
	},
	{
		title: 'CPO / Co-Founder',
		authorPhoto: {
			src: 'https://orbix360.com/images/team_img4.jpg',
			srcSet: 'https://orbix360.com/images/team_img4.jpg',
		},
		authorName: 'James Prucey',
		description: 'James is a game designer and producer with several games on the Apple app store, and he sets the Orbix360º product feature direction.',
	},
];

export const companies = [
	{
		name: 'Airbnb',
		logo: 'https://assets.maccarianagency.com/the-front/logos/airbnb.svg',
	},
	{
		name: 'Coinbase',
		logo: 'https://assets.maccarianagency.com/the-front/logos/coinbase.svg',
	},
	{
		name: 'Dribbble',
		logo: 'https://assets.maccarianagency.com/the-front/logos/dribbble.svg',
	},
];

export const mapData = [
	{
		location: {
			y: 30.797400,
			x: -86.556390,
			address: '22 North 6th Street #9E, Brooklyn, NY 11249',
		},
	},
];

export const gallery = [
	{
		image: {
			src: 'https://storage.googleapis.com/orbix360-prod-images/users/XQYqneGUzOcaqd5bH5A3w1GkNN63/4971814002360320/thumb_sala-e-jantarsmartphonejpg-1594212002282.jpg',
			srcSet: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1@2x.jpg 2x',
		},
		title: 'gallery',
		cols: 1,
	},
	{
		image: {
			src: 'https://storage.googleapis.com/orbix360-prod-images/users/THjCrx8595crMOqAFYnlOJKllXh1/6148719644770304/thumb_street-view-360-1jpg-1613585812682.jpg',
			srcSet: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery3@2x.jpg 2x',
		},
		title: 'gallery',
		cols: 3,
	},
	{
		image: {
			src: 'https://storage.googleapis.com/orbix360-prod-images/users/kmUYieF134SdtJ1aB53ihsOHvf03/6250836954972160/thumb_bcirculacaojpg-1610662815141.jpg',
			srcSet: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery2@2x.jpg 2x',
		},
		title: 'gallery',
		cols: 3,
	},
	{
		image: {
			src: 'https://images.ctfassets.net/jrspgrzel116/1MtWA4sdlCskmAswoA6uWU/daf99a3803e1be95a56b3c06002151e6/image-5.png',
			srcSet: 'https://assets.maccarianagency.com/the-front/photos/coworking/place3@2x.jpg 2x',
		},
		title: 'gallery',
		cols: 1,
	},
];
