/* eslint-disable no-redeclare */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import parse from 'csv-parse/lib/sync';
import * as t from 'io-ts';

import { validateCreateNftForm } from '../validators/createNft';
import { SelectedFile, updateActiveStep } from '../slices/createNft';
import { notifyPending, notifyFulfilled } from '../slices/notificationsActions';

import { createAssetContract, createFaucetOnDemandContract, mintTokens, transferToken, listTokenForSale, cancelTokenSale, cancelTokenSaleLegacy, buyToken, buyTokenLegacy } from '../../lib/nfts/actions';
import { uploadIPFSFileHuge } from '../../lib/util/ipfs';
import { Nft, NftMetadata } from '../../lib/nfts/decoders';
import { SystemWithToolkit, SystemWithWallet } from '../../lib/system';

import { getContractNftsQuery, getWalletAssetContractsQuery } from './queries';
import { ErrorKind, RejectValue } from './errors';
import { connectWallet } from './wallet';
import { State } from '..';

import api from 'services/api';
import { addTokenForSale, burnToken, cancelSale, updateTokenAfterSale } from 'services/tokens';
import { playerConfigs } from '../../views/EditTrax/data';
import { addTransaction } from 'services/transactionsHistory';

const config = require('../../config.json')

type FirebaseProp = 'orbix360-minter-dev' | 'orbix360-minter-qa' | 'orbix360-minter-prod';
let firebaseProjectId: FirebaseProp = 'orbix360-minter-qa';

if (typeof window !== 'undefined') {
  if (window.location.href.includes('minter-qa.orbix360')) {
    firebaseProjectId = 'orbix360-minter-qa';
  } else if (window.location.href.includes('minter.orbix360')) {
    firebaseProjectId = 'orbix360-minter-prod';
  } else {
    firebaseProjectId = 'orbix360-minter-dev';
  }
}

type Options = {
  state: State;
  rejectValue: RejectValue;
};

export const readFileAsDataUrlAction = createAsyncThunk<
  { ns: string; result: SelectedFile },
  { ns: string; file: File },
  Options
>('action/readFileAsDataUrl', async ({ ns, file }, { rejectWithValue }) => {
  const readFile = new Promise<{ ns: string; result: SelectedFile }>(
    (resolve, reject) => {
      let { name, type, size } = file;
      const reader = new FileReader();

      if (!type) {
        if (name.substr(-4) === '.glb') {
          type = 'model/gltf-binary';
        }
        if (name.substr(-5) === '.gltf') {
          type = 'model/gltf+json';
        }
      }

      reader.onload = e => {
        const buffer = e.target?.result;
        if (!buffer || !(buffer instanceof ArrayBuffer)) {
          return reject();
        }
        const blob = new Blob([new Uint8Array(buffer)], { type });
        const objectUrl = window.URL.createObjectURL(blob);
        return resolve({ ns, result: { objectUrl, name, type, size } });
      };

      reader.readAsArrayBuffer(file);
    }
  );

  try {
    return await readFile;
  } catch (e) {
    return rejectWithValue({
      kind: ErrorKind.UnknownError,
      message: 'Could not read file'
    });
  }
});

export const createAssetContractAction = createAsyncThunk<
  { name: string; address: string },
  string,
  Options
>(
  'action/createAssetContract',
  async (name, { getState, rejectWithValue, dispatch, requestId }) => {
    const { system } = getState();
    if (system.status !== 'WalletConnected') {
      return rejectWithValue({
        kind: ErrorKind.WalletNotConnected,
        message: 'Cannot create collection: Wallet not connected'
      });
    }
    try {
      const op = await createAssetContract(system, { name });
      const pendingMessage = `Creating new collection ${name}`;
      dispatch(notifyPending(requestId, pendingMessage));
      await op.confirmation();

      const { address } = await op.contract();
      const fulfilledMessage = `Created new collection ${name} (${address})`;
      dispatch(notifyFulfilled(requestId, fulfilledMessage));
      // TODO: Poll for contract availability on indexer
      dispatch(getWalletAssetContractsQuery());
      return { name, address };
    } catch (e) {
      return rejectWithValue({
        kind: ErrorKind.CreateAssetContractFailed,
        message: 'Collection creation failed'
      });
    }
  }
);

export const createMintOnDemandContractAction = createAsyncThunk<
  { name: string },
  string,
  Options
  >(
  'action/createMintOnDemandContract',
  async (name, { getState, rejectWithValue, dispatch, requestId }) => {
    const { system } = getState();
    if (system.status !== 'WalletConnected') {
      return rejectWithValue({
        kind: ErrorKind.WalletNotConnected,
        message: 'Cannot create collection: Wallet not connected'
      });
    }
    try {
      // @ts-ignore
      const op = await createFaucetOnDemandContract(system, { name });
      const pendingMessage = `Creating new on demand contract ${name}`;
      dispatch(notifyPending(requestId, pendingMessage));
      await op.confirmation();

      const { address } = await op.contract();
      const fulfilledMessage = `Created new collection ${name} (${address})`;
      dispatch(notifyFulfilled(requestId, fulfilledMessage));
      // TODO: Poll for contract availability on indexer
      dispatch(getWalletAssetContractsQuery());
      return { name, address };
    } catch (e) {
      return rejectWithValue({
        kind: ErrorKind.CreateAssetContractFailed,
        message: 'Collection creation failed'
      });
    }
  }
);

type Attributes = { name: string; value: string }[];

function appendAttributes(metadata: NftMetadata, attributes: Attributes) {
  return attributes.reduce(
    (acc, row) => {
      const keys = Object.keys(NftMetadata.props);
      const key = keys.find(k => k === row.name) as keyof NftMetadata;
      if (key && NftMetadata.props[key].decode(row.value)._tag === 'Right') {
        return { ...acc, [key]: row.value };
      }
      const attribute = { name: row.name, value: row.value };
      return { ...acc, attributes: [...acc.attributes ?? [], attribute] };
    }, 
    metadata
  );
}

export function appendStateMetadata(state: State['createNft'], metadata: NftMetadata, system: SystemWithToolkit | SystemWithWallet) {
  const creator: string  = system.tzPublicKey ? system.tzPublicKey : '';
  const appendedMetadata: NftMetadata = {
    ...metadata,
    name: state.fields.name as string,
    collection_name: state.fields.collection_name as string,
    category: state.fields.category as string,
    minter: creator,
    creators: [ creator ],
    description: state.fields.description || undefined,
    attributes: []
  };
  return appendAttributes(appendedMetadata, state.attributes);
}

export const mintTokenAction = createAsyncThunk<{ contract: string; metadata: ReturnType<typeof appendStateMetadata>; tokenId: string }, undefined, Options>(
  'action/mintToken',
  async (_, { getState, rejectWithValue, dispatch, requestId }) => {
    const { system, createNft: state } = getState();

    if (state.selectedFile === null) {
      return rejectWithValue({
        kind: ErrorKind.UnknownError,
        message: 'Could not mint token: no file selected'
      });
    } else if (system.status !== 'WalletConnected') {
      return rejectWithValue({
        kind: ErrorKind.WalletNotConnected,
        message: 'Could not mint token: no wallet connected'
      });
    } else if (!validateCreateNftForm(state)) {
      return rejectWithValue({
        kind: ErrorKind.CreateNftFormInvalid,
        message: 'Could not mint token: form validation failed'
      });
    }

    let file: File;

    try {
      const { objectUrl, name, type } = state.selectedFile;
      const fetched = await fetch(objectUrl);
      const blob = await fetched.blob();
      file = new File([blob], name, { type });
    } catch (e) {
      return rejectWithValue({
        kind: ErrorKind.UnknownError,
        message: 'Could not mint token: selected file not found'
      });
    }

    const { tags, editions, royalties, royaltiesSharing, isGenerator } = state;
    console.log(`in mintTokenAction, edition: ${editions}, royalties: ${royalties}, royaltiesSharing: ${JSON.stringify(royaltiesSharing)}`)
    let ipfsMetadata: NftMetadata = {};

    // TODO: read real royalty split map values
    let royaltiesX100 = royalties ? royalties * 100 : 0;
    let parsedRoyaltiesSplitMap = (royalties && royaltiesSharing) ? royaltiesSharing?.map(royaltiesSharingItem => {
      return { 
        address: royaltiesSharingItem.walletAddress, 
        percentage: Math.floor(royaltiesSharingItem.royalties * 100)
      }
    }) : [{ address: system.tzPublicKey, percentage: 10000}];

    console.log(`in mintTokenAction, parsedRoyaltiesSplitMap: ${JSON.stringify(parsedRoyaltiesSplitMap)}`)
    Object.assign(ipfsMetadata, { 
      isGenerator,
      tags: tags ?? [], 
      number_of_editions: editions ?? 1,
      royalties: royaltiesX100 ?? 0, 
      royalties_split_map: parsedRoyaltiesSplitMap
    });

    dispatch(updateActiveStep(1));

    try {
      console.log(`in actions, file.type: ${file.type}`)
      if (/^image\/.*/.test(file.type)) {
        console.log(`in actions, handling image`);

        const fileResponse = await uploadIPFSFileHuge(system.config.ipfsApi, file, true);

        ipfsMetadata.artifactUri = fileResponse.data.ipfsUri;
        ipfsMetadata.displayUri = fileResponse.data.display.ipfsUri;
        ipfsMetadata.thumbnailUri = fileResponse.data.thumbnail.ipfsUri;
        ipfsMetadata.formats = [
          {
            fileSize: fileResponse.headers['content-length'],
            mimeType: file.type
          }
        ];
      } else if (/^video\/.*/.test(file.type)) {
        console.log(`in actions, handling video`);

        console.log(`in actions, handling video, about to call uploadIPFSFileHuge`)
        const fileResponse = await uploadIPFSFileHuge(system.config.ipfsApi, file, false);

        const { data: { ipfsUri, url }} = fileResponse;

        const videoClipPreview = await api.put(`/mint/createPreviewClip?url=${url}`, {}, { responseType: 'blob' });

        const blob = new Blob([videoClipPreview.data], { type: "video/mp4" });
        const videoClipPreviewFile = new File([blob], 'fragment-preview', { type: blob.type })
        console.log({ videoClipPreviewFile })

        const videoClipPreviewFileResponse = await uploadIPFSFileHuge(system.config.ipfsApi, videoClipPreviewFile, false);
        console.log({ videoClipPreviewFileResponse });
        
        ipfsMetadata.displayUri = videoClipPreviewFileResponse.data.ipfsUri;
        ipfsMetadata.artifactUri = ipfsUri;
        ipfsMetadata.formats = [
          {
            fileSize: fileResponse.headers['content-length'],
            mimeType: file.type
          }
        ];
      } else {
        console.log(`other file type`)
        let displayFile: File;

        try {
          const { objectUrl, name, type } = state.displayImageFile!;
          const fetched = await fetch(objectUrl);
          const blob = await fetched.blob();
          displayFile = new File([blob], name, { type });
        } catch (e) {
          return rejectWithValue({
            kind: ErrorKind.UnknownError,
            message: 'Could not mint token: video display file not found'
          });
        }

        const fileResponse = await uploadIPFSFileHuge(system.config.ipfsApi, file, false);
        const imageResponse = await uploadIPFSFileHuge(system.config.ipfsApi, displayFile, true);

        ipfsMetadata.artifactUri = fileResponse.data.ipfsUri;
        ipfsMetadata.displayUri = imageResponse.data.display.ipfsUri;
        ipfsMetadata.thumbnailUri = imageResponse.data.thumbnail.ipfsUri;
        ipfsMetadata.formats = [
          {
            fileSize: fileResponse.data.size,
            mimeType: file.type
          }
        ];
      }

      dispatch(updateActiveStep(2));
    } catch (e) {
      return rejectWithValue({
        kind: ErrorKind.IPFSUploadFailed,
        message: 'IPFS upload failed'
      });
    }


    let metadata = appendStateMetadata(state, ipfsMetadata, system);
    let address = state.collectionAddress as string;
    let amount = 0;
    
    console.log(`in regular mintTokenAction`);
    if ((<any>window).branding === 'EDIT TRAX') {
      address = 'KT1Swuf9gYZ8oXUCNQfabhXDG2D5kivmiKMj';
      amount = 1.0;
      // TODO: get metadata for EditTrax
      metadata = {
        'tags': [],
        'number_of_editions': 1,
        'royalties': 1000,
        'royalties_split_map': [{ 'address': 'tz29miNB1d1p9LrKaExXcvit92VzdbPVHpnR', 'percentage': 10000 }],
        'artifactUri': 'ipfs://QmdgJmAbNMWrintFiCYE5GgsL9kJq1CHCZqJuiBZHfQmJS',
        'displayUri': 'ipfs://QmZDnZhUHABz9SbNcvdJzfvuKqMiRbdUDpDLS5Zmp67TU4',
        'thumbnailUri': 'ipfs://QmNM2pTdwCTUGRaCJ5wbTyv67C6UJCuhh28MTrg22kGngA',
        'formats': [{ 'fileSize': 1009, 'mimeType': 'image/png' }],
        'name': 'test',
        'collection_name': '',
        'category': '',
        'minter': 'tz29miNB1d1p9LrKaExXcvit92VzdbPVHpnR',
        'creators': ['tz29miNB1d1p9LrKaExXcvit92VzdbPVHpnR'],
        'attributes': []
      };
    }
    console.log(`in mintTokenAction, metadata: ${JSON.stringify(metadata)}`);

    try {
      // For now get editions from attribute
      if (!metadata.number_of_editions) metadata.number_of_editions = 1;

      let tokens : NftMetadata[] = []
      for (let i = 0; i < metadata.number_of_editions; i++) {
        tokens.push(metadata)
      }

      console.log(`about to call mintTokens with contract ${address}`);
      const op = await mintTokens(system, address, tokens, amount);
      const pendingMessage = `Minting new token: ${metadata.name}`;
 
      dispatch(notifyPending(requestId, pendingMessage));
      await op.confirmation(2);

      dispatch(updateActiveStep(3));

      const parsedTokens = (JSON.parse(localStorage.getItem('parsedTokens')!)).map((token: any) => ({ ...token, contractAddress: address }));
      await api.post('/tokens', { tokens: parsedTokens });

      const tokenId = parsedTokens[0].token_id;

      localStorage.removeItem('parsedTokens');

      const fulfilledMessage = `Created new token: ${metadata.name} in ${address}`;

      dispatch(notifyFulfilled(requestId, fulfilledMessage));
      dispatch(getContractNftsQuery({ address }));
      dispatch(updateActiveStep(4));

      if (!isGenerator) {
        window.location.href = `/collection/${address}/token/${tokenId}`;
      }


      return { contract: address, metadata, tokenId };
    } catch (e) {
      console.log(`mint faile with message: ${JSON.stringify(e)}`)
      return rejectWithValue({
        kind: ErrorKind.MintTokenFailed,
        message: 'Mint token failed'
      });
    }
  }
);

type PlayerConfigNameProps = 'acid-beach' | 'hush-hush' | 'rolla-disco' | 'ed-nine' | 'alpha-test';

function getEditTraxPlayer() {
  let path = window.location.pathname;
  console.log(`get pathname: ${path}`);

  let playerName = path.replace('/edit-trax/', '');

  // @ts-ignore
  return playerConfigs[playerName as PlayerConfigNameProps];
}

function getEditTraxPlayerByName(name: string) {
  let path = window.location.pathname;
  console.log(`get pathname: ${path}`);

  let playerName = name;

  // @ts-ignore
  return playerConfigs[playerName as PlayerConfigNameProps];
}

export const mintEditTraxTokenAction = createAsyncThunk<{ contract: any; metadata: ReturnType<typeof appendStateMetadata> }, { contract: any }, Options>(
  'action/mintEditTraxToken',
  async ({ contract }, { getState, rejectWithValue, dispatch, requestId }) => {
    const { system, createNft: state } = getState();

    if (system.status !== 'WalletConnected') {
      return rejectWithValue({
        kind: ErrorKind.WalletNotConnected,
        message: 'Could not mint token: no wallet connected'
      });
    }
    console.log(`in mintEditTraxTokenAction, contract: ${JSON.stringify(contract)}`);

    let editTraxPlayer = getEditTraxPlayer();
    let address = contract.contractAddress;
    const contractStorage = await system.tzkt.getContractStorage(address);
    // TODO: get price from contract storage
    let amount = contractStorage.price / 1000000;
    // TODO: get latest metadata for EditTrax
    let metadata = contract.metadata;
    console.log(`in mintTokenAction, metadata: ${JSON.stringify(metadata)}`);

    try {
      // For now get editions from attribute
      if (!metadata.number_of_editions) metadata.number_of_editions = 1;

      let tokens : NftMetadata[] = []
      for (let i = 0; i < metadata.number_of_editions; i++) {
        tokens.push(metadata)
      }

      console.log(`about to call mintTokens with contract ${address}`);
      const op = await mintTokens(system, address, tokens, amount);
      const pendingMessage = `Minting new token: ${metadata.name}`;

      dispatch(notifyPending(requestId, pendingMessage));
      await op.confirmation(2);

      const parsedTokens = (JSON.parse(localStorage.getItem('parsedTokens')!)).map((token: any) => ({ 
        ...token, 
        contractAddress: address, 
        slug: editTraxPlayer.tokenMetadata.slug 
      }));
      
      await api.post('/tokens', { tokens: parsedTokens });

      localStorage.removeItem('parsedTokens');

      const fulfilledMessage = `Created new token: ${metadata.name} in ${address}`;
      dispatch(notifyFulfilled(requestId, fulfilledMessage));
      dispatch(getContractNftsQuery({ address }));
      return { contract: address, metadata };
    } catch (e) {
      console.log(`mint faile with message: ${JSON.stringify(e)}`)
      return rejectWithValue({
        kind: ErrorKind.MintTokenFailed,
        message: 'Mint token failed'
      });
    }
  }
);


export const mintCustomContractTokenAction = createAsyncThunk<{ contract: any; metadata: ReturnType<typeof appendStateMetadata> }, { contract: any }, Options>(
  'action/mintCustomContractToken',
  async ({ contract }, { getState, rejectWithValue, dispatch, requestId }) => {
    const { system, createNft: state } = getState();

    if (system.status !== 'WalletConnected') {
      return rejectWithValue({
        kind: ErrorKind.WalletNotConnected,
        message: 'Could not mint token: no wallet connected'
      });
    }

    console.log(`in mintCustomContractTokenAction`);
    
    // for now use one of edit trax tokens
    // let editTraxPlayer = getEditTraxPlayerByName('ed-nine');
    //let editTraxPlayer = playerConfigs['ed-nine'];
    // TODO: get price from contract storage
    // TODO: get latest metadata for EditTrax
    let metadata = contract.metadata;
    console.log(`in mintCustomContractTokenAction, metadata: ${JSON.stringify(metadata)}`);
    console.log({ metadata });

    try {
      // For now get editions from attribute
      if (!metadata.number_of_editions) metadata.number_of_editions = 1;

      let tokens : NftMetadata[] = []
      for (let i = 0; i < metadata.number_of_editions; i++) {
        tokens.push(metadata)
      }

      let address = contract.contractAddress;
      const contractStorage = await system.tzkt.getContractStorage(address);
      console.log(`got contractStorage: ${JSON.stringify(contractStorage)}`)
      
      let amount = contractStorage.price / 1000000;
      console.log(`about to call mintTokens with contract ${address}`);
      
      const op = await mintTokens(system, address, tokens, amount);
      const pendingMessage = `Minting new token: ${metadata.name}`;

      dispatch(notifyPending(requestId, pendingMessage));
      await op.confirmation(2);

      const parsedTokens = (JSON.parse(localStorage.getItem('parsedTokens')!)).map((token: any) => ({
        ...token,
        contractAddress: address,
        slug: metadata.name,
        newTransaction: true,
        isCustom: true,
        amountPrice: amount,
        seller: metadata.owner,
      }));

      const transaction: Transaction = {
        tokenId: parsedTokens[0].tokenId,
        buyer: system.tzPublicKey,
        contractAddress: address,
        creator: metadata.creators[0],
        isCustom: true,
        price: amount,
        seller: metadata.owner,
        type: 'buy',        
      };

      await api.post('/tokens', { tokens: parsedTokens });
      await addTransaction(transaction);

      localStorage.removeItem('parsedTokens');

      const fulfilledMessage = `Created new token: ${metadata.name} in ${address}`;
      dispatch(notifyFulfilled(requestId, fulfilledMessage));
      dispatch(getContractNftsQuery({ address }));
      return { contract: address, metadata };
    } catch (e) {
      console.log(`mint faile with message: ${JSON.stringify(e)}`)
      return rejectWithValue({
        kind: ErrorKind.MintTokenFailed,
        message: 'Mint token failed'
      });
    }
  }
);


interface NonEmptyArrayBrand {
  readonly NonEmptyArray: unique symbol;
}

type ParsedCsvRow = t.TypeOf<typeof ParsedCsvRow>;
const ParsedCsvRow = t.intersection([
  t.type({
    name: t.string,
    description: t.string,
    artifactUri: t.string,
    collection: t.string
  }),
  t.partial({
    displayUri: t.string
  }),
  t.record(t.string, t.string)
]);

const ParsedCsv = t.brand(
  t.array(ParsedCsvRow),
  (n): n is t.Branded<Array<ParsedCsvRow>, NonEmptyArrayBrand> => n.length > 0,
  'NonEmptyArray'
);

export const mintCsvTokensAction = createAsyncThunk<null, undefined, Options>(
  'action/mintCsvTokens',
  async (_, { getState, rejectWithValue, dispatch, requestId }) => {
    const { system, createNftCsvImport: state } = getState();
    if (system.status !== 'WalletConnected') {
      return rejectWithValue({
        kind: ErrorKind.WalletNotConnected,
        message: 'Wallet not connected'
      });
    }
    if (state.selectedCsvFile === null) {
      return rejectWithValue({
        kind: ErrorKind.UnknownError,
        message: 'No CSV file selected'
      });
    }

    let text: string;
    try {
      text = await fetch(state.selectedCsvFile.objectUrl).then(r => r.text());
    } catch (e) {
      return rejectWithValue({
        kind: ErrorKind.UnknownError,
        message: 'Could not mint tokens: selected CSV file not found'
      });
    }
    const parsed = parse(text, { columns: true, skipEmptyLines: true });
    if (!ParsedCsv.is(parsed)) {
      console.log('ERROR:', parsed);
      return rejectWithValue({
        kind: ErrorKind.UnknownError,
        message: ''
      });
    }
    const attrRegex = /^attribute\./;
    const attrRegexTest = new RegExp(attrRegex.source + '.+');
    const metadataArray = parsed.map(p => {
      const attributes = Object.keys(p)
        .filter(k => attrRegexTest.test(k))
        .map(k => ({
          name: k.split(attrRegex)[1],
          value: p[k]
        }));
      const metadata: NftMetadata = {
        name: p.name,
        minter: system.tzPublicKey,
        description: p.description,
        artifactUri: p.artifactUri,
        displayUri: p.displayUri,
        attributes: [],
      };
      return appendAttributes(metadata, attributes);
    });

    try {
      let address = parsed[0].collection;
      let amount = 0;
      if ((<any>window).branding === 'EDIT TRAX') {
        address = 'KT1Swuf9gYZ8oXUCNQfabhXDG2D5kivmiKMj';
        amount = 1.0;
      }
      const op = await mintTokens(system, address, metadataArray, amount);
      const pendingMessage = `Minting new tokens from CSV`;
      dispatch(notifyPending(requestId, pendingMessage));
      await op.confirmation(2);

      const fulfilledMessage = `Created new tokens from CSV in ${address}`;
      dispatch(notifyFulfilled(requestId, fulfilledMessage));
      dispatch(getContractNftsQuery({ address }));
    } catch (e) {
      return rejectWithValue({
        kind: ErrorKind.MintTokenFailed,
        message: 'Mint tokens from CSV failed'
      });
    }

    return null;
  }
);

export const transferTokenAction = createAsyncThunk<
  { contract: string; tokenId: number; to: string },
  { contract: string; tokenId: number; to: string },
  Options
>('action/transferToken', async (args, api) => {
  const { getState, rejectWithValue, dispatch, requestId } = api;
  const { contract, tokenId, to } = args;
  const { system } = getState();

  if (system.status !== 'WalletConnected') {
    return rejectWithValue({
      kind: ErrorKind.WalletNotConnected,
      message: 'Could not transfer token: no wallet connected'
    });
  }

  try {
    const op = await transferToken(system, contract, tokenId, to);
    dispatch(notifyPending(requestId, `Transferring token to ${to}`));
    await op.confirmation(2);

    dispatch(notifyFulfilled(requestId, `Transferred token to ${to}`));
    dispatch(getContractNftsQuery({ address: contract }));

    await burnToken(tokenId);

    window.location.href = '/explore';

    return args;
  } catch (e) {
    return rejectWithValue({
      kind: ErrorKind.TransferTokenFailed,
      message: 'Transfer token failed'
    });
  }
});

export const listTokenAction = createAsyncThunk<
  { contract: string; tokenId: number; token: Token; salePrice: number; creator: string; royalties: number; sales_list_map: { address: string; percentage: number; }[] },
  { contract: string; tokenId: number; token: Token; salePrice: number; creator: string; royalties: number; sales_list_map: { address: string; percentage: number; }[] },
  Options
>('action/listToken', async (args, api) => {
  const { getState, rejectWithValue, dispatch, requestId } = api;
  const { contract, tokenId, salePrice, creator, royalties, sales_list_map, token } = args;
  const { system } = getState();

  // @ts-ignore
  // const collection = collections.collections[minterContract];

  // // @ts-ignore
  // const token = collection.tokens.find(token => token.id === tokenId);

  const marketplaceContract = system.config.contracts.marketplace.fixedPrice.tez;

  if (system.status !== 'WalletConnected') {
    return rejectWithValue({
      kind: ErrorKind.WalletNotConnected,
      message: 'Could not list token: no wallet connected'
    });
  }

  try {
    const contractM = await system.toolkit.wallet.at(marketplaceContract);
    const storage = await contractM.storage<any>();
    const sale_id = storage.next_sale_id;

    const op = await listTokenForSale(
      system,
      marketplaceContract,
      contract,
      tokenId,
      salePrice,
      1,
      creator,
      royalties,
      token?.metadata,
      sales_list_map
    );

    const pendingMessage = `Listing token for sale for ${salePrice / 1000000}ꜩ`;
    dispatch(notifyPending(requestId, pendingMessage));
    await op.confirmation(2);

    // const validateSaleResult = await validateSale(sale_id);
    // console.log(`validateSaleResult: ${validateSaleResult}`);
    
    const fulfilledMessage = `Token listed for sale for ${salePrice / 1000000}ꜩ`;

    dispatch(notifyFulfilled(requestId, fulfilledMessage));
    dispatch(getContractNftsQuery({ address: contract }));


    const sale = {
      saleId: Number(sale_id),
      royalties: royalties / 100,
      address: contract,
      price: salePrice / 1000000,
      amount: 1,
      sales_split_map:  [...sales_list_map, { address: system.tzPublicKey, percentage: 10000 }],
      royalties_split_map: token.metadata.royalties_split_map,
      fees_split_map: [{ address: config.contractOpts.marketplace.fee.address, percentage: 1000 }],
      fees: 250,
    };

    await addTokenForSale(tokenId, sale);

    return args;
  } catch (e) {
    return rejectWithValue({
      kind: ErrorKind.ListTokenFailed,
      message: 'List token failed'
    });
  }
});

async function validateSale(sale_id: number) {
  const uri = `${config.ipfsApi}/validate-sale?id=${sale_id}`;
  const { data } = await axios.get(uri);
  return data;
}

// https://us-central1-orbix360-minter-dev.cloudfunctions.net/api?address=KT1B8ZthP5aVHbHEXGriiNNBAQM6eu2wsLCp&id=1

export const cancelTokenSaleAction = createAsyncThunk<
  { contract: string; tokenId: number; saleId: number; saleType: string },
  { contract: string; tokenId: number; saleId: number; saleType: string },
  Options
>('action/cancelTokenSale', async (args, api) => {
  const { getState, rejectWithValue, dispatch, requestId } = api;
  const { contract, tokenId, saleId, saleType } = args;
  const { system } = getState();
  const marketplaceContract =
    system.config.contracts.marketplace.fixedPrice.tez;
  if (system.status !== 'WalletConnected') {
    return rejectWithValue({
      kind: ErrorKind.WalletNotConnected,
      message: 'Could not list token: no wallet connected'
    });
  }
  try {
    let op;
    if (saleType === "fixedPriceLegacy") {
      op = await cancelTokenSaleLegacy(
        system,
        marketplaceContract,
        contract,
        tokenId
      );
    } else {
      op = await cancelTokenSale(
        system,
        marketplaceContract,
        contract,
        tokenId,
        saleId
      );
    }

    dispatch(notifyPending(requestId, `Canceling token sale`));
    await op.confirmation(2);

    dispatch(notifyFulfilled(requestId, `Token sale canceled`));
    dispatch(getContractNftsQuery({ address: contract }));

    await cancelSale(tokenId);

    return { contract: contract, tokenId: tokenId, saleId: saleId, saleType: saleType };
  } catch (e) {
    return rejectWithValue({
      kind: ErrorKind.CancelTokenSaleFailed,
      message: 'Cancel token sale failed'
    });
  }
});

export const buyTokenAction = createAsyncThunk<
  { contract: string; tokenId: number; saleId: number; saleType: string },
  { contract: string; tokenId: number; buyerId: string; tokenSeller: string; salePrice: number; saleId: number; saleType: string },
  Options
>('action/buyToken', async (args, api) => {
  const { getState, rejectWithValue, dispatch, requestId } = api;
  const { contract, tokenId, tokenSeller, salePrice, saleId, saleType, buyerId } = args;
  let { system } = getState();
  const marketplaceContract = system.config.contracts.marketplace.fixedPrice.tez;


  if (system.status !== 'WalletConnected') {
    const res = await dispatch(connectWallet());
  
    if (!res.payload || !('wallet' in res.payload)) {
      return rejectWithValue({
        kind: ErrorKind.WalletNotConnected,
        message: 'Could not list token: no wallet connected'
      });
    }
  
    system = res.payload;
  }

  try {
    let op;

    if (saleType === "fixedPriceLegacy") {
      op = await buyTokenLegacy( system, marketplaceContract, contract, tokenId, tokenSeller, salePrice );
    } else {
      op = await buyToken( system, marketplaceContract, saleId, contract, tokenId, salePrice ); 
    }

    const pendingMessage = `Buying token from ${tokenSeller} for ${salePrice}`;
    dispatch(notifyPending(requestId, pendingMessage));
    await op.confirmation(2);

    const fulfilledMessage = `Bought token from ${tokenSeller} for ${salePrice}`;
    dispatch(notifyFulfilled(requestId, fulfilledMessage));
    dispatch(getContractNftsQuery({ address: contract }));
    console.log(`in buy process, about to call updateTokenAfterSale`);
    await updateTokenAfterSale(tokenId, buyerId);
    
    return { contract: contract, tokenId: tokenId, saleId: saleId, saleType: saleType };
  } catch (e) {
    return rejectWithValue({
      kind: ErrorKind.BuyTokenFailed,
      message: 'Purchase token failed'
    });
  }
});
