// eslint-disable-next-line
import { PaletteType } from '@material-ui/core';

export const light = {
	"alternate": {
		"main": "rgb(247, 249, 250)",
		"dark": "#e8eaf6",
	},
	"button": {
		"primary": "#fff",
		"secondary": "#DBDBDB"
	},
	"cardShadow": "rgba(23, 70, 161, .11)",
	"type": "light" as PaletteType,
	"primary": {
		"main": "#898989",
		"light": "#C4C4C4",
		"offDark": "#7B579A",
		"dark": "#171717",
		"contrastText": "#fff"
	},
	"secondary": {
		"light": "#fff",
		"main": "#fff",
		"dark": "#e1e1e1",
		"contrastText": "rgba(0, 0, 0, 0.87)"
	},
	"text": {
		"primary": "#898989",
		"secondary": "#DBDBDB",
	},
	"topbar": "#F6F6F6",
	"error": {
		"main": "#d32f2f",
	},
	"divider": "rgba(0, 0, 0, 0.12)",
	"background": {
		"paper": "#fff",
		"default": "#fff",
		"level2": "#f5f5f5",
		"level1": "#fff",
		"footer": "#1b1642",
	},
};

export const dark = {
	"alternate": {
		"main": "#171717",
		"dark": "#000",
	},
	"button": {
		"primary": "#101010",
		"secondary": "#303030"
	},
	"cardShadow": "rgba(0, 0, 0, .40)",
	"common": {
		"black": "#000",
		"white": "#fff"
	},
	"type": "dark" as PaletteType,
	"primary": {
		"main": "#000",
		"light": "#171717",
		"dark": "#000",
		"contrastText": "rgba(0, 0, 0, 0.87)"
	},
	"secondary": {
		"light": "#ffb74d",
		"main": "#f9b934",
		"dark": "#f57c00",
		"contrastText": "rgba(0, 0, 0, 0.87)"
	},
	"topbar": "#171717",
	"error": {
		"main": "#d32f2f",
	},
	"text": {
		"primary": "#FFF",
		"secondary": "#959595",
	},
	"divider": "rgba(255, 255, 255, 0.12)",
	"background": {
		"paper": "#000",
		"default": "#171717",
		"level2": "#333",
		"level1": "#2D3748",
		"footer": "#18181f",
	},
};