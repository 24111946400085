import React from 'react';

import { Main } from 'layouts';
import { CollectionItem } from 'views';
import WithLayout from 'WithLayout';

export type CollectionPageProps = {
    contractAddress: string;
    tokenId: number;
}

export default function CollectionPage ({ contractAddress, tokenId }: CollectionPageProps): JSX.Element {    
    return <WithLayout component={CollectionItem} layout={Main} contractAddress={contractAddress} tokenId={tokenId} />;
}