import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@material-ui/core';
import { buyTokenAction } from 'reducer/async/actions';
import { useDispatch } from 'reducer';
import { ContentModal } from 'views/CreateNFT/components';
import { Nft } from 'lib/nfts/decoders';
import useAuth from 'hooks/auth';

interface BuyTokenProps {
    token: Token;
    open: boolean;
    handleClose: () => void;
}

export default function BuyToken({ token, open, handleClose }: BuyTokenProps) {
    const { user } = useAuth();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const dispatch = useDispatch();
    
    const seller = token?.seller as User || {} as User;

    function handleCloseModal() {
        setOpenModal(false);
        handleClose();
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Checkout</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { `You are about to purchase ${token.title} (${token.sale?.price} ꜩ)` }
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button variant='contained' color="primary" onClick={() => setOpenModal(true)}>Buy now</Button>                
                </DialogActions>
            </Dialog>

            <ContentModal 
                isOpen={openModal} 
                method="buyToken"
                dispatchThunk={() => dispatch(buyTokenAction({ 
                    contract: token.sale?.address || '',
                    tokenId: token.tokenId,
                    buyerId: user.wallet,
                    tokenSeller: seller?.wallet || '',
                    salePrice: token.sale?.price || 0,
                    saleId: token.sale?.saleId || 0,
                    saleType: token.sale?.type || ''                    
                }))}
                onClose={() => handleCloseModal()}
                dispatchOnOpen={true}	 
                pendingAsyncMessage="Purchasing token..." 
            />
        </>
    );
}
