// @ts-nocheck
import React, { ChangeEvent, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, FormControlLabel, Switch, Button, Divider, Box } from '@material-ui/core';

import * as S from './styles';
import useAuth from 'hooks/auth';
import APP_CONFIG from 'config/app_config';
import api from 'services/api';

const useStyles = makeStyles(theme => ({
	inputTitle: {
		fontWeight: 700,
		marginBottom: theme.spacing(1),
	},
	switchTitle: {
		fontWeight: 700,
	},
	titleCta: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));

const Custom = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
	const classes = useStyles();

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	const { token, user, setUser } = useAuth();

	const [customURL, setCustomURL] = useState('');
	const [customLogo, setCustomLogo] = useState('');
	const [customNadir, setCustomNadir] = useState('');

	useEffect(() => {
		setCustomLogo((user?.branding_overlay) ? `${APP_CONFIG.BUCKET_URL_ROOT}/${user.branding_overlay}` : '/assets/orbix_logo_upper_left.png');
		setCustomNadir((user?.nadir_logo_url) ? `${APP_CONFIG.BUCKET_URL_ROOT}/${user.nadir_logo_url}` : '/assets/orbix_nadir.png');
	}, [])

	async function handleUploadCustomLogo(event: ChangeEvent<HTMLInputElement>) {
		const file = event.target.files;
		const fileData = new FormData();

		if (file) {
			fileData.append('imageType', 'avatar');
			fileData.append('file', file[0]);

			try {
				const response = await uploadProfileImage('logo', fileData);

				Object.assign(user, { branding_overlay: response.url.replace(APP_CONFIG.BUCKET_URL_ROOT, '') });
				setUser(user);

				setCustomLogo(URL.createObjectURL(file[0]));
				alert("Image uploaded");
			} catch (error) {
				console.log(error);
			}
		}
	}

	async function handleUploadCustomNadir(event: ChangeEvent<HTMLInputElement>) {
		const file = event.target.files;
		const fileData = new FormData();

		if (file) {
			fileData.append('file', file[0]);
			fileData.append('file', file[0]);

			try {
				const response = await uploadProfileImage('nadir', fileData);

				Object.assign(user, { nadir_logo_url: response.url.replace(APP_CONFIG.BUCKET_URL_ROOT, '') });
				setUser(user);

				alert("Image uploaded");
				setCustomNadir(URL.createObjectURL(file[0]));
			} catch (error) {
				console.log(error);
			}
		}
	}

	async function uploadProfileImage(type: string, fileData: FormData) {
		try {
			const { data, status } = await api.post(`/user/addProfileImage/${type}`, fileData, {
				headers: {
					"Authorization": `Bearer ${token}`
				}
			});

			if (status == 200) {
				return data;
			}
		} catch (error: any) {
			console.log(error);

			return { error: true, message: error.message };
		}
	}

	async function uploadCustomUrl() {
		try {
			const response = await api.post('/account/profile/field', {
				field: 'custom_url',
				value: customURL,
			}, {
				headers: {
					"Authorization": `Bearer ${token}`
				}
			});

			setUser(response.data);
		} catch (error) {
			console.log(error);
			alert("An error occurred");
		}
	}

	return (
		<div className={className} {...rest}>
			<Grid container spacing={isMd ? 4 : 2}>
				<Grid item xs={12}>
					<div className={classes.titleCta}>
						<Typography variant="h6" color="textPrimary">
							Custom
						</Typography>
					</div>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12}>
					<Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
						Upload custom logo (300*50)
					</Typography>

					<S.CustomLogo src={customLogo} alt="Custom logo from orbix user" />

					<Box>
						<input
							accept="image/*"
							id="raised-button-file"
							multiple
							type="file"
							hidden
							onChange={handleUploadCustomLogo}
						/>

						<label htmlFor="raised-button-file">
							<Button variant="contained" color="primary" size="large" component="span">
								Select File
							</Button>
						</label>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="subtitle1" color="textPrimary" className={classes.inputTitle}>
						Upload Custom Nadir (800x800)
					</Typography>

					<S.CustomNadir src={customNadir} alt="Custom nadir from orbix user" />

					<Box>
						<input
							accept="image/*"
							id="upload-custom-nadir"
							multiple
							type="file"
							hidden
							onChange={handleUploadCustomNadir}
						/>

						<label htmlFor="upload-custom-nadir">
							<Button variant="contained" color="primary" size="large" component="span">
								Select File
							</Button>
						</label>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12}>
					<FormControlLabel
						control={<Switch color="primary" defaultChecked />}
						label={
							<Typography variant="subtitle1" color="textPrimary" className={classes.switchTitle}>
								Public Profile
							</Typography>
						}
						labelPlacement="end"
					/>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item container justify="flex-start" xs={12}>
					<Button variant="contained" type="submit" color="primary" size="large">
						save
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default Custom;
