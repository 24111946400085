import React from 'react';
import { Grid, Link as MuiLink, Box, Typography } from '@material-ui/core';
import { Link } from 'wouter';

import { ipfsUriToGatewayUrl } from 'lib/util/ipfs';

import * as S from './styles';

export default function RightSide({ system, token }: ViewComponentProps & { token: Token }): JSX.Element {
    const src = ipfsUriToGatewayUrl(system.config, token?.artifactUri);    

    // @ts-ignore
    let edition_number = token.metadata.edition_number ? token.metadata.edition_number : 1;
    let number_of_editions = token.number_of_editions ? token.number_of_editions : 1;
    let hasEditions = number_of_editions > 1;

    const owner = token.owner as User;

    return (
        <Box>
            <Grid container spacing={2}>
                {hasEditions && (
                    <Grid item xs={12} md={12} sm={12}>
                        <Box display="flex" alignItems="center">
                            <Box display="flex" flexDirection="column">
                                <Typography><strong>Editions</strong></Typography>

                                <S.AuthorName variant="body2" color="textPrimary">
                                    <Link href={`/editions/${owner.wallet}/${token.editions_id}`}>
                                        {edition_number} of {number_of_editions}
                                    </Link>
                                </S.AuthorName>
                            </Box>
                        </Box>
                    </Grid>
                )}

                {token?.collection_name && (
                    <Grid item xs={12} md={12} sm={12}>
                        <Box display="flex" flexDirection="column">
                            <Typography><strong>Collection</strong></Typography>

                            <S.AuthorName variant="body2" color="textPrimary">
                                <S.StyledLink href={`/collection-detail/${token.metadata?.minter}/${token?.collection_name?.trim()}`}>
                                    {token.collection_name}
                                </S.StyledLink>
                            </S.AuthorName>
                        </Box>
                    </Grid>
                )}

                {token?.category && (
                    <Grid item xs={12} md={12} sm={12}>
                        <Box display="flex" flexDirection="column">
                            <Typography><strong>Category</strong></Typography>

                            <S.AuthorName variant="body2" color="textPrimary">
                                <S.StyledLink href={`/categories/${token.category.trim()}`}>
                                    {token.category}
                                </S.StyledLink>
                            </S.AuthorName>
                        </Box>
                    </Grid>
                )}

                {token.metadata.formats && (
                    <Grid item xs={12} md={12} sm={12}>
                        <Box display="flex" flexDirection="column">
                            <Typography><strong>MIME type</strong></Typography>
                            <Typography>{token.metadata?.formats[0].mimeType!}</Typography>
                        </Box>
                    </Grid>
                )}

                <Grid item xs={12} md={12} sm={12}>
                    <Box>
                        <Typography>
                            <strong>IPFS: </strong><br />

                            <MuiLink underline="hover" color="textPrimary" href={src} target="_blank">
                                <strong>{` ${token.artifactUri}`}</strong>
                            </MuiLink>
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                    <Box display="flex" flexDirection="column">
                        <Typography><strong>Artist Royalties</strong></Typography>
                        <Typography>{token.metadata.royalties ? (token.royalties / 100).toFixed(2) : 0}%</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                    <Box display="flex" flexDirection="column" mb={2}>
                        <Typography><strong>Owner</strong></Typography>

                        <S.AuthorName variant="body2" color="textPrimary">
                            <S.StyledLink href={`/creator/${owner.wallet}`}>
                                {owner.username}
                            </S.StyledLink>
                        </S.AuthorName>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}