// @ts-nocheck
import { createTheme, responsiveFontSizes } from '@material-ui/core';
import { light, dark } from './palette';

const theme = (mode: string) => {
	return (mode === 'light') ? light : dark
};

const getTheme = mode => responsiveFontSizes(
	createTheme({
		palette: mode === 'light' ? light : dark,
		layout: {
			contentWidth: 1236,
		},
		typography: {
			fontFamily: 'Lato',
		},
		topbar: theme(mode).topbar,
		zIndex: {
			appBar: 1200,
			drawer: 1100,
		},
		overrides: {
			MuiButton: {
				containedPrimary: {
					border: `2px solid ${theme(mode).button.secondary}`, 
					color: theme(mode).text.primary,
					backgroundColor: theme(mode).button.primary,
					boxShadow: 'none',
				},
				root: {
					textTransform: 'none'
				}
			}
		}
	}),
);

export default getTheme;