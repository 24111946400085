import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';

import { Box, Button, TextField } from '@material-ui/core';

import { SectionHeader } from "components/molecules";
import { CardBase, SectionAlternate } from "components/organisms";

import config from '../../../../config.json';
import useAuth from 'hooks/auth';

declare global {
	interface Window { 
        ethereum: any; 
    }
}

export default function Verify(): JSX.Element {
    const { user } = useAuth();

    const [address, setAddress] = useState('');
    const [message, setMessage] = useState('');
    const [signature, setSignature] = useState('');

    useEffect(() => {
        (async () => {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const walletAddress = await signer.getAddress();

            setAddress(walletAddress);
        })();
    }, [address]);

    async function handleVerifyMessage() {
        // const provider = new ethers.providers.Web3Provider(window.ethereum);
        // const signer = provider.getSigner();
        // const address = await signer.getAddress();

        // const message = `${user.wallet}-${address}`;
        // const signature = await signer.signMessage(message);

        const { data } = await axios.post(`${config.ipfsApi}/sign-message/decode`, {
            message,
            signature,
            address
        });

        console.log({ data });
        alert(!!data ? "Valid and signed message" : "Invalid message");
    }

    return (
        <SectionAlternate>
            <>
                <SectionHeader title="Verify Message" />

                <CardBase>
                    <Box display="flex" flexDirection="column" alignItems="center" width="100%" style={{ gap: 24 }}>
                        <TextField
                            placeholder="Message"
                            variant="outlined"
                            name="message"
                            fullWidth
                            multiline
                            rows={6}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />

                        <label htmlFor="">Signature</label>

                        <TextField
                            placeholder="Signature"
                            variant="outlined"
                            name="signature"
                            fullWidth
                            multiline
                            rows={6}
                            value={signature}
                            onChange={(e) => setSignature(e.target.value)}
                        />

                        <TextField
                            placeholder="Address"
                            variant="outlined"
                            name="address"
                            fullWidth
                            value={address}
                            disabled
                            onChange={(e) => setAddress(e.target.value)}
                        />

                        <Button variant="contained" type="button" color="primary" size="large" onClick={handleVerifyMessage}>Verify</Button>
                    </Box>
                </CardBase>
            </>
        </SectionAlternate>
    );
}