import React from 'react';
import { Pagination as PageItem } from '@material-ui/lab';

import * as S from './styles';

export interface PaginationProps {
    numPages: number;
    pageNumber: number;
    handlePagination: (e: any, page: number) => void;
    isLoading: boolean;
    showLastButton?: boolean;
}

export default function Pagination({ numPages, pageNumber, handlePagination, isLoading, showLastButton = false }: PaginationProps) {
    return (
        <S.Wrapper showLastButton={showLastButton}>
            <PageItem 
                count={numPages}
                page={pageNumber} 
                shape="rounded" 
                color="primary"
                onChange={handlePagination} 
                disabled={isLoading}
                siblingCount={2}
            />
        </S.Wrapper>
    );
}