import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { Section } from 'components/organisms';
import { FileUpload, Form, MintForm } from './components';

import { useDispatch, useSelector } from 'reducer';
import { clearForm, selectCollection } from 'reducer/slices/createNft';

import config from '../../config.json';
import { connectWallet } from 'reducer/async/wallet';
import { Loader } from 'components/atoms';

const globalCollection = config.contracts.nftFaucet;

export default function CreateCustomContractView() {
  const dispatch = useDispatch();
  const system = useSelector(s => s.system); 

  const [stepForm, setStepForm] = useState<number>(0);
  const isWalletConnected = system.status === 'WalletConnected';

  dispatch(selectCollection(globalCollection));

  useEffect(() => {
    if (!isWalletConnected) {
      dispatch(connectWallet());
    }

    return () => {
      dispatch(clearForm());
    };      
  }, [isWalletConnected, dispatch]);

  if (!isWalletConnected) {
    return <Loader />
  }

  return (
    <Section>
      { stepForm === 0 ? (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <FileUpload />

            <MintForm handleNextStep={() => setStepForm(1)} />
          </Grid>
        </Grid>
        ) : (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <Form />
          </Grid>
        </Grid>
        )
      }
    </Section>
  );
}
