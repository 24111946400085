import api from './api';

const PATH = '/users';

export async function verifyUsername(username: string) {
    const { data } = await api.get(`${PATH}/verify-username/${username}`);

    return data as boolean;
}

export async function getUserByWallet(wallet: string) {
    const { data: { user }} = await api.get(`${PATH}/info/${wallet}`);

    return user;
}

export async function getMyItemsTokens(wallet: string) {
    const { data: { error, object, message }} = await api.get(`${PATH}/my-items/${wallet}`);

    return { error, object, message };
}

export async function getCollaborationsTokens(filter: Filter, wallet: string) {
    const { data: { object }} = await api.post(`${PATH}/collaborations`, { wallet, filter });

    return object;
}

export async function updateUserFiles(fileData: FormData, wallet: string) {
    const { data: { error, message, object }} = await api.post(`${PATH}/update-files`, fileData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });

    return { error, message, object };
}

export async function findByCreatorOwnerOrSeller(field: string, filter: Filter, wallet: string) {
    const { data: { object }} = await api.post(`${PATH}/tokens/find-by/${field}`, { wallet, filter });

    return object;
}

export async function findForSaleTokensByWallet(filter: Filter, wallet: string) {
    const { data: { object }} = await api.post(`${PATH}/tokens/find-for-sale`, { wallet, filter });

    return object;
}

export async function findPurchasedTokensByWallet(filter: Filter, wallet: string) {
    const { data: { object }} = await api.post(`${PATH}/tokens/find-purchased`, { wallet, filter });

    return object;
}

export async function verifyEthereumWallet(wallet: string) {
    const { data: { object }} = await api.get(`${PATH}/verify-ethereum-wallet/${wallet}`);

    return object;
}

export async function linkEthereumWallet(wallet: string, ethereumWallet: string) {
    const { data: { object }} = await api.put(`${PATH}/link-ethereum-wallet/${wallet}`, { ethereumWallet });

    return object;
}
