import React from 'react';
import { Link } from 'wouter';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
	reachOut: {
		color: theme.palette.primary.light
	},
}));

const WhoWeAre = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
	const classes = useStyles();

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	const subtitle = (
		<Typography>
			Got a Virtual / Augmented Reality requirement that you can't address on our platform? 
			<Link href="/contact-us"><a className={classes.reachOut}>Reach out</a></Link> to us for partnerships and custom services.
		</Typography>
	);

	return (
		<div className={className} {...rest}>
			<Grid container spacing={isMd ? 4 : 2}>
				<Grid item xs={12} data-aos="fade-up">
					<SectionHeader
						title="Our Services"
						subtitle={subtitle}
						disableGutter
						align="left"
						subtitleProps={{ variant: 'body1', color: 'textPrimary' }}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default WhoWeAre;
