/* eslint-disable no-redeclare */
import { Buffer } from 'buffer';
import * as t from 'io-ts';
import _ from 'lodash';
import { SystemWithToolkit, SystemWithWallet } from '../system';
import { TzKt, Params, getTransactionHistory } from '../service/tzkt';
import { isLeft } from 'fp-ts/lib/Either';
import { compact } from 'fp-ts/lib/Array';
import { getRight } from 'fp-ts/lib/Option';
import * as D from './decoders';
// import { metadata } from 'figlet';

export function fromHexString(input: string) {
  if (/^([A-Fa-f0-9]{2})*$/.test(input)) {
    return Buffer.from(input, 'hex').toString();
  }
  return input;
}

//// Data retrieval and decoding functions

async function getAssetMetadataBigMap(tzkt: TzKt, address: string, limit?: number, offset?: number): Promise<D.AssetMetadataBigMap> {
  console.log(`in getAssetMetadataBigMap, limit: ${limit}`)
  const path = 'metadata';
  let params : Params = {}
  if (limit) {
    params.limit = `${limit}`
  }
  if (offset) {
    params.offset = `${offset}`
  }
  const data = await tzkt.getContractBigMapKeys(address, path, params);
  const decoded = D.LedgerBigMap.decode(data);

  if (isLeft(decoded)) {
    throw Error('Failed to decode `getAssetMetadata` response');
  }
  return decoded.right;
}

async function getLedgerBigMap(tzkt: TzKt, address: string, limit?: number, offset?: number): Promise<D.LedgerBigMap> {
  console.log(`in getLedgerBigMap, limit: ${limit}`)
  const path = 'assets.ledger';
  let params : Params = {}

  if (limit) {
    params.limit = `${limit}`
  }

  if (offset) {
    params.offset = `${offset}`
  }

  params['sort.desc'] = 'id';

  params['value.ni'] = '["tz1burnburnburnburnburnburnburjAYjjX","tz1burnburnburnburnburnburnburjAYjjX"]';

  const data = await tzkt.getContractBigMapKeys(address, path, params);
  const decoded = D.LedgerBigMap.decode(data);

  if (isLeft(decoded)) {
    throw Error('Failed to decode `getLedger` response');
  }
  
  return decoded.right;
}

async function getLedgerBigMapOwned(tzkt: TzKt, address: string, owner: string, limit?: number, offset?: number): Promise<D.LedgerBigMap> {
  console.log(`in getLedgerBigMap, limit: ${limit}`)
  const path = 'assets.ledger';
  let params : Params = {}

  if (limit) {
    params.limit = `${limit}`
  }

  if (offset) {
    params.offset = `${offset}`
  }

  params['sort.desc'] = 'id';

  params['value.eq'] = owner;

  const data = await tzkt.getContractBigMapKeys(address, path, params);
  const decoded = D.LedgerBigMap.decode(data);

  if (isLeft(decoded)) {
    throw Error('Failed to decode `getLedger` response');
  }
  return decoded.right;
}

async function getLedgerBigMapOwned_Hic(tzkt: TzKt, address: string, owner: string, limit?: number, offset?: number): Promise<D.LedgerBigMapHic> {
  console.log(`in getLedgerBigMapOwned_Hic, limit: ${limit}`)
  const path = 'ledger';
  let params : Params = {}

  if (limit) {
    params.limit = `${limit}`
  }

  if (offset) {
    params.offset = `${offset}`
  } 

  params['key.address.eq'] = owner;
  params['value.ne'] = '0';
  params['sort.desc'] = 'id';
  // params['key.address.eq'] = 'tz2QwQ2zagGDpgJ6sy6Yzbj56ZErEnLbZKWA';
  // https://api.mainnet.tzkt.io/v1/contracts/KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton/bigmaps/ledger/keys?sort.desc=id&key.address.eq=tz2QwQ2zagGDpgJ6sy6Yzbj56ZErEnLbZKWA
  // const data = await tzkt.getContractBigMapKeys(address, path, params);
  const data = await tzkt.getContractBigMapKeys('KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton', path, params);
  console.log(`in getLedgerBigMapOwned_Hic, data: ${JSON.stringify(data)}`);
  const decoded = D.LedgerBigMapHic.decode(data);

  if (isLeft(decoded)) {
    throw Error('Failed to decode `getLedger` response');
  }
  return decoded.right;
}

export async function getTokenMetadataBigMap(tzkt: TzKt, address: string, token_ids: string, limit?: number, offset?: number, firstEditionOnly?: boolean): Promise<D.TokenMetadataBigMap> {
  console.log(`in getTokenMetadataBigMap, limit: ${limit}`)
  const path = 'assets.token_metadata';
  let params: Params = {}

  if (token_ids !== '') {
    params[`key${(token_ids.split(',').length > 1) ? '.in' : ''}`] = token_ids;
  } else {
    if (limit) {
      // params.limit = `${limit}`
    }

    if (offset) {
      //  params.offset = `${offset}`
    }
  }

  params['sort.desc'] = 'id';
  if (firstEditionOnly) {
    // params['value.token_info.edition_number'] = '31';
  }
  const data = await tzkt.getContractBigMapKeys(address, path, params);
  const decoded = D.TokenMetadataBigMap.decode(data);

  if (isLeft(decoded)) {
    throw Error('Failed to decode `getTokenMetadata` response');
  }
  // console.log(`in getTokenMetadataBigMap, decoded.right: ${JSON.stringify(decoded.right)}`)
  
  return decoded.right;
}

export async function getTokenMetadataBigMap_Hic(tzkt: TzKt, address: string, token_ids: string, limit?: number, offset?: number): Promise<D.TokenMetadataBigMap> {
  console.log(`in getTokenMetadataBigMap_Hic, limit: ${limit}`)
  const path = 'token_metadata';
  let params: Params = {}

  if (token_ids !== '') {
    params[`key${(token_ids.split(',').length > 1) ? '.in' : ''}`] = token_ids;
  } else {
    if (limit) {
      // params.limit = `${limit}`
    }

    if (offset) {
      //  params.offset = `${offset}`
    }
  }

  params['sort.desc'] = 'id';

  const data = await tzkt.getContractBigMapKeys(address, path, params);
  const decoded = D.TokenMetadataBigMap.decode(data);

  if (isLeft(decoded)) {
    throw Error('Failed to decode `getTokenMetadata` response');
  }
  // console.log(`in getTokenMetadataBigMap, decoded.right: ${JSON.stringify(decoded.right)}`)

  return decoded.right;
}

export async function getFixedPriceSalesBigMap(tzkt: TzKt, address: string, token_ids: string, limit?: number, offset?: number): Promise<D.FixedPriceSaleBigMap> {
  // console.log(`in getFixedPriceSalesBigMap, limit: ${limit}`)
  const fixedPriceStorage = D.FixedPriceSaleStorage.decode(await tzkt.getContractStorage(address));

  if (isLeft(fixedPriceStorage)) {
    throw Error('Failed to decode `getFixedPriceSales` bigMap ID');
  }

  const fixedPriceBigMapId = fixedPriceStorage.right.sales;
  let params : Params = {};

  if (token_ids !== '') {
    params[`value.sale_data.sale_token.token_id${(token_ids.split(',').length > 1) ? '.in' : ''}`] = token_ids;
  } else {
    if (limit) {
      params.limit = `${limit}`;
    }

    if (offset) {
      params.offset = `${offset}`;
    }
  }

  params['active'] = 'true';
  params['sort.desc'] = 'id';

  const fixedPriceSales = await tzkt.getBigMapKeys(fixedPriceBigMapId, params);
  const decoded = D.FixedPriceSaleBigMap.decode(fixedPriceSales);

  if (isLeft(decoded)) {
    throw Error('Failed to decode `getFixedPriceSales` response');
  }

  return decoded.right;
}

async function getBigMapUpdates<K extends t.Mixed, V extends t.Mixed>(tzkt: TzKt, params: Params, content: { key: K; value: V }) {
  const bigMapUpdates = await tzkt.getBigMapUpdates(params);
  const decoder = t.array(D.BigMapUpdateRow(content));
  const decoded = decoder.decode(bigMapUpdates);

  if (isLeft(decoded)) {
    throw Error('Failed to decode `getBigMapUpdates` response');
  }

  return decoded.right;
}

async function getContracts<S extends t.Mixed>(tzkt: TzKt, params: Params, storage: S) {
  const contracts = await tzkt.getContracts(params);
  const contractsArray = t.array(t.unknown).decode(contracts);
  
  if (isLeft(contractsArray)) {
    throw Error('Failed to decode `getContracts` response');
  }
  
  const decodedArray = contractsArray.right.map(D.ContractRow(storage).decode);
  return compact(decodedArray.map(getRight));
}

async function getContract<S extends t.Mixed>(tzkt: TzKt, address: string, params: Params, storage: S) {
  const contract = await tzkt.getContract(address, params);
  const decoded = D.ContractRow(storage).decode(contract);

  if (isLeft(decoded)) {
    throw Error('Failed to decode `getContracts` response');
  }

  return decoded.right;
}

//// Main query functions

export async function getContractNfts(system: SystemWithToolkit | SystemWithWallet, address: string, limit?: number, offset?: number, firstEditionOnly?: boolean): Promise<D.Nft[]> {
  // NOTE: below filters out tokens owned by burn wallet address
  const ledger = await getLedgerBigMap(system.tzkt, address, limit, offset);
  const ownedTokens = {} as {[x:string]: string};

  let token_ids = [];
  for (let i = 0; i < ledger.length; i++) {
    const { key, value } = ledger[i];

    token_ids.push(key)
    ownedTokens[key] = value;
  }

  // workaround since token_ids parameter must contain more than 1 item
  if (token_ids.length === 1) {
    token_ids.push(token_ids[0]);
  }

  const tokens = await getTokenMetadataBigMap(system.tzkt, address, `${JSON.stringify(token_ids)}`, limit, offset, firstEditionOnly);
  const mktAddress = system.config.contracts.marketplace.fixedPrice.tez;
  const tokenSales = await getFixedPriceSalesBigMap(system.tzkt, mktAddress, `${JSON.stringify(token_ids)}`, limit, offset);
  const activeSales = tokenSales.filter(sale => sale.active);

  // Sort by token id - descending
  const tokensSorted = [...tokens].sort((a,b)=>- (Number.parseInt(a.value.token_id, 10) - Number.parseInt(b.value.token_id, 10)));

  return Promise.all(
    tokensSorted.map(
      async (token): Promise<D.Nft> => {
        const { token_id: tokenId, token_info: tokenInfo } = token.value;

        const decodedInfo = _.mapValues(tokenInfo, fromHexString) as any;
        // console.log(`in getContractNFTs, decodedInfo: ${JSON.stringify(decodedInfo)}`)
        const resolvedInfo = await system.resolveMetadata(decodedInfo[''], address);
        // console.log(`in getContractNFTs, resolvedInfo: ${JSON.stringify(resolvedInfo)}`)
        const metadata = { ...decodedInfo, ...resolvedInfo.metadata };

        const saleData = activeSales.find(v => v.value.sale_data.sale_token.fa2_address === address && v.value.sale_data.sale_token.token_id === tokenId);

        const sale = saleData && {
          id: saleData.id,
          seller: saleData.value.seller,
          price: Number.parseInt(saleData.value.sale_data.price, 10) / 1000000,
          mutez: Number.parseInt(saleData.value.sale_data.price, 10),
          saleToken: {
            address: saleData.value.sale_data.sale_token.fa2_address,
            tokenId: Number.parseInt(saleData.value.sale_data.sale_token.token_id)
          },
          saleId: saleData.value.isLegacy ? 0 : Number.parseInt(saleData.key),
          type: saleData.value.isLegacy ? 'fixedPriceLegacy' : 'fixedPrice'
        };

        return {
          id: parseInt(tokenId, 10),
          owner: ownedTokens[tokenId] || metadata.creators[0],
          title: metadata.name,
          description: metadata.description,
          artifactUri: metadata.artifactUri,
          displayUri: metadata.displayUri,
          edition_number: metadata.edition_number,
          number_of_editions: metadata.number_of_editions,
          edition_id: metadata.edition_id,
          metadata: metadata,
          sale
        };
      }
    )
  );
}

export async function getContractOwnedNfts(system: SystemWithToolkit | SystemWithWallet, address: string, owner: string, limit?: number, offset?: number): Promise<D.Nft[]> {
  // NOTE: below filters out tokens owned by burn wallet address
  const ledger = await getLedgerBigMapOwned(system.tzkt, address, owner, limit, offset);

  let token_ids = [];
  for (let i = 0; i < ledger.length; i++) {
    token_ids.push(ledger[i].key)
  }

  // workaround since token_ids parameter must contain more than 1 item
  if (token_ids.length === 1) {
    token_ids.push(token_ids[0]);
  }

  const tokens = await getTokenMetadataBigMap(system.tzkt, address, `${JSON.stringify(token_ids)}`, limit, offset);
  const mktAddress = system.config.contracts.marketplace.fixedPrice.tez;
  const tokenSales = await getFixedPriceSalesBigMap(system.tzkt, mktAddress, `${JSON.stringify(token_ids)}`, limit, offset);
  const activeSales = tokenSales.filter(sale => sale.active);

  // Sort by token id - descending
  const tokensSorted = [...tokens].sort((a,b)=>- (Number.parseInt(a.value.token_id, 10) - Number.parseInt(b.value.token_id, 10)));

  return Promise.all(
    tokensSorted.map(
      async (token): Promise<D.Nft> => {
        const { token_id: tokenId, token_info: tokenInfo } = token.value;

        const decodedInfo = _.mapValues(tokenInfo, fromHexString) as any;
        const resolvedInfo = await system.resolveMetadata(decodedInfo[''], address);
        const metadata = { ...decodedInfo, ...resolvedInfo.metadata };

        const saleData = activeSales.find(v => v.value.sale_data.sale_token.fa2_address === address && v.value.sale_data.sale_token.token_id === tokenId);

        const sale = saleData && {
          id: saleData.id,
          seller: saleData.value.seller,
          price: Number.parseInt(saleData.value.sale_data.price, 10) / 1000000,
          mutez: Number.parseInt(saleData.value.sale_data.price, 10),
          saleToken: {
            address: saleData.value.sale_data.sale_token.fa2_address,
            tokenId: Number.parseInt(saleData.value.sale_data.sale_token.token_id)
          },
          saleId: saleData.value.isLegacy ? 0 : Number.parseInt(saleData.key),
          type: saleData.value.isLegacy ? 'fixedPriceLegacy' : 'fixedPrice'
        };

        return {
          id: parseInt(tokenId, 10),
          owner,
          title: metadata.name,
          description: metadata.description,
          artifactUri: metadata.artifactUri,
          displayUri: metadata.displayUri,
          edition_number: metadata.edition_number,
          number_of_editions: metadata.number_of_editions,
          edition_id: metadata.edition_id,
          metadata: metadata,
          sale
        };
      }
    )
  );
}

export async function getContractOwnedNfts_Hic(system: SystemWithToolkit | SystemWithWallet, address: string, owner: string, limit?: number, offset?: number): Promise<D.Nft[]> {
 console.log(`in getContractOwnedNfts_Hic`);
  // NOTE: below filters out tokens owned by burn wallet address
  // const ledger = await getLedgerBigMapOwned(system.tzkt, address, owner, limit, offset);
  const hicLedger = await getLedgerBigMapOwned_Hic(system.tzkt, address, owner, limit, offset);
  console.log(`hic ledger: ${JSON.stringify(hicLedger)}`)
  let token_ids = [];
  for (let i = 0; i < hicLedger.length; i++) {
    token_ids.push(hicLedger[i].key.nat)
  }

  // workaround since token_ids parameter must contain more than 1 item
  if (token_ids.length === 1) {
    token_ids.push(token_ids[0]);
  }
  console.log(`about to get hic token ids: ${JSON.stringify(token_ids)}`)
  const tokens = await getTokenMetadataBigMap_Hic(system.tzkt, 'KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton', `${JSON.stringify(token_ids)}`, limit, offset);
  console.log(`hic tokens: ${JSON.stringify(tokens)}`)
  // const mktAddress = system.config.contracts.marketplace.fixedPrice.tez;
  // const tokenSales = await getFixedPriceSalesBigMap(system.tzkt, mktAddress, `${JSON.stringify(token_ids)}`, limit, offset);
  // const activeSales = tokenSales.filter(sale => sale.active);

  // Sort by token id - descending
  const tokensSorted = [...tokens].sort((a,b)=>- (Number.parseInt(a.value.token_id, 10) - Number.parseInt(b.value.token_id, 10)));

  return Promise.all(
    tokensSorted.map(
      async (token): Promise<D.Nft> => {
        const { token_id: tokenId, token_info: tokenInfo } = token.value;

        const decodedInfo = _.mapValues(tokenInfo, fromHexString) as any;
        console.log(`hic decodedInfo: ${JSON.stringify(decodedInfo)}`)
        const resolvedInfo = await system.resolveMetadata(decodedInfo[''], address);
        console.log(`hic resolvedInfo: ${JSON.stringify(resolvedInfo)}`)
        const metadata = { ...decodedInfo, ...resolvedInfo.metadata };

        // const saleData = activeSales.find(v => v.value.sale_data.sale_token.fa2_address === address && v.value.sale_data.sale_token.token_id === tokenId);

       /* const sale = saleData && {
          id: saleData.id,
          seller: saleData.value.seller,
          price: Number.parseInt(saleData.value.sale_data.price, 10) / 1000000,
          mutez: Number.parseInt(saleData.value.sale_data.price, 10),
          saleToken: {
            address: saleData.value.sale_data.sale_token.fa2_address,
            tokenId: Number.parseInt(saleData.value.sale_data.sale_token.token_id)
          },
          saleId: saleData.value.isLegacy ? 0 : Number.parseInt(saleData.key),
          type: saleData.value.isLegacy ? 'fixedPriceLegacy' : 'fixedPrice'
        }; */

        return {
          id: parseInt(tokenId, 10),
          owner,
          title: metadata.name,
          description: metadata.description,
          artifactUri: metadata.artifactUri,
          displayUri: metadata.displayUri,
          edition_number: metadata.edition_number,
          number_of_editions: metadata.number_of_editions,
          edition_id: metadata.edition_id,
          metadata: metadata,
        };
      }
    )
  );
}

export async function getNftAssetContract(system: SystemWithToolkit | SystemWithWallet,address: string): Promise<D.AssetContract> {
  const contract = await getContract(system.tzkt, address, {}, t.unknown);
  const metaBigMap = await getAssetMetadataBigMap(system.tzkt, address);
  const metaUri = metaBigMap.find(v => v.key === '')?.value;

  if (!metaUri) {
    throw Error(`Could not extract metadata URI from ${address} storage`);
  }

  const { metadata } = await system.resolveMetadata(fromHexString(metaUri), address);
  const decoded = D.AssetContractMetadata.decode(metadata);

  if (isLeft(decoded)) {
    throw Error('Metadata validation failed');
  }

  return { ...contract, metadata: decoded.right };
}

export async function getHistoryNfts(system: SystemWithToolkit | SystemWithWallet, address: string): Promise<D.AssetContract> {
  return await system.tzkt.getTransactionHistory(address);
}

export async function getWalletNftAssetContracts(system: SystemWithWallet, limit?: number, offset?: number): Promise<D.AssetContract[]> {
  let params : Params = {};

  if (limit) {
    params.limit = `${limit}`;
  }

  if (offset) {
    params.offset = `${offset}`;
  }

  return await getNftAssetContracts(system, system.tzPublicKey);
};

export async function getNftAssetContracts(system: SystemWithWallet, tzPublicKey: string): Promise<D.AssetContract[]> {
  console.log(`in queries getNftAssetContracts, creator: ${tzPublicKey}`);
  const contracts = await getContracts(
    system.tzkt,
    {
      creator: tzPublicKey,
      includeStorage: 'true'
    },
    t.unknown
  );

  const addresses = _.uniq(
    contracts
      .filter(c => c.kind === 'asset' && c.tzips?.includes('fa2'))
      .map(c => c.address)
  );

  const results: D.AssetContract[] = [];

  if (addresses.length === 0) {
    return results;
  }

  const assetBigMapRows = (
    await getBigMapUpdates(
      system.tzkt,
      {
        path: 'metadata',
        action: 'add_key',
        'contract.in': addresses.join(','),
        limit: '10000'
      },
      {
        key: t.string,
        value: t.string
      }
      )
  ).filter(v => v.content.key === '');

  for (const row of assetBigMapRows) {
    const contract = contracts.find(c => c.address === row.contract.address);
    
    if (!contract) {
      continue;
    }

    try {
      const metaUri = row.content.value;
      const { metadata } = await system.resolveMetadata(
        fromHexString(metaUri),
        contract.address
      );

      const decoded = D.AssetContractMetadata.decode(metadata);
      if (!isLeft(decoded)) {
        results.push({ ...contract, metadata: decoded.right });
      }
    } catch (e) {
      console.log(e);
    }
  }

  return results;
}

export type MarketplaceNftLoadingData = {
  loaded: boolean;
  error?: string;
  token: null | D.Nft;
  tokenSale: D.FixedPriceSaleBigMap[number];
  tokenMetadata: undefined | string;
};

export async function getMarketplaceNfts(system: SystemWithToolkit | SystemWithWallet, address: string, limit?: number, offset?: number): Promise<MarketplaceNftLoadingData[]> {
  let params : Params = {};

  if (limit) {
    params.limit = `${limit}`;
  }

  if (offset) {
    params.offset = `${offset}`;
  }

  const tokenSales = await getFixedPriceSalesBigMap(system.tzkt, address, '', limit, offset);
  const activeSales = tokenSales.filter(v => v.active);
  const addresses = _.uniq(activeSales.map(s => s.value.sale_data.sale_token.fa2_address));

  const uniqueAddresses = Array.from(new Set(addresses));

  if (uniqueAddresses.length === 0) {
    return [];
  }

  const tokenBigMapRows = await getBigMapUpdates(
    system.tzkt,
    {
      path: 'assets.token_metadata',
      action: 'add_key',
      'contract.in': addresses.join(','),
      limit: '10000'
    },
    {
      key: t.string,
      value: t.type({
        token_id: t.string,
        token_info: t.record(t.string, t.string)
      })
    }
  );

  // Sort descending (newest first)
  const salesToView = [...activeSales];
  const salesWithTokenMetadata = salesToView
    .map(x => ({
      tokenSale: x,
      tokenItem: tokenBigMapRows.find(
        item =>
          x.value.sale_data.sale_token.fa2_address === item.contract.address &&
          x.value.sale_data.sale_token.token_id ===
            item.content.value.token_id + ''
      )
    }))
    .map(x => ({
      loaded: false,
      token: null,
      tokenSale: x.tokenSale,
      tokenMetadata: x.tokenItem?.content?.value?.token_info['']
    }));

  return salesWithTokenMetadata;
}

export const loadMarketplaceNft = async (system: SystemWithToolkit | SystemWithWallet, tokenLoadData: MarketplaceNftLoadingData): Promise<MarketplaceNftLoadingData> => {
  const { token, loaded, tokenSale, tokenMetadata } = tokenLoadData;
  const result = { ...tokenLoadData };
  //console.log(`in loadMarketplaceNft, token: ${JSON.stringify(token)}`);
 // console.log(`in loadMarketplaceNft, tokenSale: ${JSON.stringify(tokenSale)}`);
 // console.log(`in loadMarketplaceNft, tokenMetadata: ${JSON.stringify(tokenMetadata)}`);
  if (token || loaded) {
    return result;
  }

  result.loaded = true;

  try {
    const { fa2_address: saleAddress, token_id: tokenIdStr } = tokenSale.value.sale_data.sale_token;

    const tokenId = parseInt(tokenIdStr, 10);
    const mutez = Number.parseInt(tokenSale.value.sale_data.price, 10);

    const sale = {
      id: tokenSale.id,
      seller: tokenSale.value.seller,
      price: mutez / 1000000,
      mutez: mutez,
      saleToken: {
        address: tokenSale.value.sale_data.sale_token.fa2_address,
        tokenId: Number.parseInt(tokenSale.value.sale_data.sale_token.token_id)
      },
      saleId: tokenSale.value.isLegacy ? 0 : Number.parseInt(tokenSale.key),
      type: tokenSale.value.isLegacy ? 'fixedPriceLegacy' : 'fixedPrice'
    };

    if (!tokenMetadata) {
      result.error = "Couldn't retrieve tokenMetadata";
      console.error("Couldn't retrieve tokenMetadata", { tokenSale });
      return result;
    }

    const { metadata } = (await system.resolveMetadata(fromHexString(tokenMetadata), saleAddress)) as any;

    // @ts-ignore
    const edition_number = tokenSale.value.sale_data.edition_number ? tokenSale.value.sale_data.edition_number : 1;
    // @ts-ignore
    result.token = {
      address: saleAddress,
      id: tokenId,
      title: metadata.name || '',
      owner: sale.seller,
      description: metadata.description || '',
      artifactUri: metadata.artifactUri || '',
      displayUri: metadata.displayUri || '',
      edition_number: edition_number,
      number_of_editions: metadata.number_of_editions,
      edition_id: metadata.edition_id,
      metadata: metadata,
      sale: sale
    };
    console.log(`in loadMarketplaceNft, result: ${JSON.stringify(result)}`);

    return result;
  } catch (err) {
    result.error = "Couldn't load token";
    console.error("Couldn't load token", { tokenSale, err });
    return result;
  }
};
