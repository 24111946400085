import { Box, Button, Grid } from '@material-ui/core';
import styled, { css } from 'styled-components';

const buttonProps = css`
    background: #fff;
    text-transform: capitalize;
    border-radius: 6px;

    svg {
        margin-right: 8px;
    }
`;

const centeredAbsolutePosition = css`
    position: absolute;        
    margin: auto;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

export const Wrapper = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        min-height: 220px;
        height: 250px;
        overflow: hidden;

        position: relative;
    `}
`;

export const BannerContent = styled(Box)<{ imageUrl?: string }>`
    ${({ theme, imageUrl }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        width: 100%;
        height: 250px;
        min-height: 220px;
        
        background-color: ${theme.palette.divider};     

        > label {
            position: absolute;
            bottom: 16px;
            right: 16px;

            ${theme.breakpoints.down(530)} {
                top: 16px;
                bottom: 0;
            }
        }

        .MuiButtonBase-root {
            ${buttonProps};
        }

        ${theme.breakpoints.down('sm')} {
            min-height: 120px;
            height: 150px;
        }

        img {
            width: 100%;
        }
    `}
`;

export const Content = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        
        width: 100%;
        position: relative;

        margin-right: ${theme.spacing(6)}px;
        margin-top: -${theme.spacing(8)}px;
        
        ${theme.breakpoints.down(960)} {
            margin-top: -${theme.spacing(20)}px;
        }

        /* > label {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -55%);

            ${theme.breakpoints.down(400)} {
                top: 45%;
            }
            
            > span {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        } */
    `}
`;

export const AvatarContainer = styled.div<{ isHoverAvatar?: boolean }>`
    ${({ isHoverAvatar = false }) => css`
        margin-left: auto; 
        margin-right: auto;             
        bottom: -135px;

        ${isHoverAvatar && css`
            -webkit-filter: brightness(70%);
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            -o-transition: all 1s ease;
            -ms-transition: all 1s ease;
            transition: all 1s ease;        
        `}
    `}
`;

export const Avatar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const AvatarImage = styled.div<{ hasAvatarUrl?: boolean }>`
    ${({ hasAvatarUrl = true }) => css`
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: 2px solid #fff;
        background: #c5c5c5;

        position: relative;

        ${!hasAvatarUrl && css`
            > img {
                display: none;
            }
        `}
        
        img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
        }

        label {
            align-items: center;
            justify-content: center;
            
            ${centeredAbsolutePosition};
        }

        .MuiCircularProgress-root {
            ${centeredAbsolutePosition};
        }
        
        @media screen and (max-width: 1237px) {
            width: 120px;
            height: 120px;
            
            img {
                width: 120px;
                height: 120px;
            }
        }    
    `}
`;

export const WhiteButton = styled(Button)`
    ${buttonProps};
`;

export const CancelButton = styled(Button)`
    ${({ theme }) => css`
        background: #d32f2f;
        text-transform: capitalize;
        border-radius: 6px;
        color: white;

        svg {
            margin-right: ${theme.spacing(1)}px;
        }    
    `}
`;

export const DescriptionGrid = styled(Grid)`
    ${({ theme }) => css`
        display: flex;

        ${theme.breakpoints.down(810)} {
            width: 100%;
            padding: 0 24px;
        }
    `}
`;

export const Description = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;    

        position: relative;
        margin-top: ${theme.spacing(2)}px;
        
        ${theme.breakpoints.up('sm')} {
            width: 750px;
        }
        
        ${theme.breakpoints.down(960)} {
            margin-bottom: ${theme.spacing(2)}px;
        }
        
        ${theme.breakpoints.down(810)} {
            width: 100%;
        }

        ${WhiteButton}, ${CancelButton} {
            margin-top: ${theme.spacing(2)}px;
        }

        button + button {
            margin-left: ${theme.spacing(2)}px;
        }
    `}
`;