import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { CheckCircle, AlertCircle, X } from 'react-feather';

import { Status } from 'reducer/slices/status';
import ErrorButton from 'components/atoms/ErrorButton';

interface ContentProps {
    isOpen: boolean;
    onClose: () => void;
    onRetry: () => void;
    onCancel: () => void;
    status: Status;
    sync: boolean;
    pendingMessage?: React.ReactNode;
    pendingAsyncMessage?: React.ReactNode;
    completeMessage?: React.ReactNode;
    errorMessage?: React.ReactNode;
}

export default function Content({ status, onClose, onRetry, onCancel, errorMessage, sync, pendingMessage, pendingAsyncMessage, completeMessage }: ContentProps) {
    if (status.error) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" px={4} py={10}>
                <Box color="brand.blue" mb={6}>
                    <AlertCircle size={70} />
                </Box>                

                <Typography variant="h4">
                    {errorMessage || 'Operation failed'}
                </Typography>

                <Box display="flex" flexDirection="row" justifyContent="space-between" mt={4} width={190}>
                    <Button variant="contained" color="primary" onClick={() => onRetry() }>
                        Retry
                    </Button>

                    <ErrorButton variant="outlined" onClick={() => onCancel()}>
                        <Box color="currentcolor">
                           <X size={16} strokeWidth="3" />
                        </Box>

                        <Typography variant="body1">Close</Typography>
                    </ErrorButton>
                </Box>
            </Box>
        );
    }

    if (status.status === 'in_transit') {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" px={4} py={10}>
                <CircularProgress color="primary" />

                <Box mt={3}>
                    <Typography variant="h6">
                        {sync ? pendingMessage || 'Operation pending...' : pendingAsyncMessage || 'Opening wallet...'}
                    </Typography>
                </Box>
            </Box>
        );
    }

    if (status.status === 'complete') {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" px={4} py={10}>
                <Box color="brand.blue" mb={6}>
                    <CheckCircle size="70px" />
                </Box>

                <Typography variant="h6">
                    {completeMessage || 'Operation complete'}
                </Typography>

                <Button variant="contained" onClick={() => onClose()}>Close</Button>
            </Box>
        );
    }

    return <></>;
}