import React from 'react';
import { Box, Heading, Text, Flex, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import { useSelector } from '../../reducer';
import { FilePreview } from './FilePreview';

import * as S from './styles';

function Label(props: { children: React.ReactNode }) {
  return (
    <Text fontSize="md" fontFamily="mono" color="brand.darkGray">
      {props.children}
    </Text>
  );
}

export default function Confirmation() {
  const createNft = useSelector(s => s.createNft);
  const collections = useSelector(s => s.collections.collections);

  const { selectedFile, displayImageFile, fields, attributes, collectionAddress, tags, editions, royalties } = createNft;

  return (
    <Box>
      <Heading size="lg" mb={4}>
        Confirm Details
      </Heading>

      <Flex w="400px" justify="center" mb={8}>
        <FilePreview file={(selectedFile?.type.includes('zip') ? displayImageFile : selectedFile)!} />
      </Flex>

      <Label>Collection</Label>
      <Text fontSize="md" mb={[2, 4]}>
        {collections[collectionAddress!]?.metadata?.name}
      </Text>

      <Label>Name</Label>      
      <Text fontSize="md" mb={[2, 4]}>
        {fields.name}
      </Text>

      <Label>Tags</Label>
      { tags?.length && (
      <S.Container style={{ margin: '12px 0 24px'}}>
        <S.TagContainer>
          {tags.map((tag, index) => <S.Tag key={`tag-${index}-${tag}`}>{tag}</S.Tag>)}
        </S.TagContainer>
      </S.Container>
      )}

      <Label>Description</Label>
      <Text fontSize="md" mb={[2, 4]}>
        {fields.description || 'No description provided'}
      </Text>

      <Label>Editions</Label>      
      <Text fontSize="md" mb={[2, 4]}>
        {editions}
      </Text>

      <Label>Royalties</Label>      
      <Text fontSize="md" mb={[2, 4]}>
        {royalties}
      </Text>
      
      <Accordion allowToggle>
        <AccordionItem border="none">
          <AccordionButton mt={[4, 8]} p={0}>
            <Text color="brand.neutralGray">Metadata</Text>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            {attributes?.map(({ name, value }) => (
              <Flex mt={[4, 8]}>
                <Text color="brand.neutralGray">{name}:</Text>
                <Text color="brand.darkGray" fontWeight="bold" ml={[1]}>
                  {value}
                </Text>
              </Flex>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
