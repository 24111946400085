import styled, { css } from 'styled-components';

export const Wrapper = styled.footer`
    ${({ theme }) => css`
        .MuiGrid-container {
            border-bottom: 2px solid #c4c4c4;
            margin-bottom: 24px;

            padding-bottom: 24px;
        }

        font-family: 'Poppins';

        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        margin-bottom: 1rem;	    
        
        ${theme.breakpoints.down('sm')} {
            padding-left: 24px;
            padding-right: 24px;

            margin-bottom: 0;	    

            .MuiGrid-container {
                padding-bottom: 0;
            }
        }
    `}    
`;

export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            display: flex;
            align-items: center;

            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;

            color: #FFFFFF;            
        }
        
        img {
            margin: 0 16px;
        }

        a {
            text-decoration: underline;
        }
    
        ${theme.breakpoints.down('sm')} {
            flex-direction: column;
            align-items: center;
            margin-bottom: 48px;

            span + span {
                margin-top: 24px;
            }

            img {
                margin: 0;

                position: absolute;
                bottom: 16px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
            }
        }
    `}
`;

export const Title = styled.h2`
    ${({ theme }) => css`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;

        text-transform: uppercase;
        
        color: #FFFFFF;    
        
        margin-bottom: 16px;    
        
        ${theme.breakpoints.down('sm')} {
            text-align: center;
        }
    `}
`;

export const Content = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;

        a {
            color: #FFFFFF;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 300;
        }

        ${theme.breakpoints.down('sm')} {
            align-items: center;

            margin-bottom: 32px;
        }
    `}
`;