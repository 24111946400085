import styled, { css } from 'styled-components';
import { RecButton } from '../styles';

export const Container = styled.div`
    ${({ theme }) => css`
        position: relative;
        margin-top: 48px;
        
        ${theme.breakpoints.down('sm')} {
            margin-top: 24px;
            padding: 24px;
        }
    `}
`;

export const Title = styled.div`
    color: #fff;

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;  

    margin-bottom: 24px;

    text-transform: uppercase;
`;

export const Sections = styled.div`
    display: flex;
    overflow: auto;
    justify-content: space-between;
`;

export const Image = styled.div`
    ${({ theme }) => css`
        position: relative;

        img {
            width: 100%;
            height: 450px;
        }

        margin-bottom: 48px;

        .arrow-left {
            position: absolute;
            top: 50%;
            left: 24px;

            &:hover {
                cursor: pointer;
            }
        }

        .arrow-right {
            position: absolute;
            top: 50%;
            right: 24px;

            &:hover {
                cursor: pointer;
            }
        }
        
        ${theme.breakpoints.down('sm')} {
            img {
                height: 225px;
            }

            .arrow-left {
                left: 8px;
            }

            .arrow-right {
                right: 8px;
            }

            svg {
                width: 36px;
                height: 36px;
            }
        }
    `}
`;

export const Button = styled(RecButton)`
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
`;
