import React, { useState, useEffect, MouseEvent } from 'react';
import { Link } from 'wouter';

import { Typography, Grid, Button, Box, Link as MuiLink, Menu, MenuItem, Fab, Divider } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { HiLink } from 'react-icons/hi';

import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon } from 'react-share';

import { useSelector } from 'reducer';

import { Tezos } from 'components/atoms';
import { Section } from "components/organisms";

import APP_CONFIG from 'config/app_config';

import { BurnToken, BuyToken, CancelToken, Properties, SaleToken, TransferToken } from '..';
import * as S from './styles';

export default function RightSide({ system, contract, tokenId, token }: ViewComponentProps & {token: Token}): JSX.Element {
    const { status } = useSelector(s => s);

    const creator = token.creator as User, owner = token.owner as User;
    const isOwner = system.tzPublicKey && (system.tzPublicKey === owner.wallet || system.tzPublicKey === owner.wallet);    

    const [open, setOpen] = useState<boolean>(false);
    const [isInTransit, setIsInTransit] = useState<boolean>(false);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [openBuyModal, setOpenBuyModal] = useState<boolean>(false);
    const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);
    const [openBurnTokenModal, setOpenBurnTokenModal] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);  
    const handleCloseMenu = () => setAnchorEl(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const { cancelTokenSale, buyToken, transferToken, listToken } = status;
        const hasSomeInTransitStatus = [cancelTokenSale, buyToken, transferToken, listToken].some(({ status }) => status === 'in_transit');

        setIsInTransit(hasSomeInTransitStatus);
    }, [status]);

    let isWhitelisted = true;

    return (
        <S.Wrapper>
            <Section>
                <>
                    <Box px={4}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box marginBottom={2}>
                                    <Typography variant="h4">{token.title}</Typography>
                                </Box>
                            </Grid>

                            { token?.description && (
                            <Grid item xs={12}>
                                <Box marginBottom={2}>
                                    <Typography variant="body1">{token.description}</Typography>
                                </Box>
                            </Grid>
                            )}

                            { token.sale ? (
                            <Grid item xs={12}>
                                { (isOwner && isWhitelisted) && (
                                <Box display="flex" alignItems="center">
                                    <Box display="flex" alignItems="center">
                                        <Typography>
                                            {token.sale.price}
                                        </Typography>
                                        
                                        <Tezos width={14} height={14} />
                                    </Box>

                                    <S.CancelButton variant="outlined" onClick={() => setOpenCancelModal(true)} disabled={isInTransit}>
                                        Cancel Sale
                                    </S.CancelButton>
                                </Box>
                                )}
                                { (!isOwner) && (
                                <Box mb={2}>
                                    <Button variant="contained" color="primary" onClick={() => setOpenBuyModal(true)} disabled={isInTransit}>
                                        <S.Price>
                                            {`Collect for ${token.sale.price}`}
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" id="Layer_1" data-name="Layer 1" viewBox="0 0 1169.87 1593"><title>tezos-logo-01</title><g id="Layer_2" data-name="Layer 2"><path d="M755.68,1593q-170.51,0-248.91-82.14a253.6,253.6,0,0,1-78.15-177,117.39,117.39,0,0,1,13.69-58.5A101.21,101.21,0,0,1,479.64,1238a130.22,130.22,0,0,1,116.24,0A99.55,99.55,0,0,1,633,1275.36a115,115,0,0,1,14.18,58.5,111.73,111.73,0,0,1-19.91,68.45,92.78,92.78,0,0,1-47.31,34.62,129.18,129.18,0,0,0,74.67,46.55,370,370,0,0,0,101.8,14.68,226.91,226.91,0,0,0,128.19-38.33,224,224,0,0,0,83.63-113.25,492,492,0,0,0,27.38-169.5,465.07,465.07,0,0,0-29.87-176.23,217.54,217.54,0,0,0-86.37-109.52,229.68,229.68,0,0,0-124.43-35.59,236.75,236.75,0,0,0-107.78,36.59L567.26,932.4V892.33L926.43,410.5H428.62v500A178.9,178.9,0,0,0,456,1012.8a94.34,94.34,0,0,0,83.63,40.07,139.85,139.85,0,0,0,82.63-29.12,298.38,298.38,0,0,0,69.2-71.19,24.86,24.86,0,0,1,9-11.94,18.4,18.4,0,0,1,12-4.48,41.55,41.55,0,0,1,23.4,9.95,49.82,49.82,0,0,1,12.69,33.85,197.86,197.86,0,0,1-4.48,24.89,241,241,0,0,1-85.38,106,211.78,211.78,0,0,1-119.76,36.38q-161.67,0-224-63.72A238.67,238.67,0,0,1,253.2,909.25V410.5H0V317.6H254.38V105.78L196.14,47.5V0h169l63.48,32.86V317.6l657.6-2,65.47,65.71L748.46,786.5a271,271,0,0,1,76.16-18.42A330.1,330.1,0,0,1,972,810.15a302.7,302.7,0,0,1,126.95,113.29,399.78,399.78,0,0,1,57.25,136.65,575.65,575.65,0,0,1,13.69,117,489.39,489.39,0,0,1-49.78,216.79,317.92,317.92,0,0,1-149.35,149.35A483.27,483.27,0,0,1,755.68,1593Z" /></g></svg>
                                        </S.Price>
                                    </Button>
                                </Box>
                                )}
                            </Grid>
                            ) : (isOwner && isWhitelisted) ? (
                            <Grid item xs={12}>
                                <Box display="flex" mb={2}>
                                    <Button variant="contained" color="primary" onClick={handleOpen} disabled={isInTransit}>
                                        Sell
                                    </Button>

                                    <Box ml={2}>
                                        <Fab color="primary" onClick={handleClick} style={{ height: 36, width: 36 }}>
                                            <MoreVert />
                                        </Fab>

                                        <Menu
                                            id="customized-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleCloseMenu}
                                            elevation={0}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}                                                        
                                        >
                                            <MenuItem>
                                                <Box ml={2}>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary" 
                                                        onClick={() => setOpenTransferModal(true)} 
                                                        disabled={isInTransit}
                                                        fullWidth
                                                    >
                                                        Transfer
                                                    </Button>
                                                </Box>
                                            </MenuItem>

                                            <MenuItem>
                                                <Box ml={2}>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary" 
                                                        onClick={() => setOpenBurnTokenModal(true)} 
                                                        disabled={isInTransit}
                                                        fullWidth
                                                    >
                                                        Burn Token
                                                    </Button>
                                                </Box>
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </Box>
                            </Grid>
                            ) : (<></>)}
                        </Grid>

                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="column" mb={2}>
                                <Typography>Creator:</Typography>
                                                    
                                <Link href={`/creator/${creator.wallet}`}>
                                    <MuiLink underline="hover">
                                        <S.AuthorName variant="body2" color="textPrimary">
                                            <HiLink size={30} />{creator.username}
                                        </S.AuthorName>
                                    </MuiLink>
                                </Link>
                            </Box>
                        </Grid>
                    </Box>

                    {token.metadata.tags && token.metadata.tags.length > 0 && (
                    <S.Tags display="flex" px={4} pt={1}>
                        { token?.tags && token.tags.map((tag, index) => (
                        <Link href={`/search?tag=${tag}`} key={`tag-${index}-${tag}`}>
                            <Button variant="contained" color="primary">{tag}</Button>
                        </Link>
                        ))}
                    </S.Tags>
                    )}

                    <S.Share px={4} mt={2}>
                        <Typography>Share:</Typography>
                        
                        <Box display="flex" mt={2}>                            
                            <S.ShareButton>
                                <FacebookShareButton url={`${APP_CONFIG.SHORT_URL_ROOT}/collection/${contract}/token/${tokenId}`} quote={`Check out this #CleanNFT minted on @orbix360 with #tezos : ${token.title}`}>
                                    <FacebookIcon size={40} round crossOrigin={undefined} path={undefined} />
                                </FacebookShareButton>
                            </S.ShareButton>

                            <S.ShareButton>
                                <TwitterShareButton url={`${APP_CONFIG.SHORT_URL_ROOT}/collection/${contract}/token/${tokenId}`} title={`Check out this #CleanNFT minted on @orbix360 with #tezos : ${token.title}`}>
                                    <TwitterIcon size={40} round crossOrigin={undefined} path={undefined} />
                                </TwitterShareButton>
                            </S.ShareButton>
                        </Box>
                    </S.Share>

                    <Divider />
                    
                    <Box px={4} mt={2}>
                        <Grid item>
                            <Properties system={system} token={token} />
                        </Grid>
                    </Box>
                            
                    <SaleToken 
                        open={open}
                        handleClose={handleClose}
                        contract={contract} 
                        tokenId={tokenId}    
                        token={token}                
                    />  

                    <BuyToken 
                        open={openBuyModal}
                        handleClose={() => setOpenBuyModal(false)}
                        token={token}
                    />

                    <TransferToken 
                        open={openTransferModal}
                        handleClose={() => setOpenTransferModal(false)}
                        contract={contract} 
                        tokenId={tokenId}
                    />

                    <BurnToken 
                        open={openBurnTokenModal}
                        handleClose={() => setOpenBurnTokenModal(false)}
                        contract={contract} 
                        tokenId={tokenId}
                    />

                    { token.sale && (
                        <CancelToken 
                            open={openCancelModal} 
                            handleClose={() => setOpenCancelModal(false)}
                            // TODO - CHANGE CONTRACT TO GET FROM SALEDATA
                            contract={token.sale.address}
                            tokenId={token.tokenId}
                            saleId={token.sale.saleId}
                            saleType={token.sale?.type || ''}
                        /> 
                    )}
                </>
            </Section>
        </S.Wrapper>
    );
}