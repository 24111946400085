import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { RecButton, inputButtonStyles } from '../styles';

export const Container = styled.div`
    ${({ theme }) => css`    
        padding: 64px 32px 10px;
        background: white;

        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;        

        span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;

            color: #FFFFFF;        

            ${theme.breakpoints.down('sm')} {
                margin-bottom: 24px;
            }    
        }

        input, textarea {
            background: #000;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
            border-radius: 10px;    
            padding: 10px 8px;
            width: 100%;    

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;                
        }

        input + input, input + button {
            margin-left: 10px;            
        }

        textarea {
            margin-bottom: 10px;
        }

        margin-bottom: 64px;
        
        ${theme.breakpoints.down('sm')} {
            display: flex;
            flex-direction: column;
            align-items: center;            
            
            padding: 24px;
            margin-bottom: 0;
        }
    `}
`;

export const Title = styled.h2`
    ${({ theme }) => css`
        color: black;

        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;  

        margin-bottom: 24px;

        text-align: center;
        text-transform: uppercase;
        
        ${theme.breakpoints.down('sm')} {
            margin-bottom: 24px;
            text-align: center;

            span {
                margin-bottom: 24px;
            }
        }
    `}
`;

export const SubmitBox = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;

        margin-bottom: 40px;

        ${theme.breakpoints.down('sm')} {
            align-items: center;
        }
    `}
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    &:before, &:after{
        content: "";
        flex: 1 1;
        border-bottom: 2px solid #c4c4c4;
        margin: auto;
    }
    
    &:before {
        margin-right: 10px
    }

    &:after {
        margin-left: 10px
    }
`;

export const SocialBox = styled.div`
    display: flex;

    svg + svg {
        margin-left: 12px;
    }

    margin-bottom: 36px;
`;

export const Email = styled.input`
    display: flex;
    align-items: center;

    text-transform: uppercase;

    ${inputButtonStyles};

    padding-left: 40px;
    margin-right: 24px;
`;

export const MailBox = styled(Box)`
    ${({ theme }) => css`
        display: flex;    
        margin-bottom: 10px;

        ${theme.breakpoints.down('sm')} {
            width: 100%;
            align-items: center;
            flex-direction: column;

            input {
                margin-bottom: 16px;
                width: 100%;
            }

            margin-bottom: 40px;
        }
    `}
`;

export const Sections = styled.div`
    ${({ theme }) => css`
        display: flex;
        overflow: auto;
        justify-content: space-between;
        
        flex-direction: column;
        align-items: center;
        width: 100%; 
        
        margin-bottom: 24px;

        ${theme.breakpoints.down('sm')} {
            flex-direction: column;
            align-items: center;
            width: 100%; 
            
            margin-bottom: 24px;
        }
    `}
`;

export const Image = styled.div`
    ${({ theme }) => css`
        position: relative;

        img {
            width: 100%;
            height: 450px;
        }

        margin-bottom: 48px;

        .arrow-left {
            position: absolute;
            top: 50%;
            left: 24px;

            &:hover {
                cursor: pointer;
            }
        }

        .arrow-right {
            position: absolute;
            top: 50%;
            right: 24px;

            &:hover {
                cursor: pointer;
            }
        }
        
        ${theme.breakpoints.down('sm')} {
            img {
                height: 225px;
            }

            .arrow-left {
                left: 8px;
            }

            .arrow-right {
                right: 8px;
            }

            svg {
                width: 36px;
                height: 36px;
            }
        }
    `}
`;

export const Button = styled(RecButton)`
    ${({ theme }) => css`
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 10px 10px 0px 0px;

        background: #000;
        color: #fff;

        width: 100%;

        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 10px;      
                        
        & + & {
            margin-left: 0;
            margin-top: 8px;
        }
        
        ${theme.breakpoints.down('sm')} {
            width: 100%;

            border: 1px solid #C4C4C4;
            box-sizing: border-box;
            border-radius: 10px;      
                            
            & + & {
                margin-left: 0;
                margin-top: 12px;
            }
        }
    `}
`;

export const RoundButton = styled(RecButton)`
    background: #000;
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 10px;

    color: #fff;
    font-weight: 400;

    padding: 10px 0;
`;

export const ModalBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: rgb(255, 255, 255);    
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    border: 1px solid #333;
    padding: 32px;    
`;