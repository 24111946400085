import React from 'react';
import { Link, useLocation } from 'wouter';

import { connectWallet, disconnectWallet } from 'reducer/async/wallet';
import { useDispatch } from 'reducer';
import { SystemWithWallet } from 'lib/system';

import useAuth from 'hooks/auth';

import * as S from './styles';

const Topbar = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
	const dispatch = useDispatch();  
	const { user, signIn, signOut } = useAuth();

	const [, setLocation] = useLocation();

	async function handleConnectWallet() {
		const response = await dispatch(connectWallet());
		const payload = response.payload as SystemWithWallet;
		
		if (payload && payload.status === "WalletConnected") {
			const wallet = payload.tzPublicKey!;
			await signIn({ wallet });
		}
	}

	async function handleDisconnectWallet() {
		await dispatch(disconnectWallet()); 
		setLocation('/marketplace');

		signOut();
	}

	return (
		<S.GTCOHeaderNavbar className="custom-topbar">
			<S.Container>
				<Link href="/marketplace/edit-trax">
					<a href="/marketplace/edit-trax">
						<img src="/assets/edit-trax/et_new_logo.png" alt="" />
					</a>
				</Link>

			{ user ? (
				<S.Button onClick={handleDisconnectWallet}>
					Disconnect
				</S.Button>                
				) : (
				<S.Button onClick={handleConnectWallet}>
					Connect
				</S.Button>
			)}
			</S.Container>
		</S.GTCOHeaderNavbar>
	);
};

export default Topbar;
